<template>
    <v-row justify="center">
    <v-dialog
        v-model="component.dialogEnterprisesValidation"
        width="430"
        >

        <v-card text-center>
            <v-card-title class="text-h6 grey lighten-2">
            Erro de Validação
            </v-card-title>

            <v-card-text style="font-size: 18px;">
            Usuário operador de caixa não estar associado a mais de 1 empresa
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="component.dialogEnterprisesValidation = false"
            >
                Ok
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
      component: Object,
    },
  };
</script>