const ADD_ENTERPRISE = "ADD_ENTERPRISE";
const UPDATE_ENTERPRISE = "UPDATE_ENTERPRISE";
const GET_ALL_ENTERPRISE = "GET_ALL_ENTERPRISE";
const DESTROY_ENTERPRISE = "DESTROY_ENTERPRISE";
const SELECTED_ENTERPRISE = "SELECTED_ENTERPRISE";
const GET_FILTER_ENTERPRISE = "GET_FILTER_ENTERPRISE";
const AUX_TO_UPDATE_ENTERPRISE = "AUX_TO_UPDATE_ENTERPRISE";
const VIEW_ENTERPRISE = "VIEW_ENTERPRISE";

module.exports = {
  GET_FILTER_ENTERPRISE,
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
  GET_ALL_ENTERPRISE,
  DESTROY_ENTERPRISE,
  SELECTED_ENTERPRISE,
  AUX_TO_UPDATE_ENTERPRISE,
  VIEW_ENTERPRISE,
};
