<template>
  <v-row>
    <v-card-title primary-title>Nota Fiscal</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      
    </v-col>
    <!-- exemplo de como deve aplicar as permissoes na view -->
   
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
//   v-if="component.permission.Cadastrar"
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";

export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    enterprises: Array(),
  }),

  created() {
    this.GetAll();
  },

  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    open_Dialog() {
      this.alterAddDialog(this.getComponent());
    },
    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val.data);
      this.selectValue = this.enterprises;
    },
  },
};
</script>

<style></style>
