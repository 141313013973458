const ADD_TAX_OPERATION_TYPE = "ADD_TAX_OPERATION_TYPE";
const UPDATE_TAX_OPERATION_TYPE = "UPDATE_TAX_OPERATION_TYPE";
const GET_ALL_TAX_OPERATION_TYPE = "GET_ALL_TAX_OPERATION_TYPE";
const DESTROY_TAX_OPERATION_TYPE = "DESTROY_TAX_OPERATION_TYPE";
const GET_FILTER_TAX_OPERATION_TYPE = "GET_FILTER_TAX_OPERATION_TYPE";
const AUX_TO_UPDATE_TAX_OPERATION_TYPE = "AUX_TO_UPDATE_TAX_OPERATION_TYPE"

module.exports = {
   AUX_TO_UPDATE_TAX_OPERATION_TYPE,
   ADD_TAX_OPERATION_TYPE ,
   UPDATE_TAX_OPERATION_TYPE,
   GET_ALL_TAX_OPERATION_TYPE,
   DESTROY_TAX_OPERATION_TYPE,
   GET_FILTER_TAX_OPERATION_TYPE
};
