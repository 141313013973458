const OPERATION_SAIDA = "1";
const DANFE_NORMAL = "1";
const CODE_VERIFICATION_NOTE = "1";
const OPERATION_INTERNAL = 1;
const OPERATION_EXTERNAL = 2;
const OPERATION_EXPORT = 3;

let identifyNFe = {
  keyAccess: "",
  status: "",
  codeUfEmmiter: "",
  descriptionNatureOperation: "",
  codModelSupervisior: "",
  identifyOperationDestination: "",
  seriesDocumentSupervisior: "",
  numberNfe: "",
  issuanceDateNfe: "",
  issuanceDepartureDate: "",
  operationTypeNfe: "",
  codeCountyOperation: "",
  paperFormart: "",
  typeIssueNfe: "",
  typeKeyAcessVerifier: "",
  environmentalIdentification: "",
  issuePurpose: "",
  endConsumerOperation: "",
  buyerPresenceEstablishment: "",
  indicatorIntermediaryEstablishment: "",
  noteIssuingProcess: "",
  versionOfIssueProcess: "",
  dateAndTimeEntryContingency: "",
  contingencyEntryJustification: "",
  referencedAccessKey: "",
  qrCodeNf: "",
  urlChaveNf: "",
  sinatureNf: "",
  valueBaseTotalCalculeIcms: "",
  valueTotalCalculeIcms: "",
  valueTotalProduct: "",
  valueTotalNF: "",
  valueApproximateTax: "",
  paymentMethodIndicator: "",
  meansOfPayment: "",
  valuePaymentAmount: "",
  typeOfIntegration: "",
  cnpjInstitutionPayment: "",
  valueThing: "",
  shippingmode: "",
  infoSupplementaryTax: ""
};
async function execute(revenues) {
  try {
      identifyNFe.keyAccess = revenues.access_key,
      identifyNFe.status = revenues.status,
      identifyNFe.codeUfEmmiter = String(String(revenues.enterprise.address.city_id).substr(0, 2)),
      identifyNFe.descriptionNatureOperation = revenues.tax_operation.description,
      identifyNFe.codModelSupervisior = 55,
      identifyNFe.identifyOperationDestination = rangeOperationNFe(revenues.tax_operation.code),
      identifyNFe.seriesDocumentSupervisior = revenues.series,
      identifyNFe.numberNfe = String(revenues.number_grade),
      identifyNFe.issuanceDateNfe = await formaterDateSales(
        revenues.date_of_issue
      ),
      (identifyNFe.issuanceDepartureDate = await formaterDateSales(
        revenues.date_of_issue
      )),
      identifyNFe.operationTypeNfe = OPERATION_SAIDA,
      identifyNFe.codeCountyOperation = "3205200",
      identifyNFe.paperFormart = DANFE_NORMAL,
      identifyNFe.typeIssueNfe = OPERATION_SAIDA,
      identifyNFe.typeKeyAcessVerifier = CODE_VERIFICATION_NOTE,
      identifyNFe.environmentalIdentification =revenues.enterprise.note_issuing_environment,
      identifyNFe.issuePurpose = parseInt(revenues.finality_nf), //finalidade da emissão
      identifyNFe.endConsumerOperation = revenues.cliente.type_people == "PhysicalPeople" ? "1" : "0",
      identifyNFe.buyerPresenceEstablishment = 1,
      identifyNFe.indicatorIntermediaryEstablishment = "0",
      identifyNFe.noteIssuingProcess = "0",
      identifyNFe.versionOfIssueProcess = "4.00",
      identifyNFe.dateAndTimeEntryContingency = "",
      identifyNFe.contingencyEntryJustification = "",
      identifyNFe.referencedAccessKey = "",
      identifyNFe.qrCodeNf = "",
      identifyNFe.urlChaveNf = "",
      identifyNFe.sinatureNf = "",
      identifyNFe.valueBaseTotalCalculeIcms = revenues.base_calc_icms_total,
      identifyNFe.valueTotalCalculeIcms = revenues.value_icms_total,
      identifyNFe.valueTotalProduct = revenues.value_total_products,
      identifyNFe.valueTotalNF = revenues.value_total_note,
      identifyNFe.valueApproximateTax = revenues.value_icms_total,
      identifyNFe.paymentMethodIndicator = String(checkPaymentMethodIndicator(revenues.payment_terms.form_of_payment.code)),
      identifyNFe.meansOfPayment = revenues.payment_terms.form_of_payment.code,
      identifyNFe.valuePaymentAmount = revenues.value_total_note,
      identifyNFe.typeOfIntegration = "2",
      identifyNFe.cnpjInstitutionPayment = "",
      identifyNFe.valueThing = "0",
      identifyNFe.shippingmode = "";
      identifyNFe.infoSupplementaryTax = revenues.note

  
   return identifyNFe;
  } catch (error) {
    return error;
  }
}

function checkPaymentMethodIndicator(code){
  if (code == "01") {
    return  1;
  } else {
    return 0;
  }
}
function rangeOperationNFe(codeCfop) {
  if (codeCfop >= 5100 && codeCfop <= 5415) {
    return OPERATION_INTERNAL
  } else if (codeCfop >= 6100 && codeCfop <= 6415) {
    return OPERATION_EXTERNAL;
  } else if (codeCfop >= 7100 && codeCfop <= 7415) {
    return OPERATION_EXPORT;
  } else if (codeCfop >= 5929 && codeCfop <= 5929) {
    return OPERATION_INTERNAL
  } else if (codeCfop >= 6929 && codeCfop <= 6929) {
    return OPERATION_EXTERNAL;
  }

}

async function formaterDateSales(dateRevenues) {
  let dataFormat = new Date(dateRevenues);
  let year = dataFormat.getFullYear();
  let day = dataFormat.getDate();
  let month = dataFormat.getMonth() + 1;
  let hours = dataFormat.getHours();
  let minutes = dataFormat.getMinutes();
  let seconds = dataFormat.getSeconds();

  if (day < 10) {
    day = `0${day}`;
  } else {
    day = day.toString();
  }
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = month.toString();
  }

  if (hours < 10) {
    hours = `0${hours}`;
  } else {
    hours = hours.toString();
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  } else {
    minutes = minutes.toString();
  }

  if (seconds < 10) {
    seconds = `0${seconds}`;
  } else {
    seconds = seconds.toString();
  }

  let dateTimeFormat =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;

  return dateTimeFormat;
}

module.exports = {
  execute,
};
