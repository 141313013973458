<template>
    <div>
      <v-dialog persistent width="1200" v-model="component.dialogChangeFormOfPayment">
        <v-card class="d-flex justify-center">
          <v-col>
            <v-card-title>Alteração de Forma de Pagamento</v-card-title>
            <v-divider class="mb-5" />
            <v-container>
            <v-data-table
            :headers="header"
            :items="component.changeFormOfPaymentData.sale_form_of_payment"
            hide-default-footer
            no-data-text="Nenhum resultado encontrado"
            no-results-text="Nenhum resultado encontrado"
             >
             <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="updateFormOfPayment(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
             </v-data-table>
            </v-container>
              <v-divider class="mt-1" />
              <v-card-actions class="d-flex justify-end">
                <v-btn @click="closeDialog()" class="error">Sair</v-btn>
                <v-btn @click="" color="primary"
                  >Confirmar</v-btn
                >
              </v-card-actions>
          </v-col>
        </v-card>
      </v-dialog>
      <UpdateDialog v-if="updateDialog" :component="this"/>
    </div>
  </template>
  
  <script>
  import UpdateDialog from "./UpdateDialog/index.vue"
  import { CHANGE_FORM_OF_PAYMENT } from "../../../../store/actions/taxCupom"
  import Vue from 'vue';
  export default {
    components: {
        UpdateDialog,
    },
    props: {
      component: Object(),
    },
    data: function () {
      return {
        header:[
            {text:"Forma de pagamento", value:"formofpayment.description"},
            {text:"Condição de pagamento", value:"payment_term.description"},
            {text:"Valor do pagamento", value:"value_form_payment"},
            {text:"Ações", value:"actions"},
        ],
        updateData: Object(),
        updateDialog: false,
        newFormOfPayment: Object(),
        billstoReceive: Array(),
      };
    },
    methods: {

      updateFormOfPayment(item) {
        this.updateData = item

        this.newFormOfPayment.value_form_payment = item.value_form_payment
        this.updateDialog = true
      },

      closeDialog() {
        this.component.getAllTaxCupon(this.component.current)
        this.component.dialogChangeFormOfPayment = false
      },
      
      tableItemUpdate() {        
        for(let i=0; i<this.component.changeFormOfPaymentData.sale_form_of_payment.length; i++) {
          if(this.component.changeFormOfPaymentData.sale_form_of_payment[i] == this.updateData) {
            const clonedValue = this.cloneDeep(this.newFormOfPayment);
            Vue.set(this.component.changeFormOfPaymentData.sale_form_of_payment, i, clonedValue);
            this.submit(this.component.changeFormOfPaymentData.sale_form_of_payment[i])
          }
        }
        this.updateData = {}
        this.updateDialog = false
      },

      cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    calculeCashPayment(payment, sales) {
      const AVISTA = 0;
      let salesBillsToReceive = { ...sales };
      let paymentFormPayment = payment.payment_term.deadline;
      if (paymentFormPayment == AVISTA) {
        sales.paymentMethodIndicator = "0";
        let BillsToReceive = this.mounterBillsToReceiveWithInCash(
          salesBillsToReceive.data_sale,
          salesBillsToReceive.number_sale,
          salesBillsToReceive.value_total_note,
          salesBillsToReceive.client_id,
          salesBillsToReceive.enterprise_id
        );
        return BillsToReceive;
      } else {
        sales.paymentMethodIndicator = "1";
        let BillsToReceive = this.mounterPaymentDeferredPayment(
          payment,
          salesBillsToReceive
        );
        return BillsToReceive;
      }
    },

    mounterBillsToReceiveWithInCash(
      issuanceDate,
      number_sale,
      value_total_note,
      client_id,
      enterprise_id
    ) {
      let billstoReceiveInCashArray = [];
      let dateObject = new Date(issuanceDate)
      let auxdate = dateObject.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let issuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];
      const STATUS_BAIXADO = "BAIXADO";
      const TITLE_TITULO = "DINHEIRO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_BAIXADO;
      billstoReceiveInCash.issuance_date = issuanceDateFormat;
      billstoReceiveInCash.issuance_due = issuanceDateFormat;
      billstoReceiveInCash.type_title = TITLE_TITULO;
      billstoReceiveInCash.number_title = `${number_sale}/${1}`;
      billstoReceiveInCash.value_title = value_total_note;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = 0;
      billstoReceiveInCash.title_balance = 0;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);

      billstoReceiveInCashArray.push(billstoReceiveInCash);
      return billstoReceiveInCashArray;
    },

    mounterPaymentDeferredPayment(payment, salesAttributes) {
      let deadline = payment.payment_term.deadline;
      let numberOfInstallmentsPaymentForm = payment.payment_term.amount;
      let valueTotalSales = salesAttributes.value_total_note;
      let valuePaymentDebTotal = parseFloat(valueTotalSales).toFixed(2);
      let valueOfPortion = parseFloat(
        (valuePaymentDebTotal / numberOfInstallmentsPaymentForm).toFixed(2)
      );
      let calculeValueTotalPendingOfDivision =
        valueOfPortion * numberOfInstallmentsPaymentForm;

      let billstoReceiveArray = [];
      let portion = 1;
      let aux_deadline = deadline.split("/");
      let deadlineArray = aux_deadline.map((item) => parseInt(item));
      for (portion = 1; portion <= numberOfInstallmentsPaymentForm; portion++) {
        let billsToReceiveCalcule = this.mounterBillsToReceiveWithOnTerm(
          salesAttributes.data_sale,
          salesAttributes.number_sale,
          portion,
          deadlineArray[portion - 1],
          valueOfPortion,
          salesAttributes.enterprise_id,
          salesAttributes.client_id
        );
        billstoReceiveArray.push(billsToReceiveCalcule);
      }

      if (calculeValueTotalPendingOfDivision != valuePaymentDebTotal) {
        let remaining =
          valuePaymentDebTotal - calculeValueTotalPendingOfDivision;

        billstoReceiveArray = this.calculateDiferenceInValueBillstoReceive(
          billstoReceiveArray,
          remaining
        );
        return billstoReceiveArray;
      }

      return billstoReceiveArray;
    },

    mounterBillsToReceiveWithOnTerm(
      dateIssuanceDate,
      number_sale,
      portation,
      deadline = 0,
      valueOfPortion,
      enterprise_id,
      client_id
    ) {
      let dateObject = new Date(dateIssuanceDate)
      let auxdate = dateObject.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let dateIssuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];

      const STATUS_ABERTO = "ABERTO";
      const TYPE_TITLE = "RECEBIMENTO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_ABERTO;
      billstoReceiveInCash.issuance_date = dateIssuanceDateFormat;
      billstoReceiveInCash.issuance_due =
        this.generateOfMaturityBillsToReceivce(
          dateIssuanceDate,
          deadline,
          portation
        );
      billstoReceiveInCash.type_title = TYPE_TITLE;
      billstoReceiveInCash.number_title = `${number_sale}/${portation}`;
      billstoReceiveInCash.value_title = valueOfPortion;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = valueOfPortion;
      billstoReceiveInCash.title_balance = valueOfPortion;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);
      return billstoReceiveInCash;
    },

    generateOfMaturityBillsToReceivce(dateReceive, deadline) {
      let dateObject = new Date(dateReceive);
      let auxdate = dateObject.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let stringFormatada =
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0] +
        "-" +
        arrDataExclusao[2];
      let day = 0;
      let month = 0;
      let dateNew = new Date(stringFormatada);
      dateNew.setDate(dateNew.getDate() + deadline);

      day = dateNew.getDate();
      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }

      month = dateNew.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }
      let final_date = `${dateNew.getFullYear()}-${month}-${day}`;

      return final_date;
    },

    calculateDiferenceInValueBillstoReceive
    (billstoReceiveArray, remaining) {
      for (let index = 0; index < billstoReceiveArray.length; index++) {
        let valueIndexLastInstallment = index + 1;
        if (valueIndexLastInstallment === billstoReceiveArray.length) {
          billstoReceiveArray[index].value_title += remaining;
          billstoReceiveArray[index].value_net =
            billstoReceiveArray[index].value_title;
          billstoReceiveArray[index].title_balance =
            billstoReceiveArray[index].value_title;
        }
      }
      return billstoReceiveArray;
    },


    async submit(sale_form_of_payment) {
        const payload = {
          sale_id: this.component.changeFormOfPaymentData.id,
          enterprise_id: this.component.changeFormOfPaymentData.enterprise_id,
          form_of_payments: {
                form_of_payment_id: sale_form_of_payment.formofpayment.id,
                payment_term_id: sale_form_of_payment.payment_term.id,
                sale_form_of_payment_old: this.updateData.id,
                value_form_payment: sale_form_of_payment.value_form_payment,
          },
        }
        let dueDateBillstoReceive = this.calculeCashPayment(sale_form_of_payment, this.component.changeFormOfPaymentData);
        this.billstoReceive = dueDateBillstoReceive;
        payload.billsto_receive = { ...this.billstoReceive };
        this.billstoReceive = []
        await this.$store.dispatch(CHANGE_FORM_OF_PAYMENT, payload).then(() => {
          this.closeDialog()
      });
    }
    },

  };
  </script>
  
  <style>
  </style>