<template>
  <v-row justify="center">
    <v-dialog
      persistent
      max-width="400"
      v-model="getShowDialogRegisterCloseCashier"
      transition="fab-transition"
    >
      <v-card outlined>
        <v-card-title>Fechamento de Caixa</v-card-title>
        <v-card-subtitle>Preencha os dados.</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-data-table
            :headers="header"
            :items="items"
            no-data-text="Sem registro"
            hide-default-footer
          >
            <template v-slot:item.price="{ item }">
              <v-text-field
                class="mt-3"
                dense
                v-model="item.price"
                filled
                outlined
                prefix="R$"
                placeholder="0,00"
              >
              </v-text-field>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row justify="center">
              <v-btn @click="closeDialog" class="error mr-1">Cancelar</v-btn>
              <v-btn class="success" @click="test()">Confirmar</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters([
    'getShowDialogRegisterCloseCashier'
    ])
  },
  data: () => ({
    items: [
      { Payment_of_form: "Credito", price: "" },
      { Payment_of_form: "Débito", price: "" },
    ],
    header: [
      { text: "Forma de Pagamento", value: "Payment_of_form" },
      { text: "Valor (R$)", value: "price" },
    ],
  }),
  methods: {
    closeDialog(){
      this.$store.dispatch('setShowDialogRegisterCloseCashier', false)
    }
  },
};
</script>

