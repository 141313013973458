


var rules = [
    (v) => !!v || "Campo Obrigatório"
]


module.exports = {
    rules
}