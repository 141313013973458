<template>
  <div>
    <v-form ref="form" v-model="formValidate">
      <v-container v-for="(product, idx) in arrayProduct" :key="idx">
        <v-row class="d-flex justify-center">
          <v-card-title v-if="product.isRegistered" class="blue--text">
            Item {{ idx + 1 }} Cadastrado
            <v-icon class="ml-5" color="blue">mdi-check-bold</v-icon>
          </v-card-title>
          <v-card-title v-else-if="!product.isRegistered" class="red--text">
            Item {{ idx + 1 }} Não Cadastrado
            <v-icon class="ml-5" color="red">mdi-close-thick</v-icon>
          </v-card-title>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-text-field
              outlined
              label="Produto"
              readonly
              v-model="product.description_item"
              dense
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="registerProduct(product.isRegistered, idx)"
            />
          </v-col>
          <v-col>
            <v-text-field outlined label="Quantidade" dense v-model="product.amount" />
          </v-col>
          <v-col>
            <v-text-field
              outlined
              readonly
              prefix="R$"
              label="Custo"
              dense
              v-model="product.Tax.cost_price"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-if="product.cst != undefined"
              :items="arrayCst"
              
              item-text="description"
              item-value="code"
              dense
              v-model="product.Tax.cst"
              label="CST"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="setShowGlobalDialog('cst')"
             
              outlined
            />
            <v-autocomplete
              v-else
              :items="arrayCsosn"
              
              item-text="description"
              item-value="code"
              dense
              v-model="product.Tax.csosn"
              label="CSOSN"
              append-outer-icon="mdi-plus-circle"
             
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field outlined suffix="%" label="IPI" dense v-model="product.Tax.ipi" />
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              
              label="CST/PIS"
              v-model="product.Tax.pis"
              :items="arrayPisCofins"
              item-text="description"
              item-value="code"
              dense
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="setShowGlobalDialog('cst/pis')"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              
              label="CST/COFINS"
              v-model="product.Tax.cofins"
              :items="arrayPisCofins"
              item-text="description"
              item-value="code"
              dense
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="setShowGlobalDialog('cst/cofins')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete
              outlined
              
              label="Alíquota"
              v-model="product.Tax.aliquot"
              :items="arrayAliquots"
              item-text="description"
              item-value="aliquot"
              dense
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="setShowGlobalDialog('aliquot')"
             
            />
          </v-col>
          <v-col>
            <v-autocomplete
            
              label="CFOP"
              :items="arrayCfop"
              item-text="description"
              outlined
              dense
              :rules="rulesCFOP"
              return-object
              v-model="product.Tax.cfop"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="setShowGlobalDialog('cfop')"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <div class="divider" />
      </v-container>
      <v-row class="d-flex justify-center">
        <v-btn
          class="ma-5 white--text"
          color="success"
          @click="registerItems"
          :disabled="enableButtom"
        >
          Cadastrar itens
          <v-icon class="ml-5" color="white">mdi-plus</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-data-table :headers="header" :items="getNoteEntryItensArray">
                 <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          color="red darken-4"
          @click="
            () => {
              actionDelete(item);
            }
          "
          >mdi-delete</v-icon
        >
        <v-icon
          class="mr-2"
          color="yellow darken-4"
          @click="
            () => {
              actionEdit(item);
            }
          "
          >mdi-pencil</v-icon
        >
      </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <RegisterProduct :out_of_register="true" />
      <RegisterCST />
      <RegisterPisCofins/>
      <RegisterAliquot/>
      <RegisterCFOP/>
    </v-form>
  </div>
</template>

<script>
  
import data from "./helpers/data";
import methods from "./helpers/methods";
import { watch } from "./helpers/watch";
import mapgatters from "./helpers/mapGetters";
import Computed from "./helpers/computed";
import { mapGetters } from "vuex";
import { SET_ITEMS_NOTE } from "../../../../../../../store/actions/import_xml";
import RegisterProduct from "../../../../../../products/AddDialog/index.vue";
import rules,{rulesCFOP} from "./helpers/rules";
import RegisterCST from "../../../../../../cst/AddDialog/index.vue"
import RegisterPisCofins from "../../../../../../pis_cofins/AddDialog/index.vue"
import RegisterAliquot from "../../../../../../aliquot/AddDialog/index.vue"
import RegisterCFOP from "../../../../../../cfop/AddDialog/index.vue"
export default {
  components: {
    RegisterProduct,
    RegisterCST,
    RegisterPisCofins,
    RegisterAliquot,
    RegisterCFOP
  },
  data() {
    return {
      ...data,
      rules, rulesCFOP
    };
  },
  computed: {
    ...mapGetters(mapgatters),
    ...Computed
  },
  methods: {
    ...methods
  },
  watch: {
    ...watch
  },
  props: {
    component: Object()
  },
  created() {
    
    this.arrayProduct = [...this.getItemNotes];
    
    this.sendInformationNote()
    // this.setGettersObjectToProduct(this.arrayProduct);
    // this.arrayProduct = [...this.arrayProduct];
  },
  destroyed() {
    this.arrayProduct = null;
  },
  beforeDestroy() {
    this.getNoteEntryItensArray = Array();
    this.$store.state.note_entry_itens.noteEntryItens = Array()
    this.$store.commit(SET_ITEMS_NOTE, Array());
  }
};
</script>

<style>
.divider {
  width: 100vw;
  height: 2.5px;
  border-radius: 90vw;
  background-color: grey;
}
</style>