<template>
  <a>
    <div id="card">
      <v-col>
        <v-row class="d-flex justify-center">
          <v-img :src="image" height="100px" contain />
        </v-row>
        <v-row class="d-flex justify-center fontTitle">{{title}}</v-row>
      </v-col>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    image: String(),
    title: String()
  }
};
</script>

<style >
:root {
  --my-primary-color: #cfd6e6;
}

@keyframes animatorColor {
  from {
    transform: translateX(500px);
  }
  to {
    transform: translateX(0);
  }
}
.fontTitle {
  font-size: 20px;
}
#card {
  box-shadow: 0px 0px 0px 0px grey;
  padding: 30px;
  background-color: var(--my-primary-color);
  display: flex;
  animation: animatorColor 2s forwards;
  align-items: center;
  border-radius: 10px;
  
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  font-weight: bold;
  color: black;
}
#card:hover {
  background-color: #335c67;
  color: white;
  box-shadow: 0px 0px 20px 5px #6c757d;
}

@media (max-width: 600px) {
  #card {
    padding: 30px;
    background-color: var(--my-primary-color);
    display: flex;
    animation: animatorColor 2s forwards;
    width: 80vw;
    align-items: center;
    border-radius: 10px;
  }
}
</style>