const GET_ALL_MOVESTOCK = 'GET_ALL_MOVESTOCK'
const UPDATE_ALL_MOVESTOCK = 'UPDATE_ALL_MOVESTOCK'
const DESTROY_ALL_MOVESTOCK = 'DESTROY_ALL_MOVESTOCK'
const ADD_ALL_MOVESTOCK = 'ADD_ALL_MOVESTOCK'
const GET_FILTER_MOVESTOCK = 'GET_FILTER_MOVESTOCK'

module.exports = {
    GET_ALL_MOVESTOCK,
    UPDATE_ALL_MOVESTOCK,
    DESTROY_ALL_MOVESTOCK,
    ADD_ALL_MOVESTOCK,
    GET_FILTER_MOVESTOCK,
}