<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="3"
        v-for="(image, index) in listImages"
        :key="image.title"
      >
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :to="image.path"
            class="card rounded-lg"
            :color="listColors[index]"
            :elevation="hover ? 16 : 2"
          >
            <div>
              <v-col class="d-flex justify-center">
                <v-img
                  class="mt-3"
                  max-height="230"
                  max-width="100"
                  :src="image.image"
                />
              </v-col>
              <v-col>
                <p>{{ image.title }}</p>
              </v-col>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BillsReceive from "../assets/billsReceive.png";
import BillsPay from "../assets/billstopay.png";
import Clients from "../assets/Clientes.png";
import Provider from "../assets/Fornecedor.png";
import Product from "../assets/Produto.png";
import Sellers from "../assets/Vendas.png";

import EntryNote from "../assets/entrada_notas.png";
import Invoicing from "../assets/faturamento.png";
import TaxBook from "../assets/livro_fiscal.png";
import CaxierMoviment from "../assets/Movimentacao_caixa.png";
import BalanceStock from "../assets/saldo_estoque.png";

export default {
  data: function () {
    return {
      paleteColors: [
        "#ffadad",
        "#ffd6a5",
        "#fdffb6",
        "#caffbf",
        "#9bf6ff",
        "#a0c4ff",
        "#bdb2ff",
        "#ffc6ff",
        //entrada de uma paleta de cor
        "#f94144",
        "#f3722c",
        "#f8961e",
        "#f9844a",
        "#f9c74f",
        "#90be6d",
        "#43aa8b",
        "#4d908e",
        "#577590",
        "#277da1",
      ],
      listColors: Array(),
      listImages: [
        { image: Clients, path: "/client", title: "Clientes" },
        { image: Provider, path: "/provider", title: "Fornecedor" },
        { image: Product, path: "/product", title: "Produto" },
        {
          image: BalanceStock,
          path: "/Stock_balance",
          title: "Saldo de Estoque",
        },
        { image: EntryNote, path: "/note_entry", title: "Entrada de Nota" },
        { image: Invoicing, path: "/revenues", title: "Faturamento" },
        { image: BillsPay, path: "/bills_pay", title: "Contas a Pagar" },
        {
          image: BillsReceive,
          path: "/bills_receive",
          title: "Contas a Receber",
        },
        {
          image: CaxierMoviment,
          path: "/financial_movement",
          title: "Movimentação de Caixa",
        },
        { image: TaxBook, path: "/BookSupervisionOut", title: "Livro Fiscal" },
        { image: Sellers, path: "/seller", title: "Vendedor" },
        {
          image: BalanceStock,
          path: "/move_stock",
          title: "Movimentação de Estoque",
        },
      ],
    };
  },
  methods: {
    setColors() {
      for (let index = 0; index < this.listImages.length; index++) {
        this.listColors.push(
          this.paleteColors[
            Math.floor(Math.random() * this.paleteColors.length)
          ]
        );
      }
    },
    onKey(e) {
      let keycode = e.key;
      switch (keycode) {
        case "F1":
          this.$router.push("Client").catch(() => {});
          break;
        case "F2":
          this.$router.push("produtos").catch(() => {});
          break;
        case "F3":
          this.$router.push("Seller").catch(() => {});
          break;
        case "F4":
          this.$router.push("Sales").catch(() => {});
          break;
        case "F8":
          this.$router.push("Notes").catch(() => {});
          break;
        case "F9":
          this.$router.push("users").catch(() => {});
          break;
      }
    },
  },
  created() {
    this.setColors();
  },
  mounted() {
    document.addEventListener("keyup", this.onKey);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  },
};
</script>

<style>
.card {
  width: 400px;
  height: 220px;
  margin-top: 2px;
  /* align-self: center */
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleMain {
  font-family: "Courier New", Courier, monospace;
  padding-top: 20px;
  color: white;
  font-size: 1.8rem;
}
.subtitleMain {
  margin-top: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1.7rem;
  color: white;
}
p {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
}
</style>