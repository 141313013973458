<template>
  <div>
    <!-- computador tablet -->
    <div class="d-none d-sm-flex d-lg-flex d-xl-flex">
      <v-col>
        <v-form v-model="validation.formPagePC" ref="formPC">
          <v-row>
            <v-col
              ><v-card-title class="greyFont mt-3">
                Baixa de XML Emitido</v-card-title
              ></v-col
            >
            <v-col>
              <v-row class="d-flex justify-end mt-3">
                <div class="select">
                  <v-select
                    dense
                    v-model="enterpriseSelected"
                    :items="listEmterprise"
                    item-text="fantasyName"
                    item-value="id"
                    outlined
                    label="Empresa"
                  />
                </div>
                <div class="vbtndownload">
                  <v-btn
                    @click="downloadXML"
                    color="success"
                    class="mb-1"
                    width="100%"
                    >Baixar</v-btn
                  >
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" sm="4" md="4" xl="4" lg="4">
              <v-text-field
                class="ma-2"
                outlined
                label="Data Inicial"
                type="date"
                v-model="formState.initialDate"
                :rules="rules"
                @blur="getFinalDate"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" xl="4" lg="4">
              <v-text-field
                class="ma-2"
                outlined
                label="Data Final"
                type="date"
                v-model="formState.finalDate"
                :rules="rules"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" xl="4" lg="4">
              <v-select
                class="ma-2"
                :rules="rules"
                outlined
                v-model="formState.nfeType"
                item-text="text"
                item-value="value"
                :items="headers"
                label="Tipo de nota fiscal"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <AlertComponent :active="alert.showAlert" :message="alert.message" />
    </div>
    <!-- celular -->
    <div class="d-flex d-sm-none">
      <v-col>
        <v-form v-model="validation.formPageMobile" ref="formMobile">
          <v-row class="pa-2" justify="center"
            ><v-card-title>Baixa de XML Emitido</v-card-title></v-row
          >
          <v-row class="pa-2"
            ><v-select
              v-model="enterpriseSelected"
              :items="listEmterprise"
              item-text="fantasyName"
              item-value="id"
              outlined
              label="Empresa"
          /></v-row>
          <v-row class="pa-2"
            ><v-btn @click="downloadXML" color="success" block
              >Baixar</v-btn
            ></v-row
          >
          <v-row class="pa-2"><v-divider class="ma-5" /></v-row>
          <v-row class="pa-2"
            ><v-text-field
              v-model="formState.initialDate"
              outlined
              label="Data Inicial"
              type="date"
              :rules="rules"
              @blur="getFinalDate"
          /></v-row>
          <v-row class="pa-2"
            ><v-text-field
              v-model="formState.finalDate"
              outlined
              label="Data Final"
              type="date"
              :rules="rules"
              disabled
          /></v-row>
          <v-row class="pa-2">
            <v-select
              :rules="rules"
              outlined
              v-model="formState.nfeType"
              item-text="text"
              item-value="value"
              :items="headers"
              label="Tipo de nota fiscal"
            />
          </v-row>
          <v-row class="pa-2 d-flex justify-center"
            ><v-btn color="success" @click="research" block
              >Pesquisar <v-icon>mdi-magnify</v-icon></v-btn
            ></v-row
          >
          <v-row class="pa-2"><v-divider class="ma-5" /></v-row>
        </v-form>
      </v-col>
      <AlertComponent :active="alert.showAlert" :message="alert.message" />
    </div>
  </div>
</template>

<script>
import LocalState from "./helpers/localState";
import Methods from "./helpers/methods";
import Watches from "./helpers/watch";

import AlertComponent from "../../alert_api/alert_for_components.vue";

import { getters } from "./helpers/gettersVuex";
import { mapGetters } from "vuex";

export default {
  components: {
    AlertComponent,
  },
  data: function () {
    return {
      ...LocalState,
    };
  },
  methods: {
    ...Methods,
  },
  computed: {
    ...mapGetters(getters),
  },
  watch: {
    ...Watches,
  },
  created() {
    this.getAllEnterprise();
  },
};
</script>

<style>
@import "./css/card_title_css.css";
</style>