const headers = [
    { text: "Data da Emissão", value: "" },
    { text: "Cliente", value: "" },
    { text: "Vendedor", value: "" },
    { text: "Natureza da Operação", value: "" },
    { text: "Ações", value: "actions" },
];

export { headers }

















