let transportInfo = {
    shippingModalityFreight: "", 
    socialReasonTransporter: "",
    addressesTransporter: "",
    siglaUF: "",
    amountVolumeTransported: "",
    speciesVolumeTransported: "",
    numberSeal: "",
    netWeightVolumeTransported: "",
    brandVolumeTransported: "",
  };

  async function execute(revenues) {

    if (revenues.transportNfe.length  === undefined){

      transportInfo.shippingModalityFreight = "9",
      transportInfo.socialReasonTransporter = "",
      transportInfo.addressesTransporter= "",
      transportInfo.siglaUF= "",
      transportInfo.amountVolumeTransported= "",
      transportInfo.speciesVolumeTransported= "",
      transportInfo.numberSeal= "",
      transportInfo.netWeightVolumeTransported= "",
      transportInfo.brandVolumeTransported= ""
 

    }else {
      transportInfo.shippingModalityFreight = revenues.type_freith,
      transportInfo.socialReasonTransporter = revenues.transportNfe.people.legalpeople === null ? revenues.people.physicalpeople.name: revenues.people.legalpeople.socialName,
      transportInfo.addressesTransporter= revenues.transportNfe.people.address[0].street,
      transportInfo.siglaUF= revenues.transportNfe.people.address[0].city.state.abbr,
      transportInfo.amountVolumeTransported= revenues.volume_transported,
      transportInfo.speciesVolumeTransported= revenues.volume_type,
      transportInfo.numberSeal= "",
      transportInfo.netWeightVolumeTransported= revenues.net_weight,
      transportInfo.brandVolumeTransported= revenues.gross_weight
    } 


    return transportInfo

}

module.exports = {
    execute,
  };
  