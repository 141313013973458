<template>
  <div class="body-table">
    <v-text-field class="mt-5" placeholder="Insira Sua pesquisa" outlined append-icon="mdi-magnify" />
    <v-data-table
      :headers="headers"
      disable-pagination
      disable-filtering
      hide-default-footer
      no-data-text="Nenhum Dado Encontrado"
    />
    <v-divider />
    <v-pagination class="mt-5" />
  </div>
</template>

<script>
import { headers } from "./header";
export default {
  components: {},
  props: {},
  computed: {},
  data: () => ({
    headers,
  }),
  methods: {},
  watch: {},
  created() {},
};
</script>

<style>
.body-table {
  display: flex;
  flex-direction: column;
}
</style>