<template>
  <v-row justify="center">
    <v-dialog
      fullscreen
      transition="scale-transition"
      v-model="component.dialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                component.dialog = !component.dialog;
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Registrar Pedido de Compra</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-col>
              <v-divider></v-divider>
              <v-tabs>
                <v-tab href="#tab-1">
                  <v-icon class="mr-2">mdi-file-document</v-icon>Dados
                  Gerais
                </v-tab>
                <v-tab href="#tab-2">
                  <v-icon class="mr-2">mdi-file-find</v-icon>Itens da
                  Nota
                </v-tab>
                <v-tab href="#tab-3">
                  <v-icon class="mr-2">mdi-information</v-icon>Informações da Nota
                </v-tab>
                <v-tab-item value="tab-1">
                  <GeralDate :component="this" />
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <NoteItems :component="this" />
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <Information :component="this" :transporter="purchaseOrder.transporter" />
                </v-tab-item>
              </v-tabs>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    component.dialog = !component.dialog;
                  }
                "
              >
                <v-icon class="mr-2">mdi-cancel</v-icon>Limpar Formulário
              </v-btn>
              <v-btn
                class="success"
                @click="
                  () => {
                    save();
                  }
                "
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GeralDate from "../Tabs/GenerateDate/";
import NoteItems from "../Tabs/NoteItens/index";
import Information from "../Tabs/Info/";
import setModelPurcheseOrder from "./model_purchace_order";
import { ADD_PURCHASEORDER } from "../../../store/actions/purchaseOrder";
import { GET_ALL_CSOSN } from "../../../store/actions/csosn";
import { GET_ALL_CST } from "../../../store/actions/cst";
import { CLEAN_NOTE_ENTRY_ITENS } from "../../../store/actions/note_entry_itens";
import { mapGetters } from "vuex";
import scripts from "../script/business_rule/index.js";

export default {
  components: { GeralDate, Information, NoteItems },
  props: {
    component: Object(),
    objectToForm: Object()
  },
  data: () => ({
    justVisible: Boolean(),
    attrs: false,
    on: false,
    valid: true,
    resultAliquot: null,
    search: String(),
    dialog: false,
    validCST: Boolean(),
    cst: Array(),
    csosn: Array(),
    purchaseOrder: {
      value_ipi: Number(),
      payment_term_id: Number(),
      status: String(),
      data_purchase_order: Date(),
      buyer: String(),
      type_frity: String(),
      transporter: Object(),
      seller: String(),
      value_products: Number(),
      value_freigth: Number(),
      note: "Nota",
      enterprise_id: Number(),
      transporter_id: Number(),
      provider_id: Number(),
      //ainda não setados
      value_other_incidental_expenses: Number(),
      value_outher_tax_total: Number(),
      value_untaxed_tax_total: Number(),
      value_security: Number(),
      type_freigth: String(),
      base_calc_susbt_total: Number(),
      //campos de valores
      base_calc_icms_total: Number(),
      value_icms_total: Number(),
      value_base_icms_st_product: Number(),
      value_icms_substituicao_total: Number(),
      value_account_total: Number(),
      base_calc_ipi_total: Number(),
      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      value_total_products: Number(),
      value_icms_outher_product: Number(),
      value_discont: Number(),
      value_icms_free_total: Number(),
      value_total_note: Number(),
      //Transportadora
      type_frity: String(), 
      value_frity: Number(), 
      transporter_id: String(),
      vehicle_plate: String(),
      volume_transported: Number(),
      gross_weigth: Number(),
      net_weight: Number(),
      volume_type: String(),
      outher_expenses_acess: Number()
    }
  }),
  computed: {
    ...mapGetters(["getCst", "getCsosn", "getNoteEntryItens", "getItemToUpdatePurchaseOrder"])
  },
  watch: {
    getItemToUpdatePurchaseOrder: function(val){
    },
    getNoteEntryItens: function() {
      this.calculate_all();
    }
  },
  methods: {
    ...scripts,
    save() {
      var payload = {
        ...setModelPurcheseOrder(this.purchaseOrder, this.getNoteEntryItens)
      };
      this.$store.dispatch(ADD_PURCHASEORDER, payload).then(() => {
        this.$store.commit(CLEAN_NOTE_ENTRY_ITENS)
        this.component.dialog = !this.component.dialog
      });
    },
    refresh_dates() {
      this.cst = Object.keys(this.getCst).map(key => this.getCst[key]);
      this.csosn = Object.keys(this.getCsosn).map(key => this.getCsosn[key]);
    },
    async getCsosnCst() {
      await this.$store.dispatch(GET_ALL_CSOSN, { paginate: false });
      await this.$store.dispatch(GET_ALL_CST, { paginate: false });
    }
  },
  async created() {
    this.autoLoadUpdate(this.getItemToUpdatePurchaseOrder)
    await this.getCsosnCst();
    this.refresh_dates();
  }
};
</script>

<style>
</style>