<template>
  <div>
    <v-dialog
      persistent
      max-width="600px"
      v-model="getShowDialogRegisterEmitionTag"
    >
      <v-form v-model="disableButton">
        <v-card class="pa-5">
          <v-col>
            <v-row>
              <v-card-title> Emissão Código de Barras </v-card-title>
            </v-row>
            <v-row>
              <v-autocomplete
                v-model="formData.printer"
                outlined
                label="Impressora"
                :rules="rules"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.layoutTag"
                outlined
                label="Layout Etiqueta"
                :rules="rules"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.product"
                outlined
                label="Pesquisa Produto"
                :rules="rules"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.descriptionProduct"
                outlined
                label="Descrição Produto"
                :rules="rules"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.amount"
                outlined
                label="Quantidade"
                type="numer"
                :rules="rules"
              />
            </v-row>
            <v-row class="d-flex justify-end">
              <v-btn class="mr-2 error" @click="cancel">Cancelar</v-btn>
              <v-btn
                class="ml-2 success"
                :disabled="disableButton"
                @click="submit"
                >Imprimir</v-btn
              >
            </v-row>
          </v-col>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { rules } from "./helpers/rules";
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  computed: {
    ...mapGetters(["getShowDialogRegisterEmitionTag"]),
  },
  data: () => ({
    rules,
    disableButton: Boolean(),
    formData: Object(),
  }),
  methods: {
    submit() {},
    cancel() {
      this.$store.dispatch("setShowDialogRegisterEmitionTag", false);
    },
  },
  watch: {},
  created() {},
};
</script>

<style>
</style>