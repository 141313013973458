async function calculeTributePisIn(
  productInvoiceEntry,
  aliquot_pis,
  codeCstPis
) {
  try {
    let productTributePis = classificationCstPis(
      codeCstPis,
      productInvoiceEntry,
      aliquot_pis
    );
    return productTributePis;
  } catch (error) {
    return error;
  }
}

async function classificationCstPis(
  codeCstPis,
  productInvoiceEntry,
  aliquot_pis
) {
  let cst_cofins_code_found = parseInt(codeCstPis);

  if (cst_cofins_code_found >= 1 || cst_cofins_code_found <= 97) {
    return _normal_Pis_Calculation(productInvoiceEntry, aliquot_pis);
  } else if (cst_cofins_code_found >= 98 || cst_cofins_code_found <= 99) {
    return _others_Calculation_Pis(productInvoiceEntry, aliquot_pis);
  }
}

async function _normal_Pis_Calculation(productInvoiceEntry, aliquot_pis) {
  try {
    productInvoiceEntry.aliquot_pis = aliquot_pis;
    productInvoiceEntry.value_base_pis_product =
      productInvoiceEntry.amount * productInvoiceEntry.cust;
    productInvoiceEntry.value_pis_product =
      productInvoiceEntry.value_base_pis_product * (aliquot_pis / 100);
    productInvoiceEntry.value_base_pis_st_product = 0;
    productInvoiceEntry.value_pis_st_product = 0;
    productInvoiceEntry.value_pis_st_product = 0;
    productInvoiceEntry.value_other_pis_product = 0;
    productInvoiceEntry.value_free_pis_product = 0;
    return productInvoiceEntry;
  } catch (error) {
    return error;
  }
}
async function _others_Calculation_Pis(productInvoiceEntry, aliquot_pis) {
  try {
    productInvoiceEntry.aliquot_pis = aliquot_pis;
    productInvoiceEntry.value_base_pis_product = 0;
    productInvoiceEntry.value_pis_product = 0;
    productInvoiceEntry.value_base_pis_st_product = 0;
    productInvoiceEntry.value_pis_st_product = 0;
    productInvoiceEntry.value_pis_st_product = 0;
    productInvoiceEntry.value_other_pis_product =
      productInvoiceEntry.amount * productInvoiceEntry.cust;
    productInvoiceEntry.value_free_pis_product = 0;

    return productInvoiceEntry;
  } catch (error) {
    return error;
  }
}

module.exports = {
  calculeTributePisIn,
};
