<template>
    <div class="footer">
        <p class="footer-legends">F2 Abrir Caixa - F4 Sangria - F6 Suprimento - F8 Fechar Caixa - F9
            Finalizar Venda
        </p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>
.footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #304c64;
    height: 70px;
    width: 100%;
    position: fixed;
    top: 95%;
    bottom: 0;
}

.footer-legends {
    margin-left: 10px;
    color: aliceblue;
}
</style>