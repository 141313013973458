

var transporterModel = {

    created_at: undefined,
    enterprise_id: Number(),
    id: Number(),
    name: String(),
    people: Object(),
    people_id: Number(),
    rntr: undefined,
    security: undefined,
    transporter_id: Number(),
    type_people: String(),
    updated_at: undefined,
}


export default transporterModel