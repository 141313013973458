


var watch = {
    getDataReportSales: function (val) {
        this.viewPDF(val);
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
    },
    getEnterprises(val) {
        this.listEnterprises = Object.values(val);
        this.formData.enterprise_id = this.listEnterprises[0];
    }
}

export {
    watch
}