<template>
  <div>
    <v-dialog persistent v-model="getShowDialogRegisterCorrectionLetter">
      <v-card width="100%">
        <v-card-title>Pedido de Correção de Nota Fiscal</v-card-title>
        <v-divider />
        <v-form ref="form" v-model="formValidate">
          <v-container>
            <v-row no-gutters>
              <v-col class="pa-1" cols="12" sm="3">
                <v-select
                  label="Empresa"
                  outlined
                  dense
                  :rules="rules[0]"
                  item-text="fantasyName"
                  item-value="id"
                  :items="getEnterprises.data"
                  v-model="enterpriseSelect"
                ></v-select>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3">
                <v-text-field
                  label="*Número da Nota"
                  outlined
                  type="Number"
                  v-model="formData.numberNote"
                  :rules="rules[0]"
                  dense
                  :readonly="component.justView"
                ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3">
                <v-text-field
                  label="Série da Nota"
                  outlined
                  dense
                  type="Number"
                  :rules="rules[0]"
                  v-model="formData.seriesNote"
                  :readonly="component.justView"
                ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3"> </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12">
                <v-text-field
                  label="*Chave da Nota Fiscal"
                  outlined
                  :rules="rules[0]"
                  v-model="formData.keyFiscalNote"
                  dense
                  :readonly="component.justView"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12">
                <v-textarea
                  counter
                  outlined
                  label="*Motivo"
                  :rules="rules[1]"
                  v-model="formData.reason"
                  :readonly="component.justView"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="closeDialog">Cancelar</v-btn>
            <v-btn color="success" :disabled="activateButtom" @click="submit" v-if="!component.justView"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { ADD_ORDER_CORRECTION_LETTER } from "../../../store/actions/correction_letter";
import { rulesText, rulesTextArea } from "./rules/rules";
import generatePdf from "../GeneratePdf/GeneratePdf";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterCorrectionLetter", "getEnterprises","auth/getUsers"]),
    activateButtom: function () {
      if (this.formValidate) {
        return false;
      } else {
        return true;
      }
    },
  },

  data: function () {
    return {
      rules: [rulesText, rulesTextArea],
      formValidate: Boolean(),
      currenctComponent: Object(),
      enterpriseSelect: Object(),
      formData: {
        enterprise_id: Number(),
        numberNote: String(),
        seriesNote: String(),
        insueDate: Date(),
        keyFiscalNote: String(),
        reason: String(),
      },
    };
  },
  methods: {
    generatePdf,
    closeDialog() {
      this.component.justView = false
      this.$store.dispatch("setShowDialogRegisterCorrectionLetter", false);
    },

    setData() {
      this.formData.numberNote = this.component.correctionLetterDataToView.numberNfe
      this.formData.seriesNote = this.component.correctionLetterDataToView.seriesNfe
      this.formData.insueDate = this.component.correctionLetterDataToView.date_corretion_letter
      this.formData.keyFiscalNote = this.component.correctionLetterDataToView.key
      this.formData.reason = this.component.correctionLetterDataToView.corretionInformation
    },


    submit() {
      let payload = {
        keyNfe:this.formData.keyFiscalNote,
        numberNfe: this.formData.numberNote,
        seriesNfe: this.formData.seriesNote,
        modelNf:"55",
        cnpjEmitter:this.enterpriseSelect.cnpj,
        user_identfy:this["auth/getUsers"].user.email,
        date_corretion_letter:this.formaterDate(),
        corretionInformation: this.formData.reason,
      }
      this.$store.dispatch(ADD_ORDER_CORRECTION_LETTER, payload).then((response) => {
        generatePdf(this.enterpriseSelect, response)
      })
      this.closeDialog()
    },
    async getAllEnterprise(page= 1) {
      const payload = {
        page: page,
        limit: 100,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
    formaterDate() {
  let dataFormat = new Date();
  let year = dataFormat.getFullYear();
  let day = dataFormat.getDate()
  let month = dataFormat.getMonth() + 1;

  if (day < 10) {
    day = `0${day}`;
  } else {
    day = day.toString();
  }
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = month.toString();
  }

  let dateTimeFormat =
    year +
    "-" +
    month +
    "-" +
    day +
    " " 

  return dateTimeFormat;
},

  },
  watch: {
    formValidate: function () {
    },
    getEnterprises: function (val) {
      this.enterpriseSelect = val.data[0];
    },
  },
  created() {
    this.getAllEnterprise();
    if(this.component.justView) {
      this.setData()
    }
  },
  beforeDestroy() {
    this.formData = {};
  },
};
</script>

<style>
</style>