<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Parâmetro</v-card-title>
          <v-spacer></v-spacer>
          <v-btn class="mt-3 mr-4" color="success" @click="showDialog">
            Adicionar
          </v-btn>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <ParameterItensList />
    </v-card>
    <AddDialog />
  </v-container>
</template>
  
<script>
import ParameterItensList from "../components/parameter_Items/list";
import AddDialog from "../components/parameter_Items/AddDialog";

export default {
  components: {
    ParameterItensList,
    AddDialog,
  },
  props: {},
  computed: {},
  data: () => ({}),
  methods: {
    showDialog() {
      this.$store.dispatch("setShowDialogRegisterParameterItens", true);
    },
  },
  watch: {},
  created() {},
};
</script>
  
  <style>
</style>