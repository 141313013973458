


const headersLegalPeople = [
    {
        text: 'Código', value: 'people.legalpeople.id'
      },
      {
        text: 'Nome', value: 'people.legalpeople.socialName'
      },
      {
        text: 'CNPJ', value: 'people.legalpeople.cnpj'
      }
]

const headersPhysicalPeople = [
    {
        text: 'Código', value: 'people.physicalpeople.id'
      },
      {
        text: 'Nome', value: 'people.physicalpeople.name'
      },
      {
        text: 'CPF', value: 'people.physicalpeople.cpf'
      }
]


export {headersLegalPeople, headersPhysicalPeople}