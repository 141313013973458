<template>
  <v-row>
    <v-dialog max-width="550" v-model="this.component.dialog_cl1" persistent>
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-card-title> Pesquisar Clientes </v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2 mb-6"
              @click="
                () => {
                  close();
                }
              "
              >mdi-close</v-icon
            >
          </v-row>
          <v-switch
            color="primary"
            label="Pessoa Jurídica"
            v-model="isFormalPeople"
          ></v-switch>
          <v-text-field
            outlined
            v-model="search"
            label="Pesquisar"
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-data-table
            :headers="atual.header"
            :search="search"
            :items="atual.getter"
            no-data-text="Sem registros"
            hide-default-footer
            no-results-text="Nenhum cliente encontrado"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, -1],
              'items-per-page-text': 'Items por página',
            }"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="green darken-3"
                class="ml-3"
                @click="add_client(item)"
                >mdi-plus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-pagination
            v-model="current"
            :length="lastPage"
            color="success"
            :total-visible="10"
          ></v-pagination>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { headers } from "./script";
import { GET_ALL_CLIENT } from "../../../../store/actions/client";
import { mapGetters } from "vuex";
export default {
  props: { component: Object() },
  data: () => ({
    search: String(),
    lastPage: Number(),
    current: Number(),
    isFormalPeople: Boolean(),
    pagIsLegal: Boolean(),
    atual: {
      header: Array(),
      getter: Array(),
    },
    headers,
  }),
  methods: {
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header = this.headers[
        isLegal ? type.isLegal : type.isPhisical
      ];

      this.atual.getter = isLegal
        ? this.getLegalClients
        : this.getPhysicalClients;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },
    close() {
      this.component.dialog_cl1 = !this.component.dialog_cl1;
    },
    add_client(item) {
      this.component.refreshClient(item);
      this.close();
    },
    async GetPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        paginate: true,
        page: page,
        limit: 10,
        type_people: "PhysicalPeople",
      });
    },
    async GetLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        paginate: true,
        page: page,
        limit: 10,
        type_people: "LegalPeople",
      });
    },
  },
  computed: {
    ...mapGetters([
      "getPhysicalClients",
      "getLegalClients",
      "getPaginationClient",
    ]),
  },
  watch: {
    getPaginationClient: function (val) {
      this.current = val.currentPage;
      this.lastPage = val.lastPage;
    },
    getPhysicalClients: function () {
      if (this.getPhysicalClients.length === 11) {
        this.GetPhysical();
      }
    },
    getLegalClients: function () {
      if (this.getLegalClients.length === 11) {
        this.GetLegal();
      }
    },
    current: function (val) {
      if (this.pagIsLegal) {
        this.GetLegal(val);
      } else {
        this.GetPhysical(val);
      }
    },
    isFormalPeople: function (val) {
      this.pagIsLegal = val;
      if (val) {
        this.GetLegal();
      } else {
        this.GetPhysical();
      }
      this.isLegal(val);
    },
  },
  async created() {
    await this.GetLegal();
    await this.GetPhysical();
  },
  mounted() {
    this.isLegal();
  },
};
</script>
