<template>
  <v-container>
    <v-form ref="form" :disabled="isDisable">
      <v-row>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4" >
          <v-autocomplete
            label="Transportadora"
            outlined
            item-text="name"
            item-value="id"
            return-object
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialog()"
            :items="transporter_items"
            v-model="transporter"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            v-model="component.form.vehicle_plate"
            label="Placa do Veículo"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            v-model="component.form.volume_transported"
            label="Volume Transportado"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            label="Peso Bruto da Mercadoria"
            v-model="component.form.gross_weight"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            label="Peso Líquido da Mercadoria"
            v-model="component.form.net_weight"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            label="Tipo de Volume Transportado"
            v-model="component.form.volume_type"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="4" xl="3" lg="3">
          <v-select
            :items="freight"
            v-model="component.form.type_freith"
            label="Tipo do Frete"
            v-on:change="component.some()"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="3" lg="3">
          <v-text-field
            label="Valor do Frete"
            v-model="component.form.value_freigth"
            type="number"
            v-on:change="component.some()"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="3" lg="3">
          <v-text-field
            label="Valor do Seguro"
            v-model="component.form.value_security"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="3" lg="3">
          <v-text-field
            label="Outras Dispesas Acessórias"
            v-model="component.form.value_other_incidental_expenses"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <TransporterDialog />
    </v-form>
  </v-container>
</template>

<script>
import { GET_ALL_TRANSPORTER_TO_SELECT_FIELD } from "../../../../store/actions/transporters";
import { mapGetters } from "vuex";
import TransporterDialog from "../../../transport/AddDialog";
export default {
  components: {
    TransporterDialog,
  },
  props: {
    isDisable: Boolean,
    component: Object,
    transporterView: Object(),
  },

  data: () => ({
    dialog: Boolean(),
    transporter: Object(),
    id_transporter: Number(),
    transporter_items: Array(),
    freight: [
      { text: "CIF", value: "1" },
      { text: "FOB", value: "2" },
    ],
  }),

  methods: {
    showDialog() {
      this.$store.dispatch("setShowDialogRegisterTransporter", true);
    },
    reset() {
      this.$refs.form.reset();
    },
    name_transporter(value) {
      this.transporter_items = value;
      this.transporter_items.forEach((e) => {
        if (e.type_people === "LegalPeople") {
          e.name = e.people.legalpeople.fantasyName;
        } else {
          e.name = e.people.physicalpeople.name;
        }
      });
    },
    async getAllTransporter() {
      await this.$store.dispatch(GET_ALL_TRANSPORTER_TO_SELECT_FIELD, {
        page: null,
        paginate: false,
        limit: null,
      });
    },
  },
  computed: {
    ...mapGetters(["getSelectTransporter"]),
  },
  watch: {
    getSelectTransporter: function (val) {
      this.name_transporter(val);
      this.component.form.transportNfe = {...val}
    },
    transporter: function (val) {
      if (val !== null) {
        this.component.form.transporter_id = val.id;
        this.component.form.transportNfe = val;
      }
    },
  },
  async created() {
    await this.getAllTransporter();
    this.transporter.id = this.component.form.transporter_id;
  },
};
</script>

<style>
</style>