<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Comissão de Vendedor</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="report.selectValue"
              :items="enterprise"
            ></v-select>
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form"/>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Form from "../../../components/report/comission_sellers/Form/index.vue";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { GET_ALL_SELLER_BY_ENTERPRISE } from "../../../store/actions/seller";
import { mapGetters } from "vuex";

import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";

export default {
  components: { Form },

  data: () => ({
    valid: false,
    userName: String(),
    enterprise: [],
    sellers: [],
    comissionTypes: [
      {
        comissionName: "Comissão por venda",
        comissionType: "commission_per_sale"
      },
      {
        comissionName: "Comissão por itens da venda",
        comissionType: "commission_for_sale_items"
      }

    ],
    report: {
      selectValue: Object(),
      date_initial_sales: null,
      date_finish_sales: null,
      seller: Object(),
      comission_type: "commission_per_sale"
    },
  }),

  methods: {
    async Generate() {
      let payload = {
        enterprise_id: this.report.selectValue.id,
        initial_date_sale: this.report.date_initial_sales,
        final_date_sale: this.report.date_finish_sales,
        seller_id: this.report.seller.id,
        commission_type: this.report.comission_type
      }
      let all_report = Object.assign({}, payload);

      

      let response = await axios.post("/sales/salescommission", {
        ...all_report,
      });

      let initialDate = this.convertDateFormat(this.report.date_initial_sales)
      let finalDate = this.convertDateFormat(this.report.date_finish_sales)
      let sellerName = this.report.seller.people.physicalpeople.name
      
      // this.$refs.form.reset();
      // this.$refs.form.validate();
      // this.$refs.form.resetValidation();
      let arrayenterprise = [];
      let arrayaddress = [];
      let objeto = Object.assign({}, this.report.selectValue);
      let objetoadress = Object.assign({}, this.report.selectValue.address);
      arrayenterprise.push(objeto);
      arrayaddress.push(objetoadress);
      if(payload.commission_type == "commission_per_sale") {
        let arrayCommissionSellerReportSummary = Array()
        arrayCommissionSellerReportSummary.push(response.data)
        const commissionSellerReportSummary = Object.keys(arrayCommissionSellerReportSummary).map(key => arrayCommissionSellerReportSummary[key]);
        this.createPDFcommission_per_sale(commissionSellerReportSummary, arrayenterprise, arrayaddress, initialDate, finalDate, sellerName);
      } else if(payload.commission_type == "commission_for_sale_items") {
        const productSalesReportSummary = response.data.product
        let commissionArray = Array()
        commissionArray.push(response.data.commission)
        const sellerCommissionReportSummary = Object.keys(commissionArray).map(key => commissionArray[key]);
        this.createPDFcommission_for_sale_items(productSalesReportSummary, sellerCommissionReportSummary, arrayenterprise, arrayaddress, initialDate, finalDate, sellerName);
      }
      
    },

    createPDFcommission_per_sale(item, enterprise, address, initialDate, finalDate, sellerName) {
      let data = new Date();
        item[0].percentage_commission = item[0].percentage_commission.toFixed(2).toString() + '%'
        item[0].seller_commission = item[0].seller_commission.toFixed(2)

      var doc = new jsPDF("l", "pt");
      let columns = [
        { title: "Código do Vendedor", dataKey: "cod_seller" },
        { title: "Nome", dataKey: "name_seller" },
        { title: "Número de Vendas", dataKey: "total_sales_amount" },
        { title: "Qtd de Itens Vendidos", dataKey: "total_number_of_items_sold" },
        { title: "Valor Total das Vendas", dataKey: "total_sales_value" },
        { title: "Valor de Desconto", dataKey: "total_descont_value" },
        { title: "Valor Líquido", dataKey: "net_value" },
        { title: "% Comissão", dataKey: "percentage_commission" },
        { title: "Valor de comissão", dataKey: "seller_commission" },
      ];
      
      doc.setLineWidth(1);
      doc.line(40, 85, 800, 85);
      doc.line(40, 125, 800, 125);
      doc.setFontSize(7);
      doc.text(`Usuário : ${this.userName}`, 700, 40);
      doc.text(`${data.toLocaleString()}`, 700, 50);

      doc.setFontSize(15);
      doc.text(`RELATÓRIO DE COMISSÃO DE VENDEDOR - ${sellerName}`, 180, 50);
      doc.text(`${initialDate} A ${finalDate}`, 320, 80);

      doc.setFontSize(7);
      doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
      doc.text(`Razão Social : ${enterprise[0].name}`, 240, 100);
      doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 630, 100);
      doc.text(`Rua : ${address[0].street}`, 40, 115);
      doc.text(`Numero : ${address[0].number}`, 250, 115);
      doc.text(`Cidade : ${address[0].city}`, 340, 115);
      doc.text(`Estado : ${address[0].state}`, 550, 115);
      doc.text(`CEP : ${address[0].cep}`, 700, 115);


      doc.setFontSize(13);
      doc.text("Comissão por Vendas", 320, 145);
      doc.autoTable(columns, item, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: [54, 54, 54],
        },
        startY: 150,
        theme: "grid",
      });
   
      const pages = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      for (let j = 1; j < pages + 1; j++) {
        doc.setLineWidth(1);
        doc.rect(20, 20, 800, 555);
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 795, 35);
      }

      doc.output("dataurlnewwindow");
    },


    createPDFcommission_for_sale_items(productSummary, sellerCommission, enterprise, address, initialDate, finalDate, sellerName) {
      let data = new Date();
      sellerCommission[0].percentage_commission = sellerCommission[0].percentage_commission.toFixed(2).toString() + '%'
      sellerCommission[0].seller_commission = sellerCommission[0].seller_commission.toFixed(2)
      var doc = new jsPDF("l", "pt");
      let columns = [
        { title: "Código Do Produto", dataKey: "product_code" },
        { title: "Descrição", dataKey: "description" },
        { title: "Tipo de Unidade", dataKey: "unit" },
        { title: "Qtd de Itens Vendidos", dataKey: "amount" },
        { title: "Valor do Produto", dataKey: "value_sale" },
        { title: "Valor Total das Vendas por Produto", dataKey: "value_total" },
      ];

      let commissionColumns = [
        { title: "Qtd Total de Itens Vendidos", dataKey: "products_sold" },
        { title: "Valor Total das Vendas", dataKey: "total_sales_value" },
        { title: "% Comissão", dataKey: "percentage_commission" },
        { title: "Valor de Comissão", dataKey: "seller_commission" },
      ];
      
      doc.setLineWidth(1);
      doc.line(40, 85, 800, 85);
      doc.line(40, 125, 800, 125);
      doc.setFontSize(7);
      doc.text(`Usuário : ${this.userName}`, 700, 40);
      doc.text(`${data.toLocaleString()}`, 700, 50);

      doc.setFontSize(15);
      doc.text(`RELATÓRIO DE COMISSÃO DE VENDEDOR - ${sellerName}`, 180, 50);
      doc.text(`${initialDate} A ${finalDate}`, 320, 80);

      doc.setFontSize(7);
      doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
      doc.text(`Razão Social : ${enterprise[0].name}`, 240, 100);
      doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 630, 100);
      doc.text(`Rua : ${address[0].street}`, 40, 115);
      doc.text(`Numero : ${address[0].number}`, 250, 115);
      doc.text(`Cidade : ${address[0].city}`, 340, 115);
      doc.text(`Estado : ${address[0].state}`, 550, 115);
      doc.text(`CEP : ${address[0].cep}`, 700, 115);


      doc.setFontSize(13);
      doc.text("Produtos Vendidos", 330, 145);
      doc.autoTable(columns, productSummary, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: [54, 54, 54],
        },
        startY: 150,
        theme: "grid",
      });

      doc.setFontSize(13);
      doc.text("Comissão por Itens da Venda", 320, doc.lastAutoTable.finalY + 40);
      doc.autoTable(commissionColumns, sellerCommission, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: [54, 54, 54],
        },
        startY: doc.lastAutoTable.finalY + 45,
        theme: "grid",
      });
   
      const pages = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      for (let j = 1; j < pages + 1; j++) {
        doc.setLineWidth(1);
        doc.rect(20, 20, 800, 555);
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 795, 35);
      }

      doc.output("dataurlnewwindow");
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },

    async GetAllSellerByEnterprise(enterpriseId) {
      let payload = {
        enterprise_id: enterpriseId,
        page: 1,
        limit: 100,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_SELLER_BY_ENTERPRISE, payload)
    },

    convertDateFormat(inputDate) {
      var parts = inputDate.split('-');
      var formattedDate = parts[2] + "/" + parts[1] + "/" + parts[0].substring(2);
      return formattedDate;
    }
  },

  computed: {
    ...mapGetters(["getEnterprises", "getSellerByEnterprise"]),
    ...mapGetters({ userLogin: "auth/userLogin" }),
  },

  created() {
    this.GetAllNoPage();
    this.userName = this.userLogin.user.name;
  },

  watch: {
    getEnterprises(val) {
      this.enterprise = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.enterprise[0]);
      this.report.selectValue = aux;
      this.GetAllSellerByEnterprise(this.report.selectValue.id)
    },

    getSellerByEnterprise(val) {
      this.sellers = val
      this.report.seller = val[0]
    }

  },
};
</script>
