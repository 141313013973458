<template>
  <v-form  ref="form" v-model="component.valid"  @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Fornecedor Inicial" 
          type="number"
          v-model="component.report.codProviderInitial"
          :rules="[
                  () => !!component.report.codProviderInitial || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Fornecedor Final" 
          type="number"
          v-model="component.report.codProviderFinal"
          :rules="[
                  () => !!component.report.codProviderFinal || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field outlined label="Data de Nascimento" v-model="component.report.date" type="date"></v-text-field>
      </v-col> -->
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field outlined label="CEP" v-model="component.report.cep" ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-autocomplete 
            v-model="oldState"
            :items="initials"
            item-text="text"
            chips
            deletable-chips
            return-object
            outlined
            label="Estado"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-autocomplete 
            v-model="component.new_city"
            :items="cities"
            chips
            deletable-chips
            item-text="nome"
            outlined
            item-value="nome"
            label="Cidade"
        ></v-autocomplete>
      </v-col>
    </v-row>  
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  props:{
    component: Object
  },

  data() {
    return {
      initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "es" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],
    cities: Array(),
    oldState: String(),
    }
  },

  methods:{
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  watch: {
    oldState(val) {
      this.cities = null
      if(val){
          axios
            .get(
              `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
            )
            .then((response) => {
              this.cities = response.data;
            });
      }
    },
  },
};
</script>

<style>
</style>