async function calculeTributePis(productRevenues, aliquot_pis, codeCstPis) {
  try {
    let productTributePis = classificationCstPis(
      codeCstPis,
      productRevenues,
      aliquot_pis
    );
    return productTributePis;
  } catch (error) {
    return error;
  }
}

async function classificationCstPis(codeCstPis, productRevenues, aliquot_pis) {
  let cst_cofins_code_found = parseInt(codeCstPis);

  if (cst_cofins_code_found >= 1 || cst_cofins_code_found <= 97) {
    return _normal_Pis_Calculation(productRevenues, aliquot_pis);
  } else if (cst_cofins_code_found >= 98 || cst_cofins_code_found <= 99) {
    return _others_Calculation_Pis(productRevenues, aliquot_pis);
  }
}

async function _normal_Pis_Calculation(productRevenues, aliquot_pis) {
  try {
    productRevenues.aliquot_pis = aliquot_pis;
    productRevenues.value_base_pis_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_pis_product =
      productRevenues.value_base_pis_product * (aliquot_pis / 100);
    productRevenues.value_base_pis_st_product = 0;
    productRevenues.value_pis_st_product = 0;
    productRevenues.value_pis_st_product = 0;
    productRevenues.value_other_pis_product = 0;
    productRevenues.value_free_pis_product = 0;

    return productRevenues;
  } catch (error) {
    return error;
  }
}
async function _others_Calculation_Pis(productRevenues, aliquot_pis) {
  try {
    productRevenues.aliquot_pis = aliquot_pis;
    productRevenues.value_base_pis_product = 0;
    productRevenues.value_pis_product = 0;
    productRevenues.value_base_pis_st_product = 0;
    productRevenues.value_pis_st_product = 0;
    productRevenues.value_pis_st_product = 0;
    productRevenues.value_other_pis_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_free_pis_product = 0;

    return productRevenues;
  } catch (error) {
    return error;
  }
}

module.exports = {
  calculeTributePis,
};
