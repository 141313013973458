
<template>
  <v-sheet>
    <v-data-table hide-default-footer :headers="header" :items="items">
      <template v-slot:[`item.actions`]="{ item }">
        <div class="mt-5">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="yellow darken-4"
                class="mb-5 mr-3"
                @click="showNote(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>Visualizar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red darken-4"
                class="mb-5 mr-3"
                @click="cancelNote(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-cancel
              </v-icon>
            </template>
            <span>Cnacelar</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import Header from "./helpers/header";
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      header: Header,
      items: Array(),
    };
  },
  computed: {
    ...mapGetters(["getListTaxCupom"]),
  },
  methods: {
    cancelNote(item) {
      item;
    },
    showNote(item) {
      item;
    },
    loadTable: function () {
      this.items = this.getListTaxCupom;
    },
  },
  watch: {
  },
  created() {
    this.loadTable();
  },
};
</script>

<style>
</style>