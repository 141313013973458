<template>
  <v-container>
    <v-form ref="forms" v-model="formValidation">
      <v-row>
        <v-col cols="12" lg="5" md="5" sm="5" xl="5" >
          <v-select :rules="rules" outlined dense class="mt-3" item-text="description" item-value="id" v-model="generalData.tax_operation_types_id" :items="listOperationTypes" label="*Tipo de Operação"></v-select>
          <v-text-field
           :rules="rules"
            label="Chave de acesso da NFe"
            :value="generalData.access_key"
            dense
            readonly
            outlined
          />
          <v-col>
            <v-row
              v-if="generalData.provider.isRegistered || generalData.provider.isRegistered == false"
            >
              <v-divider />
            </v-row>
            <v-row v-if="generalData.provider.isRegistered">
              <v-card-title class="blue--text">
                Fornecedor Cadastrado
                <v-icon class="ml-5" color="blue">mdi-check-bold</v-icon>
              </v-card-title>
            </v-row>
            <v-row v-else-if="generalData.provider.isRegistered == false">
              <v-card-title  class="red--text">
                Fornecedor não
                Cadastrado
                <v-icon class="ml-5" color="red">mdi-close-thick</v-icon>
              </v-card-title>
            </v-row>
            <v-row>
              <v-text-field
              :rules="rules"
                :value="generalData.provider.fantasyName"
                label="Fornecedor"
                dense
                readonly
                outlined
                append-outer-icon="mdi-plus-circle"
                @click:append-outer="registerProvider(generalData.provider.isRegistered)"
              />
            </v-row>
          </v-col>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6" xl="6">
              <v-text-field
              :rules="rules"
                label="Data de Emissão"
                :value="generalData.date_of_issue"
                dense
                readonly
                outlined
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" xl="6">
              <v-text-field
              :rules="rules"
                label="Data de Entrada"
                :value="generalData.entry_date"
                dense
                readonly
                outlined
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xl="12">
              <v-text-field :rules="rules" v-model="generalData.authorization_protocol" label="*Prot. Autorização de Uso" dense  outlined />
              <div class="d-flex flex-row">
                <v-text-field
                :rules="rules"
                  :value="generalData.number_grade"
                  class="mr-1"
                  label="Número da Nota"
                  dense
                  readonly
                  outlined
                />
                <v-text-field
                :rules="rules"
                  :value="generalData.series"
                  class="ml-1"
                  label="Série"
                  dense
                  readonly
                  outlined
                />
              </div>
              <v-textarea
                :value="generalData.additional_information"
                label="Informações Adicionais"
                counter="4000"
                dense
                readonly
                outlined
              />
            </v-col>
          </v-row>
          <RegisterProvider :out_of_register="true" />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import RegisterProvider from "../../../../../../provider/AddDialog/";
import data from "./helpers/data";
import Methods from "./helpers/methods";
import { mapGetters } from "vuex";
import { AUTO_RELOAD_PROVIDER } from "../../../../../../../store/actions/provider"
import { PREPARE_INF_GENERAL } from "../../../../../../../store/actions/import_xml"
import { copyGeneralData } from "./helpers/wipe_data";
export default {
  components: {
    RegisterProvider
  },
  data: function() {
    return {
      ...data
    };
  },
  props: {
    component: Object(),
    NotaFiscalInformacoes: Object()
  },
  methods: {
    ...Methods,
    reset() {
      this.$refs.forms.reset();
    }
  },
  computed: {
    ...mapGetters(["getGeneralData", "getLegalProvider", "getTaxOperationType"])
  },
  created() {
    this.getAllOperationType()
    if (this.getGeneralData == null) {
      this.generalData = { ...copyGeneralData };
    } else {
      this.populateObjectGeneralData(this.getGeneralData);
    }
  },
  watch: {
    formValidation: function(){
      this.component.validationSteps.push(true)
      this.$store.dispatch(PREPARE_INF_GENERAL, this.generalData)
    },
    getTaxOperationType: function(val){
       
      this.listOperationTypes = val.data
    },
    getLegalProvider: function() {
      this.generalData.provider = { ...this.getLegalProvider };
      this.generalData.provider.isRegistered = true;
    },
    getGeneralData: function(val) {
      if(!val.provider.isRegistered){
        this.$store.dispatch(AUTO_RELOAD_PROVIDER, val.provider)
      }
      this.populateObjectGeneralData(this.getGeneralData);
    }
  },
  beforeDestroy() {
    
    this.$refs.forms.reset()
    this.resetForm();
  }
};
</script>

<style>
</style>