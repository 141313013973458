

function randomNumber(max, min) {
    return  parseInt(Math.random() * (max - min) + min)
}

function generateDate(){
    const date = new Date()
    const year  = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    return `${year}-${month}-${day}`
}


export {randomNumber, generateDate}