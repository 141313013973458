<template>
    <v-container>
      <v-card elevation="4" outlined>
        <v-card-text>
          <v-row>
            <v-card-title>Relatório de EstatÍstica de Vendas</v-card-title>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                outlined
                dense
                return-object
                flat
                height="10px"
                label="Empresa"
                item-value="id"
                item-text="fantasyName"
                loader-height
                v-model="report.selectValue"
                :items="enterprise"
              ></v-select>
            </v-col>
            <v-btn
              class="mt-3 mr-4"
              width="200"
              color="success"
              type="submit"
              @click="validDate()"
              :disabled="!valid"
            >
              <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-container>
          <Form :component="this" ref="form" />
          <InvalidDateDialog :component="this" v-if="invalidDateDialog" />
        </v-container>
      </v-card>
      <AlertApi/>
    </v-container>
  </template>
<script>
  import Form from "../../../components/report/sale_stats/Form/index.vue";
  import InvalidDateDialog from "@/views/Reports/sale_stats/invalidDateDialog.vue";
  import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
  import { GET_SALE_STATS_REPORT } from "../../../store/actions/vendas";
  import { mapGetters } from "vuex";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import axios from "axios";
  import AlertApi from "../../../components/alert_api/alert_api";
  export default {
    components: { Form, InvalidDateDialog, AlertApi },
    data: () => ({
      dialog: Boolean(),
      valid: false,
      // selectValue: Object(),
      userName: String(),
      enterprise: [],
      report: {
        selectValue: Object(),
        date_initial_sales: null,
        date_finish_sales: null,
        type_cost: null,
      },
      initial_date: null,
      final_date: null,
      invalidDateDialog: false,
    }),
    
    
    methods: {
      validDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        if(this.report.date_initial_sales <= formattedDate && this.report.date_finish_sales <= formattedDate && this.report.date_initial_sales <= this.report.date_finish_sales) {
          this.Generate()
        } else {
          this.invalidDateDialog = true
        }
      },
      async Generate() {
        this.initial_date = this.report.date_initial_sales
        this.final_date = this.report.date_finish_sales
        var initialDateParts = this.initial_date.split("-");
        this.initial_date = initialDateParts[2] + "/" + initialDateParts[1] + "/" + initialDateParts[0].substring(2);
        var finalDateParts = this.final_date.split("-");
        this.final_date = finalDateParts[2] + "/" + finalDateParts[1] + "/" + finalDateParts[0].substring(2);
        let payload = {
          enterprise_id: this.report.selectValue.id,
          date_initial_sales: this.report.date_initial_sales,
          date_finish_sales: this.report.date_finish_sales,
          type_cost: this.report.type_cost.value,
        }
        let all_report = Object.assign({}, payload);
        let response = null
        await this.$store.dispatch(GET_SALE_STATS_REPORT, all_report).then((result)=> {
          response  = result.response
        })

        const typeCostLabel = this.report.type_cost.label
        this.dialog = !this.dialog;
        this.$refs.form.reset();
        this.$refs.form.validate();
        this.$refs.form.resetValidation();
        let arrayenterprise = [];
        let arrayaddress = [];
        let objeto = Object.assign({}, this.report.selectValue);
        let objetoadress = Object.assign({}, this.report.selectValue.address);
        arrayenterprise.push(objeto);
        arrayaddress.push(objetoadress);
        const statsData = Object.keys(response.data.data).map(key => response.data.data[key]);
        let totalProfitValue = 0
        let totalAmount = 0
        let totalAccountingCost = 0

        for(let i = 0; i<statsData.length; i++) {
          totalProfitValue += statsData[i].profit
          totalAmount += statsData[i].amount
          totalAccountingCost += statsData[i].accounting_cost_total
          statsData[i].profit = statsData[i].profit.toFixed(2)
          statsData[i].sale = statsData[i].sale.toFixed(2)
          statsData[i].value_sale = statsData[i].value_sale.toFixed(2)
          statsData[i].contribution_margin = statsData[i].contribution_margin.toFixed(2).toString() + '%'
        }
        statsData.push(
          {
            product: "TOTAL",
            amount: totalAmount,
            contribution_margin: response.data.totalContributionMargin.toFixed(2).toString() + '%',
            accounting_cost_total: totalAccountingCost.toFixed(2),
            profit: totalProfitValue.toFixed(2),
            value_sale: response.data.totalValueSale.toFixed(2),
          }
        )

        this.createPDF(statsData, typeCostLabel, arrayenterprise, arrayaddress, totalAmount, totalProfitValue);

      },
      
      
      
      
    createPDF( statsData, typeCostLabel, enterprise, address, totalAmount, totalProfitValue) {

        let data = new Date();
        var doc = new jsPDF("l", "pt");
        let columns = [
          { title: "Código", dataKey: "code" },
          { title: "Produto", dataKey: "product" },
          { title: "Quantidade", dataKey: "amount" },
          { title: "Preço de Venda", dataKey: "sale" },
          { title: `${typeCostLabel} (custo individual)`, dataKey: "accounting_cost" },
          { title: "Valor Total das Vendas", dataKey: "value_sale" },
          { title: `${typeCostLabel} (custo total)`, dataKey: "accounting_cost_total" },
          { title: "Lucro", dataKey: "profit" },
          { title: "Margem de Contribuição", dataKey: "contribution_margin" },
        ];

        doc.setLineWidth(1);
        doc.line(40, 85, 800, 85);
        doc.line(40, 125, 800, 125);
        doc.setFontSize(7);
        doc.text(`Usuário : ${this.userName}`, 700, 40);
        doc.text(`${data.toLocaleString()}`, 700, 50);
        doc.setFontSize(15);
        doc.text("RELATÓRIO DE ESTATÍSTICA DE VENDAS", 270, 50);
        doc.text(`${this.initial_date} A ${this.final_date}`, 350, 70);
        doc.setFontSize(7);
        doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
        doc.text(`Razão Social : ${enterprise[0].name}`, 240, 100);
        doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 630, 100);
        doc.text(`Rua : ${address[0].street}`, 40, 115);
        doc.text(`Numero : ${address[0].number}`, 250, 115);
        doc.text(`Cidade : ${address[0].city}`, 340, 115);
        doc.text(`Estado : ${address[0].state}`, 550, 115);
        doc.text(`CEP : ${address[0].cep}`, 700, 115);


        doc.setFontSize(15);
        doc.text("Resumo das Vendas", 340, 160);
        doc.setFontSize(14);
        doc.text(`Produto mais vendido:    ${statsData[0].product}`, 100, 200);
        doc.text(`Quantidade de produtos vendidos:    ${totalAmount}`, 100, 240);
        doc.text(`Número de vendas:    ${statsData.length - 1}`, 100, 280);
        doc.text(`Lucro total:    R$${totalProfitValue.toFixed(2)}`, 100, 320);



        doc.autoTable(columns, statsData, {

          columnStyles: {

            //

          },

          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: 380,
          theme: "grid",
        });

  

        // doc.setFontSize(13);

        // doc.text("Lista de Vendas Autorizadas", 320, doc.lastAutoTable.finalY + 15);

        // doc.autoTable(list_of_authorized_sales_columns, listItem, {

        //   columnStyles: {

        //     //

        //   },

        //   headStyles: {

        //     fillColor: [230, 230, 230],

        //     textColor: [54, 54, 54],

        //   },

        //   startY: doc.lastAutoTable.finalY + 20,

        //   theme: "grid",

        // });

        

  

        const pages = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
        for (let j = 1; j < pages + 1; j++) {
          doc.setLineWidth(1);
          doc.rect(20, 20, 800, 555);
          doc.setPage(j);
          doc.text(`${j} / ${pages}`, 795, 35);

        }
        doc.output("dataurlnewwindow");

      },

  

      async GetAllNoPage() {
        let value = {
          page: null,
          limit: null,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      },

    },

    computed: {
      ...mapGetters(["getEnterprises"]),
      ...mapGetters({ userLogin: "auth/userLogin" }),
    },

  

    created() {
      this.GetAllNoPage();
      this.userName = this.userLogin.user.name;
    },

  

    watch: {
      getEnterprises(val) {        
      if(val.data){
        this.enterprise = Object.values(val.data);
        this.report.selectValue = this.enterprise[0];
      }else{
        this.enterprise = Object.values(val);
        this.report.selectValue = this.enterprise[0];
      }
      },
    },
  };

</script>