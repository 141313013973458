

var product_select = Object();
var product = Object();
var aliquot = Object();
var cfop = Object();
var cst_csosn = Object();
var cst_pis = Object();
var cst_cofins = Object();




module.exports = {
    product_select,
    product,
    aliquot,
    cfop,
    cst_csosn,
    cst_pis,
    cst_cofins,
}