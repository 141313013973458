import { GET_ALL_ALIQUOT } from "../../../../../../../../store/actions/aliquot";
import { GET_ALL_CFOP } from "../../../../../../../../store/actions/cfop";
import { GET_ALL_CSOSN } from "../../../../../../../../store/actions/csosn";
import { GET_ALL_PIS_COFINS } from "../../../../../../../../store/actions/pis_cofins";
import { GET_ALL_CST } from "../../../../../../../../store/actions/cst";
import { AUTO_LOAD_PRODUCT } from "../../../../../../../../store/actions/product";
import { ADD_NOTE_ENTRY_ITENS } from "../../../../../../../../store/actions/note_entry_itens"
import modelCalculateProduct from "./models/model_calculate_product"
import { PREPARE_ITENS_NOTE, PREPARE_DATA_INF_NOTE } from "../../../../../../../../store/actions/import_xml";
//métodos getters
function getAllInformations() {
  var payload = {
    page: null,
    limit: null,
    paginate: false,
  };
  this.$store.dispatch(GET_ALL_ALIQUOT, payload);
  this.$store.dispatch(GET_ALL_CSOSN, payload);
  this.$store.dispatch(GET_ALL_CFOP, payload);
  this.$store.dispatch(GET_ALL_PIS_COFINS, payload);
  this.$store.dispatch(GET_ALL_CST, payload)

}

//métodos getters

function trimString(data) {
  if (data.length > 0) {
    return data.trim();
  } else {
    return data;
  }
}
function CallDialogRegisterProduct() {
  this.callDialog = !this.callDialog;
}

//Incluido dentro create
function setGettersObjectToProduct(arrayProduct) {

  arrayProduct.map((elementProduct) => {

    this.arrayAliquots.map((elementAliquots) => {
      if (elementProduct.Tax.aliquot == elementAliquots.aliquot) {
        elementProduct.Tax.aliquot = elementAliquots
      }
    })

    //em teste
    if (elementProduct.Tax.cst != undefined) {

      this.arrayCst.map((elementCST) => {
        if (elementProduct.Tax.cst == elementCST.code) {
          elementProduct.Tax.cst = elementCST
        }
      })
    } else {
      this.arrayCsosn.map((elementCSOSN) => {
        if (elementProduct.Tax.cst == elementCSOSN.code) {
          elementProduct.Tax.cst = elementCSOSN
        }
      })
    }


    // this.arrayCfop.map((elementCFOP) => {
    //   if (elementProduct.Tax.cfop == elementCFOP.code) {
    //     elementProduct.Tax.cfop = elementCFOP
    //   }
    // })
    this.arrayPisCofins.map((elementPisConfins) => {
      if (elementProduct.Tax.pis == elementPisConfins.code) {
        elementProduct.Tax.pis = elementPisConfins
      }
    })
    this.arrayPisCofins.map((elementPisConfins) => {
      if (elementProduct.Tax.cofins == elementPisConfins.code) {
        elementProduct.Tax.cofins = elementPisConfins
      }
    })
  })
}

//Incluido dentro da função watch
//quando não estiver cadastrado
function verifyTaxToObject(index) {

  this.arrayAliquots.map((element) => {
    if (element.aliquot == Number(this.arrayProduct[index].Tax.aliquot)) {
      this.arrayProduct[index].Tax.aliquot = element
    }
  })


  //em teste
  // this.arrayCfop.map((element) => {
  //   if (this.arrayProduct[index].Tax.cst != undefined) {
  //     if (element.code == this.arrayProduct[index].Tax.cst) {
  //       this.arrayProduct[index].Tax.cst = element
  //     }
  //   } else {
  //     if (element.code == this.arrayProduct[index].Tax.csosn) {
  //       this.arrayProduct[index].Tax.csosn = element
  //     }
  //   }
  // })

  //functionando
  this.arrayPisCofins.map((element) => {
    if (element.code == this.arrayProduct[index].Tax.cofins) {
      this.arrayProduct[index].Tax.cofins = element
    }
  });

  this.arrayPisCofins.map((element) => {
    if (element.code == this.arrayProduct[index].Tax.pis) {
      this.arrayProduct[index].Tax.pis = element
    }
  });

}

function registerProduct(isRegistered, index) {
  if (isRegistered) {
    alert("Produto ja cadastrado");
  } else {
    this.indexProductToSetArray = index;
    this.$store.dispatch("setShowDialogRegisterProduct", true);
    // por questões de teste

    this.$store.dispatch(AUTO_LOAD_PRODUCT, this.arrayProduct[index])
    // this.arrayProduct[index].isRegistered = true
    // this.isRegisteredColor();
  }
}

function isRegisteredColor(formvalidation) {

  var validationTrue = 0;
  for (let cont = 0; cont < this.arrayProduct.length; cont++) {
    if (this.arrayProduct[cont].isRegistered == true) {
      validationTrue = validationTrue + 1;
    }
  }
  if (
    this.arrayProduct.length === validationTrue &&
    this.arrayProduct.length > 0 &&
    formvalidation
  ) {
    // return false
    this.enableButtom = false;
  } else {
    // return true
    this.enableButtom = true;
  }
}

function cleanform() {
  this.$refs.form.reset()
}

function getAllProducts() {
  // this.arrayProduct = null;
}

function setShowGlobalDialog(val){
  if(val == "cst"){
      this.$store.dispatch("setShowDialogRegisterCST", true)
  }else if(val == "cst/pis"){
    this.$store.dispatch("setShowDialogRegisterPISCOFINS", true)
  }else if(val == "cst/cofins"){
    this.$store.dispatch("setShowDialogRegisterPISCOFINS", true)
  }else if(val == "aliquot"){
    this.$store.dispatch("setShowDialogRegisterAliquot", true)
  }else if(val == "cfop"){
    this.$store.dispatch("setShowDialogRegisterCFOP", true)
  }
}

//implementar
function registerItems() {
  let arrayProduct = Array()
  this.arrayProduct.forEach(element => {
    var returnProduct = modelCalculateProduct(element)
    this.$store.dispatch(ADD_NOTE_ENTRY_ITENS, returnProduct)
  });
  this.arrayProduct.forEach(element => {
    var returnProduct = modelCalculateProduct(element)
    arrayProduct.push(returnProduct)
  });
  this.$store.dispatch(PREPARE_ITENS_NOTE, arrayProduct)  
  this.component.validationSteps.push(true)
}

function populateInfNote() {

  this.infNote.base_calc_icms_total = this.component.invoice.base_calc_icms_total
  this.infNote.value_icms_total = this.component.invoice.value_icms_total
  this.infNote.value_base_icms_st_product = this.component.invoice.value_base_icms_st_product
  this.infNote.value_icms_substituicao_total = this.component.invoice.value_icms_substituicao_total
  this.infNote.value_account_total = this.component.invoice.value_account_total
  this.infNote.base_calc_ipi_total = this.component.invoice.base_calc_ipi_total
  this.infNote.value_ipi_total = this.component.invoice.value_ipi_total
  this.infNote.value_ipi_outher_total = this.component.invoice.value_ipi_outher_total
  this.infNote.value_ipi_free_total = this.component.invoice.value_ipi_free_total
  this.infNote.value_total_products = this.component.invoice.value_total_products
  this.infNote.value_icms_outher_product = this.component.invoice.value_icms_outher_product
  this.infNote.value_discont = this.component.invoice.value_discont
  this.infNote.value_icms_free_total = this.component.invoice.value_icms_free_total
  this.infNote.value_total_note = this.component.invoice.value_total_note

  this.$store.dispatch(PREPARE_DATA_INF_NOTE, this.infNote)
}
function sendInformationNote() {
  if (this.component.invoice.base_calc_ipi_total != 0) {
    this.populateInfNote()
  }
}

function mapArrayAndCreateObjectProduct(arrayProducts) {
  arrayProducts.forEach((productElement) => {
    this.productInformation.manufacturer_code = trimString(
      productElement.prod.cProd._text
    ); //código do fabricante
    this.productInformation.description_item = trimString(
      productElement.prod.xProd._text
    ); //descrição completa
    this.productInformation.bar_code = trimString(
      productElement.prod.cEANTrib._text
    );
    this.productInformation.cost_price = trimString(
      productElement.prod.vUnCom._text
    );
    this.productInformation.note_price = trimString(
      productElement.prod.vUnCom._text
    );
    this.productInformation.accounting_price = trimString(
      productElement.prod.vUnCom._text
    );
    this.productInformation.cfop = trimString(productElement.prod.CFOP._text);
    this.productInformation.ncm = trimString(productElement.prod.NCM._text);
    this.productInformation.amount = trimString(productElement.prod.qCom._text);
    //testando
    this.productInformation.isRegistered = productElement.isResgitered;

    this.arrayProduct.push(this.productInformation);

    this.productInformation = {};
  });
}

export default {
  CallDialogRegisterProduct,
  mapArrayAndCreateObjectProduct,
  registerProduct,
  getAllProducts,
  registerItems,
  getAllInformations,
  isRegisteredColor,
  cleanform,
  verifyTaxToObject,
  setGettersObjectToProduct,
  populateInfNote,
  sendInformationNote,
  setShowGlobalDialog,
};
