const headers = [
    {
        text: 'Codigo',
        value: 'product_id'
    },
    {
        text: 'Descrição',
        value: 'description_product'
    },
    {
        text:'Unidade',
        value:'description_unit'
    },
    {
        text: 'Quantidade',
        value: 'amount'
    },
    {
        text: 'Preço(R$)',
        value: 'cust'
    },
    {
        text: 'Descontos',
        value: 'value_descont'
    },
    {
        text: 'Valor Total',
        value: 'total_value' //'total_price'
    },
    {
        text: 'Ações',
        value: 'actions'
    },
];

export {
    headers
};