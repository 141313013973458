<template>
  <div class="mt-5">
    <v-row>
      <v-col>
        <v-select
          outlined
          :items="listEnterprise"
          item-text="fantasyName"
          placeholder="Null"
          v-model="component.purchaseOrder.enterprise_id"
          item-value="id"
          :readonly="component.justVisible"
          label="Empresas"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          placeholder="Null"
          v-model="component.purchaseOrder.data_purchase_order"
          :readonly="component.justVisible"
          type="Date"
          label="Data do Pedido"
          class="transparent-label"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field outlined label="Número do Pedido"></v-text-field>
      </v-col>
      <v-col>
        <v-select
          outlined
          :items="statusOrder"
          v-model="component.purchaseOrder.status"
          :readonly="component.justVisible"
          label="Status do Pedido"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="callRegisters('provider')"
          outlined
          :items="result"
          v-model="component.purchaseOrder.provider_id"
          :readonly="component.justVisible"
          item-text="name"
          item-value="id"
          label="Fornecedor"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          label="Comprador"
          :readonly="component.justVisible"
          v-model="component.purchaseOrder.buyer"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="callRegisters('seller')"
          outlined
          item-text="people.physicalpeople.name"
          :items="listSellers"
          v-model="component.purchaseOrder.seller"
          :readonly="component.justVisible"
          label="Vendedor"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="callRegisters('transporter')"
          label="Transportadora"
          outlined
          item-text="name"
          item-value="id"
          return-object
          :items="transporter_items"
          v-model="component.purchaseOrder.transporter_id"
          :readonly="component.justVisible"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          :items="freight"
          label="*Tipo do Frete"
          :readonly="component.justVisible"
          v-model="component.purchaseOrder.type_frity"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          :items="freight"
          prefix="R$"
          label="*Valor do Frete"
          type="number"
          :readonly="component.justVisible"
          v-model="component.purchaseOrder.value_freigth"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          :items="listPaymentTerm"
          item-text="description"
          item-value="id"
          label="*Condição de Pagamento"
          :readonly="component.justVisible"
          v-model="component.purchaseOrder.payment_term_id"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <RegisterProviderDialog :out_of_register="true" />
    <RegisterTransporterDialog :out_of_register="true" />
    <RegisterSellerDialog :out_of_register="true" />
  </div>
</template>

<script>
import { GET_ALL_PAYMENT_TERM } from "../../../../store/actions/paymentterm";
import { GET_AUTOCOMPLETE_PROVIDER } from "../../../../store/actions/provider";
import { GET_AUTOCOMPLETE_TRANSPORTER } from "../../../../store/actions/transporters";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import { GET_ALL_SELLER } from "../../../../store/actions/seller";
import { mapGetters } from "vuex";
import RegisterProviderDialog from "../../../provider/AddDialog/index.vue";
import RegisterTransporterDialog from "../../../transport/AddDialog/index.vue";
import RegisterSellerDialog from "../../../Sellers/AddDialog/index.vue";

export default {
  components: {
    RegisterProviderDialog,
    RegisterTransporterDialog,
    RegisterSellerDialog,
  },
  props: {
    component: Object(),
  },
  data: () => ({
    statusOrder: [
      {
        value: "Aberto",
        text: "Aberto",
      },
      {
        value: "Fechado",
        text: "Fechado",
      },
    ],
    dialog: false,
    result: Array(), // fornecedor
    transporter_items: Array(),
    transporter: Object(),
    id_transporter: Number(),
    listEnterprise: Array(),
    listPaymentTerm: Array(),
    listSellers: Array(),
    freight: ["CIF", "FOB"],
  }),
  methods: {
    callRegisters(key) {
      if (key == "provider") {
        this.$store.dispatch("setShowDialogRegisterProvider", true);
      } else if (key == "transporter") {
        this.$store.dispatch("setShowDialogRegisterTransporter", true);
      } else if (key == "seller") {
        this.$store.dispatch("setShowDialogRegisterSeller", true);
      }
    },
    async getProviders() {
      await this.$store.dispatch(GET_AUTOCOMPLETE_PROVIDER, {
        page: null,
        limit: null,
        paginate: false,
        type_people: null,
      });
    },
    getAllPaymentTerm() {
      var payload = {
        page: null,
        limit: null,
        paginate: false,
      };
      this.$store.dispatch(GET_ALL_PAYMENT_TERM, payload);
    },
    getAllSellers() {
      var payload = {
        page: null,
        limit: null,
        paginate: false,
      };
      this.$store.dispatch(GET_ALL_SELLER, payload);
    },
    getAllEnterprises() {
      var payload = {
        page: null,
        limit: null,
        paginate: false,
      };
      this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
    name_transporter(value) {
      this.transporter_items = value;
      this.transporter_items.forEach((e) => {
        if (e.type_people === "LegalPeople") {
          e.name = e.people.legalpeople.fantasyName;
        } else {
          e.name = e.people.physicalpeople.name;
        }
      });
    },
    async getAllTransporter() {
      await this.$store.dispatch(GET_AUTOCOMPLETE_TRANSPORTER, {
        page: null,
        paginate: false,
        type_people: "null",
      });
    },
    name_provider(value) {
      this.result = value;
      this.result.forEach((e) => {
        if (e.type_people === "LegalPeople") {
          e.name = e.people.legalpeople.fantasyName;
        } else {
          e.name = e.people.physicalpeople.name;
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "getProviderAuto",
      "getTransporterAuto",
      "getEnterprises",
      "getSeller",
      "getItemToAddSeller",
      "getTransporterToAdd",
      "getPaymentTerms",
    ]),
  },

  watch: {
    getPaymentTerms: function (val) {
      this.listPaymentTerm = Object.values(val);
    },
    transporter: function () {
      this.component.transporter = this.transporter;
    },
    getProviderAuto: function (val) {
      this.name_provider(val);
    },
    getTransporterToAdd: function (val) {
      this.transporter_items.push(val);
    },
    getTransporterAuto: function (val) {
      this.name_transporter(val);
    },
    getEnterprises: function () {
      this.listEnterprise = Object.values(this.getEnterprises);
    },
    getItemToAddSeller: function (val) {
      this.listSellers.push(val);
    },
    getSeller: function (val) {
      this.listSellers = Object.values(val);
    },
  },
  async created() {
    await this.getProviders();
    await this.getAllTransporter();
    this.getAllEnterprises();
    this.getAllSellers();
    this.getAllPaymentTerm();
  },
};
</script>

<style>
.transparent-label .v-label {
  background-color: white;
}
</style>