<template>
  <v-container>
    <v-form :disabled="isDisable" v-model="component.valid">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-select
            label="*Empresa"
            v-model="component.invoice.enterprise_id"
            :items="enterprises"
            item-text="fantasyName"
            item-value="id"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('enterprise')"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-autocomplete
            outlined
            color="success"
            :items="taxoperationtype"
            item-text="description"
            item-value="id"
            label="Tipo de Operação"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('taxoperationtype')"
            v-model="component.invoice.tax_operation_types_id"
            :rules="[
              () =>
                !!component.invoice.tax_operation_types_id ||
                'Campo Obrigatorio',
            ]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            label="Chave de Acesso da NFE"
            v-model="component.invoice.access_key"
            outlined
            :rules="[
              () => !!component.invoice.access_key || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.access_key &&
                  component.invoice.access_key.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            label="Prot. Autorização de uso"
            v-model="component.invoice.authorization_protocol"
            outlined
            :rules="[
              () =>
                !!component.invoice.authorization_protocol ||
                'Campo Obrigatorio',
              () =>
                (!!component.invoice.authorization_protocol &&
                  component.invoice.authorization_protocol.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.nameProvider"
            :items="providers"
            item-text="people.legalpeople.fantasyName"
            item-value="id"
            return-object
            outlined
            label="Fornecedor"
            append-icon="mdi-magnify"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('provider')"
            @click:append="() => togleSelectTableShow()"
            :rules="[(v) => !!v || 'Campo Obrigatorio']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Número da Nota"
            v-model="component.invoice.number_grade"
            outlined
            :rules="[
              () => !!component.invoice.number_grade || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.number_grade &&
                  component.invoice.number_grade.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Serie"
            v-model="component.invoice.series"
            outlined
            :rules="[
              () => !!component.invoice.series || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.series &&
                  component.invoice.series.length >= 1) ||
                'Campo tem que ser maior do que 1',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Data de Emissão"
            placeholder="null"
            type="Date"
            v-model="component.invoice.date_of_issue"
            outlined
            :rules="[
              () => !!component.invoice.date_of_issue || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.date_of_issue &&
                  component.invoice.date_of_issue.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Data de Entrada"
            v-model="component.invoice.entry_date"
            type="Date"
            placeholder="null"
            outlined
            :rules="[
              () => !!component.invoice.entry_date || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.entry_date &&
                  component.invoice.entry_date.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            outlined
            label="Informações Adicionais"
            v-model="component.invoice.note"
          ></v-text-field>
        </v-col>
      </v-row>
    <SelectTable title="Fornecedores" :component="this" />
    </v-form>
    <ProviderDialog :out_of_register="true" :headerTebale="headersLegalPeople" />
    <EnterpriseDialog />
    <TaxOperationType />
  </v-container>
</template>

<script>
// import { GET_AUTOCOMPLETE_PROVIDER } from "../../../../store/actions/provider";
import { GET_ALL_PROVIDER } from "../../../../store/actions/provider";

import { GET_ALL_TAX_OPERATION_TYPE } from "../../../../store/actions/tax_operation_types";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import ProviderDialog from "../../../provider/AddDialog";
import EnterpriseDialog from "../../../enterprise/AddDialog";
import TaxOperationType from "../../../tax_operation_type/AddDialog";
import SelectTable from "../../../Utils/SelectTable/index.vue"
import {headersLegalPeople, headersPhysicalPeople} from "./headersTable"
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
    isDisable: Boolean(),
  },
  components: { ProviderDialog, EnterpriseDialog, TaxOperationType, SelectTable },

  data: () => ({
    selectTableShow: Boolean(false),
    addDialogProvider: Boolean(),
    description: String(),
    provider_id: Number(),
    taxoperationtype: Array(),
    providers: [],
    enterprises: Array(),

    headersSelectTable: headersPhysicalPeople,
    listPeople: Array(),

    headersLegalPeople: headersLegalPeople,
    headersPhysicalPeople: headersPhysicalPeople,

    listProviderPhysicalPeople: Array(),
    listProviderLegalPeople: Array(),

    paginateInf: {
      currentPage: 1,
      lastPage: 1 
    },

    togleTypePeople: false
  }),

  methods: {
    rowClickSelectTalbe(item){
       
        this.component.nameProvider = item.type_people == "PhysicalPeople" ? item.people.physicalpeople.name : item.people.legalpeople.socialName
        this.component.select_provider = item.provider_id
        this.togleSelectTableShow()
    },
    togleSelectTableShow(){
      this.selectTableShow = !this.selectTableShow
    },
    showDialogs(component) {
      if (component == "provider") {
        this.$store.dispatch("setShowDialogRegisterProvider", true);
      } else if (component == "enterprise") {
        this.$store.dispatch("setShowDialogRegisterEnterprise", true);
      } else if (component == "taxoperationtype") {
        this.$store.dispatch("setShowDialogRegisterTaxOperationType", true);
      }
    },
    openAddProvider() {
      this.addDialogProvider = !this.addDialogProvider;
    },

    async GetAllTaxOperationType() {
      await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetAllLegalProvider(page = 1) {
      const payload = {
        type_people: "LegalPeople",
        page: page,
        limit: 10,
        paginate: true,
      }
     
      await this.$store.dispatch(GET_ALL_PROVIDER, payload ).then((response) => {
        this.listProviderLegalPeople = [...response.data]
          // 
      });
     
    },
    async GetAllPhysicalProvider(page = 1){
      const payload = {
        type_people: "PhysicalPeople",
        page: page,
        limit: 10,
        paginate: true,
      }
      await this.$store.dispatch(GET_ALL_PROVIDER, payload ).then((response) => {
          this.listProviderPhysicalPeople = [...response.data]
          this.listPeople = [...response.data]
          // 
      });
    },
    async GetAllEnterprise() {
      //   let page = 1;
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: null,
        limit: null,
        paginate: null,
      });
    },

    mountedTaxOperationType() {
      let tax = Object.values(this.getTaxOperationType);
      this.taxoperationtype = tax.filter((e) => e.type == "ENTRADA");
    },
    refresh_Provider(val) {
      this.providers = val;
    },
  },
  watch: {
    togleTypePeople: function(val){
      if(val){
        this.headersSelectTable = [...this.headersLegalPeople]
        this.listPeople = [...this.listProviderLegalPeople]
      }else{
        this.headersSelectTable = [...this.headersPhysicalPeople]
        this.listPeople = [...this.listProviderPhysicalPeople]
      }
    },
    getNewTaxOperationToAdd: function (val) {
      this.taxoperationtype.push(val);
      this.component.invoice.tax_operation_types_id = val.id;
    },
    getProviderAuto: function (val) {
      this.refresh_Provider(val);
    },
    getTaxOperationType: function () {
      this.mountedTaxOperationType();
    },
    getNewProviderToAdd: function (value) {
      var newProvider = {
        ...value,
        people: {
          legalpeople: {
            fantasyName: value.legalpeople.fantasyName,
          },
        },
      };
      this.providers.push(newProvider);
      this.component.select_provider = value.id;
    },
    getEnterprises: function (val) {
      this.enterprises = Object.values(val);
      this.selectValue = this.enterprises[0];
    },
  },
  computed: {
    ...mapGetters([
      "getProviderAuto",
      "getTaxOperationType",
      "getEnterprises",
      "getNewProviderToAdd",
      "getNewTaxOperationToAdd",
    ]),
  },

  async created() {
    await this.GetAllTaxOperationType();
    await this.GetAllLegalProvider();
    await this.GetAllPhysicalProvider()
    await this.GetAllEnterprise();
  },
};
</script>
