<template>
  <v-dialog max-width="1500px" v-model="component.nfceDialog">
      <v-card>
          <div class="container-header-select-table">
              <p class="title-select-table">NFCE EMITIDAS</p>
              <v-text-field hint="Pesquisar" append-icon="mdi-magnify" outlined v-model="component.searchNfce"/>
          </div>
          <v-data-table @click:row="component.rowClickSelectTableNfce" hide-default-footer :items="component.nfceItems" :headers="component.nfceHeaders">
          </v-data-table>
          <v-pagination v-model="component.currentPageNfce" :length="component.lastPageNfce" :total-visible="10" />
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      component: Object(),
      title: String(),
  },
  data: function () {
      return {
      }
  },
  created() {
  }
}
</script>

<style lang="css" scoped>
.title-select-table {
  text-align: start;
}

.container-header-select-table {
  padding: 5%;
}

.type-person-select-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.title-type-person-switch {
  font-size: 15px;
  margin-top: 10px;
}</style> >

.title-type-person-switch {
  font-size: 15px;
  margin-top: 10px;
}
</style> >