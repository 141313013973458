<template>
  <v-row>
    <v-dialog persistent v-model="component.dialog_alert" max-width="450">
      <v-card>
        <v-card-title>
          <v-img src="./error_icon.png" max-width="40" class="mr-5"></v-img
          >{{ title_error }}</v-card-title
        >
        <v-subheader>* {{ listErrors }}</v-subheader>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="component.dialog_alert = !component.dialog_alert"
            color="error"
            text
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
    title_error: String,
    listErrors: String,
  },
};
</script>

<style>
</style>