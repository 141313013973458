/* eslint-disable no-unused-vars */

import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard";
import Login from "../views/Login.vue";
import Aliquot from "../views/Aliquot.vue";
import PisCofins from "../views/Pis_cofins.vue";
import Bank from "../views/Bank";
import Cashier from "../views/Cashier";
import CFOP from "../views/CFOP";
import CSON from "../views/CSON.vue";
import CST from "../views/CST";
import NCM from "../views/NCM";
import ProductGroup from "../views/ProductGroup";
import Barcode from "../views/Barcode";
import Unit from "../views/Unit.vue";
import FormOfPayment from "../views/Formofpayments.vue";
import PaymentTerm from "../views/Paymentterm.vue";
import CompanyGroup from "../views/companygroup.vue";
import Card_adm from "../views/Card_adm.vue";
import Account_plans from "../views/Account_plans.vue";
import Note_models from "../views/Note_models.vue";
import Product from "../views/Product.vue";
import Client from "../views/Client.vue";
import Enterprise from "../views/Enterprise.vue";
import Provider from "../views/Provider.vue";
import Profession from "../views/Profession.vue";
import SubGroupProduct from "../views/SubGroupProduct.vue";
import Transporter from "../views/Transporter.vue";
import Seller from "../views/Seller.vue";
import CommissionType from "../views/CommissionType.vue";
import CashierOperationType from "../views/CashierTypeOperation";
import TaxOperationType from "../views/taxOperationType";
import StockBalance from "../views/Stock_balance";
import MoveStock from "../views/MoveStock";
import NoteEntry from "../views/NoteEntry";
import Revenues from "../views/Revenues";
import PreOrderRevenues from "../views/Pre_order_revenues.vue";
import billsPay from "../views/bills_pay";
import billsReceive from "../views/bills_receive";
import financialMovement from "../views/financialMovement";
import TitleDown_pay from "../views/TitleDown_pay";
import TitleDown_receive from "../views/TitleDown_receive";
import Purchase_order from "../views/Purchase_order";
import BookSupervisionIn from "../views/BookSupervisionIn.vue";
import Inventory from "../views/Inventory";
import User from "../views/User";
import Permission from "../views/Permission";
// import Parameter from "../views/Parameter";
import ReportProduct from "../views/Reports/product/";
import ReportStockBalance from "../views/Reports/stock_balance";
import ReportClient from "../views/Reports/client/";
import ReportProvider from "../views/Reports/provider";
import ReportBillsPay from "../views/Reports/bills_pay";
import ReportBillsReceive from "../views/Reports/bills_receive";
import ReportFinancialMovement from "../views/Reports/financialMovement";
import ReportNoteEntry from "../views/Reports/note_entry";
import ReportFiscalBook from "../views/Reports/fiscal_book";
import PreNoteEntry from "../views/Pre_Note_Entry.vue";
import BookSupervisionOut from "../views/BookSupervisionOut";
import PDV from "../views/Pdv.vue";
import TaxCupomActive from "../views/TaxCupomActive.vue";
import Home from "../views/Home";
import NotaFiscal from "../views/Notenfe";
import NotaNfce from "../views/Notenfe";
import CorrectionLetter from "../views/CorrectionLetter.vue";
import CancelSequenceInvoice from "../views/CancelSequenceInvoice.vue";
import TaxCupom from "../views/TaxCupon.vue";
import WriteOffXML from "../views/Write_off_xml.vue";
import ReportSales from "../views/Reports/sales/index.vue";
import ShortCutsReports from "../views/Reports/Shortcuts"
import ImportXml from "../views/NoteEntryXML.vue"
import SpedIcms from "../views/sped/icms/Sped_icms.vue"
import ExchangeControl from "../views/ExchangeControl.vue"
import ReportComissionSeller from "../views/Reports/comission_seller"
import EmitionTag from "../views/EmitionTag.vue"
import FutureSales from "../views/FutureSales.vue"
import DashBoardChart from "../views/DashBoardCharts.vue"
import Parameters from "../views/Parameters.vue"
import SaleConferenceReport from "../views/Reports/SalesConference/index.vue"
import CheckSaleStats from "../views/Reports/sale_stats/index.vue"
// import ReportIssueNFE from "../views/Reports/issue_NFE/index.vue"
export default () => [
  {
    path: "/Parameters",
    name: "Parameters",
    component: Parameters,
  },
  {
    path: "/future-sales",
    name: "Future Sales",
    component: FutureSales,
  },
  {
    path: "/dashboard-charts",
    name: "DashBoard Charts",
    component: DashBoardChart,
  },
  {
    path: "/comission-seller",
    name: "Comission Seller",
    component: ReportComissionSeller,
  },
  {
    path: "/emition-tag",
    name: "Emition Tag",
    component: EmitionTag,
  },
  {
    path: "/sped-icms",
    name: "Sped Icms",
    component: SpedIcms,
  },
  {
    path: "/shortcuts-reports",
    name: "Report Sales",
    component: ShortCutsReports,
  },
  {
    path: "/report-sales",
    name: "Report Sales",
    component: ReportSales,
  },
  {
    path: "/WriteOffXML",
    name: "Write off XML",
    component: WriteOffXML,
  },
  {
    path: "/importxml",
    name: "ImportXML",
    component: ImportXml,
  },

  // {
  //     path: "/ReportIssueNFE",
  //     name: "ReportIssueNFE",
  //     component: ReportIssueNFE,
  // },
  {
    path: "/TaxCupom",
    name: "TaxCupom",
    component: TaxCupom,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/CancelSequenceInvoice",
    name: "Sequence Calcel Invoice",
    component: CancelSequenceInvoice,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/correctionLetter",
    name: "Correction Letter",
    component: CorrectionLetter,
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/Aliquot",
    name: "Aliquot ",
    component: Aliquot,
  },
  {
    path: "/PreOrderRevenues",
    name: "PreOrderRevenues ",
    component: PreOrderRevenues,
  },
  {
    path: "/PisCofins",
    name: "PisCofins",
    component: PisCofins,
  },
  {
    path: "/Bank",
    name: "Bank",
    component: Bank,
  },
  {
    path: "/Cashier",
    name: "Cashier",
    component: Cashier,
  },
  {
    path: "/CFOP",
    name: "CFOP",
    component: CFOP,
  },
  {
    path: "/CSON",
    name: "CSON",
    component: CSON,
  },
  {
    path: "/CST",
    name: "CST",
    component: CST,
  },

  {
    path: "/ProductGroup",
    name: "ProductGroup",
    component: ProductGroup,
  },
  {
    path: "/Barcode",
    name: "Barcode",
    component: Barcode,
  },
  {
    path: "/NCM",
    name: "NCM",
    component: NCM,
  },
  {
    path: "/UNIT",
    name: "Unit",
    component: Unit,
  },

  {
    path: "/formofpayment",
    name: "FormOfPayment",
    component: FormOfPayment,
  },
  {
    path: "/paymentterm",
    name: "Paymentterm",
    component: PaymentTerm,
  },

  {
    path: "/Card_adm",
    name: "Card_adm",
    component: Card_adm,
  },

  {
    path: "/Account_plans",
    name: "Account_plans",
    component: Account_plans,
  },

  {
    path: "/Note_models",
    name: "Note_models",
    component: Note_models,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },

  {
    path: "/client",
    name: "Client",
    component: Client,
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    component: Enterprise,
  },
  {
    path: "/provider",
    name: "Provider",
    component: Provider,
  },
  {
    path: "/profession",
    name: "Profession",
    component: Profession,
  },
  {
    path: "/subproductgroup",
    name: "SubGroup",
    component: SubGroupProduct,
  },
  {
    path: "/transporter",
    name: "Transporter",
    component: Transporter,
  },

  {
    path: "/cashieroperation",
    name: "CashierOperation",
    component: CashierOperationType,
  },
  {
    path: "/seller",
    name: "Seller",
    component: Seller,
  },
  {
    path: "/commissiontype",
    name: "CommissionType",
    component: CommissionType,
  },
  {
    path: "/taxoperationtype",
    name: "Taxoperationtype",
    component: TaxOperationType,
  },
  {
    path: "/companygroup",
    name: "CompanyGroup",
    component: CompanyGroup,
  },
  {
    path: "/Stock_balance",
    name: "Stock_balance",
    component: StockBalance,
  },
  {
    path: "/move_stock",
    name: "move_stock",
    component: MoveStock,
  },
  {
    path: "/Pre_Note_Entry",
    name: "Pre_Note_Entry",
    component: PreNoteEntry,
  },
  {
    path: "/note_entry",
    name: "note_entry",
    component: NoteEntry,
  },
  {
    path: "/revenues",
    name: "revenues",
    component: Revenues,
  },
  {
    path: "/bills_pay",
    name: "bills_pay",
    component: billsPay,
  },
  {
    path: "/bills_receive",
    name: "bills_receive",
    component: billsReceive,
  },
  {
    path: "/financial_movement",
    name: "financial_movement",
    component: financialMovement,
  },
  {
    path: "/TitleDown_pay",
    name: "TitleDown_pay",
    component: TitleDown_pay,
  },
  {
    path: "/purchase_order",
    name: "Purchase_order",
    component: Purchase_order,
  },
  {
    path: "/titleDown_receive",
    name: "TitleDown_receive",
    component: TitleDown_receive,
  },
  {
    path: "/BookSupervisionIn",
    name: "BookSupervisionIn",
    component: BookSupervisionIn,
  },
  {
    path: "/BookSupervisionOut",
    name: "BookSupervisionOut",
    component: BookSupervisionOut,
  },
  {
    path: "/Inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/User",
    name: "User",
    component: User,
  },
  {
    path: "/Permission",
    name: "Permission",
    component: Permission,
  },
  // {
  //   path: "/Parameter",
  //   name: "Parameter",
  //   component: Parameter,
  // },
  {
    path: "/ReportProduct",
    name: "ReportProduct",
    component: ReportProduct,
  },
  {
    path: "/ReportStockBalance",
    name: "ReportStockBalance",
    component: ReportStockBalance,
  },
  {
    path: "/ReportClient",
    name: "ReportClient",
    component: ReportClient,
  },
  {
    path: "/ReportProvider",
    name: "ReportProvider",
    component: ReportProvider,
  },
  {
    path: "/ReportBillsPay",
    name: "ReportBillsPay",
    component: ReportBillsPay,
  },
  {
    path: "/ReportBillsReceive",
    name: "ReportBillsReceive",
    component: ReportBillsReceive,
  },
  {
    path: "/ReportFinancialMovement",
    name: "ReportFinancialMovement",
    component: ReportFinancialMovement,
  },
  {
    path: "/ReportNoteEntry",
    name: "ReportNoteEntry",
    component: ReportNoteEntry,
  },
  {
    path: "/ReportFiscalBook",
    name: "ReportFiscalBook",
    component: ReportFiscalBook,
  },
  {
    path: "/PDV",
    name: "PontoDeVenda",
    component: PDV,
  },
  {
    path: "/TaxCupomActive",
    name: "CumpomFiscal",
    component: TaxCupomActive,
  },
  {
    path: "/NotaFiscal",
    name: "NotaFiscal",
    component: NotaFiscal,
  },

  {
    path: "/NotaNfce",
    name: "NotaNfce",
    component: NotaNfce,
  },

  {
    path: "/exchange-control",
    name: "Exgange Control",
    component: ExchangeControl,
  },

  { 
    path: "/saleConferenceReport",
    name: "SaleConferenceReport",
    component: SaleConferenceReport,
  },

  { 
    path: "/closeCashierReport",
    name: "CloseCashierReport",
    component: () => import("@/views/Reports/close_cashier/index.vue"),
  },

  { 
    path: "/icmsReport",
    name: "IcmsReport",
    component: () => import("@/views/Reports/icmsApuration/index.vue"),
  },

  { 
    path: "/billsToPayReversal",
    name: "BillsToPayReversal",
    component: () => import("@/views/billsToPayReversal"),
  },

  {
    path: "/checksalestats",
    name: "CheckSaleStats",
    component: CheckSaleStats,
  },





];
