<template>
  <v-row>
    <v-card-title primary-title>Ponto de Venda</v-card-title>
    <v-spacer></v-spacer>
    <v-col>
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-value="id"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
      >
        Adicionar
      </v-select>
    </v-col>
    <v-btn color="success mr-2 mt-3" @click="open_Dialog()"> Adicionar </v-btn>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
export default {
  props: {
    component: Object,
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  data() {
    return {
      selectValue: Object(),
      enterprises: Array(),
      count_aux: 0,
    };
  },

  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },

    open_Dialog() {
      this.component.enterprise_selected = this.selectValue.company_group_id;
      this.alterAddDialog(this.component);
    },

    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
  },

  created() {
    this.GetAllNoPage();
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.selectValue = this.enterprises[0];
    },

    selectValue: function () {
      // console.log(this.selectValue.company_group_id);
      this.component.enterprise_selected = this.selectValue.company_group_id;
    },
  },
};
</script>

<style>
</style>