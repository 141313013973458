<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Produto</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="enterprise"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon>Visualizar
          </v-btn>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form" />
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Form from "../../../components/report/product/Form/";

import { GET_ALL_NCM } from "../../../store/actions/ncm";
import { GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";

export default {
  components: { Form },

  data: () => ({
    dialog: Boolean(),
    userName: String(),
    productGroup: [],
    ncm: [],
    enterprise: [],
    valid: false,
    selectValue: Object,
    report: {
      dateBuy: null,
      datePromotion: null,
      codProductInitial: null,
      codProductFinal: null,
      groupProducts: null,
      ncm: null,
      moinimoStock: null,
      maximoStock: null,
    },
  }),

  methods: {
    async Generate() {
      let all_report = Object.assign({}, this.report);
      const response = await axios.post("catalog/product", {
        ...all_report,
      });

      response.data[0].forEach((e) => {
        e.cost_price = e.cost_price
          ? `R$ ${e.cost_price.toFixed(2).replace(".", ",")}`
          : "R$ 0,00";
      });

      this.dialog = !this.dialog;
      this.$refs.form.reset();
      this.$refs.form.validate();
      this.$refs.form.resetValidation();
      let arrayenterprise = [];
      let arrayaddress = [];
      let objeto = Object.assign({}, this.selectValue);
      let objetoadress = Object.assign({}, this.selectValue.address);
      arrayenterprise.push(objeto);
      arrayaddress.push(objetoadress);

      this.createPDF(response.data[0], arrayenterprise, arrayaddress);
    },

    createPDF(items, enterprise, address) {
      let data = new Date();
      var doc = new jsPDF("p", "pt", "a4");

      var columns = [
        { title: "Código do Produto", dataKey: "product_id" },
        { title: "Código de Barras", dataKey: "bar_code" },
        { title: "Descrição", dataKey: "description_item" },
        { title: "Unidade", dataKey: "sigla" },
        { title: "Grupo de Produto", dataKey: "product_group_id" },
        { title: "Sub Grupo de Produto", dataKey: "sub_group_product_id" },
        { title: "Preço de Venda", dataKey: "cost_price" },
      ];

      doc.setFontSize(7);
      doc.text(`Usuário : ${this.userName}`, 430, 40);
      doc.text(`${data.toLocaleString()}`, 430, 50);
      doc.setFontSize(15);
      doc.text("RELATÓRIO DE PRODUTOS", 190, 70);
      doc.setFontSize(7);
      doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
      doc.text(`Razão Social : ${enterprise[0].name}`, 140, 100);
      doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 430, 100);
      doc.text(`Rua : ${address[0].street}`, 40, 115);
      doc.text(`Numero : ${address[0].number}`, 200, 115);
      doc.text(`Cidade : ${address[0].city}`, 255, 115);
      doc.text(`Estado : ${address[0].state}`, 400, 115);
      doc.text(`CEP : ${address[0].cep}`, 500, 115);
      doc.setLineWidth(1);
      doc.line(40, 85, 555, 85);
      doc.line(40, 125, 555, 125);

      doc.setFontSize(8);
      const pages = doc.internal.getNumberOfPages();
      for (let j = 1; j < pages + 1; j++) {
        doc.autoTable(columns, items, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: 145,
          theme: "grid",
          margin: {
            bottom: 60,
          },
        });
      }

      const page = doc.internal.getNumberOfPages();
      for (let j = 1; j < page + 1; j++) {
        doc.setLineWidth(1);
        doc.rect(20, 20, 555, 800);

        doc.setPage(j);
        doc.text(`${j} / ${page}`, 550, 32);
      }

      doc.output("dataurlnewwindow");
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };

      await this.$store.dispatch(GET_ALL_NCM, value);
      await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },

  computed: {
    ...mapGetters([
      "getProduct",
      "getNcm",
      "getProductGroup",
      "getEnterprises",
    ]),
    ...mapGetters({ userLogin: "auth/userLogin" }),
  },

  created() {
    this.GetAllNoPage();
    this.userName = this.userLogin.user.name;
  },

  watch: {
    getEnterprises(val) {
      this.enterprise = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.enterprise[0]);
      this.selectValue = aux;
    },

    getNcm(val) {
      this.ncm = Object.keys(val).map((key) => val[key]);
    },

    getProductGroup(val) {
      this.productGroup = Object.keys(val).map((key) => val[key]);
    },
  },
};
</script>

