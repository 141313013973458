

const GET_ALL_TAX_CUPOM = "GET_ALL_TAX_CUPOM"
const ITEM_TO_CANCEL = "ITEM_TO_CANCEL"
const CANCEL_TAX_CUPOM = "CANCEL_TAX_CUPOM"
const SET_CNPJ = "SET_CNPJ"
const REPRINT_NFCE_WITH_KEY = "REPRINT_NFCE_WITH_KEY"
const GET_FILTER_TAX_CUPOM = "GET_FILTER_TAX_CUPOM"
const GET_CLOSE_CASHIER_REPORT = "GET_CLOSE_CASHIER_REPORT"
const UPDATE_TO_CANCEL = "UPDATE_TO_CANCEL"
const CANCEL_AFTER_DEADLINE = "CANCEL_AFTER_DEADLINE"
const CHANGE_FORM_OF_PAYMENT = "CHANGE_FORM_OF_PAYMENT"

module.exports = {
    GET_ALL_TAX_CUPOM,
    ITEM_TO_CANCEL,
    CANCEL_TAX_CUPOM,
    SET_CNPJ,
    REPRINT_NFCE_WITH_KEY,
    GET_FILTER_TAX_CUPOM,
    GET_CLOSE_CASHIER_REPORT,
    UPDATE_TO_CANCEL,
    CANCEL_AFTER_DEADLINE,
    CHANGE_FORM_OF_PAYMENT,
}