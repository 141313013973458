const ADD_PROFESSION = "ADD_PROFESSION";
const UPDATE_PROFESSION = "UPDATE_PROFESSION";
const GET_ALL_PROFESSION = "GET_ALL_PROFESSION";
const DESTROY_PROFESSION = "DESTROY_PROFESSION";
const GET_FILTER_PROFESSION = "GET_FILTER_PROFESSION";

module.exports = {
  ADD_PROFESSION,
  UPDATE_PROFESSION,
  GET_ALL_PROFESSION,
  DESTROY_PROFESSION,
  GET_FILTER_PROFESSION
};
