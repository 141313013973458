<template>
  <div class="position">
    <v-alert class="rounded-xl" type="info" :value="active">
      {{ this.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean(),
    message: String(),
  },
};
</script>

<style>
.position {
  width: 100%;
  padding: 20px;
  position: absolute;
}
</style>