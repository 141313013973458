var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-form',{attrs:{"disabled":_vm.isDisable},model:{value:(_vm.component.validGenerateDate),callback:function ($$v) {_vm.$set(_vm.component, "validGenerateDate", $$v)},expression:"component.validGenerateDate"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xl":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","color":"success","items":_vm.taxoperationtype,"item-text":"description","item-value":"id","label":"*Tipo de Operação","append-outer-icon":"mdi-plus-circle","rules":[
              () =>
                !!_vm.component.invoice.tax_operation_types_id ||
                'Campo Obrigatorio',
            ]},on:{"click:append-outer":function($event){return _vm.callDialogs('operationtypedialog')}},model:{value:(_vm.component.invoice.tax_operation_types_id),callback:function ($$v) {_vm.$set(_vm.component.invoice, "tax_operation_types_id", $$v)},expression:"component.invoice.tax_operation_types_id"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xl":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","color":"success","items":_vm.enterprises,"item-text":"fantasyName","item-value":"id","label":"*Empresa","append-outer-icon":"mdi-plus-circle","rules":[(v) => !!v || 'Campo Obrigatorio']},on:{"click:append-outer":function($event){return _vm.callDialogs('enterprisedialog')}},model:{value:(_vm.component.invoice.enterprise_id),callback:function ($$v) {_vm.$set(_vm.component.invoice, "enterprise_id", $$v)},expression:"component.invoice.enterprise_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xl":"6"}},[_c('v-text-field',{attrs:{"label":"*Chave de Acesso da NFE","outlined":"","rules":[
            () => !!_vm.component.invoice.access_key || 'Campo Obrigatorio',
            () =>
              (!!_vm.component.invoice.access_key &&
                _vm.component.invoice.access_key.length >= 2) ||
              'Campo tem que ser maior do que 2',
          ]},model:{value:(_vm.component.invoice.access_key),callback:function ($$v) {_vm.$set(_vm.component.invoice, "access_key", $$v)},expression:"component.invoice.access_key"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xl":"6"}},[_c('v-text-field',{attrs:{"label":"*Prot. Autorização de uso","outlined":"","rules":[
              () =>
                !!_vm.component.invoice.authorization_protocol ||
                'Campo Obrigatorio',
              () =>
                (!!_vm.component.invoice.authorization_protocol &&
                  _vm.component.invoice.authorization_protocol.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]},model:{value:(_vm.component.invoice.authorization_protocol),callback:function ($$v) {_vm.$set(_vm.component.invoice, "authorization_protocol", $$v)},expression:"component.invoice.authorization_protocol"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"4","xl":"4"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":"","append-icon":"mdi-magnify","label":"*Fornecedor","append-outer-icon":"mdi-plus-circle","rules":[
              (v) => !!v || 'Campo Obrigatorio',
            ]},on:{"click:append-outer":function($event){return _vm.callDialogs('providerdialog')},"click:append":_vm.openAddProvider},model:{value:(_vm.component.provider_name),callback:function ($$v) {_vm.$set(_vm.component, "provider_name", $$v)},expression:"component.provider_name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"4","xl":"4"}},[_c('v-text-field',{attrs:{"label":"*Número da Nota","outlined":"","rules":[
            () => !!_vm.component.invoice.number_grade || 'Campo Obrigatorio',
            () =>
              (!!_vm.component.invoice.number_grade &&
                _vm.component.invoice.number_grade.length >= 2) ||
              'Campo tem que ser maior do que 2',
          ]},model:{value:(_vm.component.invoice.number_grade),callback:function ($$v) {_vm.$set(_vm.component.invoice, "number_grade", $$v)},expression:"component.invoice.number_grade"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"4","xl":"4"}},[_c('v-text-field',{attrs:{"label":"*Série","outlined":"","rules":[
            () => !!_vm.component.invoice.series || 'Campo Obrigatorio',
            () =>
              (!!_vm.component.invoice.series &&
                _vm.component.invoice.series.length >= 1) ||
              'Campo tem que ser maior do que 1',
          ]},model:{value:(_vm.component.invoice.series),callback:function ($$v) {_vm.$set(_vm.component.invoice, "series", $$v)},expression:"component.invoice.series"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"4","xl":"4"}},[_c('v-text-field',{attrs:{"label":"*Data de Emissão","placeholder":"null","type":"Date","outlined":"","rules":[
              () => !!_vm.component.invoice.date_of_issue || 'Campo Obrigatorio',
              () =>
                (!!_vm.component.invoice.date_of_issue &&
                  _vm.component.invoice.date_of_issue.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]},model:{value:(_vm.component.invoice.date_of_issue),callback:function ($$v) {_vm.$set(_vm.component.invoice, "date_of_issue", $$v)},expression:"component.invoice.date_of_issue"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"4","xl":"4"}},[_c('v-text-field',{attrs:{"label":"*Data de Entrada","type":"Date","placeholder":"null","outlined":"","rules":[
              () => !!_vm.component.invoice.entry_date || 'Campo Obrigatorio',
              () =>
                (!!_vm.component.invoice.entry_date &&
                  _vm.component.invoice.entry_date.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]},model:{value:(_vm.component.invoice.entry_date),callback:function ($$v) {_vm.$set(_vm.component.invoice, "entry_date", $$v)},expression:"component.invoice.entry_date"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"4","xl":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Informações Adicionais"},model:{value:(_vm.component.invoice.note),callback:function ($$v) {_vm.$set(_vm.component.invoice, "note", $$v)},expression:"component.invoice.note"}})],1)],1)],1),_c('TableProvider',{attrs:{"component":this,"title":"Fornecedor"}}),(_vm.getShowDialogRegisterTaxOperationType)?_c('OperationTypeDialog'):_vm._e(),(_vm.getShowDialogRegisterEnterprise)?_c('EnterpriseDialog'):_vm._e(),(_vm.getShowDialogRegisterProvider)?_c('ProviderDialog',{attrs:{"out_of_register":"true"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }