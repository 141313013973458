<template>
  <v-row justify="center">
    <v-dialog v-model="getShowDialogRegisterClient" fullscreen hide-overlay persistent
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="() => {
            close_dialog();
          }
            ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ header }} Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>
            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-home-account</v-icon>
              Endereço
            </v-tab>
            <v-tab href="#tab3" v-if="!register">
              <v-icon class="mr-1">mdi-card-account-mail</v-icon> Pessoal
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-family-tree</v-icon>
              Contato
            </v-tab>
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-bank</v-icon> Contas Bancárias
            </v-tab>

            <v-tab-item value="tab1">
              <v-switch v-model="register" label="Pessoa Jurídica"></v-switch>
              <v-card flat v-if="!register">
                <TabPhisical :component="this" ref="phys" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" ref="legal" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab2">
              <v-card flat>
                <v-card flat>
                  <TabAddress :component="this" />
                </v-card>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3">
              <v-card flat>
                <TabPessoal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4">
              <TabContact :component="this" />
            </v-tab-item>
            <v-tab-item value="tab5">
              <v-card flat>
                <TabBank :component="this" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!justView" @click="() => {
              clear();
            }
              " class="error">
              <v-icon class="mr-2">mdi-cancel</v-icon> Limpar Formulário
            </v-btn>
            <v-btn v-if="!justView" :disabled="!valid" color="success" @click="() => {
              submit();
            }
              ">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TabPhisical from "../Tabs/TabPhisical/";
import TabLegal from "../Tabs/TabLegal/";
import TabPessoal from "../Tabs/TabPersonal/";
import TabContact from "../Tabs/TabContact/index.vue";
import TabAddress from "../Tabs/TabAddress/index.vue";
import TabBank from "../Tabs/TabBank/";
import { ADD_PF_CLIENT, ADD_PJ_CLIENT, GET_ALL_CLIENT, ITEM_TO_UPDATE_CLIENT } from "../../../store/actions/client";
import { mapGetters } from "vuex";
import Methods from "./helpers/methods"
import {
  UPDATE_PF_CLIENT,
  UPDATE_PJ_CLIENT,
} from "../../../store/actions/client";
import { cleanCNPJ, cleanString } from "../../Utils/global_functions/clean_cnpj_cpf"
export default {
  props: { component: Object(),/* id: Number(),*/ out_of_register: Boolean() },
  components: {
    TabPhisical,
    TabLegal,
    TabContact,
    TabAddress,
    TabPessoal,
    TabBank,
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterClient", "getSelectedEnterprise", "getItemToUpdateClient"]),
  },
  data: () => ({
    justView: false,
    finishUpdate: undefined,
    header: String(),
    id: undefined,
    enterprise_id: Number(),
    register: false,
    valid: Boolean(),
    validPhysical: Boolean(),
    validLegal: Boolean(),
    validAddress: Boolean(), 
    validPersonal: Boolean(),
    validContact: Boolean(),
    validBankAccount: Boolean(),
    validTable: Boolean(false),
    physicalClient: {
      name: String(),
      lastName: String(),
      cpf: String(),
      gender: String(),
      emitter: String(),
      emitterState: String(),
      idCard: String(),
      dateOfBirth: String(),
      fatherName: String(),
      motherName: String(),
      nacionality: String(),
      naturality: String(),
      monthlyIncome: String(),
      civilStatus: String(),
      spouse: String(),
      icomeSpouse: Number(),
      // people_id: Number(),
      people_id: undefined,
    },
    LegalPeople: {
      cnpj: String(),
      fantasyName: String(),
      socialName: String(),
      stateRegistration: String(),
      municipalRegistration: String(),
      homePage: String(),
      // people_id: Number(),
      people_id: undefined,
    },
    client: {
      type_people: String(),
      people_id: Number(),
    },
    address: {
      id: undefined,
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: String(),
      state: String(),
      cep: String(),
      city_id: Number(),
      people_id: Number(),
    },
    contact: {
      primaryEmail: "",
      secundaryEmail: "",
      phone: "",
      cellPhone: "",
      phoneReference: "",
      people_id: Number(),
    },
    banks: {
      id: undefined,
      bank: String(),
      agency: String(),
      count: Number(),
      manager: String(),
      people_id: Number(),
    },
    cityObject: Object(),
  }),

  methods: {
    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        type_people: "PhysicalPeople",
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        type_people: "LegalPeople",
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    ...Methods,

    close_dialog() {
      this.justView = false
      this.finishUpdate = true
      this.wipeData()
      this.cityObject = null
      this.address.state = null
      this.header = "Criar"
      this.$store.dispatch(ITEM_TO_UPDATE_CLIENT, null);
      this.$store.dispatch("setShowDialogRegisterClient", false);
    },
    // validTotal() {
    //   if (this.ListAddress.length > 0) {
    //     if (this.ListContact.length > 0) {
    //       if (this.ListBank.length > 0) {
    //         this.validTable = true;
    //       }
    //     }
    //   }
    // },

    submit() {
      this.physicalClient.cpf = this.physicalClient.cpf.replace(/\D/g, "");
      if (this.physicalClient.people_id == undefined && this.LegalPeople.people_id == undefined) {
        this.create()
      } else {
        this.update()
      }
    },


    async update() {
      if (this.register === true) {

        let newPeople = Object();
        newPeople.id = this.id
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.legal_people.cnpj = cleanCNPJ(newPeople.legal_people.cnpj)
        newPeople.people_id = this.LegalPeople.people_id;
        newPeople.type_people = "LegalPeople";
        newPeople.address = { ...this.address };
        newPeople.address.state = this.address.state.value
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;
        if (this.out_of_register) {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        } else {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        }
        await this.$store.dispatch(UPDATE_PJ_CLIENT, newPeople).then(() => {
          this.GetAllPhysical()
          this.GetAllLegal()
          this.close_dialog();
        });
      } else {
        let newPeople = Object();
        newPeople.client = { id: this.id, type_people: "PhysicalPeople", people_id: this.physicalClient.people_id }
        newPeople.physical_people = { ...this.physicalClient };
        newPeople.address = { ...this.address };
        newPeople.address.state = this.address.state.value
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;
        if (this.out_of_register) {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        } else {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        }
        await this.$store.dispatch(UPDATE_PF_CLIENT, newPeople).then(() => {
          this.GetAllPhysical()
          this.GetAllLegal()
          this.close_dialog();
        });
      }
    },

    async create() {
      if (this.register === true) {
        let newPeople = Object();
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.legal_people.cnpj = cleanCNPJ(newPeople.legal_people.cnpj)
        newPeople.people_id = "";
        newPeople.type_people = "LegalPeople";
        newPeople.address = { ...this.address };
        newPeople.address.state = this.address.state.value
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;

        newPeople.address.cep = cleanString(this.address.cep)
        newPeople.contacts.phone = cleanString(this.contact.phone)
        newPeople.contacts.cellPhone = cleanString(this.contact.cellPhone)
        newPeople.contacts.phoneReference = cleanString(this.contact.phoneReference)
        if (this.out_of_register) {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        } else {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        }
        await this.$store.dispatch(ADD_PJ_CLIENT, newPeople).then(() => {
          this.GetAllPhysical()
          this.GetAllLegal()
          this.close_dialog();
        });
      } else {
        let newPeople = Object();

        newPeople.physical_people = { ...this.physicalClient };
        newPeople.type_people = "PhysicalPeople";
        newPeople.people_id = "";
        newPeople.address = { ...this.address };
        newPeople.address.state = this.address.state.value
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;

        newPeople.address.cep = cleanString(this.address.cep)
        newPeople.contacts.phone = cleanString(this.contact.phone)
        newPeople.contacts.cellPhone = cleanString(this.contact.cellPhone)
        newPeople.contacts.phoneReference = cleanString(this.contact.phoneReference)
        if (this.out_of_register) {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        } else {
          newPeople.enterprise_id = this.getSelectedEnterprise.id;
        }
        await this.$store.dispatch(ADD_PF_CLIENT, newPeople).then(() => {
          this.GetAllPhysical()
          this.GetAllLegal()
          this.close_dialog();
        });
      }


    },
    empty_array(array, main_array) {
      if (array.length === 0) {
        main_array.push({});
      }
    },
    clear() {
      if (this.register) {
        this.clear_legal();
      } else {
        this.clear_Physical();
      }
      this.address = [];
      this.contacts = [];
      this.banks = [];
    },
    clear_Physical() {
      this.physicalClient.name = "";
      this.physicalClient.lastName = "";
      this.physicalClient.cpf = "";
      this.physicalClient.gender = "";
      this.physicalClient.emitter = "";
      this.physicalClient.emitterState = "";
      this.physicalClient.idCard = "";
      this.physicalClient.dateOfBirth = "";
      this.physicalClient.fatherName = "";
      this.physicalClient.motherName = "";
      this.physicalClient.nacionality = "";
      this.physicalClient.naturality = "";
      this.physicalClient.monthlyIncome = "";
      this.physicalClient.civilStatus = "";
      this.physicalClient.spouse = "";
      this.physicalClient.icomeSpouse = "";
      this.physicalClient.type = "";
      this.physicalClient.address = [];
      this.physicalClient.contacts = [];
      this.physicalClient.banks = [];
      this.$refs.phys.reset();
    },
    clear_legal() {
      this.LegalPeople.cnpj = "";
      this.LegalPeople.fantasyName = "";
      this.LegalPeople.socialName = "";
      this.LegalPeople.stateRegistration = "";
      this.LegalPeople.municipalRegistration = "";
      this.LegalPeople.homePage = "";
      this.LegalPeople.address = [];
      this.LegalPeople.banks = [];
      this.LegalPeople.contacts = [];
      this.LegalPeople.related_representative = "";
      this.LegalPeople.type = "";
      this.$refs.legal.reset();
    },
    setLoadToUpdate(item) {
      this.id = item.id
      if (item.type_people == "PhysicalPeople") {
        this.register = false
        this.physicalClient = { ...item.physicalpeople }
      } else {
        this.register = true
        this.LegalPeople = { ...item.legalpeople }
      }
      this.address.state = ""
      this.cityObject = { ...item.address[0].city }
      this.enterprise_id = item.enterprise_id
      this.address = { ...item.address[0] }
      this.contact = { ...item.contacts[0] }
      this.banks = { ...item.banks[0] }
    },

    itemToUpdate(item) {
      if (item.justVisible) {
        this.justView = item.justVisible
        this.header = item.header
        this.setLoadToUpdate(item.item)

      } else if (item.isUpdate) {
        this.header = item.header
        this.setLoadToUpdate(item)

      } else {
        this.header = "Criar"
      }
    },
  },
  created() {
    this.itemToUpdate(this.getItemToUpdateClient)
  },
  beforeDestroy() {
    this.valid = false
  },

  watch: {
    validPhysical(val) {
      if(val && this.validAddress && this.validPersonal && this.validContact && this.validBankAccount) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validAddress(val) {
      if(!this.register) {
        if(val && this.validPhysical && this.validPersonal && this.validContact && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      } else {
        if(val && this.validLegal && this.validContact && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      }

    },
    validPersonal(val) {
      if(val && this.validPhysical && this.validAddress && this.validContact && this.validBankAccount) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validContact(val) {
      if(!this.resgister) {
        if(val && this.validPhysical && this.validAddress && this.validPersonal  && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      } else {
        if(val && this.validLegal && this.validAddress && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      }
    },
    validBankAccount(val) {
      if(!this.register) {
        if(val && this.validPhysical && this.validAddress && this.validPersonal && this.validContact) {
          this.valid = true
        } else {
          this.valid = false
        }
      } else {
        if(val && this.validLegal && this.validAddress && this.validContact) {
          this.valid = true
        } else {
          this.valid = false
        }
      }
    },
    validLegal(val) {
      if(val && this.validAddress && this.validContact && this.validBankAccount) {
        this.valid = true
      } else {
        this.valid = false
      }
    },



  },
};
</script>
