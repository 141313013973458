



<template>
  <v-dialog
    persistent
    max-width="500px"
    v-model="getShowDialogRegisterParameter"
  >
    <v-card class="pa-10">
      <p class="p-parameter">Cadastrar Parâmetro</p>
      <v-divider class="mb-5" />
      <div class="form-paramter">
        <v-text-field
          v-model="form.description"
          class="mr-2"
          outlined
          label="Descrição"
        />
      </div>
      <div class="form-actions-parameter">
        <v-btn class="mr-2 error" @click="closeDialog">Cancelar</v-btn>
        <v-btn class="ml-2" @click="submit" color="success">Cadastrar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  computed: {
    ...mapGetters(["getShowDialogRegisterParameter"]),
  },
  data: () => ({
    form: Object(),
  }),
  methods: {
    submit() {
      //cadastrar um novo parâmetro
    },
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterParameter", false);
    },
  },
  watch: {},
  created() {},
};
</script>

<style>
@import "./index.css";
</style>