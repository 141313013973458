const GET_ALL_PURCHASEORDER = "GET_ALL_PURCHASEORDER";
const UPDATE_PURCHASEORDER = "UPDATE_ALL_PURCHASEORDER";
const ADD_PURCHASEORDER = "ADD_PURCHASEORDER";
const DESTROY_PURCHASEORDER = "DESTROY_PURCHASEORDER";
const ITEM_TO_UPDATE = "ITEM_TO_UPDATE"

module.exports = {
    GET_ALL_PURCHASEORDER,
    UPDATE_PURCHASEORDER,
    ADD_PURCHASEORDER,
    DESTROY_PURCHASEORDER,
    ITEM_TO_UPDATE,
}