const SET_ITEMS_NOTE = 'SET_ITEMS_NOTE';
const SET_GENERAL_DATA = 'SET_GENERAL_DATA'
const SET_SHIPPING_COMPANY = 'SET_SHIPPING_COMPANY'

const PREPARE_INF_GENERAL = "PREPARE_INF_GENERAL"
const PREPARE_ITENS_NOTE = "PREPARE_ITENS_NOTE"
const PREPARE_TRANSPORTER = "PREPARE_TRANSPORTER"
const PREPARE_DATA_INF_NOTE = "PREPARE_DATA_INF_NOTE"



module.exports = {
    SET_ITEMS_NOTE,
    SET_GENERAL_DATA,
    SET_SHIPPING_COMPANY,

    PREPARE_INF_GENERAL,
    PREPARE_ITENS_NOTE,
    PREPARE_TRANSPORTER,
    PREPARE_DATA_INF_NOTE
}