import { ADD_CSOSN, UPDATE_CSOSN } from "../../../store/actions/csosn";
function reset() {
    this.$refs.form.reset();
}
function resetValidation() {
    this.$refs.form.resetValidation();
}
function closeCson() {
    this.$store.dispatch("setShowDialogRegisterCSOSN", false);
    this.reset();
    this.resetValidation();
    this.justVisible = false
}

function getComponent() {
    return this.component;
}

async function create_csosn() {
    const csosn = {
        code: this.code,
        description: this.description,
    };
    await this.$store.dispatch(ADD_CSOSN, csosn).then(() => {
        this.closeCson();
    });
}

function submit() {
    this.id == undefined ? this.create_csosn() : this.update_csosn();
}

async function update_csosn() {
    const csosn = {
        id: this.id,
        code: this.code,
        description: this.description,
    };
    await this.$store.dispatch(UPDATE_CSOSN, csosn).then(() => {
        this.closeCson();
    });
}





export default {
    reset,
    closeCson,
    create_csosn,
    submit,
    update_csosn,
    resetValidation,
    getComponent,
}