<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Livro Fiscal</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="getEnterprises.data"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon>Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form" />
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Form from "../../../components/report/fiscal_book/Form/index";
//import Result from "../../../components/report/product/AddDialog/";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GET_ALL_PRODUCT } from "../../../store/actions/product";
import { GET_ALL_NCM } from "../../../store/actions/ncm";
import { GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { dispatchGetAll } from "../../../components/Methods/dispatchGetAll";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: { Form },

  data: () => ({
    dialog: Boolean(),
    //results: Object,
    valid: Boolean(),
    selectValue: Object(),
    report: {
      dateBuy: null,
      datePromotion: null,
      codProductInitial: null,
      codProductFinal: null,
      groupProducts: null,
      ncm: null,
      moinimoStock: null,
      maximoStock: null,
    },
    request_item: Object(),
    items: {
      id: 1,
      description_item: "Notebook",
      reference: "Referência",
      date_last_buy: "24-11-2020",
      ncm_id: 2,
      note_price: "R$ 1500.00",
    },
  }),

  methods: {
    async Generate() {
      this.request_item.enterprise_id = this.selectValue.id
      this.report.mediumStock = "";
      
      let response;
      let typeBook = this.request_item.type
      let request = {...this.request_item}


      this.dialog = !this.dialog;
      this.$refs.form.reset();
      this.$refs.form.validate();
      this.$refs.form.resetValidation();
      let arrayenterprise = [];
      let arrayaddress = [];
      let objeto = Object.assign({}, this.selectValue); 
      let objetoadress = Object.assign({}, this.selectValue.address);
      arrayenterprise.push(objeto);
      arrayaddress.push(objetoadress);

      if(typeBook == "in"){
        this.request_item.enterprise_id = this.selectValue.id
        response = await axios.post("catalog/booksupervision", request)
        this.createPDF(response.data[0], arrayenterprise, arrayaddress, typeBook);
      }else{
        this.request_item.enterprise_id = this.selectValue.id 
        response = await axios.post("catalog/booksupervisionout", request)
         
        this.createPDF(response.data[0], arrayenterprise, arrayaddress, typeBook);
      }

      
    },

    createPDF(items, enterprise, address, typeBook) {
      let data = new Date();
      var doc = new jsPDF("p", "pt");
      var columns;
      
      //caso o tipo de livro fiscal for entrada, será montada a segunte tabela comas seguintes informações
      //Caso contrário será montada uma tablea diferente.
      if(typeBook == 'in'){
        columns  = [
          { title: ' Data de entrada', dataKey: 'date_invoice_entry' },
          { title: ' Codigo do fornecedor', dataKey: 'Provider_id' },
          { title: ' Serie do fornecedor', dataKey: 'serie' },
          { title: ' valor contabil ', dataKey: ' value_account_note_entry' },
          { title: ' Valor de base calculo de icms', dataKey: 'value_base_calculation_icms' },
          { title: ' Valor icms de saida', dataKey: 'value_icms_note_entry' },
          { title: 'Valor de icms outros ', dataKey: 'value_other_note_entry' },
          { title: ' Valor isento  icms ', dataKey: 'value_free_note_entry' },
          { title: ' valor de ipi ', dataKey: 'value_ipi_note_entry' },
          { title: ' valor outros de ipi', dataKey: 'value_other_ipi_note_entry' },
          { title: ' valor isento de ipi', dataKey: 'value_free_ipi_note_entry' },
          { title: ' valor base de substituição ', dataKey: 'value_base_icms_substituicao_note_entry' },
          { title: ' valor ', dataKey: 'value_icms_substituicao_note_entry' },
        ];
      }else{
         
        columns = [
            { title: 'Data de saída ', dataKey: 'date_of_issue' },
          { title: 'Código do cliente ', dataKey: 'client_id' },
          { title: 'Serie ', dataKey: 'serie' },
          { title: 'valor contábil', dataKey: 'value_account_note_entry' },
          { title: 'Valor de base calculo de icms', dataKey: 'value_base_calculo_icms' },
          { title: 'Valor icms de saida ', dataKey: 'value_icms_out' },
          { title: 'Valor de icms outros ', dataKey: 'value_icms_other_out' },
          { title: 'valor isento de outros', dataKey: 'value_free_out' },
          { title: 'valor de ipi ', dataKey: 'value_ipi_out' },
          { title: 'valor de ipi outros', dataKey: 'value_other_ipi_out' },
          { title: 'valor isento de ipi ', dataKey: 'value_free_ipi_out' },
          { title: 'valor base de st de saida', dataKey: 'value_base_icms_substituicao_out' },
          { title: 'valor de icms st', dataKey: 'value_icms_substituicao_out' },
          ]
      }

     
      doc.setLineWidth(1);
      doc.rect(20, 20, 555, 800);
      doc.line(40, 85, 555, 85);
      doc.line(40, 125, 555, 125);
      
      doc.setFontSize(7);
      doc.text(`Usuário : Luiz`, 430, 40)
      doc.text(`${data.toLocaleString()}`, 430, 50)
      doc.setFontSize(15);
      doc.text("RELATÓRIO DE LIVRO FISCAL", 190, 70);
      doc.setFontSize(7);
      doc.text(`CNPJ : ${ enterprise[0].cnpj }`, 40, 100)
      doc.text(`Razão Social : ${ enterprise[0].name }`, 140, 100)
      doc.text(`Nome Fantasia : ${ enterprise[0].fantasyName }`, 430, 100)
      doc.text(`Rua : ${ address[0].street }`, 40, 115)
      doc.text(`Numero : ${ address[0].number }`, 200, 115)
      doc.text(`Cidade : ${ address[0].city }`, 255, 115)
      doc.text(`Estado : ${ address[0].state }`, 400, 115)
      doc.text(`CEP : ${ address[0].cep }`, 500, 115)
      doc.setFontSize(10);
      doc.text("Itens :", 45, 150);
      doc.autoTable(columns, items, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: 220,
          textColor: 0
        },
        startY: 155,
        theme: "striped"
      });
      const pages = doc.internal.getNumberOfPages();
      doc.setFontSize(8);  //Optional
      for (let j = 1; j < pages + 1 ; j++) {
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 550, 40)
      }
      doc.output('dataurlnewwindow');
    },

    async GetAll(page = 1) {
           let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await dispatchGetAll(this, GET_ALL_PRODUCT, value);
      await dispatchGetAll(this, GET_ALL_NCM, value);
      await dispatchGetAll(this, GET_ALL_PRODUCT_GROUP, value);
      await dispatchGetAll(this, GET_ALL_ENTERPRISE, value);
    },
  },

  computed: {
    ...mapGetters([
      "getProduct",
      "getNcm",
      "getProductGroup",
      "getEnterprises",
    ]),
  },

  created() {
    this.GetAll();
  },

  watch: {
    getEnterprises(val) {
      this.selectValue = val.data[0];
    },
  },
};
</script>

<style>
</style>