<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Movimentação Financeira</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="enterprise"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon>Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form" />
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Form from "../../../components/report/financialMovement/Form/index";
//import Result from "../../../components/report/product/AddDialog/";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GET_ALL_ACCOUNT_PLAN } from "../../../store/actions/account_plan";
import { GET_ALL_CASHIER } from "../../../store/actions/cashier";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
//import { dispatchGetAll } from "../../../components/Methods/dispatchGetAll";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: { Form },

  data: () => ({
    dialog: Boolean(),
    //results: Object,
    valid: false,
    selectValue: Object,
    enterprise: [],
    cashier: [],
    account: [],
    report: {
      initialdatemovement: null,
      finaldatemovement: null,
      type_moviment: null,
      cash_id: null,
      account_plan_id: null,
      page: null,
      paginate: false,
    },
  }),

  methods: {
    async Generate() {
      let all_report = Object.assign({}, this.report);
      const response = await axios.post("catalog/cashmovement", {
        ...all_report,
      });
      this.dialog = !this.dialog;
      this.$refs.form.reset();
      this.$refs.form.validate();
      this.$refs.form.resetValidation();
      let arrayenterprise = [];
      let arrayaddress = [];
      let objeto = Object.assign({}, this.selectValue);
      let objetoadress = Object.assign({}, this.selectValue.address);
      arrayenterprise.push(objeto);
      arrayaddress.push(objetoadress);
      this.createPDF(response.data[0], arrayenterprise, arrayaddress);
    },

    createPDF(items, enterprise, address) {
      let data = new Date();
      let totalTitleBalance = 0;
      //let pdfName = "Relatório_Produtos";
      if (items.length > 0) {
        items.forEach((e) => {
          e.date_movement = e.date_movement.split("-").reverse().join("/");
          e.balance_movement = `R$ ${e.balance_movement
            .toFixed(2)
            .replace(".", ",")}`;
          totalTitleBalance += e.balance_movement
            ? parseFloat(
                e.balance_movement.replace("R$ ", "").replace(",", ".")
              )
            : 0;
        });
      } else {
        alert("Nenhum Item Encontrado");
      }
      items.push({
        id: { content: "Total", styles: { fontStyle: "bold" } },
        description_movement: { content: "", styles: { fontStyle: "bold" } },
        type_movement: { content: "", styles: { fontStyle: "bold" } },
        number_movement: { content: "", styles: { fontStyle: "bold" } },
        date_movement: { content: "", styles: { fontStyle: "bold" } },
        description: { content: "", styles: { fontStyle: "bold" } },
        balance_movement: {
          content: `R$ ${totalTitleBalance.toFixed(2).replace(".", ",")}`,
          styles: { fontStyle: "bold" },
        },
      });
      var doc = new jsPDF("p", "pt");
      var columns = [
        { title: "Código da Movimentação", dataKey: "id" },
        { title: "Descrição da Movimentação", dataKey: "description_movement" },
        { title: "Tipo de Movimentação", dataKey: "type_movement" },
        { title: "Número da Movimentação", dataKey: "number_movement" },
        { title: "Data da Movimentação", dataKey: "date_movement" },
        { title: "Plano de Contas", dataKey: "description" },
        { title: "Saldo do Movimento", dataKey: "balance_movement" },
      ];
      doc.setLineWidth(1);
      doc.rect(20, 20, 555, 800);
      doc.line(40, 85, 555, 85);
      doc.line(40, 125, 555, 125);

      doc.setFontSize(7);
      doc.text(`Usuário : Luiz`, 430, 40);
      doc.text(`${data.toLocaleString()}`, 430, 50);
      doc.setFontSize(15);
      doc.text("RELATÓRIO DE MOVIMENTAÇÃO FINANCEIRA", 140, 70);
      doc.setFontSize(7);
      doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
      doc.text(`Razão Social : ${enterprise[0].name}`, 140, 100);
      doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 430, 100);
      doc.text(`Rua : ${address[0].street}`, 40, 115);
      doc.text(`Numero : ${address[0].number}`, 200, 115);
      doc.text(`Cidade : ${address[0].city}`, 255, 115);
      doc.text(`Estado : ${address[0].state}`, 400, 115);
      doc.text(`CEP : ${address[0].cep}`, 500, 115);

      doc.autoTable(columns, items, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: 220,
          textColor: 0,
        },
        startY: 140,
        theme: "grid",
        styles: {
          fontSize: 7,
          cellPadding: 2,
          overflow: "linebreak",
          halign: "center",
          valign: "middle",
        },
      });
      const pages = doc.internal.getNumberOfPages();
      // const pageWidth = doc.internal.pageSize.width;  //Optional
      // const pageHeight = doc.internal.pageSize.height;  //Optional
      //console.log(pageWidth , pageHeight);
      doc.setFontSize(8); //Optional
      for (let j = 1; j < pages + 1; j++) {
        // let horizontalPos = pageWidth / 2;  //Can be fixed number
        // let verticalPos = pageHeight - 10;  //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 550, 40);
      }
      doc.output("dataurlnewwindow");
      //doc.save(pdfName + ".pdf");
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, value);
      await this.$store.dispatch(GET_ALL_CASHIER, value);
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },

  computed: {
    ...mapGetters(["getAccount_Plan", "getCashier", "getEnterprises"]),
  },

  created() {
    this.GetAllNoPage();
  },

  watch: {
    getEnterprises(val) {
      this.enterprise = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.enterprise[0]);
      this.selectValue = aux;
    },

    getCashier(val) {
      this.cashier = Object.keys(val).map((key) => val[key]);
    },

    getAccount_Plan(val) {
      this.account = Object.keys(val).map((key) => val[key]);
    },
  },
};
</script>

<style>
</style>