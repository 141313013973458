<template>
 <v-container>
  <v-divider></v-divider>
  <v-form ref="form" v-model="component.validPersonal">
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.physicalClient.motherName"
          label="Nome da Mãe"
          outlined
          required
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.physicalClient.fatherName"
          label="Nome do Pai"
          outlined
          required
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          label="*Gênero"
          outlined
          v-model="component.physicalClient.gender"
          :items="items_gender"
          :rules="[() => !!component.physicalClient.gender || 'Campo Obrigatorio']"
          :readonly="component.justView"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.physicalClient.monthlyIncome"
          label="Renda Mensal"
          outlined
          type="number"
          prefix="R$"
          required
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="component.physicalClient.civilStatus"
          label="*Estado Civil"
          :items="civilStatus"
          outlined
          required
          :rules="[() => !!component.physicalClient.civilStatus || 'Campo Obrigatorio']"
          :readonly="component.justView"
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="component.physicalClient.spouse"
          label="Cônjugue"
          outlined
          required
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          v-model="component.physicalClient.icomeSpouse"
          label="Renda Conjugal"
          outlined
          prefix="R$"
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
 </v-container>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    items_gender: ["Masculino", "Feminino"],
    civilStatus: ["Casado(a)", "Divorciado(a)", "Solteiro(a)", "Viúvo(a)"],
  }),
};
</script>

<style>
</style>