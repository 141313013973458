module.exports = {
    SET_CADASTROS: "SET_CADASTROS",
    SET_COMPRAS : "SET_COMPRAS",
    SET_MOVIMENTACAO: "SET_MOVIMENTACAO",
    SET_ENTRADAS : "SET_ENTRADAS",
    SET_SAIDAS: "SET_SAIDAS",
    SET_FATURAMENTO :"SET_FATURAMENTO",
    SET_ESTOQUE : "SET_ESTOQUE",
    SET_FINANCEIRO: "SET_FINANCEIRO",
    SET_FISCAL: "SET_FISCAL",
    SET_RELATORIOL: "SET_RELATORIOL",
    SET_SEGURANCA: "SET_SEGURANCA",
}