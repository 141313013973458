
<template>
  <div>
    <v-card class="ma-5">
      <CardTitle
        actionsShowDialog="setShowDialogRegisterCorrectionLetter"
        title="Carta de Correção de Nota Fiscal"
      />
      <Table :headers="headers"  :component="this" :lengthPaginator="lengthPaginator" />
      <AddDialog
        v-if="getShowDialogRegisterCorrectionLetter"
        :component="this"
      />
      <LoadScreen/>
    </v-card>
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise";
import {GET_ALL_CORRETION_NFE} from "../store/actions/correction_letter"
import CardTitle from "../components/correction_letter/CardTitle";
import Table from "../components/correction_letter/Table";
import tableHeaders from "./headers_tables/Correction_letter.js";
import AddDialog from "../components/correction_letter/AddDialog";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
export default {
  name: "CorrectionLetter",
  components: {
    CardTitle,
    Table,
    AddDialog,
    LoadScreen
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterCorrectionLetter","getEnterprises"]),
  },
  data: function () {
    return {
      headers: tableHeaders,
      lengthPaginator: Number(),
      selectValue: Object(),
      correctionLetterDataToView: Object(),
      justView: false
    };
  },
  methods: {
    async getAllEnterprise(page=1) {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: page,
        limit: 100,
        paginate: true,
      });
    },
    getAllCorrectionNfe(page=1) {
      this.$store.dispatch(GET_ALL_CORRETION_NFE, {
        cnpjEmitter: this.selectValue.cnpj,
        page: page,
        limit: 10,
        paginate: true,

      })

    },
    calculateNumberPage(itemQuantity) {
      if (itemQuantity % 5 > 1) {
        this.lengthPaginator = itemQuantity / 5 + 1;
      } else {
        this.lengthPaginator = itemQuantity / 5;
      }
    },

    viewCorrectionLetter(correctionToView) {
      this.correctionLetterDataToView = correctionToView
      this.justView = true
      this.$store.dispatch("setShowDialogRegisterCorrectionLetter", true);
    },
  },
  watch: {
    getEnterprises(val) {
      this.selectValue = val.data[0];
      this.getAllCorrectionNfe();
    },
  },
  async created() {
    await this.getAllEnterprise();
    
  },
};
</script>

<style>
</style>