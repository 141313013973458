import ReportProduct from "./images/relatorio-produto.png";
import ReportClient from "./images/relatorio-cliente.png";
import ReportProvider from "./images/fornecedor.png";
import ReportNoteEntry from "./images/relatorio-entrada-nota.png";
import ReportStockBalance from "./images/realtorio-saldo-estoque.png";
import ReportBillsPay from "./images/relatorio-contas-pagar.png";
import ReportBillsReceive from "./images/relatorio-contas-receber.png";
import ReportFinancialMovement from "./images/relatorio-movimentacao-financeira.png";
import ReportFiscalBook from "./images/relatorio-livro-fiscal.png";
import ReportSales from "./images/relatorio-vendas.png";
import SellerComission from "./images/comissao-de-vendedor.png";
import Dashboard from "./images/dashboard.png";


var arrayImages = [
  ReportProduct,
  ReportClient,
  ReportProvider,
  ReportNoteEntry,
  ReportStockBalance,
  ReportBillsPay,
  ReportBillsReceive,
  ReportFinancialMovement,
  ReportFiscalBook,
  ReportSales,
  SellerComission,
  Dashboard,
];

export { arrayImages };
