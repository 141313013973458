<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="300"
      v-model="component.dialog_descont"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title>Valor Desconto?</v-card-title>
          </v-row>
          <v-select
            label="Tipo Desconto"
            :items="type_descont"
            v-model="component.type_descont"
            required
            item-text="name"
            item-value="value"
            outlined
          ></v-select>

          <v-text-field outlined v-model="component.valueDescont" label="Valor">
          </v-text-field>

          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                rounded
                class="red darken-4 white--text"
                @click="
                  () => {
                    disableDialog();
                  }
                "
              >
                Cancelar
              </v-btn>
              <v-btn
                rounded
                depressed
                class="success"
                @click="
                  () => {
                    component.confirm_descont_item();
                  }
                "
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },
  data: () => ({
    quantity: "",
    type_descont: [
      { name: "DINHEIRO", value: "DINHEIRO" },
      { name: "PERCENTUAL", value: "PERCENTUAL" },
    ],
  }),
  methods: {
    disableDialog() {
      this.component.dialog_descont = !this.component.dialog_descont;
    },
    //refresh_Product(quantity) {},
  },
};
</script>
