<template>
  <v-row>
    <v-card-title primary-title>Pre-Entrada de Notas</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <v-btn
      color="success mr-2 mt-3"
      :block="$vuetify.breakpoint.xsOnly"
      @click="
        () => {
          executeAlterAddDialog();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
//v-if="component.permission.Cadastrar"
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    selectValue: Object,
    enterprises: []
  }),

  created() {
    this.GetAllNoPage();
  },

  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    getComponent() {
      return this._props.component;
    },
    // getTitle() {
    //   return this.getComponent().title;
    // },
    executeAlterAddDialog() {
      this.component.checkinfo = 1
      this.alterAddDialog(this.getComponent());
    },
    alterAddDialog(component) {
      component.addDialog = !component.addDialog;

    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      //let aux = Object.assign([], this.enterprise[0]);
      this.selectValue = this.enterprises[0];
    },
  },
};
</script>

<style></style>
