<template>
  <div>
    <v-dialog persistent width="500" v-if="getShowDialogRegisterCancelNote" v-model="getShowDialogRegisterCancelNote">
      <v-card class="d-flex justify-center">
        <v-col>
          <v-card-title>Deseja cancelar esse cupom ?</v-card-title>
          <v-divider class="mb-5" />
          <v-form ref="form" v-model="form">
            <v-text-field
              label="CNPJ Emitente"
              v-model="cnpjEmitter"
              filled
              disabled
              dense
              outlined
            />
            <v-text-field
              label="Chave de acesso"
              v-model="ItemToCancel.access_key"
              filled
              disabled
              dense
              outlined
            />
            <v-text-field
              label="Protocolo"
              v-model="ItemToCancel.authorization_protocol"
              filled
              disabled
              dense
              outlined
            />
            <v-textarea
              label="Justificativa"
              :rules="rules"
              v-model="ItemToCancel.justification"
              dense
              outlined
            />
            <v-select
              :rules="rules"
              :items="items"
              item-text="text"
              item-value="value"
              label="Modelo de Nota"
              v-model="ItemToCancel.modelNf"
              dense
              outlined
            />
            <v-divider class="mt-1" />
            <v-card-actions class="d-flex justify-end">
              <v-btn @click="closeDialog" class="error">Sair</v-btn>
              <v-btn @click="submmitCancelNote" color="primary"
                >Confirmar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-col>
        <AlertForComponents
          :active="alert"
          message="Preencha todos os campos"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CANCEL_TAX_CUPOM } from "../../../store/actions/taxCupom";
import { NoteModel, rules } from "./helpers/options";
import AlertForComponents from "../../alert_api/alert_for_components.vue";
export default {
  components: {
    AlertForComponents,
  },
  props: {
    showCancel: Boolean(),
    component: Object(),
  },
  data: function () {
    return {
      alert: Boolean(),
      form: Boolean(),
      rules: rules,
      items: NoteModel,
      ItemToCancel: Object(),
      rand: Math.random(),
      cnpjEmitter: '',
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submmitCancelNote() {
      /**
       * Verificar os valores antes de serem enviadas.
       * Atualmente está pegando da simulação de dados (mock)
       */
       if (this.form) {
        const payload = {
          cnpjEmitter: this.cnpjEmitter,
          key: this.ItemToCancel.access_key,
          protocol: this.ItemToCancel.authorization_protocol,
          justification: this.ItemToCancel.justification,
          modelNf: this.ItemToCancel.modelNf,
        };
        this.$store.dispatch(CANCEL_TAX_CUPOM, payload);
        //this.reset();
        this.$store.dispatch("setShowDialogRegisterCancelNote", false);
      } else {
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 4000);
      }
    },
    closeDialog() {
      //this.validate();
      //this.reset();
      //this.resetValidation();
      this.$store.dispatch("setShowDialogRegisterCancelNote", false);
    },
    createItemToCancel() {
      this.ItemToCancel = this.getItemToCancel;
    },
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterCancelNote", "getItemToCancel", "getcnpj"]),
  },
  watch: {
    getItemToCancel: function () {
      this.createItemToCancel();
    },
  },
  created() {
    this.createItemToCancel();
    this.cnpjEmitter = this.getcnpj
  },
  beforeDestroy(){
  },
  mounted(){
  }

};
</script>

<style>
</style>