<template>
  <v-container>
    <v-form ref="formData">
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2" >
        <v-text-field
          dense
          readonly
          v-model="component.invoice.base_calc_icms_total"
          outlined
          label="Base Calc do ICMs"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_icms_total"
          outlined
          label="Valor do ICMS"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_base_icms_st_product"
          outlined
          label="Base Calc ICMS ST"
        />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_icms_substituicao_total"
          outlined
          label="Valor do ICMS ST"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_account_total"
          outlined
          label="Valor Contábil"
          value
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.base_calc_ipi_total"
          outlined
          label="Base de Cálculo IPI"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_ipi_total"
          outlined
          label="Valor Total do IPI"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_ipi_outher_total"
          outlined
          label="Valor IPI Outros"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_ipi_free_total"
          outlined
          label="Valor Isento IPI"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_total_products"
          outlined
          label="Valor Total dos Produtos"
          value
        />
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="2" xl="2">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_icms_outher_product"
          outlined
          label="Outros Valores"
          value
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_discont"
          outlined
          label="Desconto"
          value
        />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_icms_free_total"
          outlined
          label="Valor ICMS Isento"
          value
        />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          dense
          readonly
          v-model="component.invoice.value_total_note"
          outlined
          label="Valor Total da Nota"
          value
        />
      </v-col>
    </v-row>
  </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    component: Object()
  },
  beforeDestroy(){
    this.$refs.formData.reset()
  }
};
</script>

<style>
</style>