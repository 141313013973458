
import apifiscal from "../apifiscal";
import { ADD_ORDER_CORRECTION_LETTER, GET_ALL_CORRETION_NFE  } from "./actions/correction_letter"
const { SPLICE_ERRO } = require("./actions/alert_api")

const correction_letter = {
    state: {
        correctionLetter: Object()
    },
    getters: {
        getCorrectionLetter: function (state) {
            return state.correctionLetter;
        }
        
    },
    mutations: {
        ADD_ORDER_CORRECTION_LETTER: function (state, payload) {
            state.correctionLetter.data.push(payload)
        },
        GET_ALL_CORRETION_NFE : function (state,payload){
            
            state.correctionLetter = payload
        }
    },
    actions: {
        GET_ALL_CORRETION_NFE: function ({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal"); 
            apifiscal.post("noteissue/getallcorretionNfe", payload,{
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((response) => {
                commit(GET_ALL_CORRETION_NFE, response.data.nfes);
            }).catch((error)=> {
                commit(SPLICE_ERRO, {
                    alert: "Erro ao obter as correções nfe",
                    type: 'E'
                })
            })
        },
        async ADD_ORDER_CORRECTION_LETTER ({ commit }, payload) {
            try {
                let token = localStorage.getItem("token_fiscal");
                const response = await apifiscal.post("noteissue/issuecorretionNfe", payload, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                })
                commit(ADD_ORDER_CORRECTION_LETTER, response.data.correction);
                return response.data.correction
            } catch(error) {
                commit(SPLICE_ERRO, {
                    alert: "Erro ao processar carta de correção",
                    type: 'E'
                })
            }
        },
    },
}
export default correction_letter