<template>
  <v-row justify="center">
    <v-dialog persistent v-model="dialog" hide-overlay transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="changeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }} Faturamento</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>


          <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
            <v-tab v-for="(item, index) in newArrayItems" :key="index" :href="'#tab-' + item.href">
              {{ item.tabName }}
            </v-tab>

            <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
                  more
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item v-for="(item, index) in more" :key="index" @click="addItem(item)">
                  {{ item.tabName }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>

          <v-tabs-items v-model="currentItem">
            <v-tab-item value="tab-1">
              <v-col>
                <GeneralDate :component="this" :client="client" :isDisable="disabled" />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-col>
                <RevenuesItems :component="this" :isDisable="disabled" />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-col>
                <Transport :component="this" :isDisable="disabled" />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <v-col>
                <InformationRevenue :eager="true" :component="this" :isDisable="disabled" />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-5">
              <v-col>
              <InfoStatusNfe :nfe="nfeStatusInfo" />
              </v-col>
            </v-tab-item>
          </v-tabs-items>
          <v-divider></v-divider>
          <v-card-actions v-show="!disabled">
            <v-spacer></v-spacer>
            <v-btn class="error" @click="reset()">
              <v-icon class="mr-1">mdi-cancel</v-icon> Limpar Formulário</v-btn>
            <v-btn class="success" :disabled="!valid" @click="save()">
              <v-icon class="mr-1">mdi-content-save</v-icon>
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <ConfirmRevenue v-if="confirmDialog" :component="this" :submit="submit" />
  </v-row>
</template>

<script>
import GeneralDate from "../Tabs/TabGenerateDate/";
import InformationRevenue from "../Tabs/TabInfoNote/";
import RevenuesItems from "../Tabs/TabNoteItens/";
import Transport from "../Tabs/TabTransport/";
import InfoStatusNfe from "../Tabs/TabInfoNfe"
import ConfirmRevenue from "../AddDialog/ConfirmNote/";
import businessRole from "../scripts/business_roles/";
import { ADD_REVENUES, REVENUES_UPDATE_NFE } from "../../../store/actions/revenues";
import { ISSUE_OF_INVOICE_NFE, GET_SEQUENCE_NFE_EMITER_NFE } from "../../../store/actions/nfe";
import { DESTROY_REVENUES_ITENS } from "../../../store/actions/revenuesitens";
import { mapGetters } from "vuex";

export default {
  components: {
    GeneralDate,
    InformationRevenue,
    RevenuesItems,
    Transport,
    ConfirmRevenue,
    InfoStatusNfe
  },
  props: {
    component: Object,
    title: String,
    disabled: Boolean,
    dialog: Boolean,
    changeDialog: Function,
    enterprise: Object,
    object: Object,
    enterpriseType: Number,
  },
  data: () => ({
    newArrayItems: Array(),
    currentItem: "tab-1",
    items: [
      {
        tabName: "Dados Gerais",
        href: "1",
      },
    ],
    more: [
      {
        tabName: " Itens da Nota",
        href: "2",
      },
      {
        tabName: "Transportadora",
        href: "3",
      },
      {
        tabName: "Informações da nota",
        href: "4",
      },
    ],
    validations: Array(),
    allValidated: 0,
    valid: Boolean(),
    preDialog: Boolean(),
    confirmDialog: Boolean(),
    totalNF: Number(),
    client: Object(),
    nfereferences: {
      keyAcess: String(),
      ufIssuer: String(),
      yearAndMonth: String(),
      cnpjIssuer: String(),
      invoiceTemplate: String(),
      gradeSeries: String(),
      taxDocumentNumber: String(),
      nfe_id: String(),
    },
    form: {
      enterprise_nf: Object(),
      formOfPayment_nf: Object(),
      indicationPayment: String(),
      transportNfe: Object(),
      client: Object(),
      typeOperation: Object(),
      noteNf: Object(),
      status: "Em Digitacao",
      number_grade: String(),
      finality_nf: String(),
      date_of_issue: String(),
      access_key: String(),
      authorization_protocol: String(),
      series: String(),
      type_grade_id: Number(), //Enum no back
      value_total_products: Number(),
      value_book: Number(),
      value_total_note: Number(),
      value_discount: Number(),
      value_icms_substituicao_total: Number(),
      value_freigth: Number(),
      base_calc_icms_total: Number(),
      base_calc_susbt_total: Number(),
      value_icms_free_total: Number(),
      value_account_total: Number(),
      value_outher_tax_total: Number(),
      value_icms_total: Number(),
      note: String(),
      value_security: Number(),
      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      base_calc_ipi_total: Number(),
      value_icms_outher_product: Number(),
      value_other_incidental_expenses: Number(), // Verificar
      vehicle_plate: String(),
      volume_transported: String(),
      gross_weight: String(),
      net_weight: String(),
      volume_type: String(),
      value_base_pis_total: Number(),
      value_pis_total: Number(),
      value_base_confis_total: Number(),
      value_confis_total: Number(),
      value_base_pis_st_total: Number(),
      value_pis_st_total: Number(),
      value_base_cofins_st_total: Number(),
      value_cofins_st_total: Number(),
      value_other_pis_total: Number(),
      transporter_id: null,
      client_id: Number(),
      payment_terms_id: null,
      tax_operation_types_id: Number(),
      tax_operation_type: Object(),
      account_plans_id: null,
      enterprise_id: Number(),
      shippingModalityFreight: String(),
      numberSeal: String(),
      netWeightVolumeTransported: String(),
      revenusItem: Array(),
    },
    nfeStatusObject: {
        tabName: "Status NFe",
        href: "5",
    },
      nfeStatusInfo: {},
      nfeStatusActive:false
  }),
  computed: {
    ...mapGetters(["getRevenusItens", "getEnterpriseObject", "getStatusNFe"]),
  },

  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else if (this.nfeStatusActive) { 
        this.more.push(this.nfeStatusObject) 
        this.newArrayItems = this.items.concat(this.more);
      }else{
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    ...businessRole,
    //Resetar os campos
    reset() {
      let object = {};

      for (const key in this.form) {
        let element = this.form[key];
        let type = typeof element;

        switch (type) {
          case "number":
            object[key] = Number();
            break;
          case "string":
            object[key] = String();

            break;
          case "object":
            object[key] = Array();
            break;
        }
      }
      this.form = object;
      this.form.status = "Pre-nota";
      this.form.number_grade = "Não Emitida";
      this.form.access_key = "Não Emitida";
      this.form.authorization_protocol = "Não Emitida";
      this.form.series = "Não Emitida";

      this.clear_revenues();
    },

    //Limpar o RevenuesItem
    clear_revenues() {
      try {
        this.$store.dispatch(DESTROY_REVENUES_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },

    //Atualizar os autocompletes
    refresh_dates() {
      if (this.enterpriseType === 3) {
        this.validCST = true;
      } else {
        this.validCST = false;
      }
    },
    formatDateIssuencieDue(data) {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2];

      return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
    },

    //Finalizar e Enviar
    async submit(invoices, plan, way_payment) {
      this.form.enterprise = { ...this.enterprise };
      this.form.enterprise_id = this.enterprise.id;
      this.form.account_plans_id = plan;
      this.form.enterprise_id = this.getEnterpriseObject.id;
      this.form.payment_terms_id = way_payment.id;
      this.form.tax_operation_types_id = this.form.tax_operation_type.id
      let note = { ...this.form };
      note.nfereferences =  {...this.nfereferences}
      await this.$store.dispatch(GET_SEQUENCE_NFE_EMITER_NFE, {
        enterprise_id: this.getEnterpriseObject.id,
        typeNoteModel: "NF-e",
      }).then((response) => {
        let resultSequence = response.data[0];
        note.number_grade = String(resultSequence.sequence),
        note.series = resultSequence.series

      }).catch((error) => {
        alert("Não foi possivel obter o sequencial nfe")
      })
      invoices.forEach(
        (e) => (e.issuance_due = this.formatDateIssuencieDue(e.issuance_due))
      );

      note.billstoReceive = invoices;
      let code = 0;
      let itens = this.getRevenusItens;
      itens.forEach((item_product) => {
        code += 1;
        item_product.code_item = code;
        let product = { ...item_product };
        note.revenusItem.push(product);
      });
      note.access_key = "0000000000";
      note.authorization_protocol = "000000000";
      note.receiptCode = "000000"
      note.status = "Pre-pedido"
      let statusProcessRevenues = false
      let revenuesCreate = null
      this.confirmDialog = !this.confirmDialog;
      await this.$store.dispatch(ADD_REVENUES, note).then((response) => {
        statusProcessRevenues = true
        revenuesCreate = response.response.data[0]
        note.id = revenuesCreate.id
      }).catch((error) => {
        this.confirmDialog = !this.confirmDialog;
        alert("Não foi possivel salvar o faturamento")
      })
      if (statusProcessRevenues == true) {
        note.status = "Em Digitacao"
        await this.$store.dispatch(ISSUE_OF_INVOICE_NFE, note).then((response) => {
          let nfe = response.data.nfe;
          note.status = nfe.status;
          if (note.status === "Autorizada") {
            note.number_grade = nfe.numberNfe;
            note.access_key = nfe.key;
            note.authorization_protocol = nfe.numberProtocol;
            note.series = nfe.seriesDocumentSupervisior;
            note.receiptCode = nfe.numberProtocol;
            this.changeDialog();
            this.$store.dispatch(REVENUES_UPDATE_NFE, note);
            this.nfcePrint = response.data.nfepdf;
            window.open(this.nfcePrint)

          } else {
            note.number_grade = nfe.numberNfe;
            note.access_key = nfe.key;
            note.authorization_protocol = "000000000";
            note.series = nfe.seriesDocumentSupervisior;
            note.receiptCode = "000000000";
            this.$store.dispatch(REVENUES_UPDATE_NFE, note);
            this.changeDialog();

          }

        }).catch((error) => {
          this.confirmDialog = !this.confirmDialog;
          this.changeDialog();
          this.dialog = !this.dialog
        })

      }


    },

    //Controle Submit/Editar
    save() {
      this.totalNF = this.form.value_total_note;
      this.confirmDialog = !this.confirmDialog;
    },

    //Data atual
    current_Date() {
      var dNow = new Date();
      var month = dNow.getMonth() + 1;
      var dec, dFull;

      if (month < 10) {
        month = "0" + month;
      }

      if (dNow.getDate() <= 9) {
        dec = "0";

        dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
        this.form.date_of_issue = dFull;
      } else {
        dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
        this.form.date_of_issue = dFull;
      }
    },
    //Atualiza o Objeto Form
    cloneObject() {
      if (this.object !== undefined) {
        this.form = { ...this.object };
      }
    },
    checkValidation() {
      if (this.validations.length == 2) {
        this.allValidated = 0;
        this.validations.forEach((element) => {
          if (element == true) {
            this.allValidated++;
            if (this.allValidated == 2) {
              this.valid = true;
            } else {
              this.valid = 0;
            }
          }
        });
      }
    },
  },
  watch: {
    "$vuetify.breakpoint.xsOnly": function () {
      this.setArrayItens();
    },
    form: {
      handler: function (val) {
        if (val.cliente !== undefined) {
          if (val.cliente.people.physicalpeople !== null) {
            this.client.name = val.cliente.people.physicalpeople.name;
          } else {
            this.client.name = val.cliente.people.legalpeople.fantasyName;
          }
          this.client.id = val.cliente.id;
        }
      },
      deep: true,
    },
    getRevenusItens: function (val) {
      this.value_products(val);
      this.calculate_InfoNote(val);
      this.baseCalcIcms(val);
    },
    getStatusNFe: function (val){ 
    this.nfeStatusInfo = val 
    this.nfeStatusActive= true 
    this.setArrayItens();
    }
  },

  created() {
    this.setArrayItens();
    this.cloneObject();
    this.current_Date();
  },
  mounted() {
    this.refresh_dates();
  },
  destroyed() {
    this.clear_revenues();
    this.nfeStatusActive = false 
   
  },


};
</script>

