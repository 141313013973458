const ADD_COMISSION_TYPE = "ADD_COMISSION_TYPE";
const UPDATE_COMISSION_TYPE = "UPDATE_COMISSION_TYPE";
const GET_ALL_COMISSION_TYPE = "GET_ALL_COMISSION_TYPE";
const DESTROY_COMISSION_TYPE = "DESTROY_COMISSION_TYPE";
const GET_FILTER_COMISSION_TYPE = "GET_FILTER_COMISSION_TYPE";

module.exports = {
    ADD_COMISSION_TYPE,
    UPDATE_COMISSION_TYPE,
    GET_ALL_COMISSION_TYPE,
    DESTROY_COMISSION_TYPE,
    GET_FILTER_COMISSION_TYPE
}
