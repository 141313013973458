<template>
  <v-dialog v-model="vdialog" width="60%">
    <v-card>
      <v-col>
        <v-row>
          <v-card-title>Transportadoras Cadastradas</v-card-title>
        </v-row>
        <v-row>
          <v-divider />
        </v-row>
        <v-row class="d-flex justify-center">
          <v-data-table :headers="headers" :items="values" :items-per-page="5" class="ma-5">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn class="success" @click="selectItem(item)">selecionar</v-btn>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    headers: Array(),
    values: Array(),
    component: Object()
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterSelectionTable"])
  },
  data: function() {
    return {
      vdialog: Boolean()
    };
  },
  methods: {
    selectItem(item) {
       
       
      this.component.selectedTransporter.legalpeople = {
        ...item.legalpeople,
        id: item.id
      };
      this.vdialog = false;
    }
  },
  watch: {
    getShowDialogRegisterSelectionTable: function() {
      this.vdialog = this.getShowDialogRegisterSelectionTable;
    },
    vdialog: function() {
      this.$store.dispatch("setShowDialogRegisterSelectionTable", this.vdialog);
    }
  }
};
</script>

<style>
</style>