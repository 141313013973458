<template>
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            disabled
            outlined
            dense
            label="Codigo do erro apresentado na emissao"
            :value="nfe.stat"
          />
        </v-col>
        <v-col>
          <v-textarea
            disabled
            outlined
            dense
            label="Erro informado na emissão nfce"
            :value="nfe.errorReason"
            ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-container>
  </template>
    
    <script>
  export default {
    components: {},
    props: {
      nfe: Object(),
    },
    data() {
      return {};
    },
    methods: {
  
    },
 
  };
  </script>
    
    <style>
  </style>