<template>
  <v-row justify="center">
    <v-dialog
      persistent
      max-width="800"
      v-model="getShowDialogRegisterConfirmNote"
    >
      <v-card>
        <v-container>
          <v-card-title>Confirmação da Nota</v-card-title>
          <v-divider></v-divider>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    :items="account"
                    :rules="[(v) => !!v || 'Campo Obrigatório']"
                    item-text="description"
                    item-value="id"
                    v-model="formData.accountPlans"
                    outlined
                    dense
                    label="Plano de Contas"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      dense
                      outlined
                      :items="payment"
                      item-text="description"
                      return-object
                      v-model="formData.formPayment"
                      label="Forma"
                      :rules="[(v) => !!v || 'Campo Obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      :disabled="valid_form"
                      :items="terms"
                      item-text="description"
                      dense
                      v-model="formData.conditionPayment"
                      outlined
                      return-object
                      label="Condição"
                      :rules="[(v) => !!v || 'Campo Obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      placeholder="0,00"
                      filled
                      readonly
                      dense
                      v-model="noteObject.remaining"
                      color="red"
                      outlined
                      label="Restante"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      placeholder="0,00"
                      filled
                      readonly
                      dense
                      v-model="noteObject.release"
                      color="red"
                      outlined
                      label="Lançamentos"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      placeholder="0,00"
                      filled
                      readonly
                      v-model="noteObject.note_value"
                      dense
                      outlined
                      label="Valor da Nota"
                    >
                    </v-text-field>
                  </v-col>

                  <v-btn
                    class="mt-3 mr-2 success"
                    :disabled="!valid"
                    @click="add_form"
                    rounded
                  >
                    <v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                </v-row>
              </v-container>
              <v-data-table
                :headers="header"
                dense
                :items="invoices"
                :footer-props="{
                  'items-per-page-options': [5, 10, -1],
                  'items-per-page-text': 'Items por página',
                }"
                no-data-text="Sem registro!"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="yellow darken-4"
                    class="mr-2"
                    @click="
                      () => {
                        edit_item(item);
                      }
                    "
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>

              <v-dialog v-model="dialogEdit" persistent max-width="280">
                <v-card>
                  <v-card-title> Editar Parcela </v-card-title>
                  <v-divider></v-divider>
                  <v-col>
                    <v-text-field
                      outlined
                      prefix="R$"
                      v-model="amount_value"
                      type="number"
                      label="Valor da parcela"
                    >
                    </v-text-field>
                  </v-col>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="error" @click="dialogEdit = !dialogEdit"
                      >Cancelar</v-btn
                    >
                    <v-btn class="success mr-6" @click="confirmEdit()"
                      >Confirmar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-divider></v-divider>
              <v-card-actions>
                <v-row>
                  <v-col cols="6">
                    <v-btn block color="error" @click="cancel()"
                      >Cancelar</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      block
                      color="success"
                      :disabled="!confirmButton"
                      @click="submit"
                      >Confirmar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ADD_NOTE_ENTRY } from "../../../../../../store/actions/note_entry";
import { GET_ALL_ACCOUNT_PLAN } from "../../../../../../store/actions/account_plan";
import { GET_ALL_FORM_OF_PAYMENT } from "../../../../../../store/actions/formofpayment";
import { GET_ALL_PAYMENT_TERM } from "../../../../../../store/actions/paymentterm";
import { mountNoteObject, mountBillsToPay } from "./mount_note_object";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
  },
  data: () => ({
    header: [
      { text: "Número do Título", value: "number_title" },
      { text: "Tipo do Título", value: "type_title_description" },
      { text: "R$ Valor", value: "value_title" },
      { text: "Vencimento", value: "issuance_due" },
      { text: "Ações", value: "actions" },
    ],
    formData: {
      remaining: Number(),
      release: Number(),
      noteValue: Number(),
      accountPlans: Object(),
      formPayment: Object(),
      conditionPayment: Object(),
    },
    billstoPay: Array(),
    invoices: Array(),
    noteObject: Object(),
    valid: Boolean(),
    confirmButton: Boolean(),
    payment: Array(),
    terms: Array(),
    account: Array(),
  }),

  methods: {
    mountNoteObject,
    mountBillsToPay,
    async submit() {
      try {
        this.noteObject.billstoPay = this.billstoPay;
        await this.$store
          .dispatch(ADD_NOTE_ENTRY, this.noteObject)
          .then(() => {
            this.cancel();
            // this.$store.dispatch("setShowDialogRegisterImportXml",false)
            this.$router.push('/note_entry');
          });
      } catch (error) {
        throw new Error(error);
      }
    },
    add_form() {
      this.noteObject.type_title_description =
        this.formData.conditionPayment.description;
      this.noteObject.type_title = this.formData.conditionPayment.code;
      this.noteObject.account_plans_id = this.formData.accountPlans;
      this.noteObject.release = this.noteObject.remaining;
      this.noteObject.remaining = 0;
      this.noteObject.payment_terms_id = this.formData.conditionPayment.id;

      this.billstoPay.push(this.mountBillsToPay());
      this.invoices.push(this.noteObject);
      this.confirmButton = true;
    },
    cancel() {
      this.$store.dispatch("setShowDialogRegisterConfirmNote", false);
    },

    getAllInfNote() {
      this.formData.noteValue = this.getDataInfNote.value_total_note;
      this.formData.remaining = this.getDataInfNote.value_total_note;
    },
    async getResourser() {
      const payload = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, payload);
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, payload);
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, payload);
    },
  },

  computed: {
    ...mapGetters([
      "getFormOfPayment",
      "getPaymentTerms",
      "getAccount_Plan",
      "getShowDialogRegisterConfirmNote",
      "getSelectedEnterprise",
      "getDataInfGeneral",
      "getDataItensNote",
      "getDataTransporter",
      "getDataInfNote",
    ]),
  },
  async created() {
    await this.getResourser();
    this.getAllInfNote();
    this.mountNoteObject();
  },

  watch: {
    getFormOfPayment: function (val) {
      this.payment = Object.keys(val).map((key) => val[key]);
    },
    getPaymentTerms: function (val) {
      this.terms = Object.keys(val).map((key) => val[key]);
    },
    getAccount_Plan: function (val) {
      this.account = Object.keys(val).map((key) => val[key]);
    },
  },
};
</script>

<style></style>
