

function todayDate() {
    const date = new Date()
    const day = date.getDate()
    const year = date.getFullYear()
    let month = date.getMonth() + 1

    const formattedMonth = month > 9 ? month : `0${month}`
//"2023-04-10"
    return `${year}-${formattedMonth}-${day}`
}


export {todayDate}
