<template>
  <div class="card-filter">
    <span id="title">Filtros</span>
    <div class="divider" />
    <div class="filter-components">
      <div class="component1">
        <v-text-field
          v-model="initial_date_sales"
          type="date"
          label="*Data Inicial"
          outlined
          dense
        />
      </div>
      <div class="component2">
        <v-text-field
          v-model="final_date_sales"
          type="date"
          label="*Data Final"
          outlined
          dense
        />
      </div>
      <div class="component3">
        <v-autocomplete
          :items="listEnterprise"
          item-text="fantasyName"
          v-model="enterpriseSelected"
          return-object
          chips
          label="*Empresas"
          outlined
          dense
        />
      </div>
      <v-btn @click="submit" class="success">Aplicar Filtro</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DATA_SALES } from "../../../store/actions/dashboard_charts";
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { todayDate } from "../../Utils/global_functions/today_date";

export default {
  components: {},
  props: {
    component: Object,
  },
  computed: {
    ...mapGetters(["getEnterprises", "getSelectedEnterprise", "getReportChartSales"]),
  },
  data: () => ({
    initial_date_sales: Date(),
    final_date_sales: Date(),
    listEnterprise: Array(),
    enterpriseSelected: Object(),
  }),
  methods: {
    settingDate(){
      this.final_date_sales = todayDate()
      this.initial_date_sales = todayDate()
      // const date = new Date(this.final_date_sales)
      // date.setMonth(date.getMonth() - 1);
      // const updatedDateString = date.toISOString().slice(0, 10);
      // this.initial_date_sales = updatedDateString
    },
    getEnterprise(page = 1) {
      const payload = {
        page,
        paginate: true,
        limit: 10,
      };

      this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
    async submit() {
      const payload = {
        initial_date_sales: this.initial_date_sales,
        final_date_sales: this.final_date_sales,
        enterprise_id: this.enterpriseSelected.id,
        filter: true,
      };
      await this.$store.dispatch(DATA_SALES, payload).then((response) => {
        this.component.salesDashboard = response
        // console.log(this.component.salesDashboard);
        this.component.value_total_sales = this.component.salesDashboard[0].valuesTotalSales[0].venda_total.toFixed(2)
        this.component.value_discount = this.component.salesDashboard[1].discountPerStore[0].total_discount
        this.component.average_ticket = this.component.salesDashboard[1].averageTicket
        this.component.value_exchange = this.component.salesDashboard[1].exchangeCredit

      })
    },
  },
  watch: {
    getEnterprises: function (val) {
      this.listEnterprise = val.data;
      this.enterpriseSelected = val.data[0];
      this.submit()
    },

    enterpriseSelected: function (val) {
      
      this.$store.dispatch(SELECTED_ENTERPRISE, val);
    },
  },
  created() {
    
    // this.settingDate()
    // this.getEnterprise();
  },

  mounted() {
    this.settingDate()
    this.getEnterprise()
  }
};
</script>

<style>
@import "./index.css";
</style>