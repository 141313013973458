const MAKE_UP_NOTE_VALUE = 1

let productsNFeInitial = {
  itemProduct: "",
  codeProduct: "",
  eanProduct: "",
  descriptionProduct: "",
  ncmProduct: "",
  nomenclatureValueCustomsProduct: "",
  codeCstProdut: "",
  relevantScaleIndicatorProduct: "",
  codeManufacturerProduct: "",
  recipientProduct: "",
  extIpiProduct: "",
  cfopProduct: "",
  unitBusinessProduct: "",
  amountBusinessProduct: "",
  valueUnityBusinessProduct: "",
  valueTotalGrossProduct: "",
  valueCostOfFreight: "",
  unityTaxProduct: "",
  amountTaxProduct: "",
  valueUnityTaxProduct: "",
  indicatesWhetherProduct: "",
  valueInsurancePriceProduct: "",
  sourceMerchandise: "",
  cstProduct: "",
  valueOfApproximateTax: "",
  methodDeterminingBaseIcms: "",
  valueBaseIcms: "",
  aliquotIcmsProduct: "",
  valueIcmsProduct: "",
  valueBaseRelatedCombatPoverty: "",
  percentageRelatedCombatPoverty: "",
  valueRelatedCombatPoverty: "",
  codeSituationTaxableCofins: "",
  codeSituationTaxablePis: "",
  csosnProduct: "",
  aliquotApplicableSimplesNacional: "",
  valueApplicableSimplesNacional: "",
  methodOfDeterminingIcmsST: "",
  percentageValueAddedIcmsST: "",
  percentageReductionIcmsST: "",
  valueBaseIcmsST: "",
  aliquotValueIcmsST: "",
  valueIcmsST: "",
  valueRelatedCombatPovertyFCP: "",
  percentageRelatedCombatPovertyFCP: "",
  valueRelatedCombatPovertyFCPWithheld: "",
  valueBaseIcmsSTWithheld: "",
  aliquotSuportedFinalConsumer: "",
  valueIcmsOwnSubstitute: "",
  valueIcmsStWithheld: "",
  valueBaseRelatedCombatPovertyWithhelPrevious: "",
  percentageRelatedCombatPovertyWithhelPreviousST: "",
  valueRelatedCombatPovertyWithhelST: "",
  percentageReductionBaseEffective: "",
  valueBaseEffective: "",
  aliquotBaseEffective: "",
  valueTaxEffective: "",
  percentageReductionBaseIcms: "",
  valueCreditUsedSimplesNacional: "",
  valueBasePis: "",
  aliquotPis: "",
  valuePis: "",
  amountPis: "",
  aliquotPisReais: "",
  valueBaseCofins: "",
  aliquotCofins: "",
  valueCofins: "",
  amountCofins: "",
  aliquotCofinsReais: "",
  cnpjProducerCommodity: "",
  codeSealcontrollerIPI: "",
  amountSealcontrollerIPI: "",
};

async function execute(revenues, enterprise) {
  try {
    let productArray = []
    revenues.revenusItem.forEach(item => {
         productsNFe = { ...productsNFeInitial }
         productsNFe.aliquotApplicableSimplesNacional = 0,
         productsNFe.aliquotCofinsReais = item.cst_code_confis  != "99" ? item.value_cofins_product :0,
         productsNFe.aliquotIcmsProduct = item.aliquot_icms_product,
         productsNFe.aliquotPisReais =  item.cst_code_pis != "99" ? item.value_pis_product :0,
         productsNFe.amountBusinessProduct = item.amount_add,
         productsNFe.amountCofins = item.cst_code_confis  != "99" ? item.amount_add :0,
         productsNFe.amountPis = item.cst_code_pis != "99" ?item.amount_add :0,
         productsNFe.amountSealcontrollerIPI = "",
         productsNFe.amountTaxProduct = item.amount_add,
         productsNFe.cfopProduct = parseInt(item.code_cfop),
         productsNFe.cnpjProducerCommodity = "",
         productsNFe.codeCstProdut = (String(item.code_cst) === '0' ? '00' : String(item.code_cst)),
         productsNFe.codeManufacturerProduct = "",
         productsNFe.codeProduct = String(item.product_id),
         productsNFe.codeSealcontrollerIPI = "",
         (productsNFe.codeSituationTaxableCofins = item.cst_code_confis),
         (productsNFe.codeSituationTaxablePis = item.cst_code_pis);
         productsNFe.csosnProduct = item.code_csosn,
         productsNFe.cstProduct = item.code_cst,
         productsNFe.descriptionProduct = item.description_product,
         productsNFe.eanProduct = checkGetin(item.bar_code),
         (productsNFe.extIpiProduct = ""),
         productsNFe.indicatesWhetherProduct = MAKE_UP_NOTE_VALUE,
         productsNFe.itemProduct = item.code_item,
         productsNFe.methodDeterminingBaseIcms = item.calculation_base_mode,
         productsNFe.ncmProduct = String(item.ncm_code),
         productsNFe.nomenclatureValueCustomsProduct = "",
         productsNFe.percentageReductionBaseEffective = 0
         productsNFe.percentageReductionBaseIcms = "",
         productsNFe.percentageRelatedCombatPoverty = enterprise.crt == "3" ? 2 : 0

         productsNFe.percentageRelatedCombatPovertyFCP = 0,
         productsNFe.recipientProduct = "",
         productsNFe.relevantScaleIndicatorProduct = "",
         productsNFe.sourceMerchandise = item.source_product,
         productsNFe.unitBusinessProduct = item.description_unit,
         productsNFe.unityTaxProduct = String(item.description_unit),
         productsNFe.valueApplicableSimplesNacional = 0,
         productsNFe.valueBaseCofins = item.cst_code_confis != "99" ? item.value_base_cofins_product : 0,
         productsNFe.valueBaseEffective = "",
         productsNFe.valueBaseIcms = item.value_base_icms_product,
         productsNFe.valueBaseIcmsST = item.value_base_icms_st_product,
         productsNFe.valueBaseIcmsSTWithheld = "",
         productsNFe.valueBasePis = item.value_base_pis_product,
         productsNFe.valueBaseRelatedCombatPoverty = 0,
         productsNFe.valueBaseRelatedCombatPovertyWithhelPrevious = 0 //item.valueBaseRelatedCombatPoverty,
         productsNFe.valueCofins = item.cst_code_pis  != "99" ? item.value_cofins_product : 0,
         productsNFe.valueCostOfFreight = null,
         productsNFe.valueCreditUsedSimplesNacional = "",
         productsNFe.valueIcmsOwnSubstitute = "",
         productsNFe.valueIcmsProduct = item.value_icms_product,
         productsNFe.valueIcmsST = "",
         productsNFe.valueIcmsStWithheld = "",
         productsNFe.valueInsurancePriceProduct = "",//revenues.value_security,
         productsNFe.valueOfApproximateTax = item.value_icms_product,
         productsNFe.valuePis = item.value_pis_product,
         productsNFe.valueRelatedCombatPoverty = enterprise.crt == "3"  ? caculationFunValuePoverty(productsNFe.percentageRelatedCombatPoverty, item.value_sale) : 0,
         productsNFe.valueRelatedCombatPovertyFCP = 0,
         productsNFe.valueRelatedCombatPovertyFCPWithheld = 0,
         productsNFe.valueRelatedCombatPovertyWithhelST = 0,
         productsNFe.valueTaxEffective = "",
         productsNFe.valueTotalGrossProduct = item.value_products,
         productsNFe.valueUnityBusinessProduct = item.value_sale,
         productsNFe.valueUnityTaxProduct = item.value_sale,

        productArray.push(productsNFe);
    });

    return productArray

  } catch (error) {
    return error
  }

}

// productsNFe.valueRelatedCombatPoverty = enterprise.crt == 3 ? caculationFunValuePoverty(productsNFe.percentageRelatedCombatPoverty, item.value_subtotal_product) : 0

function checkGetin(eanProduct) {
  if (eanProduct === null || eanProduct === '') {
    return 'SEM GTIN'
  }
  return eanProduct
}

function caculationFunValuePoverty(aliquot, valorProduct, cfop) {
  let valueCalcule = 0
  let taxPercentual = 0
  taxPercentual = aliquot / 100

  valueCalcule = parseFloat(valorProduct) * taxPercentual
  return valueCalcule

}
module.exports = {
  execute,
};



        //pendencia

        //productsNFe.percentageValueAddedIcmsST = 0,
        
       // productsNFe.aliquotBaseEffective = item.aliquot_fcp,
        //productsNFe.aliquotValueIcmsST = item.aliquot_fcp,
      //  productsNFe.aliquotCofins = item.aliquot_cofins,
      //  productsNFe.aliquotPis = item.aliquot_pis,
     //   
      //  productsNFe.percentageReductionIcmsST = item.percentagem_calc_st_reduction,
      //  productsNFe.aliquotSuportedFinalConsumer = item.percentage_supported_final_consumer,
   //    
     //   productsNFe.percentageRelatedCombatPovertyWithhelPreviousST = item.percentageRelatedCombatPovert,

       
        
       
        
        
        
        
        

        //caso seja simples nacional
        
        
        
        
        
        

       

        
        
        

        
        
       

        //Valores Pis 
        
        
        

       
        
        
        
     