<template>
  <v-row justify="center">
    <v-dialog persistent max-width="800" v-model="component.confirmDialog">
      <v-card>
        <v-container>
          <v-card-title>Confirmação da Nota</v-card-title>
          <v-divider></v-divider>
          <v-container>
            <v-form v-model="validAdd" ref="form">
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    :items="account"
                    item-text="description"
                    item-value="id"
                    v-model="plan"
                    :rules="[(v) => !!v || 'Campo Obrigatório']"
                    outlined
                    required
                    dense
                    label="Plano de Contas"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      dense
                      outlined
                      :items="payment"
                      item-text="description"
                      return-object
                      v-model="form"
                      required
                      :rules="[(v) => !!v || 'Campo Obrigatório']"
                      label="Forma"
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      :disabled="valid_form"
                      :items="termsFilter"
                      item-text="description"
                      dense
                      v-model="condition"
                      outlined
                      :rules="required"
                      return-object
                      label="Condição"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      placeholder="0,00"
                      filled
                      readonly
                      dense
                      v-model="remaining"
                      color="red"
                      outlined
                      label="Restante"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      placeholder="0,00"
                      filled
                      readonly
                      dense
                      v-model="debt"
                      color="red"
                      outlined
                      label="Lançamentos"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      placeholder="0,00"
                      filled
                      readonly
                      v-model="totalNF"
                      dense
                      outlined
                      label="Valor da Nota"
                    >
                    </v-text-field>
                  </v-col>

                  <v-btn
                    class="mt-3 mr-2 success"
                    :disabled="!validAdd"
                    @click="add_form()"
                    rounded
                  >
                    <v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                </v-row>
              </v-container>
              <v-data-table
                :headers="header"
                dense
                :items="invoices"
                :footer-props="{
                  'items-per-page-options': [5, 10, -1],
                  'items-per-page-text': 'Items por página',
                }"
                no-data-text="Sem registro!"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="yellow darken-4"
                    class="mr-2"
                    @click="
                      () => {
                        edit_item(item);
                      }
                    "
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>

              <v-dialog v-model="dialogEdit" persistent max-width="280">
                <v-card>
                  <v-card-title> Editar Parcela </v-card-title>
                  <v-divider></v-divider>
                  <v-col>
                    <v-text-field
                      outlined
                      prefix="R$"
                      v-model="amount_value"
                      type="number"
                      label="Valor da parcela"
                    >
                    </v-text-field>
                  </v-col>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="error" @click="dialogEdit = !dialogEdit"
                      >Cancelar</v-btn
                    >
                    <v-btn class="success mr-6" @click="confirmEdit()"
                      >Confirmar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-divider></v-divider>
              <v-card-actions>
                <v-row>
                  <v-col cols="6">
                    <v-btn block color="error" @click="cancel()"
                      >Cancelar</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      block
                      color="success"
                      :disabled="!valid"
                      @click="confirm_note()"
                      >Confirmar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ADD_NOTE_ENTRY } from "../../../../store/actions/note_entry";
import { GET_ALL_ACCOUNT_PLAN } from "../../../../store/actions/account_plan";
import { GET_ALL_FORM_OF_PAYMENT } from "../../../../store/actions/formofpayment";
import { GET_ALL_PAYMENT_TERM } from "../../../../store/actions/paymentterm";

import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    formPayment: Object,
    paymentTerms: Object,
    accountPlan: Object,
    totalNF: Number,
    changeDialog: Function,
  },
  data: () => ({
    header: [
      { text: "Número do Título", value: "number_title" },
      { text: "Tipo do Título", value: "type_title" },
      { text: "R$ Valor", value: "value_title" },
      { text: "Vencimento", value: "issuance_due" },
      { text: "Ações", value: "actions" },
    ],

    terms: [],
    termsFilter: [],
    account: [],
    payment: [],
    valid: Boolean(),
    validAdd: Boolean(),
    valid_form: Boolean(),
    dialogEdit: Boolean(),
    form: Number(),
    plan: Number(),
    debt: Number(),
    remaining: Number(),
    amount_value: Number(),
    condition: Number(),
    invoices: Array(),
    ObjectToUpdate: Object(),
    required: [(v) => !!v || "Obrigatório"],
    valueParament: {
      page: null,
      limit: null,
      paginate: false,
    },
  }),

  methods: {
    cancel() {
      this.component.confirmDialog = !this.component.confirmDialog;
      this.amount = 0;
      this.form = Object();
      this.plan = "";
      this.condition = "";
      this.$refs.form.resetValidation();
      this.invoices = [];
    },
    async getFormPayment() {
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, this.valueParament);
    },
    async getPaymentTerm() {
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, this.valueParament);
    },
    async getAccountPlan() {
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, this.valueParament);
    },

    make_issue(index) {
      let aux_deadline = this.condition.deadline.split("/");
      let deadline = aux_deadline.map((e) => parseInt(e));
      let day = 0;
      let month = 0;
      let auxdate = this.component.invoice.date_of_issue.replaceAll("-", "/");
      let a = new Date(auxdate); // Criação de vencimento da Fatura
      a.setDate(a.getDate() + deadline[index - 1]);
      day = a.getDate();
      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }
      month = a.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }
      let issuance_due = `${day}/${month}/${a.getFullYear()}`;
      return issuance_due;
    },

    add_form() {
      let final = {};
      //Montagem do objeto

      final.type_title = this.condition.description;
      final.form = { ...this.form };
      final.account_plans_id = this.plan;
      final.condition = { ...this.condition };
      final.provider_id = this.component.invoice.provider_id;
      final.enterprise_id = this.component.invoice.enterprise_id;
      let totalNf = this.totalNF;
      let amountPay = final.condition.amount;

      this.invoices = [];
      // A vista

      if (amountPay === 0) {
        let valuePay = { ...final };
        valuePay.id = 1;
        valuePay.value_title = totalNf;
        valuePay.value_net = totalNf;
        valuePay.title_balance = totalNf;
        valuePay.number_title = `${this.component.invoice.number_grade}-001`;
        valuePay.issuance_date = this.component.invoice.date_of_issue;

        valuePay.issuance_due = this.make_issue(1);
        valuePay.status = "Aberto";
        valuePay.dejection = 0;
        valuePay.interest = 0;

        valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.invoice.number_grade}`;
        this.invoices.push(valuePay);
      } else {
        //Lógica das parcelas
        let amount = parseFloat((totalNf / amountPay).toFixed(2));
        let debtTotal = parseFloat((amount * amountPay).toFixed(2));

        ////Condição com pedencia de valor
        if (debtTotal != totalNf) {
          let remaining = parseFloat((totalNf - debtTotal).toFixed(2));
          for (var i = 1; i <= amountPay; i++) {
            if (i === amountPay) {
              let valuePay = { ...final };
              switch (i.toString().length) {
                case 1:
                  valuePay.number_title = `${this.component.invoice.number_grade}-00${i}`;
                  break;
                case 2:
                  valuePay.number_title = `${this.component.invoice.number_grade}-0${i}`;
                  break;
                case 3:
                  valuePay.number_title = `${this.component.invoice.number_grade}-${i}`;
                  break;
                default:
                  valuePay.number_title = `${this.component.invoice.number_grade}-000`;
              }

              valuePay.id = i;
              valuePay.status = "Aberto";
              valuePay.issuance_date = this.component.invoice.date_of_issue;

              valuePay.issuance_due = this.make_issue(i);
              valuePay.value_title = parseFloat(
                (amount + remaining).toFixed(2)
              );
              valuePay.value_net = parseFloat((amount + remaining).toFixed(2));
              valuePay.title_balance = parseFloat(
                (amount + remaining).toFixed(2)
              );
              valuePay.dejection = 0;
              valuePay.interest = 0;
              valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.invoice.number_grade}`;
              this.invoices.push(valuePay);
            } else {
              let valuePay = { ...final };
              switch (i.toString().length) {
                case 1:
                  valuePay.number_title = `${this.component.invoice.number_grade}-00${i}`;
                  break;
                case 2:
                  valuePay.number_title = `${this.component.invoice.number_grade}-0${i}`;
                  break;
                case 3:
                  valuePay.number_title = `${this.component.invoice.number_grade}-${i}`;
                  break;
                default:
                  valuePay.number_title = `${this.component.invoice.number_grade}-000`;
              }
              valuePay.id = i;
              //valuePay.number_title = `${this.component.invoice.number_grade}-0${i}`
              valuePay.status = "Aberto";
              valuePay.issuance_date = this.component.invoice.date_of_issue;
              valuePay.issuance_due = this.component.invoice.date_of_issue;

              valuePay.issuance_due = this.make_issue(i); // Data de vencimento das faturas
              valuePay.value_title = amount;
              valuePay.value_net = amount;
              valuePay.title_balance = amount;
              valuePay.dejection = 0;
              valuePay.interest = 0;
              valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.invoice.number_grade}`;
              this.invoices.push(valuePay);
            }
          }
        } else {
          //Sem pendencia
          for (i = 1; i <= amountPay; i++) {
            let valuePay = { ...final };
            switch (i.toString().length) {
              case 1:
                valuePay.number_title = `${this.component.invoice.number_grade}-00${i}`;
                break;
              case 2:
                valuePay.number_title = `${this.component.invoice.number_grade}-0${i}`;
                break;
              case 3:
                valuePay.number_title = `${this.component.invoice.number_grade}-${i}`;
                break;
              default:
                valuePay.number_title = `${this.component.invoice.number_grade}-000`;
            }
            valuePay.id = i;
            //valuePay.number_title = `${this.component.invoice.number_grade}-0${i}`
            valuePay.status = "Aberto";
            valuePay.issuance_date = this.component.invoice.date_of_issue;
            valuePay.issuance_due = this.component.invoice.date_of_issue;
            valuePay.value_net = amount;
            valuePay.issuance_due = this.make_issue(i);
            valuePay.value_title = amount;
            valuePay.title_balance = amount;
            valuePay.dejection = 0;
            valuePay.interest = 0;
            valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.invoice.number_grade}`;
            this.invoices.push(valuePay);
          }
        }
      }

      this.debt = totalNf;
      this.remaining = 0;
    },

    edit_item(item) {
      this.dialogEdit = !this.dialogEdit;
      this.amount_value = item.value_title;
      this.ObjectToUpdate = { ...item };
    },
    confirmEdit() {
      const reducer = (acc, cur) => parseFloat((acc + cur).toFixed(2));

      this.ObjectToUpdate.value_title = parseFloat(this.amount_value);
      this.invoices.Update(this.ObjectToUpdate);

      let NewDebt = this.invoices.map((e) => e.value_title);

      this.debt = NewDebt.reduce(reducer);
      this.remaining = parseFloat((this.totalNF - this.debt).toFixed(2));

      this.dialogEdit = !this.dialogEdit;
    },

    adjust_variables() {
      this.component.invoice.base_calc_icms_total = parseFloat(
        this.component.invoice.base_calc_icms_total
      );
      this.component.invoice.base_calc_ipi_total = parseFloat(
        this.component.invoice.base_calc_ipi_total
      );
      this.component.invoice.value_account_total = parseFloat(
        this.component.invoice.value_account_total
      );
      this.component.invoice.value_base_icms_st_product = parseFloat(
        this.component.invoice.value_base_icms_st_product
      );
      this.component.invoice.value_discont = parseFloat(
        this.component.invoice.value_discont
      );
      this.component.invoice.value_freigth = parseFloat(
        this.component.invoice.value_freigth
      );
      this.component.invoice.value_icms_free_total = parseFloat(
        this.component.invoice.value_icms_free_total
      );
      this.component.invoice.value_icms_total = parseFloat(
        this.component.invoice.value_icms_total
      );
      this.component.invoice.value_ipi_free_total = parseFloat(
        this.component.invoice.value_ipi_free_total
      );
      this.component.invoice.value_ipi_outher_total = parseFloat(
        this.component.invoice.value_ipi_outher_total
      );
      this.component.invoice.value_ipi_total = parseFloat(
        this.component.invoice.value_ipi_total
      );
      this.component.invoice.value_security = parseFloat(
        this.component.invoice.value_security
      );
      this.component.invoice.value_total_note = parseFloat(
        this.component.invoice.value_total_note
      );
      this.component.invoice.value_total_products = parseFloat(
        this.component.invoice.value_total_products
      );
      this.component.invoice.value_icms_outher_product = parseFloat(
        this.component.invoice.value_icms_outher_product.replace(",", ".")
      );
      this.component.invoice.gradeItens.forEach((e) => { 
        e.aliquot_ipi_product = parseFloat(e.aliquot_ipi_product);
        e.amount = parseFloat(e.amount);
        e.value_base_icms_product = parseFloat(e.value_base_icms_product);
        e.cust = parseFloat(e.cust);
        e.value_base_icms_st_product = parseFloat(e.value_base_icms_st_product);
        e.value_free_icms_product = parseFloat(e.value_free_icms_product);
        e.value_icms_outher_product = parseFloat(e.value_icms_outher_product);
        e.value_icms_product = parseFloat(e.value_icms_product);
        e.value_ipi_product = parseFloat(e.value_ipi_product);
        e.value_sub_total_product = parseFloat(e.cust);
      });
      this.invoices.forEach((e) => {
        e.issuance_due = e.issuance_due.split("/").reverse().join("-");
      });
    },

    async confirm_note() {
      this.adjust_variables();
      this.invoices.forEach((e) => delete e.id);
      let entry_note = { ...this.component.invoice, billstoPay: this.invoices };
      entry_note.payment_terms_id = this.condition.id;
      entry_note.account_plans_id = this.plan;
      try {
        await this.$store.dispatch(ADD_NOTE_ENTRY, entry_note);
      } catch (error) {
        alert(error);
      } finally {
        this.cancel();
        this.changeDialog();
      }
    },
  },

  computed: {
    ...mapGetters(["getFormOfPayment", "getPaymentTerms", "getAccount_Plan"]),
  },
  async created() {
    await this.getFormPayment();
    await this.getPaymentTerm();
    await this.getAccountPlan();
    this.remaining = this.totalNF;
  },

  watch: {
    debt: function () {
      if (this.debt === this.totalNF) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    form: function (val) {
      this.termsFilter = []
      for(let i=0; i<this.terms.length; i++) {
        if(val.id == this.terms[i].form_of_payment_id) {
          this.termsFilter.push(this.terms[i])
        } 
      }
      if(this.termsFilter.length == 0) {
        this.termsFilter = this.terms
      }

    },
    getFormOfPayment: function (val) {
      this.payment = Object.keys(val).map((key) => val[key]);
    },
    getPaymentTerms: function (val) {
      this.terms = Object.keys(val).map((key) => val[key]);
    },
    getAccount_Plan: function (val) {
      this.account = Object.keys(val).map((key) => val[key]);
    },
  },
};
</script>

<style>
</style>