let destinationNFe = {
    cnpjorcpfDestination: "",
    idDestination: "",
    nameDestination: "",
    publicPlaceDestination: "",
    numberDestination: "",
    complementDestination: "",
    districtDestination: "",
    codeCountyDestination: "",
    nameCountyDestination: "",
    ufDestination: "",
    zipCodeDestination: "",
    codeCountryDestination: "",
    nameCountryDestination: "",
    numberPhoneDestination: "",
    recipientIEIndicatorDestination: "",
    stateRegistrationDestinationNumber: "",
    stateSuframaRegistration: "",
    stateMunicipalRegistrationDestination: "",
    emailDestination: "",
  };
  
  async function execute(revenues) {
    try {
       destinationNFe.cnpjorcpfDestination = await removeMaskCpfOrCnpj(revenues),
        destinationNFe.nameDestination = await checkNameClient(revenues),
        destinationNFe.publicPlaceDestination = revenues.cliente.people.address[0].street,
        destinationNFe.numberDestination = revenues.cliente.people.address[0].number,
        destinationNFe.complementDestination = revenues.cliente.people.address[0].complement,
        destinationNFe.districtDestination = revenues.cliente.people.address[0].district,
        destinationNFe.codeCountyDestination = String(revenues.cliente.people.address[0].city.id),
        destinationNFe.nameCountyDestination = revenues.cliente.people.address[0].city.state.abbr,
        destinationNFe.ufDestination =revenues.cliente.people.address[0].city.state.abbr,
        destinationNFe.zipCodeDestination = revenues.cliente.people.address[0].cep,
        destinationNFe.codeCountryDestination = "1058",
        destinationNFe.nameCountryDestination = "BRASIL",
        destinationNFe.numberPhoneDestination = revenues.cliente.people.contact[0].cellPhone,
        destinationNFe.recipientIEIndicatorDestination = revenues.cliente.people.legalpeople === null ? "9" : "1",
        destinationNFe.stateRegistrationDestinationNumber = revenues.cliente.people.legalpeople === null ? "ISENTO" : revenues.cliente.people.legalpeople.stateRegistration,
        destinationNFe.stateSuframaRegistration = "",
        destinationNFe.stateMunicipalRegistrationDestination = "",
        destinationNFe.emailDestination = revenues.cliente.people.contact[0].primaryEmail;
  
      return destinationNFe;
    } catch (error) {
      return error;
    }
  }
  
  async function removeMaskCpfOrCnpj(revenues) {
    if (revenues.cliente.people.legalpeople === null) {
      let cpf = revenues.cliente.people.physicalpeople.cpf;
      cpf = cpf.replaceAll(".", "").replaceAll("-", "");
      return cpf;
    } else {
      let cnpj = revenues.cliente.people.legalpeople.cnpj;
      cnpj = cnpj.replaceAll("-", "").replaceAll(".", "").replaceAll("/", "");
      return cnpj;
    }
  }
  
  async function checkNameClient(revenues) {
    if (revenues.cliente.people.legalpeople === null) {
      return revenues.cliente.people.physicalpeople.name;
    } else {
      return revenues.cliente.people.legalpeople.socialName;
    }
  }
  
  module.exports = {
    execute,
  };
  