const ISSUE_OF_INVOICE_NFE = 'ISSUE_OF_INVOICE_NFE'
const ITEM_TO_REPRINT = "ITEM_TO_REPRINT"
const PRINT_NFE = "PRINT_NFE"
const GET_ALL_ISSUE_OF_INVOICE =  'GET_ALL_ISSUE_OF_INVOICE'
const GET_SEQUENCE_NFE_EMITER_NFE = "GET_SEQUENCE_NFE_EMITER_NFE"
const REPROCESS_NFE_BEFORE_ERROR_DATA = "REPROCESS_NFE_BEFORE_ERROR_DATA"
const REPROCESS_NFE_WITH_KEY = "REPROCESS_NFE_WITH_KEY"
const CANCEL_NFE = "CANCEL_NFE"
const GET_NFE_WITH_KEY = "GET_NFE_WITH_KEY"

module.exports = {
    ISSUE_OF_INVOICE_NFE,
    ITEM_TO_REPRINT,
    PRINT_NFE,
    GET_ALL_ISSUE_OF_INVOICE,
    GET_SEQUENCE_NFE_EMITER_NFE,
    REPROCESS_NFE_BEFORE_ERROR_DATA,
    REPROCESS_NFE_WITH_KEY,
    CANCEL_NFE,
    GET_NFE_WITH_KEY
}