<template>
  <v-card class="ma-5 pa-5">
    <CardTitle showDialogState="setShowDialogRegisterEmitionTag" title="Emissão de Etiquetas" />
    <LoadScreen/>
    <AddDialog />
    <Table />
  </v-card>
</template>

<script>
import CardTitle from "../components/Utils/CardTitle_v2/index.vue";
import AddDialog from "../components/Emission_Tag/AddDialog/index.vue";
import Table from "../components/Emission_Tag/Table/index.vue"
import LoadScreen from "../components/load/index.vue"
export default {
  components: {
    CardTitle,
    AddDialog,
    Table,
    LoadScreen
  },
};
</script>

<style>
</style>