import { 
    ADD_ORDER_SEQUENCE_INVOICE, 
    GET_ALL_SEQUENCE_INVOICE 
} from "./actions/cancel_sequecence_invoice"

import apifiscal from "../apifiscal"
const {
    SPLICE_ERRO,
} = require('./actions/alert_api')

const cancelSequecenceInvoice = {
    state: {
        sequenceInvoice:Object(),
        orderSequenceInvoice: Object()
    },
    getters: {
        getOrderSequenceInvoice: function (state) {
            return state.orderSequenceInvoice;
        },
        getAllSequenceInvoice: (state) => ({
            ...state.sequenceInvoice
          }),
    },
    mutations: {
        GET_ALL_SEQUENCE_INVOICE: function (state, payload){
           state.sequenceInvoice = payload
        },
        ADD_ORDER_SEQUENCE_INVOICE: function (state, payload) {
            state.sequenceInvoice.data.push(payload)
        }
    },
    actions: {
        GET_ALL_SEQUENCE_INVOICE :function ({ commit }, payload){
            
            let token = localStorage.getItem("token_fiscal"); 
            apifiscal.post("noteissue/getalldisablementSequence", payload,{
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((response) => {
                commit(GET_ALL_SEQUENCE_INVOICE, response.data.sequence);
            }).catch((error)=> {
                commit(SPLICE_ERRO, {
                    alert: "Erro ao obter as sequências cancelada",
                    type: 'E'
                })
            })
        },
        ADD_ORDER_SEQUENCE_INVOICE: function ({context}, payload) {
            let token = localStorage.getItem("token_fiscal");           
                apifiscal.post("noteissue/disablementSequence", payload,{
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }).then((response) => {
                    context.commit(ADD_ORDER_SEQUENCE_INVOICE, response.data.correction);
                }).catch((error)=> {
                    commit(SPLICE_ERRO, {
                        alert: "Erro ao processar cancelamento de sequencia",
                        type: 'E'
                    })
                })
            
        }

    },
}
export default cancelSequecenceInvoice