<template>
  <Container>
    <v-card elevation="5">
      <v-container>
        <v-card outlined elevation="2">
          <GenerateDate />
        </v-card>
      </v-container>
    </v-card>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import GenerateDate from "../components/inventory/";
export default {
  components: {
    Container,
    GenerateDate,
  },
};
</script>

<style>
</style>