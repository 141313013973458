

const NoteModel = [
    {
        text: "Modelo de cupom fiscal eletrônico", value: "65"
    }
]

const rules = [
    (v) => !!v || "campo obrigatório"
]

module.exports = {
    NoteModel,
    rules
}