const ADD_PF_PROVIDER = "ADD_PF_PROVIDER";
const ADD_PJ_PROVIDER = "ADD_PJ_PROVIDER";
const UPDATE_PF_PROVIDER = "UPDATE_PF_PROVIDER";
const UPDATE_PJ_PROVIDER = "UPDATE_PJ_PROVIDER";
const GET_ALL_PROVIDER = "GET_ALL_PROVIDER";
const DESTROY_PROVIDER = "DESTROY_PROVIDER";
const GET_AUTOCOMPLETE_PROVIDER = "GET_AUTOCOMPLETE_PROVIDER";
const ITEM_TO_UPDATE = "ITEM_TO_UPDATE";
const AUTO_RELOAD_PROVIDER = "AUTO_RELOAD_PROVIDER"

module.exports = {
    ADD_PF_PROVIDER,
    ADD_PJ_PROVIDER,
    UPDATE_PF_PROVIDER,
    UPDATE_PJ_PROVIDER,
    GET_ALL_PROVIDER,
    DESTROY_PROVIDER,
    GET_AUTOCOMPLETE_PROVIDER,
    ITEM_TO_UPDATE,
    AUTO_RELOAD_PROVIDER
    
}
