const ADD_ALIQUOT = "ADD_ALIQUOT";
const UPDATE_ALIQUOT = "UPDATE_ALIQUOT";
const AUX_TO_UPDATE_ALIQUOT = "AUX_TO_UPDATE_ALIQUOT"
const GET_ALL_ALIQUOT = "GET_ALL_ALIQUOT";
const DESTROY_ALIQUOT = "DESTROY_ALIQUOT";
const GET_FILTER_ALIQUOT = "GET_FILTER_ALIQUOT";

module.exports = {
  ADD_ALIQUOT,
  UPDATE_ALIQUOT,
  AUX_TO_UPDATE_ALIQUOT,
  GET_ALL_ALIQUOT,
  DESTROY_ALIQUOT,
  GET_FILTER_ALIQUOT
}
