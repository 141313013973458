import { textFieldRules } from "./rules"
import { headersNFE_NFCE } from "./headers"
var localState = {
    headers: headersNFE_NFCE,
    rules: textFieldRules,
    alert: {
        showAlert: Boolean(),
        message: String(),
    },
    validation: {
        formPagePC: Boolean(),
        formPageMobile: Boolean(),
    },
    enterpriseSelected: Object(),
    listEmterprise: Array(),
    formState: {
        nfeType: String(),
        initialDate: String(),
        finalDate: String(),
    }
}


export default localState