import {
    product_select,
    product,
    aliquot,
    cfop,
    cst_csosn,
    cst_pis,
    cst_cofins,
} from "./WipeData"


function wipeData() {
    this.product_select = { ...product_select }
    this.product = { ...product }
    this.aliquot = { ...aliquot }
    this.cfop = { ...cfop }
    this.cst_csosn = { ...cst_csosn }
    this.cst_pis = { ...cst_pis }
    this.cst_cofins = { ...cst_cofins }
}


export default {
    wipeData
}