


var watchDependecy = {
    getNoteEntryItens: function () {
        this.calculate_all();
    },
    validationSteps: function(val){
        if(val.length == 7){
            this.validationButton = false
        }
    }
}



export default watchDependecy