const ADD_PRODUCT_GROUP = "ADD_PRODUCT_GROUP";
const UPDATE_PRODUCT_GROUP = "UPDATE_PRODUCT_GROUP";
const GET_ALL_PRODUCT_GROUP = "GET_ALL_PRODUCT_GROUP";
const DESTROY_PRODUCT_GROUP = "DESTROY_PRODUCT_GROUP";
const GET_FILTER_PRODUCT_GROUP = "GET_FILTER_PRODUCT_GROUP";
const AUX_TO_UPDATE_PRODUCT_GROUP = "AUX_TO_UPDATE_PRODUCT_GROUP";

module.exports = {
    ADD_PRODUCT_GROUP,
    UPDATE_PRODUCT_GROUP,
    GET_ALL_PRODUCT_GROUP,
    DESTROY_PRODUCT_GROUP,
    GET_FILTER_PRODUCT_GROUP,
    AUX_TO_UPDATE_PRODUCT_GROUP,
}
