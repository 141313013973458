<template>
  <div>

    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="3">
          <v-card color="#4EA03E" dark max width="300px" class="text-center">


            <v-card-text class="text-h5">
              Valor Total de Vendas
            </v-card-text>


            <v-row>
              <v-card-text>
                <v-col class="text-h4">
                  R${{ component.value_total_sales }}
                </v-col>

              </v-card-text>
            </v-row>

          </v-card>

        </v-col>
        <v-col cols="12" sm="3">
          <v-card color="#E2542C" dark max width="300px" class="text-center">


            <v-card-text class="text-h5">
              Valor de Desconto
            </v-card-text>


            <v-row>
              <v-card-text>
                <v-col class="text-h4">
                  R${{ component.value_discount }}
                </v-col>

              </v-card-text>
            </v-row>

          </v-card>

        </v-col>
        <v-col cols="12" sm="3">
          <v-card color="#DEA622" dark max width="300px" class="text-center">


            <v-card-text class="text-h5">
              Ticket Médio
            </v-card-text>


            <v-row>
              <v-card-text>
                <v-col class="text-h4">
                  R${{ component.average_ticket }}
                </v-col>

              </v-card-text>
            </v-row>

          </v-card>

        </v-col>
        <v-col cols="12" sm="3">
          <v-card color="#5488BF" dark max width="300px" class="text-center">


            <v-card-text class="text-h5">
              Valor da Troca
            </v-card-text>


            <v-row>
              <v-card-text>
                <v-col class="text-h4">
                  R${{ component.value_exchange }}
                </v-col>

              </v-card-text>
            </v-row>

          </v-card>

        </v-col>
      </v-row>


    

      <br>
      <v-divider></v-divider>
      <br>

    <!-- <div class="card-filter">
    <div id="body-chart"> -->
    
    <v-row justify="space-between">
      <v-col cols="12" sm="6">
        <div class="chart-module">

          <!-- Total vendas por loja -->
          <apexcharch type="bar" :height="$vuetify.breakpoint.mobile ? '300' : '500'" :series="modelSalesPerStore.series"
            :options="modelSalesPerStore.chartOptions"></apexcharch>
        </div>
      </v-col>
      <v-col cols="12" sm="6" style="text-align: -webkit-right;">
        <div class="chart-module">
          <apexcharch type="pie" :height="$vuetify.breakpoint.mobile ? '300' : '500'" :width="$vuetify.breakpoint.mobile ? '300' : '500'"
            :series="modelValuesSalesWithFormOfPaymentsModel.series"
            :options="modelValuesSalesWithFormOfPaymentsModel.chartOptions"></apexcharch>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="6">
        <div class="dataTableCard">
          <v-col cols="12" sm="6">
            <v-row>
          <div class="tableTitle">
            Produtos Mais Vendidos
          </div>
        </v-row>
        <v-row>
        <div class="dataTable">
          <v-data-table
            :headers="topProductsHeaders"
            :items="this.topProductDataTable"
            no-data-text="Nenhum Produto Encontrado"
            :items-per-page="5"
          >
          </v-data-table>
        </div>
        </v-row>
        </v-col>
        </div>
      </v-col>
      <v-col cols="12" sm="6" style="text-align: -webkit-right;">
        <div class="dataTableCard">
          <v-col cols="12" sm="6">
            <v-row>
          <div class="tableTitle">
            Valor Total de Vendas Por Produto
          </div>
        </v-row>
        <v-row>
        <div class="dataTable">
          <v-data-table
            :headers="moneyItensHeaders"
            :items="this.moneyItensDataTable"
            no-data-text="Nenhum Produto Encontrado"
            :items-per-page="5"
          >
          </v-data-table>
        </div>
        </v-row>
        </v-col>
        </div>
      </v-col>
    </v-row>

    <!-- <v-row justify="center">
      <v-col cols="12" sm="6">
         Produtos Mais Vendidos 
        <div class="chart-module">
          <apexcharch type="bar" :height="$vuetify.breakpoint.mobile ? '300' : '500'" :series="modelTopProductsSales.series"
            :options="modelTopProductsSales.chartOptions"></apexcharch>
        </div>
      </v-col>
      <v-col cols="12" sm="6" style="text-align: -webkit-right;">
         Total de Vendas Por Vendedor 
        <div class="chart-module">
          <apexcharch type="bar" :height="$vuetify.breakpoint.mobile ? '300' : '500'" :series="modelMoneyItens.series"
            :options="modelMoneyItens.chartOptions"></apexcharch>
        </div>
      </v-col>
    </v-row> -->

    <v-row justify="center">
      <v-col cols="12">
        <!-- tipo de vendas por loja -->
        <div class="chart-module">
          <apexcharch type="pie" :height="$vuetify.breakpoint.mobile ? '300' : '500'" :width="$vuetify.breakpoint.mobile ? '300' : '500'"
            :series="modelSalesPerSellers.series" :options="modelSalesPerSellers.chartOptions"></apexcharch>
        </div>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { modelSalesPerStore, dataSalesPerStore, copyT } from "./ModelDataChart/dataChartStore"
// import { dataSalesPerSellers,modelSalesPerSellers } from "./ModelDataChart/dataChartSallesPerSellers"
import { dataSalesPerSellers, modelSalesPerSellers } from "./ModelDataChart/dunutsDataChartSallesPerSellers"
import { dataTopProductsSales, modelTopProductsSales } from "./ModelDataChart/dataTopProducsSales"
import { dataMoneyItens, modelMoneyItens } from "./ModelDataChart/dataMoneyItens"
import { dataValuesSalesWithFormOfPayments, modelValuesSalesWithFormOfPaymentsModel } from "./ModelDataChart/groupedBarvaluesSalesWithFormOfPayments"

import { DATA_SALES } from "../../../store/actions/dashboard_charts";

import { mapGetters } from "vuex";
import { todayDate } from "../../Utils/global_functions/today_date";
export default {
  components: { apexcharch: VueApexCharts },
  props: {
    component: Object,
  },
  computed: {
    ...mapGetters(["getSelectedEnterprise", "getReportChartSales"]),
  },
  data: () => ({
    indicators: Array(),

    modelSalesPerStore,
    dataSalesPerStore,

    modelSalesPerSellers,
    dataSalesPerSellers,

    dataTopProductsSales,
    modelTopProductsSales,

    dataMoneyItens,
    modelMoneyItens,

    dataValuesSalesWithFormOfPayments,
    modelValuesSalesWithFormOfPaymentsModel,

    topProductDataTable: Array(),
    moneyItensDataTable: Array(),

    formOfPaymentsLabels: Array(),
    salesPerSellersLabels: Array(),
    dataSalesPerStoreLabels: Array(),

    topProductsHeaders: [
      { text: "Produto", value: "description" },
      { text: "Quantidade (vendas)", value: "number" },
    ],

    moneyItensHeaders: [
      { text: "Produto", value: "description" },
      { text: "Valor Total (vendas)", value: "number" },
    ],

  }),
  methods: {
    async getDataSales(enterprise_id) {
      const payload = {
        initial_date_sales: todayDate(), //"2022-12-07", //Mudar para a data atual
        final_date_sales: todayDate(), //"2022-12-07", //Mudar para a data atual
        enterprise_id: enterprise_id,
      };

      await this.$store.dispatch(DATA_SALES, payload).then((response) => {
        this.setDataSales(response);
        // this.setIndicators(response)
      });
    },

    setDataSales(response) {

      this.setDataValueTotalSales(response)

      this.setDataValuesSalesWithFormOfPayments(response)
  
      this.setDataSalesPerSellers(response)


      response[0].topSellingProducts.forEach(element => {
        dataTopProductsSales.series[0].data.push(element.amount)
        dataTopProductsSales.chartOptions.xaxis.categories.push(element.description_item)

        let auxTopProductDataTable = Array()
        for (let i = 0; i < dataTopProductsSales.series[0].data.length; i++) {
          auxTopProductDataTable[i] = Object()
          auxTopProductDataTable[i].description = dataTopProductsSales.chartOptions.xaxis.categories[i]
          auxTopProductDataTable[i].number = dataTopProductsSales.series[0].data[i]
        }
        this.topProductDataTable = auxTopProductDataTable
      });

      response[0].valuesTotalItensSales.forEach(element => {
        dataMoneyItens.series[0].data.push(element.sub_total_venda.toFixed(2))
        dataMoneyItens.chartOptions.xaxis.categories.push(element.descricao_produto)

        let auxdataMoneyItens = Array()
        for (let i = 0; i < dataMoneyItens.series[0].data.length; i++) {
          auxdataMoneyItens[i] = Object()
          auxdataMoneyItens[i].description = dataMoneyItens.chartOptions.xaxis.categories[i]
          auxdataMoneyItens[i].number = dataMoneyItens.series[0].data[i]
        }
        this.moneyItensDataTable = auxdataMoneyItens
        
      });

      this.modelTopProductsSales = { ...dataTopProductsSales }
      this.modelMoneyItens = { ...dataMoneyItens }
    },


    setDataValueTotalSales(response) {
      this.dataSalesPerStore.series = []
      this.dataSalesPerStore.chartOptions.xaxis.categories = []
      this.dataSalesPerStoreLabels = []
      let series = []
      for(let i = 0; i < response[0].valuesTotalSales.length; i++) {
        series.push(response[0].valuesTotalSales[i].venda_total.toFixed(2))
        this.dataSalesPerStoreLabels.push(response[0].valuesTotalSales[i].enterprise_name)
      }

      this.dataSalesPerStore = {
        series: [{
        data: series
    }],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 380
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: this.dataSalesPerStoreLabels,
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        title: {
            text: 'Valor Total de Vendas',
            align: 'center',
            floating: true
        },
        // subtitle: {
        //     text: 'Category Names as DataLabels inside bars',
        //     align: 'center',
        // },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        }
    },
      }

      this.modelSalesPerStore = this.dataSalesPerStore
      
      // response[0].valuesTotalSales.forEach(element => {
      //   dataSalesPerStore.series[0].data.push(element.venda_total.toFixed(2))
      //   dataSalesPerStore.chartOptions.xaxis.categories.push(element.enterprise_name)
      // });
    },

    setDataValuesSalesWithFormOfPayments(response) {
      this.dataValuesSalesWithFormOfPayments.chartOptions.labels = []
      this.dataValuesSalesWithFormOfPayments.series = []
      this.formOfPaymentsLabels = []
      let series = []
      for(let i = 0; i < response[0].valuesSalesWithFormOfPayments.length; i++) {
        this.formOfPaymentsLabels.push(`${response[0].valuesSalesWithFormOfPayments[i].forma_pagamento}: ${response[0].valuesSalesWithFormOfPayments[i].venda_total.toFixed(2)}`)
        series.push(response[0].valuesSalesWithFormOfPayments[i].venda_total)
      }

      this.dataValuesSalesWithFormOfPayments.chartOptions = {
        labels: this.formOfPaymentsLabels,
        title: {
            text: 'Vendas por Forma de Pagamento',
            align: 'center',
            floating: false
        },
        chart: {
            type: 'pie',
            width: 480
        },
        legend: {
            position: 'bottom',
        },
        responsive: [{
            breakpoint: 300,
            options: {
                chart: {
                    width: 200
                    
                },
            }
        }]
      }
      this.dataValuesSalesWithFormOfPayments.series = series
      this.modelValuesSalesWithFormOfPaymentsModel = this.dataValuesSalesWithFormOfPayments
    },

    setDataSalesPerSellers(response) {
      this.dataSalesPerSellers.chartOptions.labels = []
      this.dataSalesPerSellers.series = []
      this.salesPerSellersLabels = []
      let series = []
      for(let i = 0; i < response[0].valuesTotalSalesWIthSeller.length; i++) {
        this.salesPerSellersLabels.push(`${response[0].valuesTotalSalesWIthSeller[i].vendedor}: ${response[0].valuesTotalSalesWIthSeller[i].venda_total.toFixed(2)}`)
        series.push(response[0].valuesTotalSalesWIthSeller[i].venda_total)
      }

      this.dataSalesPerSellers.chartOptions = {
        labels: this.salesPerSellersLabels,
        title: {
            text: 'Vendas por Vendedor',
            align: 'center',
            floating: false
        },
        chart: {
            type: 'pie',
            width: 480
        },
        legend: {
            position: 'bottom',
        },
        responsive: [{
            breakpoint: 300,
            options: {
                chart: {
                    width: 200
                    
                },
            }
        }]
      }
      this.dataSalesPerSellers.series = series
      this.modelSalesPerSellers = this.dataSalesPerSellers
      
    }
  },
  watch: {
    getReportChartSales: function (val) {
      this.setDataSales(val);
      // this.setIndicators(val);
    },
    getSelectedEnterprise: function (val) {
      this.getDataSales(val.id);
    },
    getSelectedEnterprise: function (val) {
      // this.getDataSales(val.id)
    }
  },

};
</script>
 
<style scoped >
@import "./index.scss";
</style>