



let rules = [
  (v) => !!v || "Campo Obrigatório"
]


export let rulesCFOP = [
  (v) => typeof(v) != "string" || "Campo Obrigatório"
]


export default rules