import {description,itemNote} from "./WipeData"




function wipeData() {
    
    this.description = {...description};
    this.itemNote = {...itemNote};
 }



export default {
    wipeData
}