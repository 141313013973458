import account_plan from "./account_plan";
import alert_api from "./alert_api";
import aliquot from "./aliquot";
import auth from "./auth";
import bank from "./bank";
import barcode from "./barcode";
import bill_pays from "./bills_pay";
import bill_receive from "./bills_receive";
import book_supervision_in from "./book_supervision_in";
import book_supervision_out from "./book_supervision_out";
import cancelSequenceNoteInvoice from "./cancel_sequecence_invoice.js";
import card_adm from "./card_adm";
import cashier from "./cashier";
import cashierOperation from "./cashier_operation_type";
import cfop from "./cfop";
import client from "./client";
import comissionType from "./commission_type";
import companygroup from "./companygroup";
import correctionLetter from "./correction_letter";
import csosn from "./csosn";
import cst from "./cst";
import cstipi from "./cstipi";
import enterprise from "./enterprise";
import error from "./error";
import financial_movement from "./financial_movement";
import formofpayment from "./formofpayment";
import globalDialog from "./globalDialogs";
import links from "./links";
import load from "./load";
import move_stock from "./move_stock";
import ncm from "./ncm";
import nfe from "./nfe";
import note_entry from "./note_entry";
import note_entry_itens from "./note_entry_itens";
import note_model from "./note_model";
import paymentterm from "./paymentterm";
import Pdv from "./pdv";
import permission from "./permission";
import pis_cofins from "./pis_cofins";
import preNote_entry from "./preNote_entry";
import product from "./product";
import productgroup from "./productgroup";
import profession from "./profession";
import provider from "./provider";
import purchaseOrder from "./purchaseOrder";
import register from "./register";
import revenues from "./revenues";
import revenuesitems from "./revenuesitems";
import seller from "./seller";
import stock_balance from "./stock_balance";
import sub_product_group from "./sub_product_group";
import tax_operation from "./tax_operation_types";
import TaxCupom from "./taxCupom";
import transporter from "./transporter";
import unit from "./unit";
import vendas from "./vendas";
import ReportSales from "./report_sales";
import ItemsNote from "./importXML/items_note"
import GeneralData from "./importXML/general_data"
import ShippingCompany from "./importXML/shipping_company"
import CoreImportXml from "./importXML/core_import_xml"
import SpedIcms from "./sped/sped_icsm"
import ExchangeControl from "./exchange_control"
import ReportComissionSeller from "./report_comission_sellers"
import dashBoardChart from "./dashboard_charts";
import FutureSales from './future_sales'
import WriteOff from './write_off_xml'
import infoError from './infoError.js'
// import IssueReportNFE from "./issueReportNFE"
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    FutureSales,
    dashBoardChart,
    CoreImportXml,
    ReportComissionSeller,
    ExchangeControl,
    // IssueReportNFE,
    SpedIcms,
    ShippingCompany,
    ItemsNote,
    GeneralData,
    ReportSales,
    account_plan,
    alert_api,
    aliquot,
    auth,
    bank,
    barcode,
    bill_pays,
    bill_receive,
    book_supervision_in,
    book_supervision_out,
    cancelSequenceNoteInvoice,
    card_adm,
    cashier,
    cashierOperation,
    cfop,
    client,
    comissionType,
    companygroup,
    correctionLetter,
    csosn,
    cst,
    cstipi,
    enterprise,
    error,
    financial_movement,
    formofpayment,
    globalDialog,
    links,
    load,
    move_stock,
    ncm,
    nfe,
    note_entry_itens,
    note_entry,
    note_model,
    paymentterm,
    Pdv,
    permission,
    pis_cofins,
    preNote_entry,
    product,
    productgroup,
    profession,
    provider,
    purchaseOrder,
    register,
    revenues,
    revenuesitems,
    seller,
    stock_balance,
    sub_product_group,
    tax_operation,
    TaxCupom,
    transporter,
    unit,
    vendas,
    WriteOff,
    infoError
  },
});
