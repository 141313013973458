<template>
  <v-form ref="form" v-model="component.validPersonal">
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.PhysicalPeople.motherName"
          :readonly="component.justVisible"
          label="Nome da Mãe"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.PhysicalPeople.fatherName"
          :readonly="component.justVisible"
          label="Nome do Pai"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          label="*Gênero"
          outlined
          v-model="component.PhysicalPeople.gender"
          :readonly="component.justVisible"
          :items="items_gender"
          :rules="[() => !!component.PhysicalPeople.gender || 'Campo Obrigatorio',]"
          required
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.PhysicalPeople.monthlyIncome"
          :readonly="component.justVisible"
          label="Renda Mensal"
          outlined
          type="number"
          prefix="R$"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="component.PhysicalPeople.civilStatus"
          :readonly="component.justVisible"
          label="*Estado Civil"
          :items="civilStatus"
          outlined
          required
          :rules="[() => !!component.PhysicalPeople.civilStatus || 'Campo Obrigatorio',]"
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="component.PhysicalPeople.spouse"
          :readonly="component.justVisible"
          label="Cônjugue"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          v-model="component.PhysicalPeople.icomeSpouse"
          :readonly="component.justVisible"
          label="Renda Conjugal"
          outlined
          prefix="R$"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    items_gender: ["Masculino", "Feminino"],
    civilStatus: ["Casado(a)", "Divorciado(a)", "Solteiro(a)", "Viúvo(a)"],
  }),
};
</script>

<style>
</style>