<template>
  <v-container>
    <v-form :disabled="isDisable">
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-autocomplete
            label="Transportadora"
            item-text="name"
            item-value="id"
            :items="transportList"
            outlined
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="transporterDialog"
            v-model="transporter_id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.invoice.vehicle_plate"
            label="Placa do Veículo"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.invoice.volume_transported"
            label="Volume Transportado"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Peso Bruto da Mercadoria"
            v-model="component.invoice.gross_weigth"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Peso Líquido da Mercadoria"
            v-model="component.invoice.net_weight"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            label="Tipo de Volume Transportado"
            v-model="component.invoice.volume_type"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-select
            :items="freight"
            item-text="text"
            item-value="value"
            label="Tipo do Frete"
            v-model="component.invoice.type_freigth"
            
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-text-field
            label="Valor do Frete"
            v-model="component.invoice.value_freigth"
            type="number"
            v-on:change="component.refresh_discount()"
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-text-field
            label="Valor do Seguro"
            v-model="component.invoice.value_security"
            v-on:change="component.refresh_discount()"
            type="number"
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-text-field
            label="Outras Despesas Acessórias"
            v-model="component.invoice.outher_expenses_acess"
            v-on:change="component.refresh_discount()"
            prefix="R$"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <RegisterTransporter v-if="getShowDialogRegisterTransporter" />
    </v-form>
  </v-container>
</template>

<script>
import { GET_ALL_TRANSPORTER_TO_SELECT_FIELD } from "../../../../store/actions/transporters";
import { mapGetters } from "vuex";
import RegisterTransporter from "../../../transport/AddDialog";
export default {
  components: {
    RegisterTransporter,
  },
  props: {
    isDisable: Boolean(),
    component: Object(),
  },

  data: () => ({
    transporter_id: Number(),
    transportList: Array(),
    freight: [
      { text: "CIF", value: "cif" },
      { text: "FOB", value: "fob" },
    ],
  }),
  //"CIF", "FOB"
  methods: {
    transporterDialog() {
      this.$store.dispatch("setShowDialogRegisterTransporter", true);
    },
    async GetAllTransport(page = 1) {
      await this.$store.dispatch(GET_ALL_TRANSPORTER_TO_SELECT_FIELD, {
        page: page,
        limit: null,
        paginate: false,
      });
    },
    addNameTransport(val) {
      val.forEach((element) => {
        if (element.type_people === "PhysicalPeople") {
          element.name = element.people.physicalpeople.name;
        } else {
          element.name = element.people.legalpeople.fantasyName;
        }
      });
      return val;
    },
    mountedTransport() {
      this.transporter_id = this.component.invoice.transporter_id;
      if (
        this.component.invoice.type_freigth != "cif" &&
        this.component.invoice.type_freigth != "fob"
      ) {
        this.component.invoice.type_freigth = "cif";
      }
    },
  },

  computed: {
    ...mapGetters(["getSelectTransporter", "getShowDialogRegisterTransporter"]),
  },

  created() {
    this.GetAllTransport();
    this.mountedTransport();
    
  },

  watch: {
    transporter_id(val) {
      this.component.invoice.transporter_id = val;
    },
    getSelectTransporter(val) {
      this.transportList = this.addNameTransport(val);
    },
  },
};
</script>

<style>
</style>