import { headerTable } from "./headers";
import rules from "./rules";

var data = {
  getNoteEntryItensArray: Array(),
  infNote: Object(),
  ruless: rules,
  formValidate: Boolean(),
  arrayAliquots: Array(),
  arrayCfop: Array(),
  arrayCsosn: Array(),
  arrayCst: Array(),
  arrayPisCofins: Array(),
  indexProductToSetArray: Number(),
  callDialog: Boolean(),
  enableDisableCadastroPorduto: Boolean(),
  enableDisableAddProduto: Boolean(),
  header: headerTable,
  objectProduct: Object(),
  arrayProduct: Array(),
  arrayTax: Array(),
  //entrada de nota
  productInformation: Object(),
  enableButtom: true,
};

export default data;
