const ADD_SALES = "ADD_SALES";
const GET_CALC_TRIBUTE = "GET_CALC_TRIBUTE";
const UPDATE_SALES = "UPDATE_SALES";
const GET_ALL_SALES = "GET_ALL_SALES";
const DESTROY_SALES = "DESTROY_SALES";
const SET_CLIENTSALES = "SET_CLIENTSALES";
const OPEN_CASHIER = "OPEN_CASHIER";
const BLEED = "BLEED";
const SUPPLY = "SUPPLY"
const SET_CLOSE_SALE = "SET_CLOSE_SALE"
const CONFIRM_CLOSE_CASHIER = "CONFIRM_CLOSE_CASHIER"
const SET_CLIENT_IN_MEMORY = "SET_CLIENT_IN_MEMORY"
const SET_SELLER_IN_MEMORY = "SET_SELLER_IN_MEMORY"
const RESET_MEMORY = "RESET_MEMORY"
const SET_CART = "SET_CART"
const NEW_PRODUCT_CALCULED = "NEW_PRODUCT_CALCULED"
const UPDATE_CART = "UPDATE_CART"

module.exports = {
  NEW_PRODUCT_CALCULED,
  ADD_SALES,
  GET_CALC_TRIBUTE,
  UPDATE_SALES,
  GET_ALL_SALES,
  DESTROY_SALES,
  SET_CLIENTSALES,
  OPEN_CASHIER,
  BLEED,
  SUPPLY,
  SET_CLOSE_SALE,
  CONFIRM_CLOSE_CASHIER,
  SET_SELLER_IN_MEMORY,
  RESET_MEMORY,
  SET_CLIENT_IN_MEMORY,
  SET_CART,
  UPDATE_CART
};