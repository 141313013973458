export const rulesText = [
    (v) => !!v || "Campo Obrigatório"
]

export const rulesTextArea = [
    (v) => !!v || "Campo Obrigatório",
    (v) => v == undefined ? !!v || '' : v.length > 15 || "Sua Mensagem deve ter mais 15 Caracteres",
    (v) => v == undefined ? !!v || '' : v.length < 500 || "Sua Mensagem deve ter menos que 500 Caracteres"
]

