<template>
  <v-dialog v-model="getShowDialogRegisterFutureSeles" fullscreen>
    <div class="body-dialog">
      <v-card height="60px" class="success d-flex align-center">
        <v-btn icon @click="closeDialog" >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="title" >Vendas Futuras</v-card-title>
      </v-card>
      <div class="body-content">
        <div class="tab-product" >
          <TabProduct />
        </div>
        <div class="tab-car" >
          <TabCar />
        </div>
      </div>
    </div>
    <Footer/>
  </v-dialog>
</template>
 
<script>
import TabCar from "../TabCar/index.vue";
import TabProduct from "../TabDate/index.vue";
import {mapGetters} from "vuex"
import Footer from './Footer_Legends/index.vue'
export default {
  components: {
    TabCar,
    TabProduct,
    Footer
  },
  props: {},
  computed: {
    ...mapGetters(["getShowDialogRegisterFutureSeles"])
  },
  data: () => ({
    dialogActive: true,
  }),
  methods: {
    //Escutar teclas
    listenKey(){
      document.addEventListener('keydown', this.pressedKey);
    },

    //Tecla Pressionada
    pressedKey(e){
      switch (e.keyCode) {
        case 113:
          this.$store.dispatch('setShowDialogRegisterOpenCashier', true) //Abrir Caixa
          break;

        case 115:
          this.$store.dispatch('setShowDialogRegisterBleed', true) //Abrir Sangria
          break;

        case 117:
          this.$store.dispatch('setShowDialogRegisterSuply', true) //Suprimentos
          break;

        case 119:
          this.$store.dispatch('setShowDialogRegisterCloseCashier', true) //Fechar Caixa
          break;

        case 120:
          
          break;
        
        case 27:
          this.$store.dispatch('setShowDialogRegisterCloseCashier', false) //Fechar Dialog de 'Fechar Caixa'
      }
    },
    closeDialog(){
      this.$store.dispatch("setShowDialogRegisterFutureSeles", false);
    }
  },
  watch: {},
  created() {
    this.listenKey()
  },
};
</script>

<style>
.title{
  color: white;
}
.body-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1%;
}
.tab-car{
  width: 65%;
  padding: 1%;
}
.tab-product{
  padding: 1%;
}
.body-dialog {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

</style>