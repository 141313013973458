<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="getters"
      no-data-text="Sem Itens cadastrados!"
      :items-per-page="5"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, -1],
        'items-per-page-text': 'Items por página',
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          color="red darken-4"
          @click="
            () => {
              actionDelete(item);
            }
          "
          >mdi-delete</v-icon
        >
        <v-icon
          class="mr-2"
          color="yellow darken-4"
          @click="
            () => {
              actionEdit(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          class="mr-2"
          color="success"
          @click="
            () => {
              change_discount(item);
            }
          "
          >mdi-calculator</v-icon
        >
      </template>
    </v-data-table>
    <dialogDiscount
      v-if="dialog_discount"
      :component="this"
      :submit="submit_discount"
    />
  </div>
</template>

<script>
import dialogDiscount from "../../AddDialog/Discount/";
import { UPDATE_DISCOUNT } from "../../../../store/actions/revenuesitens";

export default {
  props: {
    component: Object,
    getters: Array,
    actionDelete: Function,
    actionEdit: Function,
  },
  components: {
    dialogDiscount,
  },
  data() {
    return {
      dialog_discount: Boolean(),
      id_discount: Number(),
      discount_value: Number(),
      headers: [
        { text: "Código", value: "product_id" },
        { text: "Descrição", value: "description_item" },
        { text: "Unidade", value: "description_unit" },
        { text: "Quantidade", value: "amount" },
        //{ text: "Valor de Venda", value: "venda_sale" },
        { text: "Valor de Venda", value: "value_sale" },
        { text: "Sub Total", value: "value_products" },
        { text: "CFOP", value: "cfop_code" },
        { text: "Cst/Csosn", value: "cst_code" },
        //{ text: "Alíquota", value: "aliquot_icms_product" },
        { text: "Alíquota", value: "aliquot" },
        { text: "Alíquot IPI", value: "aliquot_ipi_product" },
        { text: "Base ICMS", value: "value_base_icms_product" },
        { text: "Valor Icms	", value: "value_icms_product" },
        { text: "Valor Isento	", value: "value_icms_free_product" },
        { text: "Outros Valores", value: "value_icms_outher_product" },
        { text: "Base de calculo ST", value: "value_base_icms_st_product" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    change_discount(item) {
      this.id_discount = item.id;
      this.dialog_discount = !this.dialog_discount;
    },
    submit_discount() {
      let discount = Object();
      discount.id = this.id_discount;
      discount.discount = this.discount_value;

      try {
        this.$store.dispatch(UPDATE_DISCOUNT, discount);
      } catch (error) {
        alert(error);
      } finally {
        this.dialog_discount = !this.dialog_discount;
        this.discount_value = 0;
      }
    },
  },
};
</script>

<style></style>
