<template>
<div>
    <v-data-table
    :headers="component.headers"
    >

    </v-data-table>
</div>
  
</template>

<script>
export default {
    props:{
        component:Object(),
    }

}
</script>

<style>

</style>