const ADD_BANK = "ADD_BANK";
const UPDATE_BANK = "UPDATE_BANK";
const AUX_TO_UPDATE_BANK = "AUX_TO_UPDATE_BANK"
const GET_ALL_BANK = "GET_ALL_BANK";
const DESTROY_BANK = "DESTROY_BANK";
const GET_FILTER_BANK = "GET_FILTER_BANK";

module.exports = {
    ADD_BANK,
    UPDATE_BANK,
    AUX_TO_UPDATE_BANK,
    GET_ALL_BANK,
    DESTROY_BANK,
    GET_FILTER_BANK
}
