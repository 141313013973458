<template>
  <div>
    <v-card class="ma-5">
      <CardTitle />
      <Table />
      <LoadScreen/>
    </v-card>
  </div>
</template>

<script>
import CardTitle from "../components/TaxCupom/CardTitle/index.vue";
import Table from "../components/TaxCupom/Table/index.vue";
import { GET_ALL_TAX_CUPOM } from "../store/actions/taxCupom";
import LoadScreen from "../components/load/index.vue"

export default {
  components: {
    CardTitle,
    Table,
    LoadScreen,
  },
  data: function () {
    return {};
  },
  computed: {},
  methods: {
    getAllTaxCupon: function () {
      var payload = {
        page: 1,
        limit: 10,
        paginate: true,
      };
      this.$store.dispatch(GET_ALL_TAX_CUPOM, payload);
    },
  },
  watch: {},
  created() {
    this.getAllTaxCupon();
  },
};
</script>

<style>
</style>