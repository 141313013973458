<template>
  <v-col>
    <v-row>
      <v-card class="mt-0">
        <v-col>
          <v-select
          label="Tipo de Operação"
          outlined
          loader-height
          density="compact"
          v-model="type"
          :items="types"
          ></v-select>
          <v-select
          label="Modelo de Saída"
          outlined
          loader-height
          density="compact"
          v-model="number"
          :items="models"
          ></v-select>
          <v-row class="d-flex justify-center">
            <v-card-title>
              <v-icon class="mr-2">mdi-basket</v-icon>Produtos
            </v-card-title>
            
          </v-row>
          <v-row>
            <TabProduct :component="this" />
          </v-row>
        </v-col>
      </v-card>
    </v-row>
    <v-row>
      <TabGeneral :component="component" />
    </v-row>
  </v-col>
  <!-- <v-card :elevation="3" outlined :disabled="disabled">
    <v-tabs fixed-tabs v-model="tab">
      <v-tab href="#tab1">
        <v-icon class="mr-2">mdi-card-account-details </v-icon>
        Gerais</v-tab
      >
      <v-tab href="#tab2">
        <v-icon class="mr-2"> mdi-basket</v-icon> Produtos</v-tab
      >

      <v-tab-item value="tab1">
        <v-card flat>
          <v-divider class="success"></v-divider>
          <TabGeneral :component="component" />
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab2">
        <v-card flat>
          <TabProduct :component="this" />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>-->
</template>

<script>
import TabProduct from "../../FutureSales/TabDate/TabProduct/index.vue";
import TabGeneral from "../../FutureSales/TabDate/TabGeneral/index.vue";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../store/actions/tax_operation_types";
import { GET_ALL_NOTE_MODEL } from "../../../store/actions/note_model";
import { mapGetters } from "vuex";
// import { GET_ALL_PRODUCT } from "../../../store/actions/product";

export default {
  props: {
    component: Object,
    disabled: Boolean
  },

  components: {
    TabProduct,
    TabGeneral
  },
  data: () => ({
    product: Object(),
    tab: String(),
    type: '',
    model: '',
    types: Array(),
    models: Array(),
  }),
  methods: {
      async GetAllTypes(page = 1) {
        let value = {
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, value).then((response) => {
          this.GetTypes(response.data)
        });
    },

    GetTypes(value) {
      for(var i = 0; i < value.length; i++) {
        if(value[i].type == 'SAIDA') {
          this.types.push(value[i].description)
        }
      }
    },

    async GetAllModels(page = 1) {
        let value = {
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_ALL_NOTE_MODEL, value).then((response) => {
          this.GetModels(response.data)
        });
    },

    GetModels(value) {
      for(var i = 0; i < value.length; i++) {
          this.models.push(value[i].description)
        }
      
    },
},
  computed: {
    ...mapGetters(["getTaxOperationType"]),
  },
  async created() {
  // await this.$store.dispatch(GET_ALL_PRODUCT, { page: 0, limit: 10 });
  this.GetAllTypes()
  this.GetAllModels()
  }
};
</script>
