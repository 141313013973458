<template>
  <v-row justify="center">
    <v-dialog
      transition="fab-transition"
      persistent
      v-model="getShowDialogRegisterBleed"
      max-width="350"
    >
      <v-card>
        <v-card-title> {{words.title}} </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-form v-model="valid">
            <v-text-field
              outlined
              dense
              tile
              :label="words.input_label"
              v-model="bleed.value_sangria"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>
          </v-form>
          <v-divider></v-divider>

          <v-card-actions>
            <v-row justify="center">
              <v-btn @click="()=>suggest_print?submit_bleed(null):cancel_dialog()"
                class="error mr-1"
                width="150"
              >
                <v-icon class="mr-2">mdi-cancel</v-icon>
                {{words.cancel}}</v-btn>
              <v-btn
                class="success"
                width="150"
                @click="()=>current_operation=='CONFIRM'?suggestPrint():bleed_submit()"
              >
              <!-- @click="()=>current_operation=='CONFIRM'?suggestPrint():submit_bleed(1)" -->
                <v-icon class="mr-2">mdi-content-save</v-icon>
                {{words.confirm}}</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>{{words.btn}}</v-card-subtitle>
        </v-container>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { BLEED } from "../../../../store/actions/future_sales";
import { randomNumber, generateDate} from "../../../Utils/global_functions/utils_function"
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    words: {
      btn: "Pressione ESC para sair",
      confirm: "Confirmar",
      cancel: "Cancelar",
      title: "Sangria de Caixa ",
      input_label: "Valor de Sangria",
    },
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    current_operation: "CONFIRM",
    isPritting: Boolean(),
    suggest_print: Boolean(),
    valid: Boolean(),
    bleed: {
      enterprise_id: 1,
      value_sangria: Number(),
    },
  }),
  computed:{
    ...mapGetters(['getShowDialogRegisterBleed', 'getSelectedEnterprise'])
  },
  methods: {
    suggestPrint(val) {
      if (val) this.isPritting = true;

      this.suggest_print = true;
    },

    bleed_submit() {
      const payload = {
        dateMovement: generateDate(),
        numberMovement: String(randomNumber(9999, 1)) ,
        descriptionMovement: 'Sangria de caixa',
        typeMovement: 'Saída',
        enterprise_id: this.getSelectedEnterprise,
        value_sangria: this.bleed.value_sangria,
      }

      this.$store.dispatch(BLEED, payload).then((response) => {
        //implementar a emissão do comprovante de sangria
        //  
      })

    },

    //Avaliação posterior
    async submit_bleed(value) {
      if (value) this.isPritting = true;

      try {
        let object = { ...this.bleed };
        this.component.titlePDF = "COMPROVANTE DE SANGRIA";
        await this.$store.dispatch(BLEED, object).then(() => {
          this.component.open_cashie_payload = object.value_sangria;
          this.cancel_dialog();
        });
        this.component.needPrint = this.isPritting;
      } catch (error) {
         
      }
    },
    cancel_dialog() {
      this.$store.dispatch('setShowDialogRegisterBleed', false) 
    },
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
  },
  watch: {
    current_operation: function() {
      this.checkState();
    },
    suggest_print: function(v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },
  },
};
</script>

<style>
.title {
  font-family: "Courier New", Courier, monospace;
  color: #424242;
}
</style>