<template>
    <div>
      <v-dialog v-model="component.clientEmailDialog" max-width="450" persistent>
        <v-card>
          <v-card-title style="text-align: center;">Cliente Inválido</v-card-title>
          <v-divider></v-divider>
          <v-container style="height: 10px;"></v-container>
          <v-card-subtitle style="font-size: 18px;">Este cliente não possuí email cadastrado</v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              @click="
                () => {
                  confirm();
                }
              "
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      component: Object,
    },

    methods: {
        confirm() {
            this.component.clientEmailDialog = false
        }
    }
  };
  </script>
  
  <style>
  </style>