<template>
  <div>
    <v-responsive>
      <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Sem registros"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">

            <v-icon
              color="blue"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="component.viewCorrectionLetter(item)"
            >mdi-information-outline</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">

            <v-icon
              color="green darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="printItem(item)"
            >mdi-printer</v-icon>
          </template>
          <span>Imprimir Este Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination 
      class="mb-6"
      v-model="current" 
      color="success" 
      :length="total"
      ></v-pagination>
    </v-responsive>
  </div>
</template>

<script>
import generatePdf from "../GeneratePdf/GeneratePdf";
import { mapGetters } from "vuex";
export default {
  props: {
    headers: Array(),
    lengthPaginator: Number(),
    component: Object()
  },
  name: "Table",
  data: function () {
    return {
      page: 1,
      results: Array(),
      current:Number()
    };

  },

  methods: {
    generatePdf,
    printItem(correctionData) {
      generatePdf(this.component.selectValue, correctionData)
    }
  },

  computed: {
    ...mapGetters(["getCorrectionLetter"]),
  },
  watch: {
    getCorrectionLetter: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.getAllCorrectionNfe(val)
    }

  }
};
</script>

<style></style>