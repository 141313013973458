<template>
  <div class="teste">
    <v-card class="pa-5 ma-5">
      <ReportSales />
    </v-card>
  </div>
</template>

<script>
import ReportSales from "../../../components/report/sales/index.vue";
export default {
  components: {
    ReportSales
  }
};
</script>

<style>
.teste {
  margin-bottom: 30px;
}
</style>