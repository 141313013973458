/* eslint-disable no-unused-vars */
import axios from "axios";

const {
  ADD_COMPANY_GROUP,
  UPDATE_COMPANY_GROUP,
  GET_ALL_COMPANY_GROUP,
  DESTROY_COMPANY_GROUP,
  GET_FILTER_COMPANY_GROUP,
  AUX_TO_UPDATE_COMPANY_GROUP,
} = require("./actions/companygroup");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCompanygroup = {
  state: () => ({
    companygroups: Object(),
    itemToUpdateCompanyGroup: Object()
  }),

  mutations: {
    GET_ALL_COMPANY_GROUP: (state, companygroups) => {
      state.companygroups = companygroups;
    },
    GET_FILTER_COMPANY_GROUP: (state, payload) => {
      state.companygroups = payload
    },
    ADD_COMPANY_GROUP: (state, newCompanygroups) => {
      state.companygroups = newCompanygroups.data[0];
    },
    UPDATE_COMPANY_GROUP(state, editCompanygroups) {
      state.companygroups.data.Update(editCompanygroups)
    },

    DESTROY_COMPANY_GROUP: (state, id) => {
      let auxState = [...state.companygroups.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.companygroups = [...auxState];
    },
    AUX_TO_UPDATE_COMPANY_GROUP: function (state, payload) {
      state.itemToUpdateCompanyGroup = payload
    }
  },

  actions: {
    GET_ALL_COMPANY_GROUP({
      commit
    }, payload) {

      axios.post("companyGroup/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: payload.paginate
      }).then((response) => {
        commit(GET_ALL_COMPANY_GROUP, response.data[0]);
      });
    },

    GET_FILTER_COMPANY_GROUP({
      commit
    }, payload) {
      axios.post("companyGroup/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
       
      }).then((response) => {
        commit(GET_FILTER_COMPANY_GROUP, response.data[0]);
      })
    },

    ADD_COMPANY_GROUP({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("companyGroup/store", payload).then((response) => {
          resolve()
          commit(ADD_COMPANY_GROUP, response);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    async UPDATE_COMPANY_GROUP({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("companyGroup/update", payload).then((response) => {
          resolve()
          commit(UPDATE_COMPANY_GROUP, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })
      })

    },

    DESTROY_COMPANY_GROUP({
      commit
    }, payload) {

      axios.post("companyGroup/delete", payload).then((response) => {
        commit(DESTROY_COMPANY_GROUP, payload);
        commit(SPLICE_ERRO, {
          alert: response.data.menssage[0],
          type: 'S'
        })
      }).catch(r => {
        let erros = r.response.data[0].errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },
    AUX_TO_UPDATE_COMPANY_GROUP: function (context, payload) {
      context.commit(AUX_TO_UPDATE_COMPANY_GROUP, payload)
    }
  },

  getters: {
    getCompanygroup: (state) => ({
      ...state.companygroups
    }),
    getItemCompanyToUpdate: (state) => ({
      ...state.itemToUpdateCompanyGroup
    }),
  },
};

export default moduleCompanygroup;