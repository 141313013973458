<template>
    <div>
      <v-dialog persistent width="900" v-model="component.updateDialog">
        <v-card class="d-flex justify-center">
          <v-col>
            <v-card-title>Editar Forma de Pagamento</v-card-title>
            <v-divider class="mb-5" />
            <v-form ref="form" v-model="form">
              <v-text-field
                label="Forma de Pagamento Atual"
                v-model="component.updateData.formofpayment.description"
                disabled
                outlined
              />
              <v-autocomplete
                label="Nova Forma de Pagamento"
                v-model="component.newFormOfPayment.formofpayment"
                :items="paymentForms"
                return-object
                item-text="description"
                outlined
              ></v-autocomplete>
              <v-autocomplete
                label="Condição de Pagamento"
                v-model="component.newFormOfPayment.payment_term"
                :items="paymentTermsSelectItems"
                return-object
                item-text="description"
                outlined
              ></v-autocomplete>
              <v-text-field
                label="Valor"
                v-model="component.updateData.value_form_payment"
                outlined
                disabled
              />
              <v-divider class="mt-1" />
              <v-card-actions class="d-flex justify-end">
                <v-btn @click="closeDialog()" class="error">Cancelar</v-btn>
                <v-btn @click="component.tableItemUpdate()" color="primary"
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-col>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { GET_ALL_FORM_OF_PAYMENT } from "@/store/actions/formofpayment"
  import { GET_ALL_PAYMENT_TERM } from "@/store/actions/paymentterm"
  export default {
    components: {
    },
    props: {
      component: Object(),
    },
    data: function () {
      return {
        paymentForms: Array(),
        paymentTerms:Array(),
        paymentTermsSelectItems: Array(),
      };
    },
    methods: {
        async GetAllFormOfPayment() {
            let value = {
                page: null,
                limit: null,
                paginate: false,
            };
            await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, value);
        },

        async GetAllPaymentTerm(page = 1) {
            let value = {
                page: null,
                limit: null,
                paginate: false,
            };
            await this.$store.dispatch(GET_ALL_PAYMENT_TERM, value);
        },

        closeDialog() {
          this.component.updateDialog = false
        },
    },
    created() {
        this.GetAllFormOfPayment()
        this.GetAllPaymentTerm()
    },
    beforeDestroy(){
    },
    mounted(){
    },
    computed: {
    ...mapGetters(["getFormOfPayment", "getPaymentTerms"]),
    },

    watch: {
        getFormOfPayment: function(val) {
            this.paymentForms = Object.keys(val).map((key) => val[key]);
            for(let i=0; i<this.paymentForms.length; i++) {
                if(this.paymentForms[i].description == this.component.updateData.formofpayment.description) {
                    this.paymentForms.splice(i, 1)
                }
            }
        },

        getPaymentTerms: function(val) {
            this.paymentTerms = Object.keys(val).map((key) => val[key]);
        },

        "component.newFormOfPayment.formofpayment": function(val) {
          this.paymentTermsSelectItems = []
          for(let i=0; i<this.paymentTerms.length; i++) {
            if(this.paymentTerms[i].form_of_payment_id == val.id) {
              this.paymentTermsSelectItems.push(this.paymentTerms[i])
            }
          } 
          if(this.paymentTermsSelectItems.length == 0) {
            this.paymentTermsSelectItems = this.paymentTerms
          }
        }
    }


  
  };
  </script>
  
  <style>
  </style>