<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="70%">
      <v-card>
        <v-card-title> Adicionar Produto</v-card-title>
        <v-divider></v-divider>
        <!-- <v-row>
          <v-select
            label="Produto"
            outlineds
          ></v-select>
        </v-row> -->
        <v-data-table
          dense
          :headers="header"
          :items="result"
          @click:row="handleClick"
          hide-default-footer
        >
        </v-data-table>
        <v-divider></v-divider>
        <div>
          <v-pagination
            color="success"
            v-model="current"
            :length="total"
            :total-visible="10"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="changeDialog()" text> Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { GET_ALL_PRODUCT } from "../../../../../store/actions/product";
//import { dispatchGetAll } from "../../../../Methods/dispatchGetAll";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
    dialog: Boolean(),
    changeDialog: Function,
    getters: Object(),
  },
  data: () => ({
    result: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    header: [
      { text: "Codigo", value: "id" },
      { text: "Descrição", value: "description_item" },
      { text: "Referencia", value: "reference" },
      { text: "Unidade", value: "unit.description" },
      { text: "Ncm", value: "ncm.code" },
      { text: "Preço de Venda", value: "note_price" },
    ],
  }),
  methods: {
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PRODUCT, value);
    },
  },

  created() {
    this.GetAll();
  },

  computed: {
    ...mapGetters(["getProduct"]),
  },

  watch: {
    getProduct: function (val) {
      this.result = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.GetAll && this.GetAll(val);
    },
    itemSelectedOnClick: function (val) {
      this.component.product_select = { ...val };

      this.changeDialog();
    },
  },
};
</script>

<style>
</style>