<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.status"
          label="Status da venda"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.number_doc_client"
          label="Documento do Cliente"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.items_received_add_dialog.number_sale"
          label="Identificação da Venda"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.data_sale"
          label="Data da Venda"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.value_total_products"
          label="Valor Total dos Produtos"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.value_total_note"
          label="Valor Total da Nota"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.value_account_total"
          label="Valor Contábil"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.note"
          label="Anotações de Vendas"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.tax_operation_types_id"
          label="Tipo de Operação Fiscal"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="component.items_received_add_dialog.enterprise_id"
          label="Empresa"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items_received_dados_gerais: this.items_received_add_dialog,
    };
  },
  props: {
    component: Object(),
    items_received_add_dialog: Object(),
  },

  methods: {},
  created() {},
  watch: {},
  computed: {
    ...mapGetters(["getterVendas"]),
  },
};
</script>

<style>
</style>
