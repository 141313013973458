<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3" v-for="(xs, index) in information" :key="index">
        <div @click="route(index)">
          <Card :image="xs.image" :title="xs.title" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Card from "../components/card.vue";
import { arrayImages } from "../assets/images";
export default {
  components: {
    Card
  },
  methods: {
    route(index) {
      if (index == 0) {
        this.$router.push("/ReportProduct");
      } else if (index == 1) {
        this.$router.push("/ReportClient");
      } else if (index == 2) {
        this.$router.push("/ReportProvider");
      } else if (index == 3) {
        this.$router.push("/ReportNoteEntry");
      } else if (index == 4) {
        this.$router.push("/ReportStockBalance");
      } else if (index == 5) {
        this.$router.push("/ReportBillsPay");
      } else if (index == 6) {
        this.$router.push("/ReportBillsReceive");
      } else if (index == 7) {
        this.$router.push("/ReportFinancialMovement");
      } else if (index == 8) {
        this.$router.push("/ReportFiscalBook");
      } else if (index == 9) {
        this.$router.push("/report-sales");
      } else if (index == 10) {
        this.$router.push("/comission-seller")
      } else if (index == 11) {
        this.$router.push("/dashboard-charts")
      }
    }
  },
  data: function() {
    return {
      information: [
        { title: "Produto", image: arrayImages[0] },
        { title: "Cliente", image: arrayImages[1] },
        { title: "Fornecedor", image: arrayImages[2] },
        { title: "Entrada de Notas", image: arrayImages[3] },
        { title: "Saldo Estoque", image: arrayImages[4] },
        { title: "Contas a Pagar", image: arrayImages[5] },
        { title: "Contas a Receber", image: arrayImages[6] },
        { title: "Movimentção Financeira", image: arrayImages[7] },
        { title: "Livro Fiscal", image: arrayImages[8] },
        { title: "Vendas", image: arrayImages[9] },
        { title: "Comissão de Vendedor", image: arrayImages[10] },
        { title: "Dashboard", image: arrayImages[11]}
      ]
    };
  }
};
</script>

<style>
</style>