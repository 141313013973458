

const cancelSequenceInvoice = [
    { "text": "Data do cancelamento", "value": "date_of_cancelattion"},
    { "text": "Model NFe", "value": "modelNf"},
    { "text": "Número Incial do Cancelamento", "value": "sequence_initial_cancel"},
    { "text": "Número Final do Cancelamento", "value": "sequence_finish_cancel"},
    { "text": "Série da Nota", "value": "note_series" },
    { "text": "Justificativa", "value": "reason_for_cancellation"},
    { "text": "Ações", "value": "actions"},
]


export default cancelSequenceInvoice