import {
  tax_operation_type,
  type,
  normal_output_text,
  output_of_state_text,
  replacement_text,
} from "./wipedata";

function wipeData() {
  this.tax_operation_type = {...tax_operation_type};
  this.type = type;
  this.normal_output_text = normal_output_text;
  this.output_of_state_text = output_of_state_text;
  this.replacement_text = replacement_text;
}

export default {
  wipeData,
};
