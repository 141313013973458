<template>
  <v-row justify="center">
    <v-dialog v-model="component.dialog_cfop" persistent max-width="70%">
      <v-card>
        <v-card-title class="headline"> Pesquisar Cfop </v-card-title>
        <v-data-table
          :headers="headers"
          dense
          :items="result"
          @click:row="handleClick"
          hide-default-footer
        >
        </v-data-table>
        <v-divider></v-divider>
        <div>
          <v-pagination
            color="success"
            v-model="current"
            :length="total"
            :total-visible="10"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="component.dialogModalSearchCfop = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { GET_ALL_CFOP } from "../../../../../store/actions/cfop";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    current: Number(),
    total: Number(),
    result: Array(),
    itemSelectedOnClick: {},
    productSelectAdd: Object(),
    headers: [
      { text: "Código", value: "code" },
      { text: "Descrição", value: "description" },
    ],
  }),
  computed: {
    ...mapGetters(["getCfop"]),
  },
  async created() {
    await this.GetAll();
  },
  methods: {
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_CFOP, {
        page: page,
        limit: 10,
        paginate: true,
      });
    },
  },
  props: {
    component: Object,
    getters: Object,
    changeDialog: Function,
  },

  watch: {
    getCfop: function (val) {
      this.result = val.data;
      this.current = val.current_page;
      this.total = val.last_page;
    },
    current: function (val) {
      this.GetAll && this.GetAll(val);
    },
    itemSelectedOnClick: function (val) {
      this.component.descriptionCFOP = val;
      this.changeDialog();
    },
  },
};
</script>

<style></style>
