

var enterpirse_list = Array();
var enterprise_selected = Object();
var register = Boolean();
var valid = Boolean();
var validPhysical = Boolean();
var validLegal = Boolean();
var validTable = Boolean(false);

var transporter = {
    id: undefined,
    type_people: String(),
    people_id: Number(),
    rntr: Number(),
    security: String()
}
var PhysicalPeople = {
    type_people: String(),
    enterprise_id: Number(),
    client_id: String(),
    name: String(),
    lastName: String(),
    cpf: String(),
    gender: String(),
    emitter: String(),
    emitterState: String(),
    idCard: String(),
    dateOfBirth: String(),
    fatherName: String(),
    motherName: String(),
    nacionality: String(),
    naturality: String(),
    monthlyIncome: String(),
    civilStatus: String(),
    spouse: String(),
    rntr: Number(),
    security: String(),
    icomeSpouse: Number()
}
var LegalPeople = {
    cnpj: String(),
    fantasyName: String(),
    socialName: String(),
    stateRegistration: String(),
    municipalRegistration: String(),
    homePage: String(),
    people_id: Number()
}
var address = {
    street: String(),
    number: String(),
    district: String(),
    complement: String(),
    city: String,
    state: String(),
    cep: String(),
    city_id: Number(),
    people_id: Number()
}

var banks = {
    bank: String(),
    agency: String(),
    count: Number(),
    manager: String(),
    people_id: Number()
}

var contact = {
    primaryEmail: String(),
    secundaryEmail: String(),
    phone: String(),
    cellPhone: String(),
    phoneReference: String(),
    people_id: Number()
}

var cityObject = Object()

module.exports = {
    enterpirse_list,
    enterprise_selected,
    register,
    valid,
    validPhysical,
    validLegal,
    validTable,
    transporter,
    PhysicalPeople,
    LegalPeople,
    address,
    banks,
    contact,
    cityObject,
}