import axios from "axios";
const {
  ADD_PRODUCT,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCT,
  DESTROY_PRODUCT,
  GET_FILTER_PRODUCT,
  ITEM_UPDATE_PRODUCT,
  AUTO_LOAD_PRODUCT,
  SAVE_IMAGE_PRODUCT
} = require("./actions/product");
const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleProduct = {
  state: () => ({
    searchProductById: Object(),
    products: [],
    itemUpdateProduct: null,
    autoLoadProductObjec: Object(),
  }),
  mutations: {
    AUTO_LOAD_PRODUCT: function (state, payload) {
      state.autoLoadProductObjec = payload;
    },
    GET_ALL_PRODUCT: (state, products) => {
      state.products = products;
    },
    GET_PRODUCT_BY_ID: (state, product) => {
      // state.products = product;
      state.searchProductById = product
    },
    ADD_PRODUCT: (state, newProduct) => {
      state.products = newProduct;
    },
    GET_FILTER_PRODUCT: (state, payload) => {
      if (payload !== undefined) {
        state.products = payload;
      }
    },
    UPDATE_PRODUCT(state, payload) {
      let auxState = state.products;
      auxState.data.forEach((e) => {
        if (e.id === payload.id) {
          e = payload;
        }
      });
      state.products = auxState.data;
    },

    DESTROY_PRODUCT: (state, id) => {
      let auxState = [...state.products.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.products = [...auxState];
    },
    ITEM_UPDATE_PRODUCT: function (state, payload) {
      state.itemUpdateProduct = payload
    }
  },

  actions: {
    AUTO_LOAD_PRODUCT: function (context, payload) {
      context.commit(AUTO_LOAD_PRODUCT, payload);
    },
   async GET_ALL_PRODUCT({ commit }, payload) {
    try {
      const response = await axios
      .post("product/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: payload.paginate,
      })
      commit(GET_ALL_PRODUCT, response.data[0]);
      return response
      
    } catch (error) {
      return new Error(error)
    }
      // axios
      //   .post("product/index", {
      //     page: payload.page,
      //     limit: payload.limit,
      //     paginate: payload.paginate,
      //   })
      //   .then((response) => {

      //     commit(GET_ALL_PRODUCT, response.data[0]);
      //   });
    },

    async GET_PRODUCT_BY_ID({ commit }, payload) {

      try {
        const response = await axios
          .post("product/show", {
            id: payload,
          })
        commit(GET_PRODUCT_BY_ID, response);

        commit(SPLICE_ERRO, {
          alert: response.data.menssage[0],
          type: "S",
        });

        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }


    },

    // ,{ productImage: formData }, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    SAVE_IMAGE_PRODUCT({ commit }, payload) {
      let form = new FormData()
      for (let key in payload) {
        form.append(key, payload[key])
      }
      axios.post("product/saveImageProduct", form, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    },


    ADD_PRODUCT({ commit }, payload) {

      return new Promise((resolve, reject) => {
        axios
          .post("product/store", payload
          )
          .then((response) => {
            resolve({ response });
            commit(ADD_PRODUCT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_PRODUCT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("product/update", payload)
          .then(() => {
            resolve();
            commit(UPDATE_PRODUCT, payload);
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    async GET_FILTER_PRODUCT({ commit }, payload) {
      try {
        const response = await axios
        .post("products/autocomplete", {

          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
        })

        commit(GET_FILTER_PRODUCT, response.data[0]);
        return response.data[0]
      } catch (error) {
        return new Error(error)
      }
    },

    DESTROY_PRODUCT({ commit }, payload) {
      axios.post("product/delete", payload).then((response) => {
        commit(DESTROY_PRODUCT, payload.id);
        commit(SPLICE_ERRO, {
          alert: response.data.sucess.message[0],
          type: "S",
        });
      });
    },

    ITEM_UPDATE_PRODUCT: function (context, payload) {
      context.commit(ITEM_UPDATE_PRODUCT, payload)
    }
  },
  getters: {
    // getProductById:function(state){
    //   return state.searchProductById
    // }  ,
    getAutoLoadProductObjec: function (state) {
      return state.autoLoadProductObjec;
    },
    getProductById: (state) => ({
      ...state.searchProductById,
    }),
    getProduct: (state) => ({
      ...state.products,
    }),
    getItemUpdateProduct: (state) => state.itemUpdateProduct
  },
};

export default moduleProduct;
