<template>
  <Container>
    <v-card>
      <v-card-text>
        <!-- Botão Teste -->
        <CardTitle />
      </v-card-text>

      <v-text-field
        class="mx-4"
        label="Pesquisar"
        outlined
        append-icon="mdi-magnify"
      >
      </v-text-field>
      <Table :component="this" />
      <AddDialog :component="this" :items="items" />
      <AlertAPI/>
      <LoadScreen/>
    </v-card>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container.vue";
import Table from "../components/TaxCupomActive/Table";
import AddDialog from "../components/TaxCupomActive/AddDialog/index.vue";
import CardTitle from "../components/TaxCupomActive/CardTitle";
import { GET_ALL_TAX_CUPOM } from "../store/actions/taxCupom";
import AlertAPI from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
export default {
  components: {
    Container,
    Table,
    AddDialog,
    CardTitle,
    LoadScreen,
    AlertAPI,
  },

  data() {
    return {
      Content: undefined,
      items: undefined,
      addDialogVendas: Boolean(),
      headers: [
        { text: "Id", value: "id" },
        { text: "Data da Venda", value: "data_sale" },
        { text: "Numero da nota", value: "note" },
        { text: "Cliente", value: "cliente.people.physicalpeople.name" },
        { text: "Vendedor", value: "seller.people.physicalpeople.name" },
        { text: "PDV", value: "pdv.code" },
        { text: "Valor da nota(R$)", value: "value_total_note" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    call_dialog(items) {
      this.items = items;
      this.addDialogVendas = !this.addDialogVendas;
      //console.log(items);
    },
    
  },
  computed: {
    ...mapGetters(["getterVendas"]),
  },

  created() {
    // this.getAllTaxCupon();
  },
  watch: {
    // getterVendas: function () {
    //   this.Content = this.getterVendas;
    //   //  console.log("Dentro de Vendas",this.Content)
    // },
  },
};
</script>

<style>
</style>