<template>
  <v-row>
    <v-dialog v-model="component.dialogCashier" max-width="550">
      <v-card>
        <v-container fluid>
          <v-form>
            <v-card-title>Configurações de Caixa</v-card-title>
            <v-card-subtitle
              >Selecione o Caixa e marque suas devidas
              permissões</v-card-subtitle
            >
            <v-form v-model="valid">
              <v-col
                cols="6"
                class="d-flex"
                v-if="component.cashier.name == null"
              >
                <v-autocomplete
                  :items="cashierList"
                  label="Caixa"
                  item-text="description"
                  :rules="[(val) => !!val || 'Campo obrigatório!']"
                  v-model="component.cashierObject"
                  placeholder="Selecione um Caixa"
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="d-flex" v-else>
                <v-autocomplete
                  :items="cashierList"
                  readonly
                  label="Caixa"
                  item-text="description"
                  :rules="[(val) => !!val || 'Campo obrigatório!']"
                  v-model="component.cashierObject"
                  placeholder="Selecione um Caixa"
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-row class="ml-1 mr-1">
                <v-col>
                  <v-text-field
                    outlined
                    type="number"
                    label="Limite de desconto"
                    v-model="component.cashier.limit_descont_value"
                    prefix="R$"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    label="Limite de desconto em percentual"
                    type="number"
                    v-model="component.cashier.limit_descont_percentual"
                    :rules="[
                      () =>
                        (!!component.cashier.limit_descont_percentual &&
                          component.cashier.limit_descont_percentual >= 1 &&
                          component.cashier.limit_descont_percentual <= 100) ||
                        'Campo tem que ser maior do que 1 e menor que 100',
                    ]"
                    suffix="%"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-card elevation="4">
              <v-subheader>O que é permitido?</v-subheader>
              <v-row class="pa-2">
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Abrir Caixa"
                    v-model="component.cashier.open_cashier"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Fechar Caixa"
                    v-model="component.cashier.close_cashier"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Realizar Sangria"
                    v-model="component.cashier.bleed"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Realizar Suplemento"
                    v-model="component.cashier.supply"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Cancelar Venda"
                    v-model="component.cashier.cancel_vend"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="4" xl="4" sm="4">
                  <v-checkbox
                    label="Cancelar Item"
                    v-model="component.cashier.cancel_item"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Cancelar Cupom"
                    v-model="component.cashier.cancel_cupon"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="4" md="4" xl="4" sm="4">
                  <v-checkbox
                    label="Imprimir Cupom"
                    v-model="component.cashier.print_cupon"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-form>

          <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-btn
              class="error"
              @click="component.dialogCashier = !component.dialogCashier"
            >
              <v-icon class="mr-2">mdi-cancel</v-icon> Cancelar</v-btn
            >
            <v-btn class="success" @click="submit()" :disabled="!valid">
              <v-icon class="mr-2">mdi-content-save</v-icon> Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { GET_ALL_CASHIER } from "../../../../store/actions/cashier";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },
  data: () => ({
    valid: Boolean(),
    cashierList: Array(),
  }),
  methods: {
    async getCashierAll() {
      await this.$store.dispatch(GET_ALL_CASHIER, { page: 0 });
    },
    submit() {
      this.component.cashier.name = this.component.cashierObject.description;
      this.component.cashier.cashier_id = this.component.cashierObject.id;
      this.component.dialogCashier = !this.component.dialogCashier;
    },
  },
  computed: {
    ...mapGetters(["getCashier"]),
  },
  watch: {
    getCashier: function (val) {
      this.cashierList = Object.values(val);
    },
  },
  async created() {
    await this.getCashierAll();
  },
};
</script>

<style>
</style>