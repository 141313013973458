<template>
  <v-row>
    <v-dialog v-model="component.dialog_fiscal" max-width="1000">
      <v-card>
        <v-card-title>Informações do Livro Fiscal</v-card-title>
        <v-container>
          <v-form readonly>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Data de Emissão"
                  v-model="form.date_of_issue"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Data de Vencimento"
                  v-model="form.date_invoice_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Número NF"
                  v-model="form.number_grade"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Série"
                  v-model="form.serie"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Origem"
                  v-model="form.origin"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Alíquota"
                  v-model="form.aliquot"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor Contábil"
                  v-model="form.value_account_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor da base de calculo de icms"
                  v-model="form.value_base_calculation_icms"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor de Icms"
                  v-model="form.value_icms_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Outros Valores"
                  v-model="form.value_other_note_entry"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor Isento"
                  v-model="form.value_free_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor base de IPI"
                  v-model="form.value_base_ipi_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor de IPI"
                  v-model="form.value_ipi_note_entry"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor Outros de IPI"
                  v-model="form.value_other_ipi_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor Isento de IPI"
                  v-model="form.value_free_ipi_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor Base de Substituicao Tributária"
                  v-model="form.value_base_icms_substituicao_st"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Valor de Icms Substituicao"
                  v-model="form.value_icms_substituicao_note_entry"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Informação da Nota"
                  v-model="form.information"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            @click="component.dialog_fiscal = !component.dialog_fiscal"
            class="error"
          >
            <v-icon class="mr-2"> mdi-cancel</v-icon>
            Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
    objectToView: Object,
  },
  data: () => ({
    form: Object(),
  }),
  created() {
    if (this.objectToView !== null) {
      this.form = { ...this.objectToView };
    }
  },
};
</script>

<style>
</style>