<template>
  <v-col>
    <v-form ref="form" v-model="form">
      <v-row>
        <v-col>
          <v-card-title>Relatório de Vendas</v-card-title>
        </v-col>
        <v-col>
          <div class="comandpalet">
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-select
                  :items="listEnterprises"
                  v-model="formData.enterprise_id"
                  item-text="fantasyName"
                  item-value="id"
                  outlined
                  dense
                  label="Empresas"
                />
              </v-col>
              <v-col cols="2">
                <v-btn :disabled="!form" @click="getReport" class="success"
                  >Visualizar</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-divider />
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            type="date"
            :rules="rules"
            v-model="formData.initial_date_sale"
            outlined
            label="Data da venda Inicial"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            type="date"
            v-model="formData.final_date_sale"
            :rules="rules"
            outlined
            label="Data da venda Final"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formData.initial_code_client"
            outlined
            label="Código do cliente Inicial"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.final_code_client"
            outlined
            label="Código do cliente Final"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.invoice_number"
            outlined
            label="Número da Nota Fiscal"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import data from "../../helpers/data";
import methods from "../../helpers/methods";
// import getters from "../../helpers/getters";
import { watch } from "../../helpers/watch";
import { mapGetters } from "vuex";
export default {
  props: {},
  data: function () {
    return {
      ...data,
    };
  },
  methods: {
    ...methods,
  },
  computed: {
    ...mapGetters(["getEnterprises", "getDataReportSales"]),
  },
  watch: {
    ...watch,
  },
  created() {
    this.getAllEnterprises();
  },
};
</script>

<style>
</style>