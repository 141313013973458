<template>
  <v-row>
    <v-dialog max-width="300" v-model="component.dialog_discount">
      <v-card>
        <v-card-title>Valor de Desconto</v-card-title>
        <v-col>
          <v-text-field
            outlined
            label="Valor"
            v-model="component.discount_value"
            prefix="R$"
            type="number"
          ></v-text-field>
          <v-divider></v-divider>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="component.dialog_discount = !component.dialog_discount"
            >Cancelar</v-btn
          >
          <v-btn class="success mr-9" @click="submit()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
    submit: Function,
  },
};
</script>

<style>
</style>