<template>
  <v-col>
    <v-row class="d-flex justify-center">
      <div id="divTitle">
        <p id="text">Todos os Relatórios</p>
      </div>
    </v-row>
    <v-row>
      <v-divider />
    </v-row>
  </v-col>
</template>

<script>
export default {};
</script>

<style>
@keyframes movementTitle {
  from {
    transform: translateX(-500px);
  }
  to {
    transform: translateX(0);
  }
}

#text {
  font-weight: bold;
  color: grey;
  font-size: 30px;
}
#divTitle {
  animation: movementTitle 2s;
}
</style>