<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              v-on="on"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip> 

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip> -->

         <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  information(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Visualizar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
       class="mb-6"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import DestryDialog from "../destroyDialog/destroyDialog";
import {
  GET_FILTER_MOVESTOCK,

} from "../../../store/actions/move_stock";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String,
    headers: Array,
    change_addDialog: Function,
    information: Function,
  },
  components: { DestryDialog },
  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),
  methods: {
    // executeAlterUpdateDialog(item) {
    //   const component = this._props.component;
    //   this.setObjectToUpdate(component, item);
    //   this.alterAddDialog(component);
    //   this._props.component.show_object();
    // },
    //alterAddDialog() {},
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
      //confirm("Deseja Continuar a exclusão?") && this.destroy_object(item);
    },
    // setIdToDestroy(component, id) {
    //   component.idToDestroy = id;
    // },
    // alterDestroyDialog(component) {
    //   component.destroyDialog = !component.destroyDialog;
    // },
    // setObjectToUpdate(component, object) {
    //   component.objectToUpdate = object;
    // },
    // alterUpdateDialog(component) {
    //   component.updateDialog = !component.updateDialog;
    // },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    async moveStockFilter(page = 1) {
      if(this.component.filter == "") {
        this.component.GetAll(1)
      } else {
        let value = {
          search: this.component.filter,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_MOVESTOCK, value);
      }
    },
  },
  created() {
    this.component.GetAll();
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
    "component.filter": function () {
      this.moveStockFilter();
    },
  },
};
</script>
