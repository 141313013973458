<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="filterText" label="Pesquisar" outlined append-icon="mdi-magnify" @click:append="filterStockBalance()" @keyup.enter="filterStockBalance()">
        </v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :search="search"
        :headers="headers"
        :information="information"
      />
    </v-card>
    <AddDialog v-if="infoDialog" :component="this" />
    <LoadScreen/>
    <AlertApi />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/Stock_balance/CardTitle/CardTitle";
import Table from "../components/Stock_balance/Table/Table";
import AddDialog from "../components/Stock_balance/AddDialog/Index";
import LoadScreen from "../components/load/index.vue"
//import {
 // GET_ALL_STOCK_BALANCE,
//   DESTROY_STOCK_BALANCE,
//   UPDATE_STOCK_BALANCE,
// } from "../store/actions/stock_balance";
//import { dispatchGetAll } from "../components/Methods/dispatchGetAll";
import AlertApi from "../components/alert_api/alert_api";
import { GET_ALL_NCM } from '../store/actions/ncm';
import { GET_ALL_UNIT } from '../store/actions/unit';
import { mapGetters } from "vuex";

export default {
  data: () => ({
    filter: String(),
    filterText: String(),
    infoDialog: false,
    search: "",
    //getter: "getStockBalance",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    itemToInfo: Array,
    objectToUpdate: {},
    title: "Saldo Estoque",
    headers: [
      { text: "Código do Produto", value: "product_id" },
      { text: "Descrição do Produto", value: "products.description_item" },
      { text: "Ncm", value: "products.ncm.code" },
      { text: "Unidade de Medida", value: "products.unit.sigla" },
      { text: "Saldo de Estoque", value: "current_balance" },
      { text: "Actions", value: "actions" },
    ],
    results: Array,
    total: Number,
    current: Number,
    currentItem: {},
    unit: [],
    ncm: []
  }),

  methods: {
    filterStockBalance() {
      if(this.filterText.length >= 2 || this.filterText == "") {
        this.filter = this.filterText
      }
    },
    // async destroy_object(item) {
    //   const stock = {
    //     id: item.id,
    //   };
    //   try {
    //     await this.$store.dispatch(DESTROY_STOCK_BALANCE, stock);
    //   } catch (error) {
    //     alert("error", error);
    //   }
    // },
    // async change_addDialog(item) {
    //   this.addDialog = !this.addDialog;
    //   this.objectToUpdate = item;
    // },
    // async update_object(item) {
    //   const stock = {
    //     id: item.id,
    //   };
    //   try {
    //     await this.$store.dispatch(UPDATE_STOCK_BALANCE, stock);
    //   } catch (error) {
    //     alert(error);
    //   }
    // },

    information(item){
      this.infoDialog = !this.infoDialog;
      this.itemToInfo = item;
    },

    async GetAllNoPage(){
      let value = {
        page: null,
        limit: null,
        paginate: false
      }
      await this.$store.dispatch(GET_ALL_UNIT, value);
      await this.$store.dispatch(GET_ALL_NCM, value);
    },


  },

  async created(){
    await this.GetAllNoPage();
  },

  watch:{
    getUnit: function(val){
      this.unit = Object.keys(val).map(key => val[key])
    },
    getNcm: function(val){
      this.ncm = Object.keys(val).map(key => val[key])
    },
    filterText: function(val){
      if(val == "") {
        this.filter = this.filterText
      }
    }
  },

  computed: {
    ...mapGetters(["getUnit"]),
    ...mapGetters(["getNcm"]),
  },


  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    AddDialog,
    LoadScreen,
  },

};
</script>

<style scoped></style>
