
<template>
  <v-card class="ma-5 pa-5">
    <CardTitle title="Vendas Futuras" showDialogState="setShowDialogRegisterFutureSeles" />
    <AddDialog/>
    <Table/>
    <OpenCashier v-if="getShowDialogRegisterOpenCashier"/>
    <Bleeding v-if="getShowDialogRegisterBleed" />
    <Suply v-if="getShowDialogRegisterSuply"/>
    <CloseCashier v-if="getShowDialogRegisterCloseCashier" />
  </v-card>
</template>

<script>
import CardTitle from "../components/Utils/CardTitle_v2/index.vue";
import AddDialog from "../components/FutureSales/AddDialog/index.vue"
import Table from "../components/FutureSales/Tables/DefaltTable/index.vue"

import OpenCashier from "../components/FutureSales/CashierOperations/Open_Cashier/index.vue"
import Bleeding from "../components/FutureSales/CashierOperations/Bleed/index.vue"
import Suply from "../components/FutureSales/CashierOperations/Supply/index.vue"
import CloseCashier from "../components/FutureSales/CashierOperations/Close_Cashier/index.vue"

import { mapGetters } from "vuex";

export default {
  components: {
    CardTitle,
    AddDialog,
    Table,
    //##
    OpenCashier,
    Bleeding,
    Suply,
    CloseCashier,
  },
  props: {},
  computed: {
    ...mapGetters([
      'getShowDialogRegisterOpenCashier',
      'getShowDialogRegisterBleed',
      'getShowDialogRegisterSuply',
      'getShowDialogRegisterCloseCashier',
    ])
  },
  data: () => ({}),
  methods: {},
  watch: {},
  created() {},
};
</script>

<style>
</style>