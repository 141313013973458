<template>
  <v-row justify="center">
    <v-dialog
      v-model="getShowDialogRegisterEnterprise"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                close_addDialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nova Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-divider></v-divider>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>

            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-home-account</v-icon> Endereço
            </v-tab>

            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-receipt</v-icon> Fiscal
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-receipt</v-icon> Chaves
            </v-tab>

            <v-tab-item :eager="true" value="tab1" class="mt-6">
              <v-card flat>
                <TabGeral :component="this" ref="geral" />
              </v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab2" class="mt-6">
              <v-card flat>
                <TabAddress :component="this" ref="address" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3" class="mt-6">
              <v-card flat>
                <TabFiscal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4" class="mt-6">
              <v-card flat>
                <TabKeyAccess :component="this" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="() => clear()">
              <v-icon class="mr-2">mdi-cancel</v-icon> Limpar formulário</v-btn
            >
            <v-btn
              color="success"
              :disabled="!valid"
              class="mr-4"
              @click="
                () => {
                  submit();
                }
              "
              ><v-icon class="mr-2">mdi-content-save</v-icon>Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import TabGeral from "../Tab/TabGeral";
import TabAddress from "../Tab/TabAddress/";
import TabFiscal from "../Tab/TabFiscal/";
import TabKeyAccess from "../Tab/TabKeyAccess";
import { mapGetters } from "vuex";

import {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { GET_ALL_COMPANY_GROUP } from "../../../store/actions/companygroup";
import { dispatchGetAll } from "../../Methods/dispatchGetAll";
import {cleanCNPJ } from "../../Utils/global_functions/clean_cnpj_cpf"
export default {
  components: { TabGeral, TabAddress, TabFiscal, TabKeyAccess },

  props: {
    component: Object(),
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterEnterprise", "getAuxEnterpriseToUpdate", "getViewEnterprise"]),
  },

  data: () => ({
    //justVisible: Boolean(),
    oldState: Object,
    companyGroup: [],
    cities: [],
    valid: Boolean(),
    enterprise: {
      status: String(),
      name: String(),
      fantasyName: String(),
      cnpj: String(),
      registrationState: String(),
      registrationMunicipal: String(),
      accountancy: String(),
      aliquot_pis: Number(0),
      aliquot_cofins: Number(0),
      conter: String(),
      crc: String(),
      csc: String(),
      noteissung_enviroment: Number(),
      enterprise_type: String(),
      country_code: Number(),
      cnai: String(),
      crt: Number(),
      token: String(),
      company_group_id: Number(),
      tax_regime: Number(),
      certified_password: String(),
      description_country: String(),
      token_ibpt: String(),
      certified_path: String(),
      cnpj_authorization: String(),
      cpf_authorization: String(),
      street: String(),
      number: String(),
      complement: String(),
      district: String(),
      city: String(),
      state: String(),
      cep: String(),
      addresses_id: Number(),
      city_id: Number(),
      information_nfe: String(),
      information_nfce: String(),
    },
    newcity: [{ text: "Cariacica", value: "ac" }],
    initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "es" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],
  }),

  methods: {
    // autoload(payload) {
    //   this.justVisible = payload
    // },
    clear() {
      this.$refs.address.reset();
      this.$refs.geral.reset();
    },
    close_addDialog() {
      this.$store.dispatch("setShowDialogRegisterEnterprise", false);
      this.clear();
      this.justVisible = false;
    },

    submit() {
      if (this.$refs.address === undefined) {
        alert("Preencha os endereços!");
      } else {
        this.enterprise.state = this.oldState.text;
        this.enterprise.city_id = this.newcity.id;
        this.enterprise.city = this.newcity.nome;
        this.created_enterprises();
      }
    },

    async created_enterprises() {
      this.enterprise.cnpj = cleanCNPJ(this.enterprise.cnpj)
      const enterprise = { ...this.enterprise };
      await this.$store.dispatch(ADD_ENTERPRISE, enterprise).then(() => {
        this.close_addDialog();
      });
    },

    async update_enterprises() {
      const enterprise = { ...this.enterprise };
      // const enterprise = {
      //   id: this.enterprise.id,
      //   enable: this.enterprise.enable,
      //   social_reason: this.enterprise.social_reason,
      //   fantasy_name: this.enterprise.fantasy_name,
      //   cnpj: this.enterprise.cnpj,
      //   type_enterprise: this.enterprise.type_enterprise,
      //   type_of_taxation: this.enterprise.type_of_taxation,
      //   state_registration: this.enterprise.state_registration,
      //   municipal_registration: this.enterprise.municipal_registration,
      //   accounting: this.enterprise.accounting,
      //   counter: this.enterprise.counter,
      //   crc: this.enterprise.crc,
      //   street: this.enterprise.street,
      //   number: this.enterprise.number,
      //   neighborhood: this.enterprise.neighborhood,
      //   complement: this.enterprise.complement,
      //   city: this.enterprise.city,
      //   state: this.enterprise.state,
      //   cep: this.enterprise.cep,
      // };
      await this.$store.dispatch(UPDATE_ENTERPRISE, enterprise).then(() => {
        this.close_addDialog();
      });
    },

    getComponent() {
      return this._props.component;
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        pagination: false,
      };
      await dispatchGetAll(this, GET_ALL_COMPANY_GROUP, value);
    },
  },

  created() {
    this.GetAllNoPage();
  },

  watch: {

    // viewEnterprise: function (){
    //   console.log(this.justVisible);
    //   this.justVisible = this.getViewEnterprise
    //   console.log(this.justVisible);
    // },
    oldState(val) {
      if (val) {
        axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
          )
          .then((response) => {
            this.cities = response.data;
          });
      }
    },
  },

  beforeDestroy() {
    // this.getComponent().objectToUpdate = {};
  },
};
</script>
