<template>
  <div>
    <v-col>
      <!-- <v-text-field outlined label="Pesquisar" append-icon="mdi-magnify" /> -->
      <v-data-table
        hide-default-footer
        :items="itemsXML"
        :headers="headersTable"
      />
      <v-divider class="pa-5" />
      <v-row class="d-flex justify-center">
        <v-pagination v-model="page" :length="1"></v-pagination>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LocalState from "./helpers/localState";
import { Getters } from "./helpers/gettersVuex";
import Watches from "./helpers/watches";
export default {
  data: function () {
    return {
      ...LocalState,
    };
  },
  watch: {
    ...Watches,
  },
  computed: {
    ...mapGetters(Getters),
  },
};
</script>

<style>
</style>