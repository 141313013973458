<template>
  <div>
    <v-dialog persistent width="500" v-model="getShowDialogRegisterReprintNFE">
      <v-card>
        <v-col>
          <v-card-title>Deseja reimprimir a nota fiscal ?</v-card-title>
          <v-divider />
          <v-text-field
            v-model="itemToReprint.cnpjorcpfDestination"
            label="CNPJ Emitente"
            filled
            disabled
            dense
            outlined
          />
          <v-text-field
            v-model="itemToReprint.key"
            label="Chave de acesso"
            filled
            disabled
            dense
            outlined
          />
          <v-text-field
            v-model="itemToReprint.numberProtocol"
            label="Protocolo"
            filled
            disabled
            dense
            outlined
          />
          <v-divider />
          <v-card-actions class="d-flex justify-end">
            <v-btn @click="closeDialog" class="error">Sair</v-btn>
            <v-btn @click="submmitReprintNote" color="primary">Confirmar</v-btn>
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PRINT_NFE } from "../../../store/actions/nfe";
export default {
  computed: {
    ...mapGetters(["getShowDialogRegisterReprintNFE", "getItemToReprint"]),
  },
  data: function () {
    return {
      itemToReprint: Object(),
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterReprintNFE", false);
    },
    submmitReprintNote() {
      this.$store.dispatch(PRINT_NFE, this.itemToReprint);
    },
    loadFields() {
      this.itemToReprint.cnpjorcpfDestination =
        this.getItemToReprint.cnpjorcpfDestination;
      this.itemToReprint.key = this.getItemToReprint.key;
      this.itemToReprint.numberProtocol = this.getItemToReprint.numberProtocol;
    },
  },
  watch: {
    getItemToReprint: function () {
      this.loadFields();
    },
  },
  created() {},
};
</script>

<style>
</style>