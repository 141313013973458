const tax_operation_type = {
    id: undefined,
    status: String(),
    code: String(),
    type: String(),
    description: String(),
    normal_output: String(),
    output_of_state: String(),
    replacement: String(),
    calculate_tax: String(),
    move_stock: String(),
    move_financial: String(),
    feeds_cost: String(),
  }
  const type = null
  const normal_output_text = String()
  const output_of_state_text = String()
  const replacement_text = String()


  export {
    tax_operation_type,
    type,
    normal_output_text,
    output_of_state_text,
    replacement_text ,
  }