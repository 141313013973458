<template>
  <v-container>
    <v-form >
      <v-row>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2">
          <v-text-field
            label="Base Calc do ICMs"
            outlined
            v-model="component.nfceData.base_cal_icms_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2">
          <v-text-field
            label="Valor do ICMS"
            outlined
            v-model="component.nfceData.value_icms_total"
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="4" md="4" xl="2" lg="2">
          <v-text-field
            label="Base Calc ICMS ST"
            outlined
            v-model="component.nfceData.base_calc_susbt_total"
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6" xl="3" lg="3">
          <v-text-field
            label="Valor do ICMS ST"
            outlined
            v-model="component.nfceData.value_icms_substituicao_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" xl="3" lg="3">
          <v-text-field
            label="Valor Contábil"
            outlined
            v-model="component.nfceData.value_account_total"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Base de Cálculo IPI"
            outlined
            v-model="component.nfceData.base_calc_ipi_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor Total do IPI"
            outlined
            v-model="component.nfceData.value_ipi_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor IPI Outros"
            outlined
            v-model="component.nfceData.value_ipi_outher_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor Isento IPI"
            outlined
            v-model="component.nfceData.value_ipi_free_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor Total dos Produtos"
            outlined
            v-model="component.nfceData.value_total_products"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Outros valores"
            outlined
            v-model="component.nfceData.value_outher_tax_total"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="4" md="4" xl="4" lg="4" >
          <v-text-field
            label="Desconto"
            outlined
            v-model="component.nfceData.value_descount_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="4" lg="4" >
          <v-text-field
            label="Valor ICMS Isento"
            outlined
            v-model="component.nfceData.value_icms_free_total"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4" >
          <v-text-field
            label="Valor Total da Nota"
            outlined
            v-model="component.nfceData.value_total_note"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    component: Object,
  },


};
</script>

<style></style>
