<template>
<v-container>
<v-data-table
:headers="header" :items="items_received_add_dialog.sale_form_of_payment"></v-data-table>
  </v-container>
</template>

<script>
export default {
    props:{
    items_received_add_dialog:Object(),
  },
data() {
    return {
        header:[
            {text:"Forma de pagamento", value:"formofpayment.description"},
            {text:"Termo de pagamento", value:"payment_term.description"},
            {text:"Valor do pagamento", value:"value_form_payment"}
        ]
    }
},
created(){
    //console.log('PAGAMENTO', this.items_received_add_dialog.sale_form_of_payment);
}
}
</script>

<style>

</style>

