var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getters,"no-data-text":"Sem Itens cadastrados!","items-per-page":5,"footer-props":{
      'items-per-page-options': [5, 10, 15, -1],
      'items-per-page-text': 'Items por página',
    }},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
            _vm.actionEdit(item);
          }}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
            _vm.actionDelete(item);
          }}},[_vm._v("mdi-delete")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
            _vm.change_discount(item);
          }}},[_vm._v("mdi-calculator")])]}}],null,true)}),(_vm.dialog_discount)?_c('dialogDiscount',{attrs:{"component":this,"submit":_vm.submit_discount}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }