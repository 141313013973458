<template>
  <v-row justify="center">
    <v-dialog
      v-model="getShowDialogRegisterTransporter"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="close_dialog"
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                close_dialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nova transportadora</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>
            <v-tab href="#tab2" v-if="!register">
              <v-icon class="mr-1">mdi-family-tree</v-icon>
              Pessoal
            </v-tab>
            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-card-account-mail</v-icon> Contato
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-home-account</v-icon>
              Endereço
            </v-tab>
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-bank</v-icon> Contas Bancárias
            </v-tab>

            <v-tab-item value="tab1">
              <v-switch v-model="register" label="Pessoa Jurídica"></v-switch>
              <div class="d-inline-flex">
                <v-select
                  :items="enterpirse_list"
                  v-model="enterprise_selected"
                  v-if="out_of_register"
                  item-text="fantasyName"
                  item-value="id"
                  label="Empresa"
                  outlined
                />
              </div>
              <v-card flat v-if="!register">
                <TabPhisical :component="this" ref="phys" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" ref="legal" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab2">
              <v-card flat>
                <TabPessoal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3">
              <v-card flat>
                <TabContact :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4">
              <v-card flat>
                <TabAddress :component="this" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab5">
              <v-card flat>
                <TabBank :component="this" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!justVisible"
              @click="
                () => {
                  clear();
                }
              "
              class="error"
            >
              <v-icon class="mr-2">mdi-cancel</v-icon>Limpar Formulário
            </v-btn>
            <v-btn
              v-if="!justVisible"
              color="success"
              :disabled="!valid"
              @click="
                () => {
                  //submit();
                  redirectRegister();
                }
              "
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Methods from "./helpers/Methods";
import TabPessoal from "../Tabs/TabPersonal/";
import TabContact from "../Tabs/TabContact/index.vue";
import TabAddress from "../Tabs/TabAddress/index.vue";
import TabPhisical from "../Tabs/TabPhisical/";
import TabLegal from "../Tabs/TabLegal/";
import TabBank from "../Tabs/TabBank/index.vue";
import { mapGetters } from "vuex";
import {
  ADD_PJ_TRANSPORTER,
  ADD_PF_TRANSPORTER,
  UPDATE_PF_TRANSPORTER,
  UPDATE_PJ_TRANSPORTER,
  ITEM_TO_UPDATE,
} from "../../../store/actions/transporters";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { cleanCNPJ } from "../../Utils/global_functions/clean_cnpj_cpf";
export default {
  props: { component: Object, id: Number, out_of_register: Boolean() },
  components: {
    TabContact,
    TabAddress,
    TabPhisical,
    TabLegal,
    TabBank,
    TabPessoal,
  },

  computed: {
    ...mapGetters([
      "getShowDialogRegisterTransporter",
      "getEnterprises",
      "getItemToUpdateTransporter",
    ]),
  },
  data: () => ({
    justVisible: Boolean(),
    enterpirse_list: Array(),
    enterprise_selected: Object(),
    register: Boolean(),
    valid: Boolean(),
    validPhysical: Boolean(),
    validLegal: Boolean(),
    validPersonal: Boolean(),
    validContact: Boolean(),
    validAddress: Boolean(),
    validBankAccount: Boolean(),
    validTable: Boolean(false),

    transporter: {
      id: undefined,
      type_people: String(),
      people_id: Number(),
      rntr: Number(),
      security: String(),
    },
    PhysicalPeople: {
      type_people: String(),
      enterprise_id: Number(),
      client_id: String(),
      name: String(),
      lastName: String(),
      cpf: String(),
      gender: String(),
      emitter: String(),
      emitterState: String(),
      idCard: String(),
      dateOfBirth: String(),
      fatherName: String(),
      motherName: String(),
      nacionality: String(),
      naturality: String(),
      monthlyIncome: String(),
      civilStatus: String(),
      spouse: String(),
      rntr: Number(),
      security: String(),
      icomeSpouse: Number(),
    },
    LegalPeople: {
      cnpj: String(),
      fantasyName: String(),
      socialName: String(),
      stateRegistration: String(),
      municipalRegistration: String(),
      homePage: String(),
      people_id: Number(),
    },
    address: {
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: String(),
      state: String(),
      cep: String(),
      city_id: Number(),
      people_id: Number(),
    },

    banks: {
      bank: String(),
      agency: String(),
      count: Number(),
      manager: String(),
      people_id: Number(),
    },

    contact: {
      primaryEmail: String(),
      secundaryEmail: String(),
      phone: String(),
      cellPhone: String(),
      phoneReference: String(),
      people_id: Number(),
    },

    cityObject: Object(),
  }),
  methods: {
    ...Methods,
    async getAllEnterprises() {
      if (this.out_of_register) {
        var payload = {
          page: null,
          limit: null,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
      }
    },
    clear() {
      if (this.register) {
        this.clear_legal();
      } else {
        this.clear_Physical();
      }
      this.address = {};
      this.contact = {};
      this.bank = {};
    },
    close_dialog() {
      this.$store.dispatch("setShowDialogRegisterTransporter", false);
      if (this.register) {
        this.clear_legal();
      } else {
        this.clear_Physical();
      }
    },

    redirectRegister() {
      this.PhysicalPeople.cpf = this.PhysicalPeople.cpf.replace(/\D/g, "");
      this.address.cep = this.address.cep.replace(/\D/g, "");
      this.contact.cellPhone = this.contact.cellPhone.replace(/\D/g, "");
      if(this.contact.phone) {
        this.contact.phone = this.contact.phone.replace(/\D/g, "");
      }
      if(this.contact.phoneReference) {
        this.contact.phoneReference = this.contact.phoneReference.replace(/\D/g, "");
      }
      this.transporter.id != undefined ? this.update() : this.submit(); //submit - create
    },

    async update() {
      if (this.register === true) {
        let newPeople = {};
        newPeople.id = this.transporter.id;
        newPeople.transporter = { ...this.transporter };
        newPeople.type_people = "LegalPeople";
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.legal_people.cnpj = cleanCNPJ(newPeople.legal_people.cnpj);
        if (this.out_of_register == true) {
          newPeople.enterprise_id = this.enterprise_selected;
        } else {
          newPeople.enterprise_id = this.id;
        }
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;
        await this.$store
          .dispatch(UPDATE_PJ_TRANSPORTER, newPeople)
          .then(() => {
            this.$store.dispatch(ITEM_TO_UPDATE, Object());
            this.wipeData();
            this.close_dialog();
          });
      } else {
        let newPeople = {};
        newPeople.id = this.transporter.id;
        newPeople.transporter = { ...this.transporter };
        newPeople.type_people = "PhysicalPeople";
        newPeople.physical_people = { ...this.PhysicalPeople };
        if (this.out_of_register == true) {
          newPeople.enterprise_id = this.enterprise_selected;
        } else {
          newPeople.enterprise_id = this.id;
        }
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;

        await this.$store
          .dispatch(UPDATE_PF_TRANSPORTER, newPeople)
          .then(() => {
            this.$store.dispatch(ITEM_TO_UPDATE, Object());
            this.$store.commit("SET_IS_UPDATED", true);
            this.wipeData();
            this.close_dialog();
          });
      }
    },

    async submit() {
      if (this.register === true) {
        let newPeople = {};
        newPeople.transporter = { ...this.transporter };
        newPeople.transporter.type_people = "LegalPeople";
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.legal_people.cnpj = cleanCNPJ(newPeople.legal_people.cnpj);
        if (this.out_of_register == true) {
          newPeople.enterprise_id = this.enterprise_selected;
        } else {
          newPeople.enterprise_id = this.id;
        }
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;
        await this.$store.dispatch(ADD_PJ_TRANSPORTER, newPeople).then(() => {
          this.$store.dispatch(ITEM_TO_UPDATE, Object());
          this.wipeData();
          this.close_dialog();
        });
      } else {
        let newPeople = {};
        newPeople.transporter = { ...this.transporter };
        newPeople.transporter.type_people = "PhysicalPeople";
        newPeople.physical_people = { ...this.PhysicalPeople };
        if (this.out_of_register == true) {
          newPeople.enterprise_id = this.enterprise_selected;
        } else {
          newPeople.enterprise_id = this.id;
        }
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;
        await this.$store.dispatch(ADD_PF_TRANSPORTER, newPeople).then(() => {
          this.$store.dispatch(ITEM_TO_UPDATE, Object());
          this.wipeData();
          this.close_dialog();
        });
      }
    },
    empty_array(array, main_array) {
      if (array.length === 0) {
        main_array.push({});
      }
    },

    validTotal() {
      if (this.ListAddress.length > 0) {
        this.validTable = true;
      }
    },
    clear_Physical() {
      this.PhysicalPeople.name = "";
      this.PhysicalPeople.lastName = "";
      this.PhysicalPeople.cpf = "";
      this.PhysicalPeople.gender = "";
      this.PhysicalPeople.emitter = "";
      this.PhysicalPeople.emitterState = "";
      this.PhysicalPeople.idCard = "";
      this.PhysicalPeople.dateOfBirth = "";
      this.PhysicalPeople.fatherName = "";
      this.PhysicalPeople.motherName = "";
      this.PhysicalPeople.nacionality = "";
      this.PhysicalPeople.naturality = "";
      this.PhysicalPeople.monthlyIncome = "";
      this.PhysicalPeople.civilStatus = "";
      this.PhysicalPeople.spouse = "";
      this.PhysicalPeople.icomeSpouse = "";
      this.PhysicalPeople.type = "";
      this.PhysicalPeople.address = [];
      this.PhysicalPeople.contacts = [];
      this.PhysicalPeople.banks = [];
      this.PhysicalPeople.rntr = "";
      this.PhysicalPeople.security = "";
      // this.$refs.phys.reset();
    },
    clear_legal() {
      this.LegalPeople.cnpj = "";
      this.LegalPeople.fantasyName = "";
      this.LegalPeople.socialName = "";
      this.LegalPeople.stateRegistration = "";
      this.LegalPeople.municipalRegistration = "";
      this.LegalPeople.homePage = "";
      this.LegalPeople.address = [];
      this.LegalPeople.banks = [];
      this.LegalPeople.contacts = [];
      this.LegalPeople.related_representative = "";
      this.LegalPeople.type = "";
      this.LegalPeople.rntr = "";
      this.LegalPeople.security = "";
      // this.$refs.legal.reset();
    },
  },
  created() {
    this.getAllEnterprises();
  },
  watch: {
    getItemToUpdateTransporter: function (val) {
      if (val) {
        if (val.justVisible) {
          this.justVisible = val.justVisible;
          this.itemToUpdate(val.item);
        } else {
          this.itemToUpdate(val);
        }
      }
    },
    getEnterprises: function () {
      this.enterpirse_list = Object.values(this.getEnterprises);
    },
    validPhysical(val) {
      if(val && this.validAddress && this.validPersonal && this.validContact && this.validBankAccount) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validAddress(val) {
      if(!this.register) {
        if(val && this.validPhysical && this.validPersonal && this.validContact && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      } else {
        if(val && this.validLegal && this.validContact && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      }

    },
    validPersonal(val) {
      if(val && this.validPhysical && this.validAddress && this.validContact && this.validBankAccount) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validContact(val) {
      if(!this.resgister) {
        if(val && this.validPhysical && this.validAddress && this.validPersonal  && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      } else {
        if(val && this.validLegal && this.validAddress && this.validBankAccount) {
          this.valid = true
        } else {
          this.valid = false
        }
      }
    },
    validBankAccount(val) {
      if(!this.register) {
        if(val && this.validPhysical && this.validAddress && this.validPersonal && this.validContact) {
          this.valid = true
        } else {
          this.valid = false
        }
      } else {
        if(val && this.validLegal && this.validAddress && this.validContact) {
          this.valid = true
        } else {
          this.valid = false
        }
      }
    },
    validLegal(val) {
      if(val && this.validAddress && this.validContact && this.validBankAccount) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
  },
};
</script>
