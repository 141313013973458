<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-container>
          <v-card-title>{{ title }} Usuário</v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid_form">
            <v-row class="ma-2">
              <v-select
                v-model="component.user.status"
                :items="statusList"
                label="Status"
                outlined
                :rules="[() => !!component.user.status|| 'Campo Obrigatório']"
              />
            </v-row>
            <v-row class="ma-2">
              <v-text-field
                v-model="component.user.name"
                class="mr-1"
                label="Nome"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="component.user.email"
                label="E-mail"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-row>
            <div v-if="component.changePassword">
              <v-row class="ma-2">
                <v-text-field
                  class="mr-1"
                  v-model="component.user.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  hint="Minimo 8 digitos"
                  label="Senha"
                  outlined
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                  label="Confirmar Senha"
                  v-model="component.user_confirm_pass"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :rules="[rules.required, rules.min]"
                  hint="Minimo 8 digitos"
                  @click:append="show2 = !show2"
                  outlined
                ></v-text-field>
              </v-row>
            </div>
            <v-row class="ma-2">
              <v-autocomplete
                :items="enterpriseItems"
                v-model="component.user.enterprises"
                item-text="fantasyName"
                item-value="id"
                outlined
                return-object
                :rules="[rules.required]"
                label="Empresas"
                multiple
                chips
              ></v-autocomplete>
            </v-row>
            <v-row class="ma-2">
              <v-select
                :items="rolesItems[0]"
                item-text="label"
                v-model="component.user.roles"
                item-value="id"
                :rules="[rules.required]"
                outlined
                return-object
                label="Permissões"
                multiple
                chips
              ></v-select>
            </v-row>

            <v-row class="ma-2">
              <v-text-field
                outlined
                readonly
                v-model="cashier.name"
                clearable
                label="*Permissões de Caixa"
                @click:append="activeCashier()"
                append-icon="mdi-cash-register"
              >
              </v-text-field>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="actionDialog()" class="error">
              <v-icon  class="mr-2">mdi-cancel</v-icon>Cancelar</v-btn
            >
            <v-btn @click="submit" :disabled="!valid_form" class="success">
              <v-icon class="mr-2">mdi-content-save</v-icon> Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <cashierSetting v-if="dialogCashier" :component="this" />
    <dialogEnterprisesValid v-if="dialogEnterprisesValidation" :component="this" />
  </v-row>
</template>

<script>
//actionDialog()
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { GETALL_ROLES } from "./../../../store/actions/permission";
import cashierSetting from "./cashierSettings";
import dialogEnterprisesValid from "./enterpriseValidation/index.vue"

export default {
  components: {
    cashierSetting,
    dialogEnterprisesValid,
  },
  props: {
    component: Object,
    dialog: Boolean,
    actionDialog: Function,
    title: String,
  },
  data: () => ({
    dialogEnterprisesValidation: false,
    dialogCashier: Boolean(),
    rolesItems: Array(),
    statusList: ["Ativo", "Inativo", "Bloqueado"],
    enterpriseItems: Array(),
    isValid: Boolean(),
    show1: Boolean(),
    show2: Boolean(),
    cashierObject: {
      id: Number(),
      description: String(),
    },

    rules: {
      required: (value) => !!value || "Obrigatório",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
    },
    cashier: {
      id: null,
      name: null,
      limit_descont_value: 0,
      limit_descont_percentual: 0,
      cancel_vend: Boolean(),
      cancel_item: Boolean(),
      close_cashier: Boolean(),
      open_cashier: Boolean(),
      cancel_cupon: Boolean(),
      print_cupon: Boolean(),
      supply: Boolean(),
      bleed: Boolean(),
      cashier_id: Number(),
      user_id: Number(),
    },
  }),
  watch: {
    getRoles: function () {
      this.rolesItems = this.getRoles;
    },
    getEnterprises: function (val) {
      this.enterpriseItems = Object.values(val.data);
    },
  },
  methods: {
    // validForm() {
    //   this.component.checkAuth();
    //   var isPass = this.component.changePassword;
    //   var valid;
    //   if (isPass) valid = this.component.isLoading || !this.component.isAuth;
    //   else valid = this.component.isLoading;
    //   this.isValid = valid;
    // },
    submit() {
      if(this.component.user.enterprises.length > 1 && this.cashier.name != null) {
        this.dialogEnterprisesValidation = true
      } else {
        this.$refs.form.validate();
        if (this.valid_form) {
          this.component.confirm(this.cashier);
        }
      }
    },

    activeCashier() {
      this.dialogCashier = !this.dialogCashier;
    },
  },
  beforeDestroy() {
    this.component.user.cashier_operation = {};
  },
  destroyed() {
    delete this.component.user.id;
  },

  async created() {
    let value = Object();
    value.limit = null;
    value.page = 1;
    value.paginate = null;

    await this.$store.dispatch(GETALL_ROLES, value);
    await this.$store.dispatch(GET_ALL_ENTERPRISE, {
      page: 1,
      limit: null,
      paginate: null,
    });
    if (this.component.user.cashier_operation !== undefined) {
      this.cashier = { ...this.component.user.cashier_operation };
      // delete this.cashier.id;
      this.cashierObject.description = this.cashier.name;
      this.cashierObject.id = this.cashier.cashier_id;
    }
  },
  computed: {
    ...mapGetters(["getRoles", "getEnterprises"]),
  },
};
</script>

<style></style>
