const ADD_CFOP = "ADD_CFOP";
const UPDATE_CFOP = "UPDATE_CFOP";
const AUX_CFOP_TO_UPDATE = "AUX_CFOP_TO_UPDATE"
const GET_ALL_CFOP = "GET_ALL_CFOP";
const DESTROY_CFOP = "DESTROY_CFOP";
const GET_FILTER_CFOP = "GET_FILTER_CFOP";

module.exports = {
    AUX_CFOP_TO_UPDATE,
    ADD_CFOP,
    UPDATE_CFOP,
    GET_ALL_CFOP,
    DESTROY_CFOP,
    GET_FILTER_CFOP
}
