<template>
  <v-row>
    <v-dialog
      persistent
      v-model="this._props.component.addDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title primary-title> Registro Ponto de Vendas </v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            class="mt-6"
            v-model="valid"
            @submit.prevent="submit"
          >
            <v-col>
              <v-select
                v-model="status"
                outlined
                :items="selectField"
                label="Ativo"
              >
              </v-select>

              <v-text-field
                v-model="code"
                label="*Código do PDV"
                color="success"
                outlined
                :rules="[() => !!code || 'Campo obrigatório']"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
              />

              <v-text-field
                v-model="description"
                label="*Descrição"
                color="success"
                outlined
                :rules="[() => !!description || 'Campo obrigatório']"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="100"
              />

              <v-text-field
                :rules="[() => !!print || 'Campo obrigatório']"
                v-model="print"
                label="*Impressora"
                color="success"
                outlined
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="100"
              />
            </v-col>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="closePdvDialog()">
                <v-icon class="mr-1">mdi-cancel</v-icon>
                Cancelar</v-btn
              >

              <v-btn
                :disabled="!valid"
                @click="submit()"
                color="success"
                class="mr-4"
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ADD_PDV, UPDATE_PDV } from "../../../store/actions/pdv";

export default {
  props: {
    component: Object,
  },

  data() {
    return {
      selectField: ["Ativo", "Inativo"],
      pdv: String(),
      status: String(),
      code: String(),
      description: String(),
      print: String(),
      id: null,
      valid: true,
    };
  },

  methods: {
    closePdvDialog() {
      this.component.addDialog = false;
    },

    submit() {
      this.id == null ? this.create_pdv() : this.update_pdv();
    },

    async create_pdv() {
      console.log("create PDV");

      if (this.status == "") {
        this.status = "Ativo";
      }

      const pdv = {
        status: this.status,
        code: this.code,
        description: this.description,
        print: this.print,
        enterprise_id: this.component.enterprise_selected,
      };

      console.log(pdv);
      await this.$store.dispatch(ADD_PDV, pdv).then(() => {
        this.closePdvDialog();
      });
    },

    async update_pdv() {
      console.log("Update PDV");

      if (this.status == "") {
        this.status = "Ativo";
      }
      const PDV = {
        id: this.id,
        status: this.status,
        code: this.code,
        description: this.description,
        print: this.print,
        enterprise_id: this.component.enterprise_selected, //"1" //deixar dinamico!!!!!
      };
      await this.$store.dispatch(UPDATE_PDV, PDV).then(() => {
        //this.closePdvDialog;
        this.component.addDialog = false;
      });
    },

    //####################################
    //essa parte serve para montar um componente de update

    mountedObject() {
      this.object = { ...this.component.objectToUpdate };
      this.id = this.object.id;
      this.description = this.object.description;
      this.print = this.object.print;
      this.code = this.object.code;

      if (this.id >= 0) {
        this.pdv = this.object.pdv.toString();
      } else {
        this.pdv = this.object.pdv;
      }
    },
  },

  async created() {
    this.mountedObject();
  },

  beforeDestroy() {
    this.component.objectToUpdate = {};
  },
};
</script>

<style>
</style>