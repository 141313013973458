const GET_ALL_PRE_NOTEENTRY = 'GET_ALL_PRE_NOTEENTRY';
const UPDATE_PRE_NOTEENTRY = 'UPDATE_PRE_NOTEENTRY';
const DESTROY_PRE_NOTEENTRY = 'DESTROY_PRE_NOTEENTRY';
const ADD_PRE_NOTEENTRY = 'ADD_PRE_NOTEENTRY';
const CONFIRM_PRE_NOTEENTRY = 'CONFIRM_PRE_NOTEENTRY';
const UPDATED_LIST_ITEMS = 'UPDATED_LIST_ITEMS';


module.exports = {
    GET_ALL_PRE_NOTEENTRY,
    UPDATE_PRE_NOTEENTRY,
    DESTROY_PRE_NOTEENTRY,
    ADD_PRE_NOTEENTRY,
    CONFIRM_PRE_NOTEENTRY,
    UPDATED_LIST_ITEMS
}