<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="getShowDialogRegisterOpenCashier"
      max-width="350"
      transition="fab-transition"
    >
      <v-card>
        <v-card-title class="title">{{words.title}}</v-card-title>
        <v-card-subtitle>{{words.desc}}</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form v-model="valid">
            <v-text-field
              outlined
              dense
              tile
              :label="words.input_label"
              v-model="openCashier.openning_value"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>
          </v-form>

          <v-card-actions>
            <v-row justify="center">
              <v-btn
                class="error mr-1"
                @click="suggest_print?open_cashier(null):cancel()"
              >
                <v-icon class="mr-2"> mdi-cancel</v-icon> {{words.cancel}}</v-btn>
                <v-btn
                  class="success"
                  :disabled="!valid"
                  @click="()=>current_operation=='CONFIRM'?suggestPrint():open_cashier(1)"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon> {{words.confirm}}</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>{{words.btn}}</v-card-subtitle>
        </v-container>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import { OPEN_CASHIER } from "../../../../store/actions/future_sales";
import { generateDate, randomNumber } from '../../../Utils/global_functions/utils_function';
export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters(['getShowDialogRegisterOpenCashier', 'getSelectedEnterprise'])
  },
  data: () => ({
    words: {
      btn: "Pressione ESC para sair",
      confirm: "Confirmar",
      cancel: "Cancelar",
      title: "Abrir Caixa",
      desc: "Informe o valor de abertura do caixa.",
      input_label: "Valor de Abertura",
    },
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    current_operation: "CONFIRM",
    valid: Boolean(),
    openCashier: {
      enterprise_id: 1,
      openning_value: String(),
    },
    isPritting: Boolean(),
    suggest_print: Boolean(),
  }),

  methods: {
    cancel() {
      this.$store.dispatch('setShowDialogRegisterOpenCashier', false) //Abrir Caixa
    },
    suggestPrint(val) {
      if (val) this.isPritting = true;
      this.suggest_print = true;
    },
    async open_cashier() {
      try {
        const payload = {
          dateMovement: generateDate(),
          numberMovement: String(randomNumber(9999, 1)),
          descriptionMovement: 'Sangria de caixa',
          typeMovement: 'Entrada',
          enterprise_id: this.getSelectedEnterprise,
          openning_value: this.openCashier.openning_value,
        }

        await this.$store.dispatch(OPEN_CASHIER, payload);
        // this.component.needPrint = this.isPritting;
        // this.component.open_cashie_payload = object.openning_value;
        this.cancel();
      } catch (error) {
        error;
      }
      // if (value) this.isPritting = true;

      // let object = { ...this.openCashier };
      // this.component.titlePDF = "COMPROVANTE DE Abertura de caixa";

    },
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
  },
  watch: {
    current_operation: function() {
      this.checkState();
    },
    suggest_print: function(v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Courier New", Courier, monospace;
}
</style>
