const GET_ALL_REVENUES = "GET_ALL_REVENUES";
const GET_ALL_NOT_EMITTER_NFE = "GET_ALL_NOT_EMITTER_NFE"
const UPDATE_REVENUES = "UPDATE_REVENUES";
const DESTROY_REVENUES = "DESTROY_REVENUES";
const ADD_REVENUES = "ADD_REVENUES";
const CONFIRM_REVENUES = "CONFIRM_REVENUES";
const ADD_PRENOTE = "ADD_PRENOTE";
const REVERSE_REVENUE = "REVERSE_REVENUE";
const GET_FILTER_REVENUE = " GET_FILTER_REVENUE";
const REVENUES_UPDATE_NFE = "REVENUES_UPDATE_NFE"

module.exports = {
  GET_FILTER_REVENUE,
  GET_ALL_REVENUES,
  GET_ALL_NOT_EMITTER_NFE,
  UPDATE_REVENUES,
  DESTROY_REVENUES,
  ADD_REVENUES,
  CONFIRM_REVENUES,
  ADD_PRENOTE,
  REVERSE_REVENUE,
  REVENUES_UPDATE_NFE
};
