import toxml from 'xml-js'
import { SET_SHIPPING_COMPANY } from "../../../../../../store/actions/import_xml"
function trimString(data) {
    if (data.length > 0) {
        return data.trim()
    } else {
        return data
    }
}

function Shipping(inputxml, store) {

    var readFile = new FileReader();
    var transporter = Object();

    readFile.readAsText(inputxml)
    readFile.onload = () => {
        var data = readFile.result

        var result = toxml.xml2json(data, { compact: true, spaces: 1 });
        var resultJSON = JSON.parse(result);
        
        transporter = trimString(resultJSON.nfeProc.NFe.infNFe.transp)
        store.dispatch(SET_SHIPPING_COMPANY, transporter)
    }
}

export default Shipping