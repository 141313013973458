<template>
  <div class="header">
    <h1 class="title ma-5">{{ title }}</h1>
    <div class="selectEnterprise">
      <v-select
        class="vSelect"
        :items="listEnterprise"
        item-text="fantasyName"
        item-value="id"
        v-model="enterpriseSelected"
        dense
        outlined
        label="Empresa"
      />
      <v-btn
        @click="generateSpad"
        :disabled="!component.disableButtonCardTitle"
        class="vButton success"
      >
        Confirmar
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE,
} from "../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  props: {
    title: String(),
    action: String(),
    component: Object(),
  },
  data: () => ({
    listEnterprise: Array(),
    enterpriseSelected: Number(),
  }),
  methods: {
    generateSpad() {
      try {
        this.$store.dispatch(this.action, this.component.form);
      } catch (error) {
        return error;
      }
    },
    getAllEnterprise(page = 1) {
      const payload = {
        page,
        limit: 10,
        paginate: true,
      };
      this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  watch: {
    getEnterprises: function (val) {
      this.listEnterprise = [...val.data];
      this.enterpriseSelected = this.listEnterprise[0];
    },
    enterpriseSelected: function (val) {
      this.$store.dispatch(SELECTED_ENTERPRISE, val);
    },
  },
  created() {
    this.getAllEnterprise();
  },
};
</script>

<style>
@media (max-width: 415px) {
  .header {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }
  .selectEnterprise {
    margin: 5%;
  }
  .vButton {
    width: 100%;
  }
}

@media (min-width: 416px) {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .selectEnterprise {
    display: flex;
    flex-direction: row;
    margin: 5px;
    margin-top: 10px;
  }
  .vButton {
    margin-left: 10px;
  }
}
</style>
