

var Watches = {
    getEnterprises: function (val) {
        this.listEmterprise = Object.values(val)
        this.enterpriseSelected = this.listEmterprise[0]
    },
}


export default Watches