


const correctionLetterHeadersTable = [
    { "text": "Data da correção", "value": "date_corretion_letter"},
    { "text": "chave", "value": "key" },
    { "text": "Número NFe", "value": "numberNfe"},
    { "text": "Modelo Nfe", "value": "modelNf"},
    { "text": "Serie", "value": "seriesNfe"},
    { "text": "Codigo Operação", "value": "eventSequenceNumber"},
    { "text": "Ações", "value": "actions" },

]



export default correctionLetterHeadersTable