<template>
  <div id="frame">
    <div class="divTitle">
      <v-card-title>Controle de Trocas</v-card-title>
    </div>
    <div class="form">
      <div>
        <v-select
          item-text="fantasyName"
          return-object
          v-model="component.selectedEnterprise"
          :items="enterprises"
          class="mr-1"
          outlined
          dense
        />
      </div>
      <div>
        <v-btn @click="showDialog" class="success ml-1">Adicionar</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },
  data: () => ({
    enterprises: Array(),
  }),
  methods: {
    showDialog() {
      this.$store.dispatch("setShowDialogRegisterExchangeControl", true);
    },
    getAllResourses(page = 1) {
      try {
        var payload = {
          page: page,
          limit: 10,
          paginate: true,
        };
        this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  created() {
    this.getAllResourses();
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  watch: {
    getEnterprises: function (val) {
      this.enterprises = [...val.data];
      this.component.selectedEnterprise = this.enterprises[0];
    },
    "component.selectedEnterprise": function (val) {
      this.$store.dispatch(SELECTED_ENTERPRISE, val);
    },
  },
};
</script>

<style>
@import "index.css";
</style>
