
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";





export default  function generatePdf(enterprise, CorrectionData) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    var docDefinition = {
        content: [
            {
                text: "--------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            { text: `${enterprise.fantasyName}`, style: "anotherStyle" },
            {
                text: `${enterprise.address.street}, ${enterprise.address.number} - ${enterprise.address.city}/${enterprise.address.my_city.state.abbr}`,
                style: "anotherStyle",
            },
            {
                text: `CNPJ: ${enterprise.cnpj} - IE: ${enterprise.registrationState}`,
                style: "anotherStyle",
            },
            {
                text: "--------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: "Carta de Correção",
                style: "anotherStyle",
            },
            {
                text: `Data de Emissão....: ${CorrectionData.date_corretion_letter}                                                                                                       `,
                style: "body",
            },
            {
                text: `Número da Nota...: ${CorrectionData.numberNfe}                                                                                                      `,
                style: "body",
            },
            {
                text: `Série da Nota....: ${CorrectionData.seriesNfe}                                                                                                        `,
                style: "body",
            },
            {
                text: `Chave da Nota....: ${CorrectionData.key}                                                                                                      `,
                style: "body",
            },
            {
                text: `Motivo....:  ${CorrectionData.corretionInformation}                                                                                                      `,
                style: "body",
            },

        ],
        styles: {
            body: {
                alignment: "left",
                fontSize: 14,
            },
            anotherStyle: {
                alignment: "center",
                fontSize: 16,
            },
            titleStyle:{
                alignment: "center",
                fontSize: 18,
                bold: true
            }
        },
    };
    pdfMake.createPdf(docDefinition).open();
}