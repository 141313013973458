<template>
  <v-form  ref="form" v-model="component.valid"  @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Fornecedor Inicial" 
          type="number"
          v-model="component.report.billstopayinitialcode"
          :rules="[
                  () => !!component.report.billstopayinitialcode || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Fornecedor Final" 
          type="number"
          v-model="component.report.billstopayfinalcode"
          :rules="[
                  () => !!component.report.billstopayfinalcode || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined label="Data de Vencimento" 
          type="date" 
          v-model="component.report.issuance_due"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Tipo de Título" 
          :items="titulo" 
          v-model="component.report.type_title"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Plano de Contas" 
          :items="component.account" 
          item-text="description" 
          item-value="id" 
          v-model="component.report.account_plans_id"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props:{
    component: Object
  },

  data() {
    return {
      titulo: ["Boleto", "Depósito Bancário", "Dinheiro"]
    }
  },

  methods:{
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  }
};
</script>

<style>
</style>