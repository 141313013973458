<template>
  <v-row>
    <v-card-title>Vendedor</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-text="fantasyName"
        loader-height
        v-model="enterprise"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <v-btn
      v-if="component.permission.Cadastrar"
      color="success mr-2 mt-3"
      v-on:click="
        () => {
          executeDrawer();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
//import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";

export default {
  props: {
    component: Object,
    getters: Object,
  },

  data: () => ({
    enterprise: "",
    enterprises: [],
  }),

  methods: {
    executeDrawer() {
      this.$store.dispatch("setShowDialogRegisterSeller", true);
      // this.component.addDialog = !this.component.addDialog;
      //this.component.selectValue = this.enterprise;
    },
  },

  // async created() {
  //   await this.$store.dispatch(GET_ALL_ENTERPRISE);
  // },

  // computed: {
  //   ...mapGetters(["getEnterprises"]),
  // },

  watch: {
    getters(val) {
      this.enterprises = Object.values(val);
      this.enterprise = this.enterprises[0];
    },

    enterprise(val) {
      this.component.selectValue = val;
    },
  },
};
</script>

<style>
</style>