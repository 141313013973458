const headersTable = [
    { text: "Status", value: "status" },
    { text: "Data de Emissão", value: "date_of_issue" },
    { text: "Cliente", value: "cliente.people.name" },
    { text: "Número Nota", value: "number_grade" },
    { text: "Série", value: "series" },
    { text: "Valor dos Produtos", value: "value_total_products" },
    { text: "Valor da Nota", value: "value_total_note" },
    { text: "Base ICMS", value: "base_calc_icms_total" },
    { text: "Valor ICMS", value: "value_total_note" },
    { text: "Base IPI", value: "value_total_note" },
    { text: "Valor IPI", value: "value_total_note" },
    { text: "Valor Isento", value: "value_total_note" },
    { text: "Valor Outros", value: "value_total_note" },
    // { text: "Ações", value: "actions" },
]



module.exports = {
    headersTable
}