<template>
  <v-row>
    <v-container>
      <v-card-title class="TitleMain">Apuração do Inventário</v-card-title>
      <v-divider></v-divider>
      <v-form>
        <v-card outlined class="ma-4">
          <v-card-title> <h5 class="InfoTitle">Referência</h5> </v-card-title>
          <v-row class="ml-4 mr-4">
            <v-col cols="12" xs="3" xl="4" sm="4" md="4" lg="4">
              <v-text-field
                outlined
                type="date"
                label="Data Inicial"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-text-field
                outlined
                type="date"
                label="Data Final"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="ma-4" outlined>
          <v-card-title> <h5 class="InfoTitle">Tipo</h5> </v-card-title>
          <v-row class="ml-4 mr-4">
            <v-col>
              <v-checkbox color="success" label="Termo Abertura"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                color="success"
                label="Termo Encerrramento"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox color="success" label="Livros"></v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="ma-4">
          <v-card-title> <h5 class="InfoTitle">Modelos</h5> </v-card-title>
          <v-row class="ml-4 mr-4">
            <v-col>
              <v-checkbox color="success" label="Inventário"></v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn width="200" class="green white--text mr-2"
          ><v-icon class="mr-2">mdi-check</v-icon> Gerar</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-row>
</template>

<script>
export default {};
</script>
<style>
.TitleMain {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: #264867;
  font-weight: bold;
}
.InfoTitle {
  color: #666666;
}
</style>
0