<template>
  <v-form ref="form" v-model="validationForm">
    <v-row>
      <v-col>
        <v-text-field
          outlined
          v-model="component.cstPis"
          label="*CST/PIS"
          :rules="component.textRules"
          :readonly="component.justVisible"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changePisCofinsDialog()"
          append-icon="mdi-magnify"
          @click:append="openCstPisTable()"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-layout>
          <v-select
            class="ma-2"
            label="Forma de calculo do Pis"
            :readonly="component.justVisible"
            v-model="component.product.pis_calculation_form"
            outlined
            :items="formcalpis"
          >
          </v-select>
        </v-layout>
      </v-col>

      <v-col>
        <v-text-field
          class="ma-2"
          :readonly="component.justVisible"
          type="number"
          outlined
          label="Aliquota Pis (%)"
          v-model="component.product.framing_class"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-layout>
          <v-select
            class="ma-2"
            :readonly="component.justVisible"
            label="Forma de calculo de Subst. Trib"
            v-model="component.product.pis_st_calculation_form"
            outlined
            :items="formpis"
          >
          </v-select>
        </v-layout>
      </v-col>
    </v-row>

    <ModalPisCofins v-if="getShowDialogRegisterPISCOFINS" :component="this" />
    <PisDataTable :component="this" title="CST PIS"/>
  </v-form>
</template>

<script>
import ModalPisCofins from "../../../pis_cofins/AddDialog/index";
import PisDataTable from "@/components/products/Tabs/TaxPis/PisDialog/index.vue";
import { mapGetters } from "vuex";
import {GET_ALL_PIS_COFINS, GET_FILTER_PIS_COFINS} from "../../../../store/actions/pis_cofins"
export default {
  props: {
    component: Object(),
  },

  components: {
    ModalPisCofins,
    PisDataTable,
  },

  data: () => ({
     validationForm:false,
    auxPIS: 0,
    page: Number(),
    last_page: Number(),
    item_listNcm: Array(),
    item_list: Array(),
    addDialogPisCofins: false,
    cst_pis_cofins: Array(),
    objectToUpdate: Object(),
    vSelect: String(),
    items: [
      "Nacional",
      "Estrangeira - Imoportação direta",
      "Estrangeira - Adquirida no mercado interno",
    ],
    formpis: [
      "Nacional",
      "Estrangeira - Imoportação direta",
      "Estrangeira - Adquirida no mercado interno",
    ],
    formcalpis: ["Porcentagem", "Em valor"],

    selectTableShow: Boolean(),
    pisHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "code"},
    ],
    pisItems: Array(),
    currentPage: 1,
    lastPage: 1,
    search: String(),
  }),

  methods: {
    openCstPisTable() {
      this.selectTableShow = !this.selectTableShow
    },
    //Continuação
   async getAllPisCofins(page){
    const payload = {
      page: page,
      paginate: true,
      limit: 10
    }
      await this.$store.dispatch(GET_ALL_PIS_COFINS,payload).then((response) => {
        this.pisItems = response.data
        this.currentPage = response.current_page
        this.lastPage = response.last_page
      })
    },

    rowClickSelectTable(item) {
      this.component.product.cst_pis_id = item.id
      this.component.cstPis = item.description
      this.selectTableShow = !this.selectTableShow
    },

    autocompletePis(page = 1) {
      if(this.search == "") {
        this.getAllPisCofins(1)
      } else {
        const payload = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
        }
        this.$store.dispatch(GET_FILTER_PIS_COFINS, payload).then((response) => {
          this.pisItems = response.data
          this.currentPage = response.current_page
          this.lastPage = response.last_page
        })
      }
    },

    change_addDialog(item) {
      this.objectToUpdate = item;
    },
    changePisCofinsDialog() {
      this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
    },
    reset() {
      this.$refs.form.resetValidation();
    },
  },

  computed: {
    ...mapGetters(["getPisCofins", "getShowDialogRegisterPISCOFINS"]),
  },

  watch: {
     validationForm:function(val){
      this.component.validations[3] = val
      this.component.checkValidation()
    },
    'component.validations': function() {
      this.component.validations[3] = this.validationForm;
      this.component.checkValidation()
    },

    currentPage: function () {
      this.getAllPisCofins(this.currentPage)
    },

    search: function () {
      this.autocompletePis(1)
    }
    // getPisCofins: function (val) {
    //   
    //   val;
    //   this.cst_pis_cofins = [];
    //   this.cst_pis_cofins = Object.keys(this.getPisCofins).map(
    //     (key) => this.getPisCofins[key]
    //   );
    //   if (this.auxPIS == 0) {
    //     this.auxPIS = 1;
    //   } else {
    //     this.component.product.cst_pis_id =
    //       this.cst_pis_cofins[this.cst_pis_cofins.length - 1];
    //   }
    // },
  },

  created() {
    this.getAllPisCofins(1)
    // this.cst_pis_cofins = Object.keys(this.getPisCofins).map(
    //   (key) => this.getPisCofins[key]
    // );
  },
};
</script>
