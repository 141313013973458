async function calculeTributeCofins(
  productInvoiceEntry,
  codeCstCofins,
  aliquot_cofins
) {
  let productTributeCofins = classificationCstCofins(
    codeCstCofins,
    productInvoiceEntry,
    aliquot_cofins
  );
  return productTributeCofins;
}

async function classificationCstCofins(
  codeCstCofins,
  productInvoiceEntry,
  aliquot_cofins
) {
  try {
    let cst_cofins_code_found = parseInt(codeCstCofins);

    if (cst_cofins_code_found >= 1 || cst_cofins_code_found <= 97) {
      return _normal_Cofins_Calculation(productInvoiceEntry, aliquot_cofins);
    } else if (cst_cofins_code_found >= 98 || cst_cofins_code_found <= 99) {
      return _others_Calculation_Cofins(productInvoiceEntry, aliquot_cofins);
    }
  } catch (error) {
    return error;
  }
}

async function _normal_Cofins_Calculation(productInvoiceEntry, aliquot_cofins) {
  try {
    productInvoiceEntry.aliquot_cofins = aliquot_cofins;
    productInvoiceEntry.value_base_cofins_product = productInvoiceEntry.amount * productInvoiceEntry.cust;
    productInvoiceEntry.value_cofins_product = productInvoiceEntry.value_base_cofins_product * ( productInvoiceEntry.aliquot_cofins / 100);
    productInvoiceEntry.value_base_cofins_st_product = 0;
    productInvoiceEntry.value_cofins_st_product = 0;
    productInvoiceEntry.value_other_cofins_product = 0;
    productInvoiceEntry.value_free_cofins_product = 0;

    return productInvoiceEntry;
  } catch (error) {
    return error;
  }
}

async function _others_Calculation_Cofins(productInvoiceEntry, aliquot_cofins) {
  try {
    productInvoiceEntry.aliquot_cofins = aliquot_cofins;
    productInvoiceEntry.value_base_cofins_product = 0;
    productInvoiceEntry.value_cofins_product = 0;
    productInvoiceEntry.value_base_cofins_st_product = 0;
    productInvoiceEntry.value_cofins_st_product = 0;
    productInvoiceEntry.value_other_cofins_product = productInvoiceEntry.amount * productInvoiceEntry.cust;
    productInvoiceEntry.value_free_cofins_product = 0;

    return productInvoiceEntry;
  } catch (error) {
    return error;
  }
}

module.exports = {
  calculeTributeCofins,
};
