function setModelPurchaseOrder(purchageOrder, arrayItems) {
  var modelPurchageOrder = {
    // value_products: parseFloat(purchageOrder.value_products),
    enterprise_id: purchageOrder.enterprise_id,
    value_freigth: purchageOrder.value_freigth,
    payment_term_id: purchageOrder.payment_term_id,
    transporter_id: purchageOrder.transporter_id.id,
    provider_id: purchageOrder.provider_id,
    status: purchageOrder.status,
    buyer: purchageOrder.buyer,
    type_frity: purchageOrder.type_frity,
    seller: purchageOrder.seller,
    note: purchageOrder.note,
    data_purchase_order: purchageOrder.data_purchase_order,
    itensPurchaseOrder: arrayItems,
    //Atributos a serem adicionados
    note: "Note", // mudar valores
    // value_freigth: purchageOrder.value_freight, // mudar valores
    value_products: parseFloat(purchageOrder.value_total_note) , // mudar valores
    base_calc_icms_total: purchageOrder.base_calc_icms_total,
    value_icms_total: parseFloat(purchageOrder.value_icms_total),
    value_base_icms_st_product: purchageOrder.value_base_icms_st_product,
    value_icms_substituicao_total: purchageOrder.value_icms_substituicao_total,
    value_account_total:parseFloat(purchageOrder.value_account_total),
    base_calc_ipi_total: purchageOrder.base_calc_ipi_total,
    value_ipi_total: purchageOrder.value_ipi_total,
    value_ipi_outher_total: purchageOrder.value_ipi_outher_total,
    value_ipi_free_total: purchageOrder.value_ipi_free_total,
    value_total_products: parseFloat(purchageOrder.value_total_products),
    value_icms_outher_product: purchageOrder.value_icms_outher_product,
    value_discont: purchageOrder.value_discont,
    value_icms_free_total: purchageOrder.value_icms_free_total,
    value_total_note: purchageOrder.value_total_note,
  };

  return modelPurchageOrder;
}

export default setModelPurchaseOrder;
