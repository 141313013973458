import toxml from "xml-js";
import { SET_ITEMS_NOTE } from "../../../../../../store/actions/import_xml";

function trimString(data) {
  if (data.length > 0) {

    if (!Number(data)) {
      return data.trim();
    } else {
      var elementToTrim = data.trim();
      return elementToTrim
    }
  } else {
    return data;
  }
}

function setArrayProduct(arrayProductsToSet) {
  let returnArrayProducts = Array();
  let product = {
    Tax: {
      aliquot: String(),
      cofins: String(),
      cost_price: String(),
      cst: String(),
      ipi: String(),
      pis: String(),
      cfop: Object(),
    }
  };

  let returnArrayTax = Array();
  let tax = Object();

  arrayProductsToSet.forEach((productElement) => {
    product.manufacturer_code = trimString(productElement.prod.cProd._text); //código do fabricante
    product.description_item = trimString(productElement.prod.xProd._text); //descrição completa
    product.bar_code = trimString(productElement.prod.cEANTrib._text);
    product.cost_price = trimString(productElement.prod.vUnCom._text);
    product.note_price = trimString(productElement.prod.vUnCom._text);
    product.accounting_price = trimString(productElement.prod.vUnCom._text);
    product.ncm = trimString(productElement.prod.NCM._text);
    product.amount = trimString(productElement.prod.qCom._text);
    product.isRegistered = false;

    //populando o imposto
    if (productElement.imposto.ICMS.ICMS00.CST != undefined) {
      tax.cst = trimString(productElement.imposto.ICMS.ICMS00.CST._text)
    } else {
      tax.csosn = trimString(productElement.imposto.ICMS.ICMS00.CSOSN._text)
    }
    tax.cfop = trimString(productElement.prod.CFOP._text);
    tax.cost_price = trimString(productElement.prod.vUnCom._text);
    tax.pis = trimString(productElement.imposto.PIS.PISAliq.CST._text);
    tax.cofins = trimString(productElement.imposto.COFINS.COFINSAliq.CST._text);
    if (productElement.imposto.IPI.IPITrib == undefined) {
      tax.ipi = 0
    } else {
      tax.ipi = trimString(productElement.imposto.IPI.IPITrib.pIPI._text);
    }
    tax.aliquot = trimString(productElement.imposto.ICMS.ICMS00.pICMS._text);

    returnArrayProducts.push(product);
    returnArrayTax.push(tax);

    tax = {};
    product = {
      Tax: {
        aliquot: String(),
        cofins: String(),
        cost_price: String(),
        cst: Object(),
        ipi: String(),
        pis: String(),
      }
    };
  });

  return { returnArrayProducts, returnArrayTax };
}

async function noteItems(inputxml, store, enterprise_id) {
  var readFile = new FileReader();
  readFile.readAsText(inputxml);
  var arrayProducts = Array();

  readFile.onload = () => {
    var data = readFile.result;

    var result = toxml.xml2json(data, { compact: true, spaces: 1 });
    var resultJSON = JSON.parse(result);

    arrayProducts = resultJSON.nfeProc.NFe.infNFe.det;


    // var teste = setArrayProduct(arrayProducts)
    

    let payload = {
      enterprise_id: enterprise_id.id,
      arrayProducts: setArrayProduct(arrayProducts).returnArrayProducts,
      arrayTax: setArrayProduct(arrayProducts).returnArrayTax
    };
    
    store.dispatch(SET_ITEMS_NOTE, payload);
  };

  return arrayProducts;
}

export default noteItems;
