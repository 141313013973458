<template>
  <v-row justify="center">
    <v-dialog persistent v-model="getShowDialogRegisterPISCOFINS" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>PIS/COFINS</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" class="mt-6">
            <v-col>
              <v-text-field
                label="*Codigo"
                type="number"
                v-model="code"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="4"
                :readonly = "justVisible"
                :rules="[() => !!code || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-textarea
                label="*Descrição"
                v-model="description"
                :readonly = "justVisible"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closePisCofins();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar
              </v-btn>
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import UiBusinessRules from "../../../UIBusinessRule/Pis_Confins/AddDialog";

export default {
  name:"AddDialog",
  props: {
    component: Object
  },

  computed: {
    ...mapGetters([
      "getShowDialogRegisterPISCOFINS",
      "getItemToUpdatePisCofins"
    ])
  },
  data() {
    return {
      justVisible: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      code: Number(),
      description: "",
      search: ""
    };
  },

  methods: {
    ...UiBusinessRules
  },
  watch: {
    getItemToUpdatePisCofins: function (val) {

    if (val.justVisible) {
      this.justVisible = val.justVisible;
      this.code = val.item.code;
      this.description = val.item.description;
    } else {
      this.code = this.getItemToUpdatePisCofins.code;
      this.created_at = this.getItemToUpdatePisCofins.created_at;
      this.description = this.getItemToUpdatePisCofins.description;
      this.id = this.getItemToUpdatePisCofins.id;
      this.updated_at = this.getItemToUpdatePisCofins.updated_at;
      }
    }
  },
  async created() {
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.code = this.object.code;
    // this.description = this.object.description;
  },

  beforeDestroy() {
    // this.getComponent().objectToUpdate = {};
  }
};
</script>
