


var mapGettersArray = [
    "getSelectedEnterprise",
    "getShowDialogRegisterImportXml",
    "getNoteEntryItens",
    
    "getDataInfGeneral",
    "getDataItensNote",
    "getDataTransporter",
    "getShowDialogRegisterConfirmNote"
];


export default mapGettersArray