
import {
  ADD_PIS_COFINS,
  UPDATE_PIS_COFINS,
} from "../../store/actions/pis_cofins";


function reset() {
  this.$refs.form.reset();
}
function resetValidation() {
  this.$refs.form.resetValidation();
}
function closePisCofins() {
  this.$store.dispatch("setShowDialogRegisterPISCOFINS", false);
  this.reset();
  this.resetValidation();
  this.justVisible = false;
}
function getComponent() {
  return this._props.component;
}
async function create_pisCofins() {
  const pisCofins = {
    code: this.code,
    description: this.description,
  };

  await this.$store.dispatch(ADD_PIS_COFINS, pisCofins).then(() => {
    this.closePisCofins();
  });
}
function submit() {
  this.id == undefined ? this.create_pisCofins() : this.update_pisCofins();
}
async function update_pisCofins() {
  const pisCofins = {
    id: this.id,
    code: this.code,
    description: this.description,
  };

  await this.$store.dispatch(UPDATE_PIS_COFINS, pisCofins).then(() => {
    this.closePisCofins();
  });
}



export default {
  reset,
  resetValidation,
  closePisCofins,
  getComponent,
  create_pisCofins,
  submit,
  update_pisCofins,
}