<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-container>
          <v-card-title>Editar item da nota</v-card-title>
          <v-divider></v-divider>
          <v-form v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="component.itemToUpdate.amount"
                  label="Quantidade"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="component.itemToUpdate.cust"
                  label="Custo"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  prefix="R$"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.aliquot_itens"
                  v-model="component.itemToUpdate.aliquot_icms_product"
                  item-text="aliquot"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  label="Alíquota"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.cfop_itens"
                  v-model="component.itemToUpdate.cfop_id"
                  item-value="id"
                  item-text="code"
                  return-object
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  label="CFOP"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  outlined
                  item-text="code"
                  :items="component.cstCsosn"
                  v-model="component.itemToUpdate.csosn_id"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  item-value="id"
                  label="CST/CSOSN"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="IPI"
                  v-model="component.itemToUpdate.aliquot_ipi_product"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.pisCofins_itens"
                  v-model="component.itemToUpdate.cst_pis_id"
                  label="CST/PIS"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  item-value="id"
                  item-text="code"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.pisCofins_itens"
                  v-model="component.itemToUpdate.cst_confis_id"
                  label="CST/COFINS"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  item-value="id"
                  item-text="code"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="changeDialog" class="error">
            <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
          >
          <v-btn @click="confirm" class="success" :disabled="!valid"
            ><v-icon class="mr-1">mdi-content-save</v-icon> Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    changeDialog: Function,
    submit: Function,
    component: Object,
  },
  data: () => ({
    itemToUpdate: Object(),
    formEdit: {
      id: Number(),
      product_id: Number(),
      provider_id: Number(),
      tax_operation_types_id: Number(),
      enterprise_id: Number(),
      amount: Number(),
      cust: Number(),
      aliquot: Number(),
      cfop: Object(),
      cst_csosn: Object(),
      ipi: Number(),
      cst_pis: Object(),
      cst_cofins: Object(),
    },
    valid: Boolean(),
  }),
  methods: {
    confirm() {

      this.itemToUpdate.cust = parseFloat(this.formEdit.cust)
      this.itemToUpdate.amount = this.formEdit.amount
      this.itemToUpdate.aliquot_icms_product = this.formEdit.aliquot
      this.itemToUpdate.cfop_id = this.formEdit.cfop.id
      this.itemToUpdate.code_cfop = String(this.formEdit.cfop.code)
      this.itemToUpdate.csosn_id = this.formEdit.cst_csosn.id
      this.itemToUpdate.code_csosn = this.formEdit.cst_csosn.code
      this.itemToUpdate.aliquot_ipi_product = parseFloat(this.formEdit.ipi) 
      this.itemToUpdate.cst_pis_id =  this.formEdit.cst_pis.id
      this.itemToUpdate.cst_code_pis = this.formEdit.cst_pis.code
      this.itemToUpdate.cst_confis_id = this.formEdit.cst_cofins.id
      var item = this.itemToUpdate
      this.submit(item);
    },
    refreshObject(item) {

      this.itemToUpdate = {...item}

      this.formEdit.id = item.id;
      this.formEdit.product_id = item.product_id;
      this.formEdit.enterprise_id = item.enterprise_id;
      this.formEdit.amount = item.amount;
      this.formEdit.cust = item.cust;
      this.formEdit.aliquot = item.product.aliquot;
      this.formEdit.cfop = item.product.cfop;
      this.formEdit.ipi = item.product.ipi;
      this.formEdit.cst_pis = item.cst_pis;
      this.formEdit.cst_cofins = item.cst_confis;
      this.formEdit.provider_id = item.provider_id;
      this.formEdit.tax_operation_types_id = item.tax_operation_types_id;
    },
    refreshObjectBeforeCreateItem(item) {
      this.itemToUpdate = {...item}
      //carregamento do formulário
      this.formEdit.id = item.id;
      this.formEdit.product_id = item.product_id;
      this.formEdit.enterprise_id = item.enterprise_id;
      this.formEdit.amount = parseFloat(item.amount) 
      this.formEdit.cust = item.cust;
      this.formEdit.aliquot = item.aliquot_icms_product;
      this.formEdit.cfop = { id: item.cfop_id, code: parseFloat(item.code_cfop)};
      this.formEdit.ipi = item.value_ipi_product;

      this.formEdit.cst_pis = {id:item.cst_pis_id, code: item.cst_code_pis};

      this.formEdit.cst_cofins = {id: item.cst_confis_id, code: item.cst_code_confis};
      this.formEdit.provider_id = item.provider_id;
      this.formEdit.cst_csosn = { id:item.csosn_id, code: parseFloat(item.code_csosn)}
      this.formEdit.tax_operation_types_id = item.tax_operation_types_id;
    },
  },

  created() {
    if (this.component.ObjectToUpdate.product === undefined) {
      this.refreshObjectBeforeCreateItem(this.component.ObjectToUpdate);
    } else {
      this.refreshObject(this.component.ObjectToUpdate);
    }
  },
};
</script>

<style>
</style>