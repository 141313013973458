<template>
  <v-form ref="form" v-model="component.validAddress">
    <br>
    <v-row>
      <v-col cols="2">
        <v-text-field
          :readonly="component.justVisible"
          v-model="component.address.cep"
          outlined
          v-mask="'#####-###'"
          :rules="cep_rules"
          label="CEP"
          @click:append="
            () => {
              loadAddress()
            }
              "
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      </v-row>
      <v-row>
      <v-col cols="10">
        <v-text-field
          v-model="component.address.street"
          :rules="textRules"
          outlined
          label="*Rua"
          :readonly="component.justVisible"
        >
        </v-text-field>
      </v-col>
      <v-col
        ><v-text-field
          v-model="component.address.number"
          :rules="textRules"
          outlined
          label="*Número"
          :readonly="component.justVisible"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.address.district"
          :rules="textRules"
          outlined
          label="*Bairro"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.address.complement"
          outlined
          label="*Complemento"
          :readonly="component.justVisible"
          :rules="textRules"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="oldState"
          :rules="textRules"
          outlined
          label="Estado"
          item-text="text"
          return-object
          :readonly="component.justVisible"
          :items="initials"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="component.cityObject"
          outlined
          label="Cidade"
          :readonly="component.justVisible"
          :items="cities"
          item-text="nome"
          return-object
          :rules="textRules"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
export default {
  props: { component: Object() },
  data: () => ({
    valid: Boolean(),
    textRules: [(v) => !!v || "Campo obrigatório!"],
    cep_rules: [
      (v) => !!v || "CEP é obrigatório",
    ],
    initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "es" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],

    cities: Array(),
    oldState: String(),
    auxCity: String(),
  }),

  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
    loadAddress() {
      axios.get(
        `https://viacep.com.br/ws/${this.component.address.cep}/json`
      )
      .then((response) => {
        this.component.address.street = response.data.logradouro
        this.component.address.district = response.data.bairro
        this.auxCity = response.data.localidade
        this.oldState = response.data.uf.toLowerCase()
      });
    },
    loadCity() {
      if(this.cities.length != 0) {
        for(let i = 0; i <= this.cities.length; i++) {
          if (this.cities[i].nome == this.auxCity) {
            this.component.cityObject = this.cities[i]
          }
        }
      }
    }
  },
  watch: {
    oldState(val) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val}/municipios`
        )
        .then((response) => {
          this.cities = response.data;
          this.component.address.state = val;
          this.loadCity()
        });
    },
  },
};
</script>
