





export default [

    {
        text: "status",
        value: "test",
        align: 'center',
    },
    {
        text: "data de Emissão",
        value: "test",
        align: 'center',
    },
    {
        text: "Data de Entrada",
        value: "test",
        align: 'center',
    },
    {
        text: "Fornecedor",
        value: "test",
        align: 'center',
    },
    {
        text: "Número nota",
        value: "test",
        align: 'center',
    },
    {
        text: "Valor dos Produtos",
        value: "test",
        align: 'center',
    },
    {
        text: "Valor da Nota",
        value: "test",
        align: 'center',
    },
    {
        text: "Bc ICMS",
        value: "test",
        align: 'center',
    },
    {
        text: "Valor ICMS",
        value: "test",
        align: 'center',
    },
    {
        text: "Outros Valores",
        value: "test",
        align: 'center',
    },
    {
        text: "Valor Base de Substituição",
        value: "test",
        align: 'center',
    },
    {
        text: "Valor de ICMS Substituição",
        value: "test",
        align: 'center',
    },
    {
        text: "Ações",
        value: "test",
        align: 'center',
    },

]
