<template>
    <div>
        <div class="space" />
        <v-text-field label="Pesquisar" outlined append-icon="mdi-magnify" />
        <v-divider />
        <v-data-table hide-default-footer :headers="Header" />
        <v-pagination class="mb-5 mt-10" :length="1"></v-pagination>
    </div>
</template>
  
<script>
import Header from "./helpers/headers";
export default {
    data: () => ({
        Header,
    }),
};
</script>
  
<style>
.space{
    height: 50px;
}
</style>
  