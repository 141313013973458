<template>
  <v-dialog max-width="1500" v-model="fatherComponent.togleSefazTable">
    <div class="body-sefaz">
      <div class="header">
        <p class="sefaz-title">Escolha o Xml que deseja Baixar</p>
      </div>
      <v-divider />

      <v-data-table
        :headers="headers"
        :items="mockData"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
      <template v-slot:item.actions="{ item }">
      <v-icon
        color="yellow darken-4"
        class="mr-2"
      >
        mdi-eye
      </v-icon>
      <v-icon
        color="success"
        class="mr-2"
      >
        mdi-file-download
      </v-icon>
      <v-icon
        color="green darken-2"
        class="mr-2"
      >
        mdi-file-upload
      </v-icon>
    </template>
    </v-data-table>
      <v-pagination v-model="page" :length="1"></v-pagination>
    </div>
  </v-dialog>
</template>

<script>
import { headers, mockData } from "./helpers";
export default {
  data: () => ({
    page:1,
    headers,
    mockData,
  }),
  props: {
    fatherComponent: Object(),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
