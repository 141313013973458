import { SET_GENERAL_DATA } from "../../../../../../../../store/actions/import_xml";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../../../../../../store/actions/tax_operation_types";
import { AUTO_RELOAD_PROVIDER } from "../../../../../../../../store/actions/provider"
function registerProvider(isRegistered) {
  if (isRegistered) {
    alert("Fornecedor já registrado");
  } else if (isRegistered == false) {
    this.$store.dispatch("setShowDialogRegisterProvider", true);
  } else if (isRegistered == null) {
    alert("Insira um arquivo xml");
  }
}

function resetForm() {
  this.$store.commit(SET_GENERAL_DATA, this.copyGeneralData);
}

function getAllOperationType(page = 1) {
  const payload = {
    page: page,
    limit: 10,
    paginate: true,
  };
  this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, payload);
}

function populateObjectGeneralData(generalDataStore) {
  //  

  this.generalData.access_key = generalDataStore.Id;
  this.generalData.provider = { ...generalDataStore.provider };
  this.generalData.number_grade = generalDataStore.cNF;
  this.generalData.series = generalDataStore.serie;
  this.generalData.date_of_issue = generalDataStore.dhEmi;
  this.generalData.entry_date = generalDataStore.dhSaiEnt;

  if (this.generalData.provider.isRegistered == false) {
    //  
    var payload = { ...this.generalData.provider }
    payload.pj = true;
    this.$store.dispatch(AUTO_RELOAD_PROVIDER, payload)
  }
}

function teste() {
   
}

export default {
  populateObjectGeneralData,
  registerProvider,
  resetForm,
  getAllOperationType,
  teste,
  // reset
};
