import { rules } from "./rules";

var data = {
  formValidation: Boolean(),
  listOperationTypes: Array(),
  rules: rules,
  // isRegistered: null,
  generalData: {
    additional_information: String(),
    tax_operation_types_id: Number(),
    access_key: String(),
    authorization_protocol: String(),
    provider: {
      isRegistered: undefined
    },
    number_grade: Number(),
    series: String(),
    date_of_issue: String(),
    entry_date: String(),
    enterprise_id: Number(),
  },
  copyGeneralData: {
    additional_information: String(),
    tax_operation_types_id: Number(),
    access_key: String(),
    authorization_protocol: String(),
    provider: {
      isRegistered: undefined
    },
    number_grade: Number(),
    series: String(),
    date_of_issue: String(),
    entry_date: String(),
    enterprise_id: Number(),
  },
};

export default data;
