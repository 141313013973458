<template>
    <v-row justify="center">
      <v-dialog persistent v-model="component.dialogCancel" hide-overlay transition="dialog-bottom-transition" fullscreen>
        <v-card>
          <v-toolbar color="success" class="white--text" flat>
            <v-btn icon dark @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Cancelamento de Nfce Fora do Prazo</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
  
          <v-container>
  
  
            <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
              <v-tab v-for="(item, index) in newArrayItems" :key="index" :href="'#tab-' + item.href">
                {{ item.tabName }}
              </v-tab>
  
              <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
                    more
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
  
                <v-list class="grey lighten-3">
                  <v-list-item v-for="(item, index) in more" :key="index" @click="addItem(item)">
                    {{ item.tabName }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
  
            <v-tabs-items v-model="currentItem">
              <v-tab-item value="tab-1">
                <v-col>
                  <GeneralDate :component="this" />
                </v-col>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-col>
                  <RevenuesItems :component="this" />
                </v-col>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-col>
                  <InformationRevenue :component="this" />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="closeDialog()">
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
              <v-btn class="success" @click="save()">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import GeneralDate from "./Tabs/TabGenerateDate/index.vue";
  import InformationRevenue from "./Tabs/TabInfoNote/index.vue";
  import RevenuesItems from "./Tabs/TabNoteItens/index.vue";
  import { ADD_REVENUES } from "../../../../../store/actions/revenues"
  import { ISSUE_OF_INVOICE_NFE } from "../../../../../store/actions/nfe"
  import { CANCEL_AFTER_DEADLINE } from "../../../../../store/actions/taxCupom"
  import { GET_ALL_TAX_CUPOM } from "../../../../../store/actions/taxCupom";
  export default {
    components: {
      GeneralDate,
      InformationRevenue,
      RevenuesItems,
    },
    props: {
      component: Object,
    },
    data: () => ({
      newArrayItems: Array(),
      currentItem: "tab-1",
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: " Itens da Nota",
          href: "2",
        },
        {
          tabName: "Informações da nota",
          href: "3",
        },
      ],
      nfceData: Object(),
      nfeToPrint: String(),
    }),
    methods: {
        setArrayItens() {
            this.newArrayItems = Array();
            if (this.$vuetify.breakpoint.xsOnly) {
                this.newArrayItems = this.items;
            } else {
                this.newArrayItems = this.items.concat(this.more);
            }
        },
        addItem(item) {
            const removed = this.items.splice(0, 1);
            this.items.push(...this.more.splice(this.more.indexOf(item), 1));
            this.more.push(...removed);
            this.$nextTick(() => {
                this.currentItem = "tab-" + item.href;
            });
        },
        closeDialog() {
          this.component.dialogCancel = false
        },
        setData() {
          this.nfceData = this.component.cancelItemData
          this.nfceData.note_model = Object()
          this.nfceData.note_model.tax_document_type = "Nf-e"
          this.nfceData.cliente.people.name = String()
          if(this.nfceData.cliente.type_people == "PhysicalPeople") {
            this.nfceData.cliente.people.name = this.nfceData.cliente.people.physicalpeople.name
          } else {
            this.nfceData.cliente.people.name = this.nfceData.cliente.people.legalpeople.fantasyName
          }
          this.nfceData.info = String()
          this.nfceData.taxoperationToCancel = this.component.taxOperationToCancel
        },
        async save() {
          this.createRevenue()
          this.closeDialog();
        },

        async createRevenue() {
          let todayDate = this.getTodayDate()
          for(let i=0; i<this.nfceData.itens_sales.length; i++) {
            this.nfceData.itens_sales[i].description_product = this.nfceData.itens_sales[i].product.description_item
            this.nfceData.itens_sales[i].description_unit = this.nfceData.itens_sales[i].product.unit.description
            this.nfceData.itens_sales[i].code_cfop = this.nfceData.itens_sales[i].product.cfop.code.toString()
            this.nfceData.itens_sales[i].cst_confis_id = this.nfceData.itens_sales[i].cst_cofins_id
            this.nfceData.itens_sales[i].value_book = 0
          }
          const payload = {

            status: "Pre-pedido",
            number_grade: "1",
            date_of_issue: todayDate,
            finality_nf: "1",
            access_key: this.nfceData.access_key,
            authorization_protocol: this.nfceData.authorization_protocol,
            series: this.nfceData.series,
            value_total_products: this.nfceData.value_total_products,
            value_total_note: this.nfceData.value_total_note,
            value_account_total: this.nfceData.value_account_total,
            value_icms_total: this.nfceData.value_icms_total,
            value_discount: this.nfceData.value_descount_total,
            value_icms_substituicao_total: this.nfceData.value_icms_substituicao_total,
            base_calc_icms_total: this.nfceData.base_cal_icms_total,
            base_calc_susbt_total: this.nfceData.base_calc_susbt_total,
            value_icms_free_total: this.nfceData.value_icms_free_total,
            value_outher_tax_total: this.nfceData.value_outher_tax_total,
            note: this.nfceData.note,
            value_ipi_total: this.nfceData.value_ipi_total,
            value_ipi_outher_total: this.nfceData.value_ipi_outher_total,
            value_ipi_free_total: this.nfceData.value_ipi_free_total,
            base_calc_ipi_total: this.nfceData.base_calc_ipi_total,
            value_total_discount: this.nfceData.value_descount_total,
            value_base_pis_total: this.nfceData.value_base_pis_total,
            value_pis_total: this.nfceData.value_pis_total,
            value_base_confis_total: this.nfceData.value_base_cofins_total,
            value_confis_total: this.nfceData.value_cofins_total,
            value_base_pis_st_total: this.nfceData.value_base_pis_st_total,
            value_pis_st_total: this.nfceData.value_pis_st_total,
            value_base_cofins_st_total: this.nfceData.value_base_cofins_st_total,
            value_cofins_st_total: this.nfceData.value_cofins_st_total,
            value_other_pis_total: this.nfceData.value_other_pis_total,
            type_grade_id: 1,
            transporter_id: null,
            client_id: this.nfceData.cliente.id,
            payment_terms_id: this.nfceData.sale_form_of_payment[0].payment_term.id,
            tax_operation_types_id: this.nfceData.taxoperationToCancel.id,
            account_plans_id: this.nfceData.accont_plan.id,
            enterprise_id: this.nfceData.enterprise_id,
            receiptCode: "000000",

            revenusItem: this.nfceData.itens_sales,

            
            billstoReceive: []
          }
          for(let i=0; i<this.nfceData.sale_form_of_payment.length; i++) {
            payload.billstoReceive.push(
            {
                status: "Aberto",
                issuance_date: todayDate,
                issuance_due: todayDate,
                type_title: this.nfceData.sale_form_of_payment[i].formofpayment.description,
                number_title: this.nfceData.number_sale,
                value_title: this.nfceData.sale_form_of_payment[i].value_form_payment,
                value_net: this.nfceData.sale_form_of_payment[i].value_form_payment,
                dejection: this.nfceData.value_descount_total,
                interest: null,
                title_balance: this.nfceData.value_total_note,
                annotation: `TITULO A RECEBER REFERENTE A VENDA ${this.nfceData.number_sale}`,
                enterprise_id: this.nfceData.enterprise_id,
                client_id: this.nfceData.client_id,
                account_plans_id: this.nfceData.account_plans_id,
                revenues_id: null,
                sale_id: null,
              }
            )
          }
          await this.$store.dispatch(ADD_REVENUES, payload).then((response) => {
            this.nfeEmission()
        }).catch((error) => {
        })
        },

        async nfeEmission() {
          let date = this.convertDateFormat(this.nfceData.data_sale);
          let todayDate = this.getTodayDate()
          this.nfceData.nfereferences = {
            keyAcess:  this.nfceData.access_key,
            ufIssuer:  String(this.nfceData.enterprise.address.my_city.state.id),
            yearAndMonth:  date,
            cnpjIssuer:  this.nfceData.enterprise.cnpj,
            invoiceTemplate:  "01",
            gradeSeries:  this.nfceData.series,
            taxDocumentNumber: this.nfceData.number_sale,
          }
          this.nfceData.client = this.nfceData.cliente
          this.nfceData.base_calc_icms_total = this.nfceData.base_cal_icms_total
          this.nfceData.tax_operation_type = this.nfceData.tax_operation
          this.nfceData.formOfPayment_nf = {}
          this.nfceData.formOfPayment_nf.code = this.nfceData.sale_form_of_payment[0].formofpayment.code
          this.nfceData.number_grade = "1",
          this.nfceData.date_of_issue = todayDate
          this.nfceData.finality_nf = 1
          this.nfceData.enterprise_nf = this.nfceData.enterprise
          this.nfceData.transportNfe = Object()
          this.nfceData.revenusItem = this.nfceData.itens_sales
          this.nfceData.revenusItem.forEach(item => {
            item.amount_add = item.amount,
            item.code_cfop = item.cfop.code
            item.description_product = item.product.description_item
            item.bar_code = item.product.bar_code
            item.ncm_code = item.product.ncm.code
            item.value_products = item.value_subtotal_product
            item.code_csosn = item.csosn.code
            item.code_cst = item.cst.code
            
            item.source_product = "0"
            item.calculation_base_mode = "0"
            item.cst_code_confis = "99"
            item.cst_code_pis = "99"
            item.aliquot_icms_product = 12
            item.description_unit = "UN"
          })
          for(let i=0; i<this.nfceData.revenusItem.length;i++) {
            this.nfceData.revenusItem[i].code_item = i+1
          }
           
          this.nfceData.tax_operation_type.normal_output = {}
          this.nfceData.tax_operation_type.normal_output.code = this.nfceData.tax_operation_type.code

          this.nfceData.indicationPayment = "1"

          await this.$store.dispatch(ISSUE_OF_INVOICE_NFE, this.nfceData).then((response) => {
            this.nfeToPrint = response.nfce
            this.cancelSaleAfterDeadline()
          }).catch((error) => {
        })
        },

        async cancelSaleAfterDeadline() {
          const payload = {
            enterprise_id: this.nfceData.enterprise_id,
            sale_id: this.nfceData.id
          }
          await this.$store.dispatch(CANCEL_AFTER_DEADLINE, payload).then((response) => {
            window.open(this.nfeToPrint)
            this.getAllTaxCupon()
          })
        },

        getAllTaxCupon: function (page = 1) {
          var payload = {
            page: page,
            limit: 10,
            paginate: true,
          };
          this.$store.dispatch(GET_ALL_TAX_CUPOM, payload);
    },

        convertDateFormat(inputDate) {
          var dateObject = new Date(inputDate);
          var year = dateObject.getFullYear().toString().slice(-2);
          var month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
          var result = year + month;
          return result;
        },

        getTodayDate() {
          var todayDate = new Date();
          var year = todayDate.getFullYear();
          var month = todayDate.getMonth() + 1;
          month = month < 10 ? '0' + month : month;
          var day = todayDate.getDate();
          day = day < 10 ? '0' + day : day;
          var dateFormat = year + '-' + month + '-' + day;
          return dateFormat
        }
    },
    created() {
        this.setArrayItens();
        this.setData()
  },
  };
  </script>
  
  