<template>
  <v-dialog persistent fullscreen max-width="1000" v-model="showDialig">
    <v-card>
      <v-toolbar color="success" class="white--text" flat>
        <v-toolbar-title>Importar XML</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <TabImport :component="this" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TabImport from "./Tabs/importXML";
import { mapGetters } from "vuex";

export default {
  components: {
    TabImport
    // TabLoad,
  },
  data() {
    return {
      showDialig: true,
      infNFe: {}
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterImportXml", false);
    }
  },
  props: {
    component: Object()
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterImportXml"])
  },
  watch: {
    infNFe: function() {
      this.component.infNFe = this.infNFe;
    }
  }
};
</script>

<style>
</style>