import axios from "axios"

const {
    GET_ALL_BOOKSUPERVISION_IN
} = require("./actions/book_supervision_in");

// const {
//     SPLICE_ERRO
// } = require("./alert_api")

const moduleBookSupervisionIn = {
    state: () => ({
        booksupervisionins: Object()
    }),

    mutations:{
        GET_ALL_BOOKSUPERVISION_IN: (state, payload) => {
            state.booksupervisionins = payload
        }
    },

    actions: {
        async GET_ALL_BOOKSUPERVISION_IN({
            commit
        }, payload) {
            await axios.post("booksupervision/index", { 
                page: payload.page, 
                limit: payload.limit, 
                paginate: payload.paginate
            }).then((response) => {
                commit(GET_ALL_BOOKSUPERVISION_IN, response.data[0])
            })
        }
    },

    getters: {
        getBookSupervisionIn: (state) => ({ ...state.booksupervisionins })
    }
}

export default moduleBookSupervisionIn;