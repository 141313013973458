

var Headers = [
    { text: "Id", value: "id" },
    { text: "Data da Venda", value: "data_sale" },
    { text: "Chave de Acesso", value: "access_key" },
    { text: "Número da Nota", value: "number_sale" },
    { text: "Cliente", value: "cliente.people.physicalpeople.name" },
    { text: "Vendedor", value: "seller.people.physicalpeople.name" },
    { text: "PDV", value: "pdv_id" },
    { text: "Valor da Nota", value: "value_total_note" },
    { text: "Ações", value: "actions" },
]


export default Headers