<template>
  <div>
    <v-dialog :retain-focus="false" persistent max-width="600px" v-model="getShowDialogRegisterGeneralDataSales">
      <v-card>
        <v-col>
          <v-row class="d-flex justify-center">
            <v-card-title>
              <v-icon class="mr-2">mdi-card-account-details</v-icon>Dados Gerais
            </v-card-title>
          </v-row>
          <v-row>
            <v-text-field
              append-icon="mdi-magnify"
              label="Vendedor"
              outlined
              v-model="seller.name"
              class="ma-2 mt-2"
              @blur="getByIdSeller(seller.name)"
              @click:append="
            () => {
              search_seller();
            }
          "
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              append-icon="mdi-magnify"
              append-outer-icon="mdi-account-plus"
              label="Cliente"
              v-model="client.name != undefined ? client.name : client.socialName"
              outlined
              class="ma-2 mt-2"
              @click:append="
            () => {
              search_client();
            }
          "
              @click:append-outer="
            () => {
              add_client();
            }
          "
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-btn @click="closeGeneralDataSalesDialog" class="mr-5 mb-5 success">Ok</v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <TableSearchSeller :component="this" v-if="dialog" />
    <TableSearchClient :component="this" v-if="dialog_cl1" />
    <AddClient :component="this" />
  </div>
</template>

<script>
// import { GET_BY_ID } from "../../../../store/actions/client";
// import {  } from "../../../../store/actions/seller";
import TableSearchSeller from "../../Tables/TableSearchSeller";
import TableSearchClient from "../../Tables/TableSearchClient";
import AddClient from "../../AddDialog/Add_Client";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object()
  },
  components: { TableSearchSeller, TableSearchClient, AddClient },
  name:'general-data',
  data: () => ({
    dialog: false,
    dialog_cl1: false,
    dialog_cl2: false,
    client: Object(),
    seller: Object()
  }),
  methods: {
    closeGeneralDataSalesDialog() {
      this.$store.dispatch("setShowDialogRegisterGeneralDataSales", false);
    },
    search_client() {
      this.dialog_cl1 = !this.dialog_cl1;
    },
    add_client() {
      this.dialog_cl2 = !this.dialog_cl2;
    },
    search_seller() {
      this.dialog = !this.dialog;
    },
    getByIdClient(val) {
      try {
        this.$store.dispatch(GET_BY_ID, val);
      } catch (e) {
        console.error(e);
      }
    },
    getByIdSeller(val) {
      //Necessário implementar
      try {
        //precisa implementar GET_BY_ID_SELLER 
        // this.$store.dispatch(GET_BY_ID_SELLER, val);
      } catch (e) {
        console.error(e);
      }
    },

    refreshClient(val) {
       
      if (val.type_people === "PhysicalPeople") {
        this.component.peoplesInf.client = { ...val.physicalpeople };
        this.client = val.physicalpeople
      } else {
        this.component.peoplesInf.client = { ...val.legalpeople };
        this.client = val.legalpeople
      }
      // this.component.client.id = val.id;
      // this.component.clientNfceObject = val;
      // this.component.enterpriseNfceObject = this.getEnterprises.data[0];
    },
    refreshSeller(val) {
      this.seller = { ...val.people.physicalpeople }
      this.component.peoplesInf.seller = { ...val.people.physicalpeople };
    },
    reFreshInMemory() {
      // Client check if was setted
      if (this.component.client.type_people) {
        if (this.component.client.type_people === "PhysicalPeople") {
          this.component.client = this.component.client.physicalpeople;
        }
      }
      // Seller check if was setted
      if (this.component.seller.type_people) {
        if (this.component.seller.type_people === "PhysicalPeople") {
          this.component.seller = { ...this.component.seller.physicalpeople };
        }
      }
    }
  },
  mounted() {
     
    // this.reFreshInMemory();
  },
  computed: {
    ...mapGetters([
      // "getResult",
      // "getResultSeller",
      "getEnterprises",
      "getShowDialogRegisterGeneralDataSales"
    ])
  },
  watch: {
    getResult: function(val) {
      val
      // this.refreshClient(val);
    },
    getResultSeller: function(val) {
      val
      // this.refreshSeller(val);
    }
  }
};
</script>
