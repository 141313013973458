
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise"
import { GET_ALL_XML_NFCE } from "../../../../store/actions/write_off_xml"
function getAllEnterprise() {
    var payload = {
        page: null, limit: null, paginate: false,
    }
    try {
        this.$store.dispatch(GET_ALL_ENTERPRISE, payload)
    } catch (error) {
        return error
    }
}

function getFinalDate() {
    const date = new Date(`${this.formState.initialDate} 00:00:00`)
    const dateFinal = new Date(date.getFullYear(),date.getMonth() + 1, 0)
    const updatedDateString = dateFinal.toISOString().slice(0, 10);
    this.formState.finalDate = updatedDateString
}
function downloadXML() { }

function research() {
    try {
        if (this.validation.formPagePC || this.validation.formPageMobile) {
            var payload = {
                nfeType: this.formState.nfeType,
                initialDate: this.formState.initialDate,
                finalDate: this.formState.finalDate,
            }
            if(payload.nfeType == 'nfce') {;
                this.$store.dispatch(GET_ALL_XML_NFCE, payload)
            }
            else if(payload.nfeType == 'nfe') {
                this.$store.dispatch(GET_ALL_XML_NFE, payload)
            }
        } else {
            setTimeout(() => {
                this.alert.showAlert = false
            }, 2500);
            this.alert.showAlert = true
            this.alert.message = "Insira a data inicial, data final e empresa"
        }
    } catch (error) {
        return error
    }
}



export default {
    getAllEnterprise,
    research,
    downloadXML,
    getFinalDate,
   

}