import transporterModel from "./models"

function modelArrayTransporter(value) {
    

    var newTransporter = { ...transporterModel }

    newTransporter.enterprise_id = value.enterprise_id
    newTransporter.id = value.id
    newTransporter.name = value.legalpeople.fantasyName
    newTransporter.people = {
        address: value.address,
        bank_data: value.banks,
        contact: value.contacts,
        created_at: value.legalpeople.created_at,
        id: value.legalpeople.id,
        updated_at: value.legalpeople.updated_at
    };
    newTransporter.people_id = value.legalpeople.people_id
    newTransporter.rntr = value.rntr
    newTransporter.security = value.security
    newTransporter.transporter_id = value.t
    newTransporter.type_people = value.t

    return newTransporter
}


export default {
    modelArrayTransporter
}