
let identifyReferenceNFe = {
  keyAccess: "",
  ufIssuer: "",
  yearAndMonth: "",
  cnpjIssuer: "",
  invoiceTemplate: "",
  gradeSeries: "",
  taxDocumentNumber: "",
  nfe_id: ""
};
async function execute(revenues) {
  try {
      identifyReferenceNFe.keyAccess = revenues.nfereferences.keyAcess,
      identifyReferenceNFe.ufIssuer = revenues.nfereferences.ufIssuer,
      identifyReferenceNFe.yearAndMonth = revenues.nfereferences.yearAndMonth,
      identifyReferenceNFe.cnpjIssuer = revenues.nfereferences.cnpjIssuer,
      identifyReferenceNFe.invoiceTemplate = revenues.nfereferences.invoiceTemplate,
      identifyReferenceNFe.gradeSeries = revenues.nfereferences.gradeSeries,
      identifyReferenceNFe.taxDocumentNumber = revenues.nfereferences.taxDocumentNumber,
      identifyReferenceNFe.nfe_id = ""
    return identifyReferenceNFe

  } catch (error) {
    return error


  }



}

module.exports = {
  execute,
};


