<template>
  <v-row>
    <v-card-title>Pedido de Compras</v-card-title>
    <v-spacer></v-spacer>
    <AddDialog :component="this" v-if="dialog" />
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        v-model="component.enterpriseId"
        :items="enterprises"
        item-text="fantasyName"
        item-value="id"
        flat
        label="Empresas"
      ></v-select>
    </v-col>
    <v-btn
      class="mr-2 mt-3"
      color="success"
      @click="
        () => {
          dialog = !dialog;
        }
      "
    >Adicionar</v-btn>
  </v-row>
</template>

<script>
import AddDialog from "../AddDialog/";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { CLEAN_NOTE_ENTRY_ITENS } from "../../../store/actions/note_entry_itens";


import { mapGetters } from "vuex";
export default {
  props: {
    component: Object()
  },
  components: {
    AddDialog
  },
  data: () => ({
    dialog: false,
    enterprises: Array()
  }),
  methods: {
    get_enterprise() {
      this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: null,
        limit: null,
        paginate: false
      });
    }
  },
  computed: {
    ...mapGetters(["getEnterprises"])
  },
  watch: {
    dialog: function(val){
      if(!val){
        this.$store.commit(CLEAN_NOTE_ENTRY_ITENS)
      }
    },
    getEnterprises: function(val) {
      this.enterprises = Object.values(val);
      this.component.enterpriseId = this.enterprises[0].id;
    }
  },
  async created() {
    await this.get_enterprise();
  }
};
</script>
