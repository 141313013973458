import generalData from "../ModuleFunctionData/generaldata";
import noteItems from "../ModuleFunctionData/noteitems";
import Shipping from "../ModuleFunctionData/shippingcompany";
import store from "../../../../../../store";

function clearForm() {
     
}
function importxml() {
    if (this.inputxml == null) {
        alert("Não existe nenhum arquivo para importar");
    } else {
        this.NotaFiscalInformacoes.GeneralData = generalData(
            this.inputxml,
            store
        );
        this.NotaFiscalInformacoes.NoteItems = noteItems(
            this.inputxml,
            store,
            this.getSelectedEnterprise
        );
        this.NotaFiscalInformacoes.Shipping = Shipping(this.inputxml, store);
    }
}
function verifyContents() {
     this.$store.dispatch("setShowDialogRegisterConfirmNote", true)
}

function openSefazTable(){
    this.togleSefazTable = !this.togleSefazTable
}


export default {
    clearForm,
    importxml,
    verifyContents,
    openSefazTable
}