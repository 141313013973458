<template>
  <div>
    <div class="d-flex d-sm-none">
      <Mobile />
    </div>
    <div class="d-none d-sm-flex d-md-none">
      <Tablet />
    </div>
    <div class="d-none d-md-flex d-lg-flex d-xl-flex">
      <Computer />
    </div>
  </div>
</template>

<script>
import Computer from "./responsive/computer/index.vue";
import Tablet from "./responsive/tablet/index.vue";
import Mobile from "./responsive/mobile/index.vue";
import Methods from "./helpers/methods";
// import Data from "./helpers/data";
export default {
  components: {
    Computer,
    Tablet,
    Mobile
  },

  methods: {
    ...Methods
  },
  data: function() {
    return {
      // ...Data
    };
  },
  created() {
    this.getAllEnterprises();
  }
};
</script>

<style>
</style>