<template>
  <v-row justify="center">
    <v-dialog
      v-model="getShowDialogRegisterNoteNFCEViewCancel"
      max-width="300"
      persistent
    >
      <v-card>
        <v-container>
          <v-row justify="center">
            <v-card-title>Deseja cancelar está nota?</v-card-title>
          </v-row>
          <v-row justify="center">
            <v-card-actions>
              <v-btn
                class="red darken-3 white--text"
                @click="
                  () => {
                    cancel();
                  }
                "
                >Cancelar</v-btn
              >
              <v-btn class="success">Confirmar</v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getShowDialogRegisterNoteNFCEViewCancel"]),
  },
  props: {
    component: Object,
  },
  methods: {
    cancel() {
      this.$store.dispatch("setShowDialogRegisterNoteNFCEViewCancel", false);
    },
  },
};
</script>
