const reducer = (accumulator, currentValue) => accumulator + currentValue;

const calculate_value = function (val, key) {
    let value = val.map((e) => e[key])
    return value.reduce(reducer).toFixed(2)
};

const calculate_InfoNote = function (val) {
   
    if (val.length !== 0) {
        this.form.base_calc_icms_total = calculate_value(val, 'value_base_icms_product')
        this.form.value_icms_total = calculate_value(val, 'value_icms_product')
        this.form.value_base_icms_st_product = calculate_value(val, 'value_base_icms_st_product')
        this.form.value_account_total = calculate_value(val, 'value_accounting_product')
        this.form.base_calc_ipi_total = calculate_value(val, 'value_base_ipi_product')
        this.form.value_ipi_total = calculate_value(val, 'value_ipi_product')
        this.form.value_ipi_outher_total = calculate_value(val, 'value_ipi_outher_product')
        this.form.value_ipi_free_total = calculate_value(val, 'value_ipi_free_product')
        this.form.value_icms_free_total = calculate_value(val, 'value_free_icms_product')
        this.form.value_icms_outher_product = parseFloat(calculate_value(val, 'value_icms_outher_product'))
        this.form.value_base_pis_total = parseFloat(calculate_value(val, 'value_base_pis_product'))
        this.form.value_pis_total = parseFloat(calculate_value(val, 'value_pis_product'))
        this.form.value_base_confis_total = parseFloat(calculate_value(val, 'value_base_confis_product'))
        this.form.value_confis_total = parseFloat(calculate_value(val, 'value_confis_product'))
        this.form.value_base_pis_st_total = parseFloat(calculate_value(val, 'value_base_pis_st_product'))
        this.form.value_pis_st_total = parseFloat(calculate_value(val, 'value_pis_st_product'))
        this.form.value_base_cofins_st_total = parseFloat(calculate_value(val, 'value_base_cofins_st_product'))
        this.form.value_cofins_st_total = parseFloat(calculate_value(val, 'value_cofins_st_product'))
        this.form.value_other_pis_total = parseFloat(calculate_value(val, 'value_cofins_st_product'))

    } else {
        this.form.value_icms_total = 0;
        this.form.value_base_icms_st_product = 0;
        this.form.value_account_total = 0;
        this.form.base_calc_ipi_total = 0;
        this.form.value_ipi_total = 0;
        this.form.value_ipi_outher_total = 0;
        this.form.value_ipi_free_total = 0;
        this.form.value_icms_free_total = 0;
        this.form.value_icms_outher_product = 0;

        this.form.value_base_pis_total = 0;
        this.form.value_pis_total = 0;
        this.form.value_base_confis_total = 0;
        this.form.value_confis_total = 0;
        this.form.value_base_pis_st_total = 0;
        this.form.value_pis_st_total = 0;
        this.form.value_base_cofins_st_total = 0;
        this.form.value_cofins_st_total = 0;
        this.form.value_other_pis_total = 0;
    }

};

const subtraction = function () {
    let value = parseFloat(this.form.value_discount);
    this.value_products(this.getRevenusItens);
    if (isNaN(value) === false) {
        this.form.value_total_note -= value;
    }
};

const some = function () {

    let old;
    let oldIcms;
    let value = parseFloat(this.form.value_freigth);

    if (this.getRevenusItens.length === 0) {
        old = 0;
        oldIcms = 0;
    } else {
        this.baseCalcIcms(this.getRevenusItens);
        this.calculate_InfoNote(this.getRevenusItens);
        old = parseFloat(this.form.base_calc_icms_total);
        oldIcms = parseFloat(this.form.value_icms_total);
    }

    if (isNaN(old) === true) {
        old = 0;
    }
    if (isNaN(oldIcms) === true) {
        oldIcms = 0;
    }
    if (this.form.type_freith === "CIF") {
        if (isNaN(value) === true) {
            this.baseCalcIcms(this.getRevenusItens);
            this.calculate_InfoNote(this.getRevenusItens);
        } else {
            let aliquot = 17
            this.form.base_calc_icms_total = old + value;
            this.form.value_icms_total =
                oldIcms + parseFloat((this.form.base_calc_icms_total / 100) * aliquot);
        }
    }
};

const baseCalcIcms = function (val) {
    if (val.length !== 0) {
        let base_icms_product = val.map((e) => e.value_base_icms_product);
        this.form.base_calc_icms_total = base_icms_product
            .reduce(reducer)
            .toFixed(2);
    } else {
        this.form.base_calc_icms_total = 0;
    }
};

const value_products = function (val) {
    if (val.length !== 0) {
        let value_products = val.map((e) => e.value_products);
        this.form.value_total_products = value_products
            .reduce(reducer)
            .toFixed(2);
        this.form.value_total_note = this.form.value_total_products;
    } else {
        this.form.value_total_products = 0;
        this.form.value_total_note = 0;
    }
};

module.exports = {
    calculate_InfoNote,
    subtraction,
    some,
    baseCalcIcms,
    value_products,
}

