<template>
  <Container>
    <v-card elevation="5">
      <CardTitle :component="this" />
      <ImportXML />
      <LoadScreen />
    </v-card>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/note_entry_xml/CardTitle";
import Table from "../components/note_entry_xml/Table";
import AddDialog from "../components/note_entry_xml/AddDialogImportXML";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
import ImportXML from "../components/note_entry_xml/AddDialogImportXML/Tabs/importXML/index.vue"
export default {
  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    LoadScreen,
    ImportXML
  },
  data() {
    return {
      addDialogImportXML: false,
      headers: [
        { text: "Status", value: "status" },
        { text: "Data de Emissão", value: "date_of_issue" },
        { text: "Data de Entrada", value: "entry_date" },
        {
          text: "Fornecedor",
          value: "provider.people.legalpeople.fantasyName"
        },
        { text: "Numero Nota", value: "number_grade" },
        { text: "Série", value: "series" },
        { text: "Valor dos Produtos", value: "value_total_products" },
        { text: "Valor da Nota", value: "value_total_note" },
        { text: "BC ICMS", value: "base_calc_icms_total" },
        { text: "Valor ICMS", value: "value_icms_total" },
        { text: "Outros Valores", value: "value_untaxed_tax_total" },
        { text: "Valor base de Substituição", value: "base_calc_susbt_total" },
        {
          text: "Valor de ICMS Substituição",
          value: "value_icms_substituicao_total"
        },
        { text: "Ações", value: "actions" }
      ]
    };
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterImportXml"])
  },
  methods: {
    // callAddDialog() {
    //   this.addDialogImportXML = !this.addDialogImportXML;
    // }
  }
};
</script>

<style>

</style>