import axios from "axios"
import apifiscal from "../apifiscal"
import { GET_ALL_TAX_CUPOM, ITEM_TO_CANCEL, CANCEL_TAX_CUPOM, SET_CNPJ, GET_FILTER_TAX_CUPOM, REPRINT_NFCE_WITH_KEY, GET_CLOSE_CASHIER_REPORT, UPDATE_TO_CANCEL, CANCEL_AFTER_DEADLINE, CHANGE_FORM_OF_PAYMENT } from "./actions/taxCupom"
const { SPLICE_ERRO } = require("./actions/alert_api");

const TaxCupom = {
    state: {
        listOfTaxCupom: Array(),
        itemToCancel: Object(),
        taxCupom: Object(),
        cnpj: String(),
        reprintnfce : Object(),
        closeCashierReport: Object(),
        noteUpdatedCancel: Object(),
        noteCancelAfterDeadline: Object(),
        changeFormOfPayment: Object(),
    },
    getters: {
        getListTaxCupom: function (state) {
            return state.listOfTaxCupom
        },
        getItemToCancel: function (state) {
            return state.itemToCancel
        },
        getCancelTaxCupom: function (state) {
            return state.taxCupom
        },
        getcnpj: function (state) {
            return state.cnpj
        },
        rePrintNfce: (state)=> ({
            ...state.reprintnfce ,
          }),
    },
    mutations: {
        GET_ALL_TAX_CUPOM: function (state, payload) {
            state.listOfTaxCupom = payload
        },
        GET_FILTER_TAX_CUPOM: function (state, payload) {
            state.listOfTaxCupom = payload
        },
        ITEM_TO_CANCEL: function (state, payload) {
            state.itemToCancel = payload
        },
        CANCEL_TAX_CUPOM: function (state, payload) {
            state.taxCupom = payload
        },
        SET_CNPJ: function (state, payload) {
            state.cnpj = payload
        },
        REPRINT_NFCE_WITH_KEY :(state, payload)=> {
            state.reprintnfce = payload
        }, 
        GET_CLOSE_CASHIER_REPORT: function (state, payload) {
          state.closeCashierReport = payload
        },
        UPDATE_TO_CANCEL: function (state, payload) {
          state.noteUpdatedCancel = payload
        },
        CANCEL_AFTER_DEADLINE: function (state, payload) {
          state.noteCancelAfterDeadline = payload
        },
        CHANGE_FORM_OF_PAYMENT: function (state, payload) {
          state.changeFormOfPayment = payload
        },
        
    },
    actions: {

        CANCEL_TAX_CUPOM: async function (context, payload) {
        try {
            let token=localStorage.getItem("token_fiscal");
            await apifiscal.post("/noteissuenfce/cancelnfce", payload, {headers: {'Authorization': 'Bearer '+ token,}}).then((response) => {
                context.commit(CANCEL_TAX_CUPOM, response.data);
            })
          } catch (error) {
            //console.log(error)
          }
    
        },
        GET_ALL_TAX_CUPOM: function (context, payload) {
                axios.post("sales/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                    context.commit(GET_ALL_TAX_CUPOM, response.data[0])
                })
        },
        GET_FILTER_TAX_CUPOM({
          commit
        }, payload) {
          axios.post("sales/autocomplete", {
              serach: payload.search,
              limit: payload.limit,
              page: payload.page,
              paginate: payload.paginate
            
          }).then((response) => {
            commit(GET_FILTER_TAX_CUPOM, response.data);
          })
        },
        ITEM_TO_CANCEL: function (context, payload) {
            context.commit(ITEM_TO_CANCEL, payload)
        },
        SET_CNPJ: function (context, payload) {
            context.commit(SET_CNPJ, payload)
        },
        REPRINT_NFCE_WITH_KEY({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
          
            return new Promise((resolve, reject) => {
              apifiscal.post("/noteissuenfce/nfcereprintdanfe", payload, {
                headers: {
                  'Authorization': 'Bearer ' + token,
                }
              }).then((response) => {
                if (response && response.data) {
                  commit('REPRINT_NFCE_WITH_KEY', response.data);
                  resolve(response.data);
                } else {
                  reject(new Error('Invalid response'));
                }
              }).catch((error) => {
                reject(error);
              });
            });
        },

        async GET_CLOSE_CASHIER_REPORT({ commit }, payload) {
          return new Promise((resolve, reject) => {
            axios.post("sales/getcashclosingreport", payload).then((response) => {
              resolve({ response })
              commit(GET_CLOSE_CASHIER_REPORT, response)
            }).catch((error) => {
              commit(SPLICE_ERRO, {
                alert: "Registros do extrato de caixa não encontrados",
                type: 'E'
              })
            })
          })
        },

        async UPDATE_TO_CANCEL({
          commit
        }, payload) {
          try {
            const response = await axios.post("sales/updateSaleStatusToCanceled", payload)
              commit(UPDATE_TO_CANCEL, response);
              return response
          } catch (error) {
            console.log('catch');
            commit(SPLICE_ERRO, {
              alert: "Erro ao atualizar status da nota para cancelada",
              type: 'E'
            })
          }
        },

        async CANCEL_AFTER_DEADLINE({
          commit
        }, payload) {
          try {
            const response = await axios.post("sales/cancelOutOfDeadLineSale", payload)
              commit(CANCEL_AFTER_DEADLINE, response);
              return response
          } catch (error) {
            commit(SPLICE_ERRO, {
              alert: "Erro ao cancelar nota",
              type: 'E'
            })
          }
        },

        async CHANGE_FORM_OF_PAYMENT({
          commit
        }, payload) {
          try {
            const response = await axios.post("sales/changeFormPayment", payload)
            commit(SPLICE_ERRO, {
              alert: "Forma de pagamento alterada com sucesso",
              type: 'S'
            })
              commit(CHANGE_FORM_OF_PAYMENT, response);
              return response
          } catch (error) {
            commit(SPLICE_ERRO, {
              alert: "Erro ao alterar forma de pagamento",
              type: 'E'
            })
          }
        },
    } 
}

export default TaxCupom