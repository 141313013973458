import axios from "axios";
const {
    ADD_FINANCIAL_MOVEMENTE,
    UPDATE_FINANCIAL_MOVEMENTE,
    GET_ALL_FINANCIAL_MOVEMENTE,
    DESTROY_FINANCIAL_MOVEMENTE,
    ADD_TRANSFER_CASHIER
} = require("./actions/financial_movement");
const {
    SPLICE_ERRO
} = require('./actions/alert_api')

//Importações 

const moduleFinancial_movement = {
    state: () => ({
        financialMovement: Object(),
    }),

    mutations: {
        GET_ALL_FINANCIAL_MOVEMENTE: (state, financialMovement) => {
            state.financialMovement = financialMovement;
        },
        ADD_FINANCIAL_MOVEMENTE: (state, newfinancialMovement) => {
            state.financialMovement = newfinancialMovement[0];
        },
        UPDATE_FINANCIAL_MOVEMENTE(state, updatecashmovement) {
            state.financialMovement.data.Update(updatecashmovement)
        },

        DESTROY_FINANCIAL_MOVEMENTE: (state, id) => {

            let auxState = [...state.financialMovement.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            );
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.financialMovement = [...auxState];

        },

        ADD_TRANSFER_CASHIER(state, payload) {
            state.financialMovement = payload
        }


    },
    actions: {

        GET_ALL_FINANCIAL_MOVEMENTE({
            commit
        },
            payload
        ) {
            axios.post("cashmovement/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }

            ).then((response) => {
                commit(GET_ALL_FINANCIAL_MOVEMENTE, response.data[0]);
            });
        },

        ADD_FINANCIAL_MOVEMENTE({
            commit
        }, payload) {
            axios.post("cashmovement/store", payload).then((response) => {

                commit(ADD_FINANCIAL_MOVEMENTE, response.data, payload);
                commit(SPLICE_ERRO, {
                    alert: "Cadastro realizado com sucesso!"
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: err,
                        type: 'E'
                    }))
                }
            });
        },
        UPDATE_FINANCIAL_MOVEMENTE({
            commit
        }, payload) {
            axios.post("cashmovement/update", payload).then((response) => {
                //console.log("Deu certo na atualizacao", response)
                commit(UPDATE_FINANCIAL_MOVEMENTE, response.data[0]);

                commit(SPLICE_ERRO, {
                    alert: 'Item atualizado com sucesso!'
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: err,
                        type: 'E'
                    }))
                }
            });
        },
        DESTROY_FINANCIAL_MOVEMENTE({ commit }, { id }) {
            axios.post("cashmovement/delete", {
                id
            })
                .then(() => {

                    commit(DESTROY_FINANCIAL_MOVEMENTE, id);
                    commit(SPLICE_ERRO, {
                        alert: "Item deletado com sucesso!"
                    })
                }).catch(r => {

                    commit(SPLICE_ERRO, {
                        alert: r.response.data.sucess.message[0],
                        type: 'E'
                    })
                });
        },

        ADD_TRANSFER_CASHIER(context, payload) {
            //console.log("payload =>", payload)
            try {
                axios.post('transferCashier/createTransferCashier', payload).then((response) => {
                    //console.log("this is my response =>", response)
                    context.commit(ADD_TRANSFER_CASHIER, payload)
                })

            } catch (error) {
                //console.log("this is my error =>", error)
            }
        }


    },

    /**Transfer Cash Register */



    getters: {
        getFinancialMovement: (state) => ({
            ...state.financialMovement
        }),
    },
};
export default moduleFinancial_movement;