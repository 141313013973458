import toxml from "xml-js";
import { SET_GENERAL_DATA } from "../../../../../../store/actions/import_xml";

function trimString(data) {
  if (data.length > 0) {
    return data.trim();
  } else {
    return data;
  }
}

function generalData(inputXML, store) {
  var GENERAL_DATA = {
    Id: String(),
    provider: {
      address: Object(),
      contact: Object(),
    },
    dhEmi: String(),
    dhSaiEnt: String(),
    cNF: String(),
    serie: String(),
    infCpl: String(),
  };

  var readFile = new FileReader();
  readFile.readAsText(inputXML);

  readFile.onload = () => {
    var data = readFile.result;

    var result = toxml.xml2json(data, { compact: true, spaces: 1 });
    var resultJSON = JSON.parse(result);

    GENERAL_DATA.Id = trimString(resultJSON.nfeProc.NFe.infNFe._attributes.Id);
    // GENERAL_DATA.xNome = trimString(resultJSON.nfeProc.NFe.infNFe.emit.xNome._text);
    GENERAL_DATA.provider.providerCNPJ = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.CNPJ._text
    );
    GENERAL_DATA.provider.providerName = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.xNome._text
    );
    GENERAL_DATA.provider.fantasyName = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.xFant._text
    );
    GENERAL_DATA.provider.contact.fone = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.fone._text
    );
    GENERAL_DATA.provider.address.street = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.xLgr._text
    );
    GENERAL_DATA.provider.address.complement = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.xCpl._text
    );
    GENERAL_DATA.provider.address.neighborhood = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.xBairro._text
    );
    GENERAL_DATA.provider.address.city = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.xMun._text
    );
    GENERAL_DATA.provider.address.state = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.UF._text
    );
    GENERAL_DATA.provider.address.cep = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.CEP._text
    );
    GENERAL_DATA.provider.address.country = trimString(
      resultJSON.nfeProc.NFe.infNFe.emit.enderEmit.xPais._text
    );
    //
    var dataEmissao = new Date(
      trimString(resultJSON.nfeProc.NFe.infNFe.ide.dhEmi._text)
    );
    var dataEntrada = new Date(
      trimString(resultJSON.nfeProc.NFe.infNFe.ide.dhEmi._text)
    );

    GENERAL_DATA.dhEmi =
      dataEmissao.getDate() +
      "/" +
      (dataEmissao.getMonth() + 1) +
      "/" +
      dataEmissao.getFullYear();

    GENERAL_DATA.dhSaiEnt =
      dataEntrada.getDate() +
      "/" +
      (dataEntrada.getMonth() + 1) +
      "/" +
      dataEntrada.getFullYear();

    GENERAL_DATA.cNF = trimString(resultJSON.nfeProc.NFe.infNFe.ide.cNF._text);
    GENERAL_DATA.serie = trimString(
      resultJSON.nfeProc.NFe.infNFe.ide.serie._text
    );
    

    var enterprise_id = store.getters.getSelectedEnterprise.id;
    store.dispatch(SET_GENERAL_DATA, {
      generalData: GENERAL_DATA,
      enterprise_id: enterprise_id,
    });
  };
  // return GENERAL_DATA
}

export default generalData;
