<template>
  <div>
    <v-data-table
      :headers="Headers"
      :items="items"      
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="component.call_dialog(item)"
            >
              mdi-eye
            </v-icon>
          </template>
            <span>Visualizar Nota</span>
          </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="green darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="changeFormOfPayment(item)"
            >
              mdi-cash-edit
            </v-icon>
          </template>
            <span>Alterar Forma de Pagamento</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="calcelDialog(item)"
            >
              mdi-cancel
            </v-icon>
          </template>
          <span>Cancelar Nota</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="activator_reprint(item)"
              style="margin-right: 10px;"
            >
              mdi-printer
            </v-icon>
          </template>
          <span>Imprimir Nota</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="calcelAfterDeadline(item)"
            >
            mdi-timer-cancel-outline
            </v-icon>
          </template>
          <span>Cancelamento Fora do Prazo</span>
        </v-tooltip>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-pagination
        class="mb-6"
        v-model="current"
        :length="total"
        color="success"
        :total-visible="10"
        ></v-pagination>
        <CancelViewNFCE />
        <CancelNote />
        <TaxOperationTypeDialog v-if="taxOperationTypeErrorDialog" :component="this"/>
        <CancelAfterDeadlineDialog v-if="dialogCancel" :component="this"/>
        <ChangeFormOfPaymentDialog v-if="dialogChangeFormOfPayment" :component="this"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ITEM_TO_CANCEL, GET_ALL_TAX_CUPOM, REPRINT_NFCE_WITH_KEY } from "../../../store/actions/taxCupom";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../store/actions/exchange_control";
import CancelViewNFCE from "../../notenfce/AddDialog/View_cancel/index.vue";
import CancelNote from "../cancel_cupom/index.vue";
import TaxOperationTypeDialog from "../AddDialog/CancelAfterDeadline/ErrorDialog/index.vue"
import CancelAfterDeadlineDialog from "../AddDialog/CancelAfterDeadline/CancelDialog/index.vue"
import ChangeFormOfPaymentDialog from "../AddDialog/ChangeFormOfPayment/index.vue"
import Headers from "./helpers/header";
export default {
  components: {
    CancelViewNFCE,
    CancelNote,
    TaxOperationTypeDialog,
    CancelAfterDeadlineDialog,
    ChangeFormOfPaymentDialog,
  },
  props: {
    component: Object(),
  },
  data() {
    return {
      itemSelected: Object(),
      showCancel: false,
      Headers: Headers,
      results: Array(),
      total: Number(),
      current: Number(),
      Content: undefined,
      items: Array(),
      nfcePrint: Object(),

      taxOperationTypeValidation: false,
      taxOperationTypeErrorDialog: false,
      dialogCancel: false,
      cancelItemData: Object(),
      taxOperationToCancel: Object(),

      changeFormOfPaymentData: Object(),
      dialogChangeFormOfPayment: false,
    };
  },
  methods: {
    getAllTaxCupon: function (page = 1) {
      var payload = {
        page: page,
        limit: 10,
        paginate: true,
      };
      this.$store.dispatch(GET_ALL_TAX_CUPOM, payload);
    },
    calcelDialog(item) {
      this.$store.dispatch(ITEM_TO_CANCEL, item);
      this.$store.dispatch("setShowDialogRegisterCancelNote", true);
    },
    async activator_reprint(nfce) {
      const cnpj = this.getEnterprises[0].cnpj.replace(/\D/g, "");
      let payload = {
        cnpjEmitter: cnpj,
        keyNfe: nfce.access_key,
        modelNf: "65",
      };
      if (nfce.status === "Autorizada") {
        await this.$store.dispatch(REPRINT_NFCE_WITH_KEY, payload).then((data)=>{
          this.nfcePrint =  data.nfce
          window.open(this.nfcePrint);
        });
  
      } else {
        alert("Essa nota não pode ser impressa");
      }
    },

    calcelAfterDeadline(item) {
      if(this.taxOperationTypeValidation) {
        this.cancelItemData = item
        this.dialogCancel = true
      } else {
        this.taxOperationTypeErrorDialog = true
      }
    },

    changeFormOfPayment(item) {
      console.log("item", item);
      this.changeFormOfPaymentData = item
      this.dialogChangeFormOfPayment = true
    },
    
    getAllTaxOperationType() {
      const payload = {
        paginate: false,
        page: null,
        limit: null
      }
      this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, payload).then((response) => {
        const taxOperationTypes = response[0]
        for(let i=0; i<taxOperationTypes.length; i++) {
          if(taxOperationTypes[i].code) {
            this.taxOperationTypeValidation = true
            this.taxOperationToCancel = taxOperationTypes[i]
          }
        }
      });
    }
  },
  created() {
    this.getAllTaxCupon()
  },
  mounted() {
    this.getAllTaxOperationType()
  },
  computed: {
    ...mapGetters(["getListTaxCupom"]),
    ...mapGetters(["getEnterprises"]),
  },
  watch: {
    current: function(val){
      this.getAllTaxCupon(val)
    },
    getListTaxCupom: function (val) {
      for(let i = 0; i<val.data.length; i++) {
        if(val.data[i].cliente.type_people == "PhysicalPeople") {
          val.data[i].cliente.people.name = String()
          val.data[i].cliente.people.name = val.data[i].cliente.people.physicalpeople.name
        } else if(val.data[i].cliente.type_people == "LegalPeople") {
          val.data[i].cliente.people.name = String()
          val.data[i].cliente.people.name = val.data[i].cliente.people.legalpeople.fantasyName
        }
      }
      this.items = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    rePrintNfce: function (val) {
      this.nfcePrint = val.nfce;
    },
  },
};
</script>

<style>
</style>

//:headers="component.headers"
//:items="results"