<template>
  <v-card class="ma-5 pa-5 d-flex align-center" elevation="5" >
    <Home />
  </v-card>
</template>

<script>
import Home from "../components/home/main";
export default {
  components: {
    Home
  }
};
</script>

<style>
</style>