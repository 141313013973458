<template>
  <div>
    <v-card class="ma-5 pa-2">
      <Filters :component="this"/>
    </v-card>
    <v-card class="ma-5 pa-2">
      <Charts :component="this"/>
    </v-card>
  </div>
</template>

<script>
import Charts from "../components/DashboardCharts/Chart/index";
import Filters from "../components/DashboardCharts/Filter/index.vue";
export default {
  components: { Filters, Charts },
  props: {},
  computed: {},
  data: () => ({
    salesDashboard: {},
    value_total_sales: 0,
    value_discount: 0,
    average_ticket: 0,
    value_exchange: 0,
  }),
  methods: {},
  watch: {},
  created() {},
};
</script>

<style>
</style>