<template>
  <v-col>
    <v-form v-model="component.disableButtonCardTitle">
      <v-row>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-text-field
            :rules="fieldsRules"
            outlined
            v-model="form.startDateOfTheMove"
            label="Data inicial movimentação"
            type="date"
          />
        </v-col>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-text-field
            outlined
            v-model="form.finishDateOfTheMove"
            :rules="fieldsRules"
            label="Data final movimentação"
            type="date"
          />
        </v-col>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-text-field
            outlined
            :rules="fieldsRules"
            v-model="form.booknumber"
            label="Número do Livro"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-text-field
            outlined
            v-model="form.stockclosingdate"
            :rules="fieldsRules"
            label="Data de fechamento de Estoque"
            type="date"
          />
        </v-col>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-text-field
            outlined
            v-model="form.destinationFile"
            :rules="fieldsRules"
            label="Nome do arquivo de destino"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-select
            outlined
            v-model="form.generate_ecf_register"
            :rules="fieldsRules"
            :items="generate_ecf_itens"
            label="Gerar Registro de Ecf"
          />
        </v-col>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-select
            outlined
            v-model="form.printCreditSt"
            :rules="fieldsRules"
            :items="printCredtItens"
            label="Imprimir Crédito de Substituição"
          />
        </v-col>
        <v-col cols="12" lg="" md="" sm="" xl="">
          <v-select
            outlined
            v-model="form.period"
            :items="apurationIpiItens"
            :rules="fieldsRules"
            label="Apuração de Ipi"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import { fieldsRules } from "./helpers/rules";
import { mapGetters } from "vuex";
import {
  apurationIpiItens,
  generate_ecf_itens,
  printCredtItens,
} from "./helpers/itensSelect";
export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters(["getSelectedEnterprise"]),
  },
  data: () => ({
    fieldsRules,
    apurationIpiItens,
    generate_ecf_itens,
    printCredtItens,
    form: {
      enterprise_id: null,
      startDateOfTheMove: String(),
      finishDateOfTheMove: String(),
      booknumber: String(),
      stockclosingdate: String(),
      destinationFile: String(),
      generate_ecf_register: String(),
      printCreditSt: String(),
      period: String(),
    },
  }),
  watch: {
    getSelectedEnterprise: function(val){
      this.form.enterprise_id = val
    }
  },
};
</script>
