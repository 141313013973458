


var Data = {
    togleSefazTable: Boolean(false),
    inputxml: null,
    resetForm: Boolean(),
    validationSteps: Array(),
    validationButton: Boolean(true),
    NotaFiscalInformacoes: {
        GeneralData: Object(),
        NoteItems: Array(),
        Shipping: {
            shipping: {
                modFrete: { _text: "" },
                transporta: {
                    CNPJ: { _text: "" },
                    IE: { _text: "" },
                    UF: { _text: "" },
                    xEnder: { _text: "" },
                    xMun: { _text: "" },
                    xNome: { _text: "" }
                },
                vol: [
                    {
                        esp: { _text: "" },
                        pesoB: { _text: "" },
                        pesoL: { _text: "" },
                        qVol: { _text: "" }
                    }
                ]
            }
        }
    }
}


var ItemsNote = {
    invoice: {

        //Dados Gerais
        tax_operation_types_id: Number(),
        access_key: String(),
        authorization_protocol: String(),
        provider_id: Number(),
        number_grade: Number(),
        series: String(),
        date_of_issue: String(),
        entry_date: String(),

        //Itens da Nota
        gradeItens: Array(),

        //Cabeçalho da Nota
        base_calc_icms_total: Number(),
        value_icms_total: Number(),
        base_calc_susbt_total: Number(),
        value_icms_substituicao_total: Number(),
        base_calc_ipi_total: Number(),
        value_ipi_total: Number(),
        value_ipi_outher_total: Number(),
        value_ipi_free_total: Number(),
        value_total_products: Number(),
        value_discont: Number(),
        type_freigth: String,
        value_freigth: Number(),
        value_security: Number(),
        value_icms_free_total: Number(),
        value_total_note: Number(),
        value_untaxed_tax_total: Number(),
        value_account_total: Number(),
        value_outher_tax_total: Number(),
        value_other_incidental_expenses: Number(),
        value_icms_outher_product: Number(),
        value_base_icms_st_product: Number(),

        //Transportadora
        transporter_id: String(),
        vehicle_plate: String(),
        volume_transported: Number(),
        gross_weigth: Number(),
        net_weight: Number(),
        volume_type: String(),
        outher_expenses_acess: Number(),

        status: String(),
        note: String(),
        account_plans_id: Number(),
        enterprise: Object(),
        enterprise_id: Number(),
    },
}



export {
    Data,
    ItemsNote,
}