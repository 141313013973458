<template>
  <v-row justify="center">
    <v-dialog
      persistent
      :retain-focus="false"
      v-model="getShowDialogRegisterAliquot"
      max-width="600"
    >
      <v-card>
        <v-card-title primary-title>{{ action }} Alíquota</v-card-title>

        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Descrição"
                color="success"
                v-model="description"
                :rules="rules"
                outlined
                :readonly="justVisible"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Aliquota - Valor entre 0 e 100 %."
                color="success"
                type="number"
                v-model="aliquot"
                suffix="%"
                maxlength="5"
                :readonly="justVisible"
                :rules="[
                  () => !!aliquot || 'Campo Obrigatorio',
                  () =>
                    (!!aliquot && aliquot.length >= 1 && aliquot <= 100) ||
                    'Campo tem que ser maior do que 1 e menor que 100',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="closeAliquotDialog()">
                <v-icon class="mr-1">mdi-cancel</v-icon>
                Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { ADD_ALIQUOT, UPDATE_ALIQUOT } from "../../../store/actions/aliquot";
import UiBusinessRules from "../../../UIBusinessRule/Aliquot/AddDialog";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    action: String,
  },
  name: "aliquot-dialog",
  components: {},
  data() {
    return {
      justVisible: Boolean(),
      valid: true,
      id: undefined,
      description: String(),
      aliquot: String(),
      resultAliquot: null,
      dialog_alert: Boolean(),
      title_error: String(),
      listErrors: Array(),
      rules: [
        (description) => !!description || "Campo Obrigatório.",
        (description) =>
          (description && description.length >= 2) || "Mínimo 2 caracteres",
      ],
    };
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterAliquot", "getAuxAliquotToUpdate"]),
  },
  methods: {
    ...UiBusinessRules,
  },

  async created() {
    // this.mountedObject();
  },
  watch: {
    getAuxAliquotToUpdate: function (val) {
      
      if (val.justVisible) {
        this.justVisible = val.justVisible;
        this.description = val.item.description;
        this.aliquot = val.item.aliquot;
      } else {
        this.aliquot = this.getAuxAliquotToUpdate.aliquot;
        this.description = this.getAuxAliquotToUpdate.description;
        this.id = this.getAuxAliquotToUpdate.id;
      }
    },
  },
  beforeDestroy() {
    // this.getComponent().objectToUpdate = {};
  },
};
</script>
