<template>
  <div>
    <v-data-table
      :headers="atual.header"
      :search="search"
      :items="atual.getter"
      hide-default-footer
      no-data-text="Nenhum Transportador Cadastrado"
      no-results-text="Nenhum Transportador Encontrado"
    >
      <template v-slot:[`item.actions`]="{ item, index }">
        <v-icon
          color="green darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              visibleItem(item);
            }
          "
          >mdi-eye</v-icon
        >
        <v-icon
          v-if="component.permission.Editar"
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item, index);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          v-if="component.permission.Excluir"
          class="mr-2"
          @click="
            () => {
              delete_item(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- <Edit :component="this" ref="edit" /> -->
    <Delete :component="this" />
    <div>
      <v-divider class="mt-6"></v-divider>
      <v-pagination
        v-model="atualCurrent"
        :length="atualTotal"
        color="success"
        :total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
// import Edit from "./Edit_Item/";
import Delete from "./Delete_Item/";
import {
  GET_ALL_TRANSPORTER,
  DESTROY_TRANSPORTER,
  ITEM_TO_UPDATE,
  GET_AUTOCOMPLETE_TRANSPORTER,
} from "../../../store/actions/transporters";

import { mapGetters } from "vuex";
import { setMaskCNPJ, insertCnpjMask } from "../../Utils/global_functions/clean_cnpj_cpf";
export default {
  props: { component: Object(), search: String() },
  components: { /*Edit,*/ Delete },
  data: () => ({
    dialog: false,
    dialog_delete: false,
    current: 1,
    headers,
    ObjectToDestroy: Object(),
    pagPhysicalTotal: Number(),
    pagPhysicalCurrent: Number(),
    pagLegalTotal: Number(),
    pagLegalCurrent: Number(),
    atualTotal: Number(),
    atualCurrent: Number(),
    atual: {
      header: Array(),
      getter: Array(),
    },
  }),
  computed: {
    ...mapGetters([
      "getPhisycalTransporter",
      "getLegalTransporter",
      "getIsUpdatedTransporter",
      "getTransporterToAdd",
      "getTransportPhysicalAuto",
      "getTransportLegalAuto",
    ]),
  },

  methods: {
    visibleItem(item) {
      this.$store.dispatch(ITEM_TO_UPDATE, { item: item, justVisible: true });
      this.$store.dispatch("setShowDialogRegisterTransporter", true);
    },
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
      this.headers[isLegal ? type.isLegal : type.isPhisical];

      this.atual.getter = isLegal
        ? this.getLegalTransporter 
        : this.getPhisycalTransporter;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },
    edit_item(item) {
      this.$store.dispatch(ITEM_TO_UPDATE, item);
      this.$store.dispatch("setShowDialogRegisterTransporter", true);
    },
  
    //Estruturar Arrays para Edição.
    contact_edit(item) {
      item.contacts.forEach((e) => {
        this.$refs.edit.contact.id = e.id;
        this.$refs.edit.contact.primaryEmail = e.primaryEmail;
        this.$refs.edit.contact.secundaryEmail = e.secundaryEmail;
        this.$refs.edit.contact.phone = e.phone;
        this.$refs.edit.contact.cellPhone = e.cellPhone;
        this.$refs.edit.contact.phoneReference = e.phoneReference;
      });
    },
    address_edit(item) {
      item.address.forEach((e) => {
        this.$refs.edit.address.id = e.id;
        this.$refs.edit.address.street = e.street;
        this.$refs.edit.address.number = e.number;
        this.$refs.edit.address.district = e.district;
        this.$refs.edit.address.complement = e.complement;
        this.$refs.edit.address.city = e.city.name;
        this.$refs.edit.address.state = e.state;
        this.$refs.edit.address.cep = e.cep;
        this.$refs.edit.address.city_id = e.city_id;
      });
    },
    bank_edit(item) {
      item.banks.forEach((e) => {
        this.$refs.edit.banks.id = e.id;
        this.$refs.edit.banks.bank = e.bank;
        this.$refs.edit.banks.agency = e.agency;
        this.$refs.edit.banks.count = e.count;
        this.$refs.edit.banks.manager = e.manager;
      });
    },
    delete_item(item) {
      this.dialog_delete = !this.dialog_delete;
      this.ObjectToDestroy = { ...item };
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_TRANSPORTER, this.ObjectToDestroy);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_delete = !this.dialog_delete;
      }
    },
    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_TRANSPORTER, {
        page: page,
        limit: 10,
        paginate: true,
        type_people: "PhysicalPeople",
      });
    },

    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_TRANSPORTER, {
        page: page,
        limit: 10,
        paginate: true,
        type_people: "LegalPeople",
      });
    },
    
    async AutocompleteTransportPhysical(page = 1) {
      if (this.search == "") {
        this.GetAllPhysical();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_TRANSPORTER, {
          type_people: "PhysicalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.search,
          page: page,
          limit: null,
          paginate: false,
        });
      }
    },

    async AutocompleteTransportLegal(page = 1) {
      if (this.component.search == "") {
        this.GetAllLegal();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_TRANSPORTER, {
          type_people: "LegalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.component.search,
          page: page,
          limit: null,
          paginate: false,
        });
      }
    },
    PagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },
  },

  created() {
    this.GetAllPhysical();
    this.GetAllLegal();
  },
  watch: {
    getIsUpdatedTransporter: function (value) {
      if (value) {
        this.GetAllPhysical();
        this.GetAllLegal();
      }
      this.$store.commit("SET_IS_UPDATED", false);
    },

    getPhisycalTransporter: function (val) {
      if (val[0].page.last_page === undefined) {
        this.pagPhysicalCurrent = 1;
      } else {
        this.pagPhysicalTotal = val[0].page.last_page;
        this.pagPhysicalCurrent = val[0].page.current_page;
        this.PagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
      }
    },

    getLegalTransporter: function (val) {
      insertCnpjMask(val);
      this.pagLegalTotal = val[0].page.last_page;
      this.pagLegalCurrent = val[0].page.current_page;
    },

    getTransporterToAdd: function (val) {
      val.legalpeople.cnpj = setMaskCNPJ(val.legalpeople.cnpj);
      this.atual.getter.push(val);
    },
    
    atualCurrent: function (val) {
      if (this.component.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },
    search: function () {
      this.AutocompleteTransportPhysical();
      this.AutocompleteTransportLegal();
    },
  },
  mounted() {
    this.isLegal();

    this.$watch(
      () => this.component.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>