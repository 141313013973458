<template>
  <v-container style="margin-bottom: 50px">
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-file-input v-model="inputxml" outlined label="Importar XML" dense />
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          class="success"
          @click="importxml()"
          >Importar</v-btn
        >
        <v-btn  @click="openSefazTable" class="ml-5 success" >Tabela Sefaz</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-tabs centered>
      <v-tab href="#tab-generaldata">Dados Gerais</v-tab>
      <v-tab href="#tab-noteitem">Itens da Nota</v-tab>
      <v-tab href="#tab-shippingcompany">Transportadora</v-tab>
      <v-tab href="#tab-noteinformation">Informações da nota</v-tab>
      <v-tab-item value="tab-generaldata">
        <GeneralData
          :component="this"
          v-if="getShowDialogRegisterImportXml"
          :NotaFiscalInformacoes="NotaFiscalInformacoes"
        />
      </v-tab-item>
      <v-tab-item value="tab-noteitem">
        <NoteItems :component="this" v-if="getShowDialogRegisterImportXml" />
      </v-tab-item>
      <v-tab-item value="tab-shippingcompany">
        <ShippingCompany
          :component="this"
          v-if="getShowDialogRegisterImportXml"
          :NotaFiscalInformacoes="NotaFiscalInformacoes"
        />
      </v-tab-item>
      <v-tab-item value="tab-noteinformation">
        <NoteInformations
          :component="this"
          v-if="getShowDialogRegisterImportXml"
        />
      </v-tab-item>
    </v-tabs>
    <ConfirmNote v-if="getShowDialogRegisterConfirmNote" />
    <LoadLayer />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="success"
        :disabled="validationButton"
        @click="verifyContents"
        >Fatura</v-btn
      >
    </v-card-actions>
    <TableSefaz :fatherComponent="this" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import TableSefaz from "./table_sefaz/index.vue"
import GeneralData from "./Tabs/GeneralData";
import NoteItems from "./Tabs/NoteItens";
import ShippingCompany from "./Tabs/ShippingCompany";
import NoteInformations from "./Tabs/NoteInformation";
import HelperList from "./LocalComponents/help_list.vue";
import ConfirmNote from "./ConfirmNote/index.vue";
import Methods from "./helpers/methods";
import { Data, ItemsNote } from "./helpers/data";
import mapGettersArray from "./helpers/mapGetters";
import watchDependecy from "./helpers/watch";
import BusinessRules from "./business_rule/index";
import LoadLayer from "../../../../load";

export default {
  components: {
    TableSefaz,
    GeneralData,
    NoteItems,
    ShippingCompany,
    NoteInformations,
    HelperList,
    ConfirmNote,
    LoadLayer,
  },
  props: {
    component: Object(),
  },
  data() {
    return {
      ...Data,
      ...ItemsNote,
    };
  },
  computed: {
    ...mapGetters(mapGettersArray),
  },
  beforeDestroy() {},
  methods: {
    ...Methods,
    ...BusinessRules,
  },
  watch: {
    ...watchDependecy,
  },

  created() {
    this.validationSteps = Array();
  },
};
</script>

<style></style>

