<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
        <v-switch
          v-model="isFormalPeople"
          label="Pessoa Jurídica"
          color="primary"
        >
        </v-switch>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :component="this" :search="search" />
      <AddDialog
        v-if="getShowDialogRegisterProvider"
        :showDialog="showDialog"
        :component="this"
        :Id="enterpriseId"
      />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>
<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/provider/CardTitle/";
import AddDialog from "../components/provider/AddDialog/index";
import loadScreen from "../components/load/";
import AlertAPI from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    showDialog: Boolean(),
    dialog: false,
    search: "",
    isFormalPeople: false,
    enterpriseId: Number(),
    permission: Object(),
  }),

  components: {
    AddDialog,
    Container,
    loadScreen,
    CardTitle,
    AlertAPI,
    Table: () => import("./../components/provider/Table/"),
  },
  computed: {
    ...mapGetters(["getCadastrosLinks", "getShowDialogRegisterProvider"]),
  },
  watch: {
    getShowDialogRegisterProvider: function () {
      if (this.getShowDialogRegisterProvider) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    enterpriseId: function (value) {
      this.$store.dispatch(SELECTED_ENTERPRISE, value);
    },
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>

<style scoped></style>
