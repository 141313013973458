/* eslint-disable no-unused-vars */
import axios from "axios";
const {
    GET_ALL_MOVESTOCK,
    ADD_ALL_MOVESTOCK,
    DESTROY_ALL_MOVESTOCK,
    UPDATE_ALL_MOVESTOCK,
    GET_FILTER_MOVESTOCK,
} = require("./actions/move_stock");
const {
    SPLICE_ERRO
} = require("./actions/alert_api");

const moduleSubGroup = {
    state: () => ({
        move_stock: Object,
    }),

    mutations: {
        GET_ALL_MOVESTOCK: (state, move_stocks) => {
            // move_stocks.data.forEach(e => {
            //     e.date_movement = e.date_movement.split('-').reverse().join('/')
            // })
            state.move_stock = move_stocks;
        },
        GET_FILTER_MOVESTOCK: (state, move_stocks) => {
            state.move_stock = move_stocks;
        },

        ADD_ALL_MOVESTOCK: (state, newItem) => {
            state.move_stock = newItem;
        },

        UPDATE_ALL_MOVESTOCK: (state, payload) => {
            //state.move_stock.data.Update(payload)
            let auxState = state.move_stock
            auxState.data.forEach((e) => {
                if (e.id === payload.id) {
                    e = payload
                }
            })
            state.move_stock = auxState.data
        },

        DESTROY_ALL_MOVESTOCK: (state, id) => {
            let auxState = [...state.move_stock.data]
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            )
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.move_stock = [...auxState];
        }

    },

    actions: {
        GET_ALL_MOVESTOCK({
            commit
        },
            payload
        ) {
            axios.post('/movimentacaoestoque/index', { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then(r => {
                
                commit(GET_ALL_MOVESTOCK, r.data[0])
            });
        },

        GET_FILTER_MOVESTOCK({
            commit
        },
            payload
        ) {
            axios.post('/movimentacaoestoque/autocomplete', { search: payload.search, page: payload.page, limit: payload.limit, paginate: payload.paginate }).then(r => {
                
                commit(GET_FILTER_MOVESTOCK, r.data[0])
            });
        },

        ADD_ALL_MOVESTOCK({
            commit
        }, payload) {
            axios.post('/movimentacaoestoque/store', payload).then(r => {
                commit(SPLICE_ERRO, {
                    alert: r.data.message,
                });
                commit(ADD_ALL_MOVESTOCK, r.data)

            }).catch((r) => {
                r.response.message && commit(SPLICE_ERRO, {
                    alert: r.response.message,
                    type: "E",
                })
                let erros = r.response.data.errors;
                for (let erro in erros) {
                    erros[erro].forEach((err) =>
                        commit(SPLICE_ERRO, {
                            alert: erro + ' : ' + err,
                            type: "E",
                        })
                    );
                }
            });
        },

        UPDATE_ALL_MOVESTOCK({
            commit
        }, payload) {
            axios.post('/movimentacaoestoque/update', payload).then(r => {
                commit(UPDATE_ALL_MOVESTOCK, r.data[0])
                commit(SPLICE_ERRO, {
                    alert: r.data.message,
                });
            }).catch((r) => {
                r.response.message && commit(SPLICE_ERRO, {
                    alert: r.response.message,
                    type: "E",
                })
                let erros = r.response.data.errors;
                for (let erro in erros) {
                    erros[erro].forEach((err) =>
                        commit(SPLICE_ERRO, {
                            alert: erro + ' : ' + err,
                            type: "E",
                        })
                    );
                }
            });
        },

        DESTROY_ALL_MOVESTOCK({ commit }, payload) {
            axios.post("movimentacaoestoque/delete", {
                id: payload.id
            }).then((response) => {
                commit(DESTROY_ALL_MOVESTOCK, payload.id);
                commit(SPLICE_ERRO, {
                    alert: response.data.errors.message[0],
                    type: "S"
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach((err) =>
                        commit(SPLICE_ERRO, {
                            alert: erro + ' : ' + err,
                            type: "E",
                        })
                    );
                }
            });
        }
    },

    getters: {
        getMoveStock: (state) => ({
            ...state.move_stock
        }),
    },
};

export default moduleSubGroup;