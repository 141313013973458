<template>
    <v-dialog max-width="500px" v-model="component.selectTableShow">
        <v-card>
            <div class="container-header-select-table">
                <p class="title-select-table">{{ title }}</p>
                <v-text-field hint="Pesquisar" append-icon="mdi-magnify" outlined v-model="component.searchInputNcm" @keyup.enter="setAutocomplete()"/>
            </div>
            <v-data-table @click:row="component.rowClickSelectTable" hide-default-footer :items="component.ncmItems" :headers="component.ncmHeaders">
            </v-data-table>
            <v-pagination v-model="component.currentPage" :length="component.lastPage" />
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        component: Object(),
        title: String(),
    },
    data: function () {
        return {
        }
    },
    methods: {
        setAutocomplete() {
            if(this.component.searchInputNcm.length >= 2) {
                this.component.searchNcm = this.component.searchInputNcm
            }
        },
    },
}
</script>

<style lang="css" scoped>
.title-select-table {
    text-align: start;
}

.container-header-select-table {
    padding: 5%;
}

.type-person-select-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}

.title-type-person-switch {
    font-size: 15px;
    margin-top: 10px;
}</style> >

.title-type-person-switch {
    font-size: 15px;
    margin-top: 10px;
}
</style> >