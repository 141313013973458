<template>
    <div id="frame">
      <div class="divTitle">
        <v-card-title>{{ title }}</v-card-title>
      </div>
      <div class="form">
        <div>
          <v-select
            item-text="fantasyName"
            item-value="id"
            v-model="selectedEnterprise"
            :items="enterprises"
            class="mr-1"
            outlined
            dense
          />
        </div>
        <div class="button" >
          <v-btn @click="showDialog" class="success ml-1 sizeButton ">Adicionar</v-btn>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    GET_ALL_ENTERPRISE,
    SELECTED_ENTERPRISE,
  } from "../../../store/actions/enterprise";
  import { mapGetters } from "vuex";
  export default {
    props: {
      title: String(),
      showDialogState: String(),
    },
    data: () => ({
      enterprises: Array(),
      selectedEnterprise: Number(),
    }),
    methods: {
      showDialog() {
        this.$store.dispatch(this.showDialogState, true);
      },
      getAllResourses(page = 1) {
        try {
          var payload = {
            page: page,
            limit: 10,
            paginate: true,
          };
          this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
        } catch (error) {
          throw new Error(error);
        }
      },
    },
    created() {
      this.getAllResourses();
    },
    computed: {
      ...mapGetters(["getEnterprises"]),
    },
    watch: {
      getEnterprises: function (val) {
        this.enterprises = [...val.data];
        this.selectedEnterprise = this.enterprises[0].id;
      },
      selectedEnterprise: function (val) {
        this.$store.dispatch(SELECTED_ENTERPRISE, val);
      },
    },
  };
  </script>
  
  <style>
  @import "index.css";
  </style>
  