var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","retain-focus":false,"max-width":"600"},model:{value:(_vm.getShowDialogRegisterAliquot),callback:function ($$v) {_vm.getShowDialogRegisterAliquot=$$v},expression:"getShowDialogRegisterAliquot"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.action)+" Alíquota")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Descrição","color":"success","rules":_vm.rules,"outlined":"","readonly":_vm.justVisible},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Aliquota - Valor entre 0 e 100 %.","color":"success","type":"number","suffix":"%","maxlength":"5","readonly":_vm.justVisible,"rules":[
                () => !!_vm.aliquot || 'Campo Obrigatorio',
                () =>
                  (!!_vm.aliquot && _vm.aliquot.length >= 1 && _vm.aliquot <= 100) ||
                  'Campo tem que ser maior do que 1 e menor que 100',
              ],"outlined":""},model:{value:(_vm.aliquot),callback:function ($$v) {_vm.aliquot=$$v},expression:"aliquot"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":function($event){return _vm.closeAliquotDialog()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar ")],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }