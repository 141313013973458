<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        />
      </v-card-text>
      <!--Table-->
      <Table
        :change_addDialog="change_addDialog"
        :headers="headers"
        :component="this"
        :destroy_object="destroy_object"
      />
      <AddDialogPDV
        v-if="addDialog"
        :component="this"
        :addDialog="addDialog"
        :action="action"
        
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
//Components

import Container from "../components/Utils/Container/Container.vue";
import CardTitle from "../components/Pdv/CardTitle/index.vue";
import Table from "../components/Pdv/Table/index.vue";
import AddDialogPDV from "../components/Pdv/AddDialog/index.vue";
import loadScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";

// Actions

import { DESTROY_PDV, GET_ALL_PDV, GET_FILTER_PDV } from "../store/actions/pdv";

export default {
  data() {
    return {
      empty_or_full_items: Object(),
      permission: Object(),
      enterprise_selected: String(),
      addDialog: false,
      action: String(),
      addDialogPdv: Boolean(),
      objectToUpdate: Object(),
      filter: String(),
      clear_items: Number(),
      headers: [
        { text: "Status", value: "status" },
        { text: "Código", value: "code" },
        { text: "Descrição", value: "description" },
        { text: "Impressora", value: "print" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  components: {
    Container,
    CardTitle,
    Table,
    AddDialogPDV,
    loadScreen,
    AlertApi,
  },

  methods: {
    async GetAll(page = 1) {
      const value = {
        page: page,
        limit: 10,
        paginate: true,
      };

      await this.$store.dispatch(GET_ALL_PDV, value);
    },

    async change_addDialog(item) {
      this.action = "Atualizar";
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
      console.log(item);
    },

    async pdv_filter(page = 1) {
      if (!this.filter === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_PDV, value);
      }
    },

    async destroy_object(item) {
      console.log(item)
      const pdv = {
        id: item.id,
      };

      try {
        await this.$store.dispatch(DESTROY_PDV, pdv);
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },
  },
  watch: {
    filter: function () {
      this.pdv_filter();
    },
  },

  async created() {
    await this.GetAll();
  },
};
</script>

<style>
</style>