<template>
  <v-card class="carTable" :elevation="3" :disabled="disabled" >
    <v-card-title>Carrinho</v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      fixed-header
      :items-per-page="-1"
      height="50vh"
      hide-default-footer
      no-data-text="Carrinho Vazio"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="orange darken-3"
          @click="
            () => {
              descontItemCar(item);
            }
          "
        >mdi-calculator</v-icon>
        <v-icon
          small
          color="orange darken-3"
          class="ml-2"
          @click="
            () => {
              editItemCar(item);
            }
          "
        >mdi-pencil</v-icon>
        <v-icon
          small
          color="red darken-3"
          class="ml-2"
          @click="
            () => {
              deleteItem(item);
            }
          "
        >mdi-delete</v-icon>
      </template>
      <template
        v-slot:item.value_descont_product="{ item }"
      >{{ parseFloat(item.value_descont_product).toFixed(2) }}</template>
    </v-data-table>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card-actions>
      <v-card-text class="text-h6">Total a Pagar: R$ {{ total.toFixed(2) }}</v-card-text>
      <v-spacer></v-spacer>
      <CustomButtom />
      <v-btn
        class="red darken-4 white--text"
        @click="
          () => {
            ClearCart();
          }
        "
      >
        <v-icon left>mdi-cart-remove</v-icon>Limpar
      </v-btn>
      <v-btn
        class="green darken-4 white--text"
        :disabled="valid"
        @click="
          () => {
            FinishSaleFormOfPaymentsEndCondidtionsPayments();
          }
        "
      >
        <v-icon left>mdi-cart-arrow-right</v-icon>PAGAR
      </v-btn>
    </v-card-actions>
    <EditProduct :component="this" />
    <DescontProduct :component="this" />
    <DeleteProduct :component="this" />
    <ClearCar :component="this" />
    <FinishSale v-if="dialog_finish" :component="this" />
    <AddPerson :component="this" />
    <AlertError
      v-if="dialog_alert"
      :title_error="errorTitle"
      :listErrors="errorsList"
      :component="this"
    />
  </v-card>
</template>

<script>
import CustomButtom from "./component/buttom.vue";
import { headers } from "./script";
import EditProduct from "../AddDialog/Edit_Product";
import DescontProduct from "../AddDialog/Descont_Product";
import DeleteProduct from "../AddDialog/Delete_Product";
import ClearCar from "../AddDialog/Delete_All_Cart/";
import FinishSale from "../AddDialog/Finish_Sale/";
import AlertError from "../AddDialog/Modal_Error/";
import {UPDATE_CART} from '../../../store/actions/future_sales'
import AddPerson from '../TabDate/TabGeneral/index.vue'
import {generateDate} from "../../Utils/global_functions/utils_function"
import { mapGetters } from "vuex";

export default {
  components: {
    DescontProduct,
    EditProduct,
    DeleteProduct,
    ClearCar,
    FinishSale,
    AlertError,
    CustomButtom,
    AddPerson
  },
  props: {
    component: Object,
    datas: Object,
    disabled: Boolean
  },

  data: () => ({
    headers,
    itemToDelete: Number(),
    dialog: false,
    dialog_delete: false,
    dialog_clear: false,
    dialog_finish: false,
    dialog_descont: false,
    dialog_alert: Boolean(),
    errorsList: Array(),
    errorTitle: String(),
    element: Object(),
    items: Array(),
    valid: Boolean(),
    indexOf: Number(),
    amount: "",
    valueDescont: Number(),
    type_descont: "",
    total: 0,
    peoplesInf:{
      seller: undefined,
      client: undefined
    },
    sale: {
      status: "Em Digitacao",
      number_doc_client: "",
      wasProcessed: false,
      data_sale: new Date,
      access_key: String(),
      number_sale: String(),
      authorization_protocol: String(),
      series: String(),

      value_total_products: Number(),
      value_total_note: Number(),
      value_descount_total: Number(),
      value_account_total: Number(),

      value_icms_substituicao_total: Number(),
      base_cal_icms_total: Number(), //alterado  back ok
      base_calc_susbt_total: Number(), //alterado back ok
      value_icms_free_total: Number(),
      value_outher_tax_total: Number(),
      value_icms_total: Number(),

      note: "Venda de mercadoria", // Valor Mocado

      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      base_calc_ipi_total: Number(),

      value_base_pis_total: Number(),
      value_base_pis_st_total: Number(),
      value_other_pis_total: Number(),
      value_pis_st_total: Number(),
      value_pis_total: Number(),

      value_base_cofins_st_total: Number(),
      value_base_cofins_total: Number(),
      value_other_cofins_total: Number(),
      value_cofins_st_total: Number(),
      value_cofins_total: Number(),

      client_id: Number(),
      tax_operation_types_id: 2,
      enterprise_id: 1,
      account_plans_id: 1,
      pdv_id: 1,
      seller_id: Number(),
      note_models_id: 2,
      form_of_payments: Array(),
      itens_sales: Array(),
      enterprise: Object(),
      client: Object(),
      user_id: 1
    }
  }),
  methods: {
    editItemCar(item) {
      this.dialog = !this.dialog;
      this.element = { ...item };
      // this.indexOf = this.getCart.indexOf(item);
      // this.amount = this.element.amount;
    },
    onKey(e) {
      let KeyCode = e.key;
      switch (KeyCode) {
        case "F9":
          if (this.items.length > 0) {
            this.FinishSale();
          } else {
            this.valid = true;
          }
          break;

        case "Escape":
          this.dialog_finish = false;

          break;
      }
    },
    async recalculetributeEditQuantidadeItem(item) {
      await this.$store
        .dispatch(GET_CALC_TRIBUTE, {
          product_id: item.product_id,
          amount_add: item.amount,
          enterprise_id: 1
        })
        .then(response => {
          let productTribute = response.data.data;
          if (productTribute) {
            this.element.value_base_icms_product =
              productTribute.value_base_icms_product;
            this.element.value_accounting_product =
              productTribute.value_accounting_product;
            this.element.value_icms_product = productTribute.value_icms_product;
            this.element.aliquot_ipi_product =
              productTribute.aliquot_ipi_product;
            this.element.value_cofins_product =
              productTribute.value_cofins_product;
            this.element.aliquot_icms_product =
              productTribute.aliquot_icms_product;
            this.element.value_base_icms_st_product =
              productTribute.value_base_icms_st_product;
            this.element.value_icms_st_product =
              productTribute.value_icms_st_product;
            this.element.value_ipi_product = productTribute.value_ipi_product;
            this.element.value_free_cofins_product =
              productTribute.value_free_cofins_product;
            this.element.value_other_pis_product =
              productTribute.value_other_pis_product;
            this.element.value_pis_st_product =
              productTribute.value_pis_st_product;
            this.element.value_free_icms_product =
              productTribute.value_free_icms_product;
            this.element.value_icms_outher_product =
              productTribute.value_icms_outher_product;
            this.element.value_base_ipi_product =
              productTribute.value_base_ipi_product;
            this.element.aliquota_pis = productTribute.aliquota_pis;
            this.element.value_base_pis_product =
              productTribute.value_base_pis_product;
            this.element.value_pis_product = productTribute.value_pis_product;
            this.element.value_base_cofins_product =
              productTribute.value_base_cofins_product;
            this.element.value_base_pis_st_product =
              productTribute.value_base_pis_st_product;
            this.element.value_base_cofins_st_product =
              productTribute.value_base_cofins_st_product;
            this.element.value_cofins_st_product =
              productTribute.value_cofins_st_product;
            this.element.value_other_pis_st_product =
              productTribute.value_other_pis_st_product;
            this.element.value_other_cofins_product =
              productTribute.value_other_cofins_product;
            this.element.value_free_pis_product =
              productTribute.value_free_pis_product;
            this.element.aliquota_cofins = productTribute.aliquota_cofins;
            this.element.value_ipi_free_product =
              productTribute.value_ipi_free_product;
            this.element.value_ipi_outher_product =
              productTribute.value_ipi_outher_product;
            this.element.cfop_id = productTribute.cfop_id;
            this.element.cst_id = productTribute.cst_id;
            this.element.csosn_id = productTribute.csosn_id;
            this.element.cst_pis_id = productTribute.cst_pis_id;
            this.element.cst_cofins_id = productTribute.cst_cofins_id;
          }
        });
    },

    deleteItem(item) {
      this.itemToDelete = item.tableId
      this.dialog_delete = !this.dialog_delete;
      //  
    },
    clearClientSeller() {
      this.component.clear();
    },
    descontItemCar(item) {
      this.dialog_descont = !this.dialog_descont;
      this.element = { ...item };
      this.indexOf = this.getCart.indexOf(item);
    },
    ClearCart() {
      this.dialog_clear = !this.dialog_clear;
    },

    FinishSaleFormOfPaymentsEndCondidtionsPayments() {
       if(this.peoplesInf.seller == undefined && this.peoplesInf.client == undefined){
          
          alert('Favor inserir um Cliente e um Vendedor')
       }else if(this.items.length < 1) {
        alert('Favor inserir um item no carrinho')
      } else {
         this.dialog_finish = !this.dialog_finish;

       }
      // let validClient = this.peoplesInf.client;
      // let validSeller = this.datas.seller.seller;
      // this.sale.client = this.component.clientNfceObject;
      // this.sale.enterprise = this.component.enterpriseNfceObject;
      // if (validClient == undefined || validSeller == undefined) {
      //   this.dialog_alert = !this.dialog_alert;
      //   this.errorTitle = "Campos Obrígatórios";
      //   this.errorsList = [
      //     { text: "Campo de Cliente" },
      //     { text: "Campo de Vendedor" }
      //   ];
      // } else {
      //   this.sale.client_id = validClient;
      //   this.sale.seller_id = validSeller;
      // }
      // 
    },

    calculate(items) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      let item = items || [...this.items];
      this.items = [];
      let variable = item.map(e => e.total_value);
      this.total = variable.reduce(reducer, 0);
      this.items = [...this.$store.getters.getCart];
    },

    confirm_clean_car() {
      this.$store.dispatch(CLEAN_CART);
      this.calculate(this.$store.getters.getCart);
    },
    confirm_descont_item() {
      const index = this.items.findIndex((element) => element.id == this.element.id)
      let newList = []
      switch (this.type_descont) {
        case "DINHEIRO":

          this.items[index].total_value = this.items[index].total_value - parseFloat(this.valueDescont)
          newList = [...this.items]
          this.$store.dispatch(UPDATE_CART, newList);
          this.calculate(this.$store.getters.getCart);
          this.dialog_descont = !this.dialog_descont;
          this.valueDescont = 0;
          break;

        case "PERCENTUAL":
          this.items[index].total_value = this.items[index].total_value - (this.items[index].total_value * (this.valueDescont / 100))
          newList  = [...this.items]
          this.$store.dispatch(UPDATE_CART, newList);
          this.calculate(this.$store.getters.getCart);
          this.dialog_descont = !this.dialog_descont;
          this.valueDescont = 0;
          break;
      }

      this.calculate(this.$store.getters.getCart);
    },
    async confirmAlterQuantProduct() {

      const index = this.items.findIndex((element) => element.id == this.element.id)
      this.items[index].amount = parseInt(this.amount) 
      this.items[index].total_value = this.items[index].amount * this.items[index].cust
      const newList = [...this.items]

      await this.$store.dispatch(UPDATE_CART, newList);
      this.calculate(this.$store.getters.getCart);
      this.amount = 0;
      this.dialog = !this.dialog;
    },

    confirm_delete() {
      const index = this.getCart.findIndex((e) => e.tableId == this.itemToDelete)
      this.items.splice(index, 1)
      this.$store.dispatch(UPDATE_CART,this.items)
      this.calculate(this.$store.getters.getCart);
      this.dialog_delete = !this.dialog_delete;
    },


    clearCart() {
      this.items = Array()
      this.$store.dispatch(UPDATE_CART,  this.items);
      this.calculate(this.$store.getters.getCart);
      this.dialog_clear = false;
    }
  },

  watch: {
    getCart: function(val) {
       
      this.calculate(val);
    },
    items: function() {
      if (this.items.length > 0) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getCart", "getNewProductCalculed"])
  },
  mounted() {
    // this.calculate(this.$store.getters.getCart);
  },
  created() {
    document.addEventListener("keyup", this.onKey);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  }
};
</script>
<style>
.carTable{
  /* height: 100%; */
  min-height: 100%;
  background-color: aqua;
}
</style>