<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          append-icon="mdi-magnify"
          outlined
        >
        </v-text-field>
      </v-card-text>
      <Table :component="this" />
      <LoadScreen/>
    </v-card>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container.vue";
import CardTitle from "../components/notenfe/CardTitle";
import Table from "../components/notenfe/Table";
import { GET_ALL_ISSUE_OF_INVOICE } from "../store/actions/nfe";
import LoadScreen from "../components/load/index.vue"

export default {
  components: {
    LoadScreen,
    Container,
    CardTitle,
    Table,
  },
  data() {
    return {
      addDialogImportXML: false,
    };
  },
  methods: {
    getAllInvoice() {
      this.$store.dispatch(GET_ALL_ISSUE_OF_INVOICE, {
        page: 1,
        limit: 10,
        paginate: true,
      });
    },
  },
  created() {
    this.getAllInvoice();
  },
};
</script>

<style>
</style>