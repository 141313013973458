
<template>
  <div>
    <v-col>
      <v-card-title>Cupom Fiscal</v-card-title>
      <v-text-field outlined class="mt-5 ml-3" append-icon="mdi-magnify" />
    </v-col>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>