
function mountNoteObject() {
    formatDate(this.getDataInfGeneral.date_of_issue)
    
    //verificar dados e atribuir às variáveis
    this.noteObject.base_calc_susbt_total = null
    this.noteObject.outher_expenses_acess = null
    this.noteObject.value_other_incidental_expenses = null
    this.noteObject.value_outher_tax_total = null
    this.noteObject.value_security = null
    this.noteObject.value_untaxed_tax_total = null
    this.noteObject.note = null
    //finalizado 

    this.noteObject.status = "CONFIRMADA"
    this.noteObject.number_title = `${this.getDataInfGeneral.number_grade}-${this.getDataInfGeneral.series}`
    this.noteObject.enterprise = 0
    this.noteObject.gross_weigth = this.getDataTransporter.volume.grossWeigth
    this.noteObject.net_weigth = this.getDataTransporter.volume.netWeight
    this.noteObject.value_freigth = this.getDataTransporter.value_shipping
    this.noteObject.type_freigth = this.getDataTransporter.modeShipping
    this.noteObject.transporter_id = this.getDataTransporter.legalpeople.id
    this.noteObject.gradeItens = [...this.getDataItensNote]
    this.noteObject.payment_terms_id = this.formData.conditionPayment.id
    this.noteObject.account_plans_id = this.formData.accountPlans
    this.noteObject.access_key = this.getDataInfGeneral.access_key
    this.noteObject.authorization_protocol = this.getDataInfGeneral.authorization_protocol
    this.noteObject.base_calc_icms_total = parseFloat(this.getDataInfNote.base_calc_icms_total)
    this.noteObject.base_calc_ipi_total = parseFloat(this.getDataInfNote.base_calc_ipi_total)
    // this.noteObject.date_of_issue = this.getDataInfGeneral.date_of_issue
    this.noteObject.date_of_issue = formatDate(this.getDataInfGeneral.date_of_issue)
    this.noteObject.enterprise_id = this.getSelectedEnterprise.id
    this.noteObject.entry_date = formatDate(this.getDataInfGeneral.entry_date)
    this.noteObject.number_grade = this.getDataInfGeneral.number_grade
    this.noteObject.provider_id = this.getDataInfGeneral.provider.id
    this.noteObject.series = this.getDataInfGeneral.series
    this.noteObject.tax_operation_types_id = this.getDataInfGeneral.tax_operation_types_id
    this.noteObject.value_account_total = parseFloat(this.getDataInfNote.value_account_total)
    this.noteObject.value_base_icms_st_product = this.getDataInfNote.value_base_icms_st_product
    this.noteObject.value_discont = this.getDataInfNote.value_discont
    this.noteObject.value_icms_free_total = this.getDataInfNote.value_icms_free_total
    this.noteObject.value_icms_outher_product = this.getDataInfNote.value_icms_outher_product
    this.noteObject.value_icms_substituicao_total = this.getDataInfNote.value_icms_substituicao_total
    this.noteObject.value_icms_total = parseFloat(this.getDataInfNote.value_icms_total)
    this.noteObject.value_ipi_free_total = parseFloat(this.getDataInfNote.value_ipi_free_total)
    this.noteObject.value_ipi_outher_total = parseFloat(this.getDataInfNote.value_ipi_outher_total)
    this.noteObject.value_ipi_total = parseFloat(this.getDataInfNote.value_ipi_total)
    this.noteObject.value_total_note = parseFloat(this.getDataInfNote.value_total_note)
    this.noteObject.value_total_products = parseFloat(this.getDataInfNote.value_total_products)
    this.noteObject.vehicle_plate = this.getDataTransporter.vehicle_plate
    this.noteObject.volume_transported = this.getDataTransporter.volume.volume_transported
    this.noteObject.volume_type = this.getDataTransporter.volume.especification
    this.noteObject.issuance_due = getDeadLineDate(this.getDataInfGeneral.date_of_issue)
    this.noteObject.value_title = this.getDataInfNote.value_total_note
    this.noteObject.remaining = this.getDataInfNote.value_total_note
    this.noteObject.release = 0
    this.noteObject.note_value = this.getDataInfNote.value_total_note

    //precisa preencher
    this.noteObject.code_csosn = 0
    this.noteObject.cst_confis_id = 0
    this.noteObject.cust = 0
    this.noteObject.description_product = 0
    this.noteObject.description_unit = 0
    this.noteObject.value_accounting_product = 0
    this.noteObject.value_base_icms_product = 0
    this.noteObject.value_icms_product = 0
    this.noteObject.value_sub_total_product = 0
}

function formatDate(date) {
    let arrayDate = date.split('/')
    let newStringDate = arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0]
    console.log(newStringDate)
    return newStringDate
}

function getDeadLineDate(date) {
    let day = date.substr(0, 2)
    let month = Number(date.substr(3, 1))
    let year = Number(date.substr(5, 4))

    let newMonth;
    let newYear;

    if (month == '12') {
        newMonth = '01'
        newYear = year + 1
        return `${newYear}-${newMonth}-${day}`
    } else {
        newMonth = month + 1
        return `${year}-${newMonth}-${day}`
    }
}

function mountBillsToPay() {
    let objectBillstoPay = Object()

    objectBillstoPay.account_plans_id = this.formData.accountPlans;
    objectBillstoPay.annotation = "";
    objectBillstoPay.condition = this.formData.conditionPayment;
    objectBillstoPay.dejection = 0;
    objectBillstoPay.enterprise_id = this.getSelectedEnterprise.id;
    objectBillstoPay.form = this.formData.formPayment;
    objectBillstoPay.interest = 0;
    objectBillstoPay.issuance_date = formatDate(this.getDataInfGeneral.date_of_issue)
    objectBillstoPay.issuance_due = getDeadLineDate(this.getDataInfGeneral.date_of_issue)
    objectBillstoPay.number_title = `${this.getDataInfGeneral.number_grade}-${this.getDataInfGeneral.series}`
    objectBillstoPay.provider_id = this.getDataInfGeneral.provider.id
    objectBillstoPay.status = "Aberto"
    objectBillstoPay.type_title = this.formData.conditionPayment.description
    objectBillstoPay.title_balance = parseFloat(this.getDataInfNote.value_total_note)
    objectBillstoPay.value_net = parseFloat(this.getDataInfNote.value_total_note)
    objectBillstoPay.value_title = parseFloat(this.getDataInfNote.value_total_note)
    objectBillstoPay.annotation = "Referente à nota" + `${this.getDataInfGeneral.number_grade}-${this.getDataInfGeneral.series}`
    return objectBillstoPay
}


export { mountNoteObject, mountBillsToPay }

//getDataInfGeneral
//getDataItensNote
//getDataTransporter
//getDataInfNote
