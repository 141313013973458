<template>
  <v-row>
    <v-container>
      <v-card width="32vw" flat height="100%" max-height="100%">
        
        <v-row>
          <v-col>
            <v-switch
              class="ml-2"
              color="primary"
              label="Visualizar imagens"
              v-model="view_image"
            ></v-switch>
          </v-col>
        </v-row>

        <v-text-field
          class="ma-2"
          dense
          :label="labelCode"
          v-model="code_bar"
          outlined
          @keyup.enter="enterCode()"
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="activeBarcode()"
                  v-on="on"
                  :color="color_barcode"
                >
                  mdi-barcode-scan
                </v-icon>
              </template>
              {{ text_barcode }}
            </v-tooltip>
          </template>

          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="active_idProduct()"
                  v-on="on"
                  :color="color_numeric"
                >
                  mdi-numeric
                </v-icon>
              </template>
              {{ text_numeric }}
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  @click="searchProduct()"
                  v-on="on"
                  :color="color_numeric"
                >
                  mdi-magnify
                </v-icon>
              </template>
              {{ text_search }}
            </v-tooltip>
          </template>
        </v-text-field>

        <v-card flat v-if="!view_image">
          <v-data-table
            dense
            :headers="headers"
            height="40vh"
            :items="productList"
            :no-data-text="data_text"
            hide-default-footer
            :footer-props="{
              'items-per-page-options': [5, 10],
              'items-per-page-text': 'Items por página',
            }"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                color="blue darken-3"
                @click="
                  () => {
                    activatorView(item);
                  }
                "
                >mdi-magnify</v-icon
              >
              <v-icon
                small
                color="green darken-3"
                class="ml-2"
                @click="
                  () => {
                    activatorAdd(item);
                  }
                "
                >mdi-plus-circle</v-icon
              >
            </template>
          </v-data-table>
        </v-card>

        <v-flex v-else>
          <v-card outlined height="40vh" class="ma-2">
            <v-container class="scroll">
              <v-row justify="center">
                <v-col
                  v-for="item in productList"
                  :key="item.description_item"
                  cols="12"
                  md="6"
                  sm="12"
                  lg="4"
                >
                  <div class="cardDiv" @click="activatorAdd(item)">
                    <img class="imagem" :src="`${url}${item.photo}`" /><img />
                  </div>
                  <div class="descriptCard">
                    <p class="subtitle">
                      {{ item.description_pdv }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>
        <v-divider></v-divider>
        <v-pagination
          color="success"
          v-model="paginationInfo.currentPage"
          :length="paginationInfo.lastPage"
          :total-visible="10"
        ></v-pagination>
      </v-card>
    </v-container>

    <InfoProduct
      v-if="dialog"
      :component="this"
      :itemProduct="element"
      :readonly="readonly"
    />
    <AddCard v-if="dialog_add" :component="this" />
    <AlertError
      v-if="dialog_alert"
      :component="this"
      :title_error="title_error"
      :listErrors="subtitle_error"
    />
  </v-row>
  
</template>

<script>
import { headers } from "./script";
import InfoProduct from "../../InfoProduct";
import AddCard from "../../AddDialog/Add_Cart";
import AlertError from "../../AddDialog/Modal_Error/";
// import { ADD_CART, UPDATE_CART } from "../../../../store/actions/cart";
// import {
//   GET_ALL_PRODUCT,
//   GET_BY_BARCODE,
//   GET_BY_PRODUCT,
// } from "../../../../store/actions/product";
// import { GET_CALC_TRIBUTE } from "../../../../store/actions/future_sales";
import {ADD_NOTE_ENTRY_ITENS} from "../../../../store/actions/note_entry_itens"
import { mapGetters } from "vuex";
import {SET_CART} from '../../../../store/actions/future_sales'
import { GET_ALL_PRODUCT } from "../../../../store/actions/product";
import modelCalculateProduct from './helpers/model_calculate_product'
export default {
  props: { component: Object() },
  components: { InfoProduct, AddCard, AlertError },
  data: () => ({
    paginationInfo:{
      lastPage: 0,
      currentPage:0
    },
    readonly: Boolean(),
    valid: false,
    view_image: false,
    dialog: false,
    dialog_add: false,
    statusBar_code: String(),
    amount: 1,
    current: Number(),
    total: Number(),
    color_numeric: "gray",
    color_barcode: "gray",
    active_barcode: Boolean(),
    active_numeric: Boolean(),
    text_barcode: "Ativar Modo Manual",
    text_numeric: "Ativar modo cód de Produto",
    labelCode: "Código de Barra",
    data_text: "Sem produtos disponíveis",
    text_search: "Consultar por Cód de barra",
    info_sale: { amount: Number(), value_subtotal_product: Number() },
    update_amount: Object(),
    search: String(),
    code_bar: String(),
    product: Object(),
    element: Object(),
    dialog_alert: Boolean(),
    title_error: String(),
    subtitle_error: String(),
    barcodeLoad: Boolean(),
    url: process.env.VUE_APP_URL_DEFAULT,
    productList: Array(),
    itens_sales: {
      product: Object(),
      description: String(),
      code_item: Number(),
      amount: Number(),
      product_id: Number(),
      value_sale: Number(),
      value_descont_product: Number(),
      value_subtotal_product: Number(),
      value_base_icms_product: Number(), //alterado no back end
      value_accounting_product: Number(), //alterado no back end

      aliquot_icms_product: Number(), //alterado no back end
      value_icms_product: Number(),
      value_free_icms_product: Number(),
      value_icms_outher_product: Number(),
      value_base_icms_st_product: Number(),
      value_icms_st_product: Number(),

      aliquot_ipi_product: Number(),
      value_base_ipi_product: Number(),
      value_ipi_product: Number(),
      value_ipi_free_product: Number(),
      value_ipi_outher_product: Number(),

      aliquota_pis: Number(),
      value_base_pis_product: Number(),
      value_pis_product: Number(),
      value_base_pis_st_product: Number(),
      value_other_pis_st_product: Number(),
      value_other_pis_product: Number(),
      value_free_pis_product: Number(),
      value_pis_st_product: Number(), //adicionado no back end

      aliquota_cofins: Number(),
      value_base_cofins_product: Number(),
      value_cofins_product: Number(), //adicionado no back end
      value_other_cofins_product: Number(),
      value_free_cofins_product: Number(),
      value_base_cofins_st_product: Number(),
      value_cofins_st_product: Number(),
      valueUnityBusinessProduct: Number(),
      valueUnityTaxProduct: Number(),

      cfop_id: Number(),
      cst_ipi: Number(),
      csosn_id: Number(),
      sale_id: Number(),
      cst_pis_id: Number(),
      cst_cofins_id: Number(),
      csosnProduct: String(),
      sourceMerchandise: String(),
    },

    headers,
  }),

  methods: {
    getAllProduct(page = 1){
      const payload = {
        page,
        paginate: true,
        limit: 10
      }
      
      this.$store.dispatch(GET_ALL_PRODUCT, payload).then((response) => {
        this.productList = response.data
        this.paginationInfo.lastPage = response.last_page
        this.paginationInfo.currentPage = page
      })
    },
    activeDialog() {
      this.dialog_add = !this.dialog_add;
    },
    activeView() {
      this.dialog = !this.dialog;
    },
    searchProduct() {
      this.readonly = false;
      this.element = Object();
      this.activeView();
    },
    async searchProductGetBarCode(code) {
      let product = Object();
      await this.$store.dispatch(GET_BY_BARCODE, code).then((response) => {
        let { data } = response.data;
        product = { ...data };
      });

      return product;
    },
    activatorView(item) {
      let auxItem = { ...item };
      auxItem.csosn_code = auxItem.csosn === null ? "0" : auxItem.csosn.code;
      auxItem.cst_code = auxItem.cst === null ? "0" : auxItem.cst.code;
      this.element = { ...auxItem };
      this.readonly = true;
      this.activeView();
    },
    activeBarcode() {
      if (this.color_barcode === "gray") {
        this.color_barcode = "success";
        this.text_barcode = "Desativar Modo Manual";
        this.active_barcode = true;
        if (this.color_numeric === "success") {
          this.active_idProduct();
        }
      } else {
        this.color_barcode = "gray";
        this.active_barcode = false;
        this.text_barcode = "Ativar Modo Manual";
      }
    },
    active_idProduct() {
      if (this.color_numeric === "gray") {
        this.labelCode = "Código do Produto";
        this.color_numeric = "success";
        this.text_numeric = "Desativar Modo Cód de Produto";
        this.active_numeric = true;
        if (this.color_barcode === "success") {
          this.activeBarcode();
        }
      } else {
        this.getProducts();
        this.labelCode = "Código de Barra";
        this.color_numeric = "gray";
        this.active_numeric = false;
        this.text_numeric = "Ativar Modo Cód de Produto";
      }
    },
    enterCode() {
      if (this.active_numeric) {
        this.search_IdProduct();
      } else {
        this.addMultiProduct();
      }
    },
    search_IdProduct() {
      let id = this.code_bar;
      this.$store.dispatch(GET_BY_PRODUCT, id);
    },
    //checa se ter produto no carrinho caso tenha devolve o item para recalculo
    CheckAddItensCart(idProduct) {
       
      let valueproduct = false;
      let itensCart = this.getCart;
      valueproduct = itensCart.find((itens) => itens.product_id === idProduct);
      if (valueproduct) {
        return valueproduct;
      }
      return false;
    },

    async calculeTributeProductCart(product) {

      const returnProduct = modelCalculateProduct(product)
      const response = await this.$store.dispatch(ADD_NOTE_ENTRY_ITENS, returnProduct)

      return response

    },
    async AddDialogProductCar(amount = 0) {
      let productCar = this.CheckAddItensCart(this.product.id);
      //caso tenha esse produto adicionado no carrinho
      if (productCar != false) {
        this.itens_sales.product = this.product;
        this.itens_sales.product_id = this.product.id;
        this.itens_sales.cfop_id = this.product.cfop_id;
        this.itens_sales.cst_ipi = this.product.cst_ipi;
        this.itens_sales.csosn_id = this.product.csosn_id;
        this.itens_sales.cst_pis_id = this.product.cst_pis_id;
        this.itens_sales.cst_cofins_id = this.product.cst_cofins_id;
        this.itens_sales.amount =
          parseFloat(this.amount) + parseFloat(productCar.amount);
        this.itens_sales.value_sale = this.product.sale_price;
        this.itens_sales.value_descont_product = 0;
        this.itens_sales.value_subtotal_product =
          parseFloat(this.itens_sales.value_sale) *
          parseInt(this.itens_sales.amount);
        //campos nfce
        this.itens_sales.description = this.product.description_item;
        this.itens_sales.eanProduct = this.product.bar_code;
        this.itens_sales.ncmProduct = this.product.ncm.description;
        this.itens_sales.codeCstProdut = this.product.cst.code;
        this.itens_sales.cfopProduct = this.product.cfop.code;
        this.itens_sales.unitBusinessProduct = this.product.unit.sigla;
        this.itens_sales.unityTaxProduct = this.product.unit.sigla;
        this.itens_sales.sourceMerchandise = this.product.source_product;
        this.itens_sales.valueOfApproximateTax = 0;
        this.itens_sales.methodDeterminingBaseIcms =
          this.product.calculation_base_mode;
        this.itens_sales.valueBaseRelatedCombatPoverty = 0;
        this.itens_sales.percentageRelatedCombatPoverty = 0;
        this.itens_sales.valueRelatedCombatPoverty = 0;
        this.itens_sales.codeSituationTaxableCofins =
          this.product.cstcofins.code;
        this.itens_sales.valueUnityBusinessProduct = this.product.sale_price;
        this.itens_sales.valueUnityTaxProduct = this.product.sale_price;
        this.itens_sales.csosnProduct = this.product.csosn.code;

        await this.calculeTributeProductCart(this.itens_sales);
        this.dialog_add = false;
        this.$store.dispatch(UPDATE_CART, this.itens_sales);
        this.itens_sales = {};
      } else {

        this.itens_sales.product = this.product;
        this.itens_sales.cost_price = this.product.cost_price
        this.itens_sales.product_id = this.product.id;
        this.itens_sales.description = this.product.description_item;
        this.itens_sales.amount = amount > 0 ? amount : this.amount;
        this.itens_sales.value_sale = this.product.sale_price;
        this.itens_sales.cfop_id = this.product.cfop_id;
        this.itens_sales.cst_ipi = this.product.cst.code; //indo undefined
        this.itens_sales.csosn_id = this.product.csosn_id;
        this.itens_sales.cst_pis_id = this.product.cst_pis_id;
        this.itens_sales.cst_cofins_id = this.product.cst_cofins_id;
        this.itens_sales.value_descont_product = 0;
        this.itens_sales.value_subtotal_product =
          parseFloat(this.itens_sales.value_sale) *
          parseInt(this.itens_sales.amount);

        //campos nfce
        this.itens_sales.eanProduct = this.product.bar_code;
        this.itens_sales.ncmProduct = this.product.ncm.code;
        this.itens_sales.nomenclatureValueCustomsProduct = "";
        this.itens_sales.codeCstProdut = this.product.cst.code;
        this.itens_sales.relevantScaleIndicatorProduct = "";
        this.itens_sales.codeManufacturerProduct = "";
        this.itens_sales.recipientProduct = "";
        this.itens_sales.extIpiProduct = "";
        this.itens_sales.cfopProduct = this.product.cfop.code;
        this.itens_sales.unitBusinessProduct = this.product.unit.sigla;
        this.itens_sales.valueUnityBusinessProduct = this.product.sale_price;
        this.itens_sales.valueCostOfFreight = 0;
        this.itens_sales.unityTaxProduct = this.product.unit.sigla;
        this.itens_sales.indicatesWhetherProduct = 1;
        this.itens_sales.valueTotalGrossProduct = 0;
        this.itens_sales.valueTotalGrossProduct = "";
        this.itens_sales.sourceMerchandise = this.product.source_product;
        this.itens_sales.valueOfApproximateTax = 0;
        this.itens_sales.methodDeterminingBaseIcms =
          this.product.calculation_base_mode;
        this.itens_sales.valueBaseRelatedCombatPoverty = 0;
        this.itens_sales.percentageRelatedCombatPoverty = 0;
        this.itens_sales.valueRelatedCombatPoverty = 0;
        this.itens_sales.codeSituationTaxableCofins =
          this.product.cst_confis.code; 
        this.itens_sales.codeSituationTaxablePis = this.product.cst_pis.code; 

        this.itens_sales.csosnProduct = this.product.csons.code; 
        this.itens_sales.sourceMerchandise = this.product.source_product;
        const calculedProduct = await this.calculeTributeProductCart(this.itens_sales);

         

        this.dialog_add = false;
        this.$store.dispatch(SET_CART, calculedProduct);
        this.itens_sales = {};
      }
    },
    //ativa e copia item selecinado para variavel produto
    activatorAdd(item) {
      this.product = { ...item };
      this.activeDialog();
    },

    async addMultiProduct() {
      let code = Object();
      let string = this.code_bar;
      let index = string.indexOf("*");
      this.amount = 1;
      if (index !== -1) {
        this.amount = string.slice(0, index);
        let barcode = string.slice(index);
        code.barcode = barcode.replace("*", "");
      } else {
        code.barcode = string;
      }
      await this.$store.dispatch(GET_BY_BARCODE, code).then((response) => {
        let { data } = response.data;
        if (data !== null) {
          this.statusBar_code = "primary";
          this.product = data;
          this.AddDialogProductCar();
          this.code_bar = String();
        } else {
          this.statusBar_code = "red";
          this.title_error = "Erro ao buscar o código de barras!";
          this.subtitle_error = [{ text: "Verifique o código de barras!" }];
          this.dialog_alert = true;
        }
      });
    },
    async getProducts(page = 1) {
    page
      // await this.$store.dispatch(GET_ALL_PRODUCT, { page: page, limit: 9 });
    },
  },
  computed: {
    ...mapGetters([
      "getProductsBarcode",
      "getBarcode",
      "getProduct",
      "getCart",
    ]),
  },
  watch: {
    'paginationInfo.currentPage': function(val){
      this.getAllProduct(val)
    },
    // getProduct: function (val) {
    //   if (val.data === undefined) {
    //     this.productList = Object.values(val);
    //     this.total = 1;
    //     this.current = 1;
    //   } else {
    //     this.productList = val.data;
    //     this.total = val.lastPage;
    //     this.current = val.currentPage;
    //   }
    // },
    current: function (val) {
      this.getProducts && this.getProducts(val);
    },
  },
  async created() {
    // await this.getProducts();
    this.getAllProduct()
  },
};
</script>
<style scoped>
.scroll {
  max-height: 100%;
  overflow: auto;
}
.subtitle {
  color: #286a7d;
  font-weight: bold;
  font-size: 12px;
  padding: 3%;
}
.imagem {
  height: 100%;
  width: 100%;
}
.cardDiv {
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.19), 2px 0px 3px rgba(0, 0, 0, 0.23);
  height: 150px;
  width: 150px;
  overflow: hidden;
}
.descriptCard {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.19), 2px 2px 3px rgba(0, 0, 0, 0.23);
  border-top: rgb(167, 167, 167) 1px solid;
  width: 150px;
  height: 30px;
}
</style>
