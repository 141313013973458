const ADD_BARCODE = "ADD_BARCODE";
const UPDATE_BARCODE = "UPDATE_BARCODE";
const AUX_TO_UPDATE_BARCODE = "AUX_TO_UPDATE_BARCODE";
const GET_ALL_BARCODE = "GET_ALL_BARCODE";
const DESTROY_BARCODE = "DESTROY_BARCODE";
const GET_FILTER_BARCODE = "GET_FILTER_BARCODE";

module.exports = {
    ADD_BARCODE,
    UPDATE_BARCODE,
    AUX_TO_UPDATE_BARCODE,
    GET_ALL_BARCODE,
    DESTROY_BARCODE,
    GET_FILTER_BARCODE
}
