

var watch = {

  getItemToAddPisConfins:function(val){
    this.arrayPisCofins.push(val)
  },
  getAliquotToAdd:function(val){
    this.arrayAliquots.push(val)
  },
  getCfopToAdd:function(val){
    this.arrayCfop.push(val)
  },
  
  "component.invoice.base_calc_ipi_total": function () {
    this.populateInfNote()
  },
  getItemNotes: function () {
    this.arrayProduct = this.getItemNotes;
  },
  getAliquot: function () {
    this.arrayAliquots = Object.values(this.getAliquot);
  },
  getCfop: function () {
    this.arrayCfop = Object.values(this.getCfop);
    this.setGettersObjectToProduct(this.arrayProduct)
  },
  getCsosn: function () {
    this.arrayCsosn = Object.values(this.getCsosn);
  },
  getCst: function () {
    this.arrayCst = Object.values(this.getCst)
  },
  getPisCofins: function () {
    this.arrayPisCofins = Object.values(this.getPisCofins);
    this.setGettersObjectToProduct(this.arrayProduct)
  },
  "component.resetForm": function () {
    this.cleanform()
  },
  getNoteEntryItens: function (val) {
    this.getNoteEntryItensArray = [...val]
  },
  arrayProduct: function () {
    this.getAllInformations()
    this.isRegisteredColor()
  },
  formValidate: function (val) {
    this.isRegisteredColor(val)
  },
  getProduct: function () {
    this.arrayProduct[this.indexProductToSetArray] = { ...this.getProduct };
    this.arrayProduct[this.indexProductToSetArray].isRegistered = true
    this.arrayProduct[this.indexProductToSetArray].Tax = this.getItemTax[this.indexProductToSetArray]
    this.arrayProduct = [...this.arrayProduct]
    this.verifyTaxToObject(this.indexProductToSetArray)
    this.arrayProduct = [...this.arrayProduct]
  },
};

export { watch };
