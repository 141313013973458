
<template>
  <!-- d -->
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="800">
      <v-card>
        <v-card-title primary-title>Registrar Contas a Receber</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form">
            <v-col>
              <!-- IMPLANTAÇÃO -->

              <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
                <v-tab
                  v-for="(item, index) in newArrayItems"
                  :key="index"
                  :href="'#tab-' + item.href"
                >
                  {{ item.tabName }}
                </v-tab>

                <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="align-self-center mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      more
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(item, index) in more"
                      :key="index"
                      @click="addItem(item)"
                    >
                      {{ item.tabName }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tabs>

              <v-tabs-items v-model="currentItem">
                <v-tab-item value="tab-1">
                  <v-col>
                    <GenerateData :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <Values :eager="true" :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <Accounting :eager="true" :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <WriteOffs />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                  <v-textarea
                  :readonly="justVisible"
                    outlined
                    label="*Anotações"
                    auto-grow
                    v-model="object.annotation"
                    required
                    :rules="[() => !!object.annotation || 'Campo Obrigatorio',]"
                  ></v-textarea>
                </v-tab-item>
              </v-tabs-items>

              <!-- <v-tabs class="success">
                <v-tab href="#tab-1">
                  <v-icon class="mr-2"> mdi-card-account-details </v-icon>Dados
                  Gerais
                </v-tab>
                <v-tab href="#tab-2">
                  <v-icon class="mr-2">mdi-cash-check</v-icon>Valores
                </v-tab>
                <v-tab href="#tab-3">
                  <v-icon class="mr-2">mdi-account-cash</v-icon>Contábil
                </v-tab>
                <v-tab href="#tab-4">
                  <v-icon class="mr-2">mdi-file-outline</v-icon>Baixas
                </v-tab>
                <v-tab href="#tab-5">
                  <v-icon class="mr-2">mdi-file-outline</v-icon>Anotações
                </v-tab>
                <v-tab-item value="tab-1">
                  <v-col>
                    <GenerateData :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <Values :eager="true" :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <Accounting :eager="true" :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <WriteOffs />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                  <v-textarea
                    outlined
                    label="Anotações"
                    auto-grow
                    v-model="object.annotation"
                  ></v-textarea>
                </v-tab-item>
              </v-tabs> -->

              <!-- IMPLANTAÇÃO -->
            </v-col>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    changeDialog();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar
              </v-btn>
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!Validation_form.enable_Button_Submit"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WriteOffs from "../Tabs/WriteOffs";
import GenerateData from "../Tabs/GenerateData";
import Values from "../Tabs/Values";
import Accounting from "../Tabs/Accounting";
import { mapGetters } from "vuex";

import {
  VIEW_BILLS_RECEIVE,
  ADD_BILLSRECEIVE,
  UPDATE_BILLSRECEIVE,
} from "../../../store/actions/bills_receive";

export default {
  props: {
    component: Object,
  },

  components: {
    WriteOffs,
    GenerateData,
    Values,
    Accounting,
  },

  data() {
    return {
      justVisible: Boolean(),
      newArrayItems: Array(),
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: "Valores",
          href: "2",
        },
        {
          tabName: "Contábil",
          href: "3",
        },
        {
          tabName: "Baixas",
          href: "4",
        },
        {
          tabName: "Anotações",
          href: "5",
        },
      ],
      currentItem: "tab-1",
      Validation_form: {
        enable_Button_Submit: this.component.enable_disable_save_addDialog,
        tab_Generate_Data: false,
        tab_Values: false,
        tab_Accounting: false,
      },
      attrs: false,
      on: false,
      accounting_plan: [],
      valid: Boolean(),
      resultAliquot: null,
      search: String(),
      formHasErrors: false,
      plan: String(),
      object: {
        id: undefined,
        client_id: String(),
        status: String(),
        issuance_date: String(),
        issuance_due: String(),
        type_title: String(),
        number_title: String(),
        value_title: String(),
        dejection: 0,
        interest: 0,
        value_net: 0,
        title_balance: 0,
        account_plans_id: String(),
        enterprise_id: undefined,
        client: {
          people_name: String(),
          people_id: null,
          type_people: String(),
        },
      },
      aux1: 0,
      aux2: 0,
      aux3: 0,
      validGenerate: false,
      validValues: false,
      validAccounting: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedEnterprise", "getAddedClients", 'getViewBillsReceive']),
  },
  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    getComponent() {
      return this.component;
    },
    changeDialog() {
      this.$store.dispatch(VIEW_BILLS_RECEIVE, Object())
      this.component.addDialog = !this.component.addDialog;
    },
    async create_bills() {
      this.object.account_plans_id = this.plan;
      this.object.enterprise_id = this.getSelectedEnterprise.id;
      const bills = {
        ...this.object,
      };
      await this.$store.dispatch(ADD_BILLSRECEIVE, bills).then(() => {
        this.changeDialog();
      });
    },
    submit() {
      this.object.id == undefined ? this.create_bills() : this.update_bills();
    },
    async update_bills() {
      this.object.account_plans_id = this.plan.id;
      const bills = {
        ...this.object,
      };
      await this.$store.dispatch(UPDATE_BILLSRECEIVE, bills).then(() => {
        this.changeDialog();
      });
    },
    validate() {
      this.$refs.form.validate();
    },

    loadAccountPlan(){
      this.accounting_plan = Object.keys(this.component.getAccount_Plan).map(
        (key) => this.component.getAccount_Plan[key]
      );
    },

    mountObjectToUpdate() {
      this.loadAccountPlan()
      if (Object.keys(this.getComponent().objectToUpdate).length) {
        this.object = { ...this.getComponent().objectToUpdate };
        this.object.dejection = this.object.dejection.toString();
        this.object.interest = this.object.interest.toString();
        this.object.issuance_date = this.object.issuance_date
          .split("/")
          .reverse()
          .join("-");
        this.object.issuance_due = this.object.issuance_due
          .split("/")
          .reverse()
          .join("-");

        if (this.object.client.type_people == "PhysicalPeople") {
          this.object.client.people_name =
            this.object.client.people.physicalpeople.name;
        } else {
          this.object.client.people_name =
            this.object.client.people.legalpeople.fantasyName;
        }

        let accountPlan = Object.values(this.component.getAccount_Plan);
        this.plan = accountPlan.find((r) => {
          if (r.id == this.object.account_plans_id) {
            return r;
          }
        });
        this.validValues = true;
        this.validAccounting = true;
      } else {
        this.object.enterprise_id = this.component.enterprise_id;
        this.object.invoiceentry_id = null;
        this.object.status = "Aberto";
      }
    },
    viewObject(object){
      this.object = {...object.item}

      this.justVisible = object.justVisible
      this.object.status = object.item.status
      this.object.issuance_date = object.item.issuance_date.replace(/[^0-9]/g, '-').split("-").reverse().join("-");
      this.object.issuance_due = object.item.issuance_due.replace(/[^0-9]/g, '-').split("-").reverse().join("-");

      switch (object.item.type_title) {
        case "DINHEIRO":
          this.object.type_title = 'Dinheiro'
          break;
        
        case "BOLETO":
          this.object.type_title = 'Boleto'
          break;

        default:
          this.object.type_title = 'Depósito Bancário'
          break;
      }
      
      this.plan = object.item.account_plans_id
      this.object.number_title = object.item.number_title
      this.object.client.people_name = object.item.client.name

      
    }
  },

  async created() {
    this.loadAccountPlan()
    this.setArrayItens()
    if(this.getViewBillsReceive.justVisible){
      this.viewObject(this.getViewBillsReceive)

    }else{
      this.justVisible = false
      this.mountObjectToUpdate();
    }
    


  },

  watch: {
    "$vuetify.breakpoint.xsOnly": function () {
      this.setArrayItens()
    },
    getAddedClients: function (value) {
      if (value.type_people == "LegalPeople") {
        this.object.client.people_name = value.legalpeople.fantasyName;
        this.object.client.people_id = value.legalpeople.people_id;
      } else {
        this.object.client.people_name = value.physicalpeople.name;
      }
      this.object.client_id = value.id;
      this.object.client.type_people = value.type_people;
    },
    "Validation_form.tab_Generate_Data": function () {
      //console.log(this.Validation_form.enable_Button_Submit)
      if (
        this.Validation_form.tab_Generate_Data == true &&
        this.Validation_form.tab_Values == true &&
        this.Validation_form.tab_Accounting == true
      ) {
        this.Validation_form.enable_Button_Submit = true;
      }
    },
    "Validation_form.tab_Values": function () {
      //console.log(this.Validation_form.enable_Button_Submit)
      if (
        this.Validation_form.tab_Generate_Data == true &&
        this.Validation_form.tab_Values == true &&
        this.Validation_form.tab_Accounting == true
      ) {
        this.Validation_form.enable_Button_Submit = true;
      }
    },
    "Validation_form.tab_Accounting": function () {
      // console.log(this.Validation_form.enable_Button_Submit)
      if (
        this.Validation_form.tab_Generate_Data == true &&
        this.Validation_form.tab_Values == true &&
        this.Validation_form.tab_Accounting == true
      ) {
        this.Validation_form.enable_Button_Submit = true;
      }
    },

    "object.value_title": function (val) {
      this.aux1 = parseFloat(val);
      this.object.value_net = this.aux1 - this.aux2 + this.aux3;
      if (isNaN(this.object.value_net)) {
        this.object.value_net = "";
      }
      this.object.title_balance = this.object.value_net;
    },
    "object.dejection": function (val) {
      this.aux2 = parseFloat(val);
      this.object.value_net = this.aux1 - this.aux2 + this.aux3;
      if (isNaN(this.object.value_net)) {
        this.object.value_net = "";
      }
      this.object.title_balance = this.object.value_net;
    },
    "object.interest": function (val) {
      this.aux3 = parseFloat(val);
      this.object.value_net = this.aux1 - this.aux2 + this.aux3;
      if (isNaN(this.object.value_net)) {
        this.object.value_net = "";
      }
      this.object.title_balance = this.object.value_net;
    },
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>

