const rulesText = [(v) => v.length !== 0 || 'Campo obrigatório!']

const incomeNegative = [
    v => !!v || 'Código é obrigatório',
    v => v.length >= 1 || 'Campo tem que ser maior que 1',
    v => {
        if (v < 0) {
            return 'Valores negativos não são permitidos!'
        } else {
            return true
        }
    }
]

export { incomeNegative, rulesText }