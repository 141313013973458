<template>
    <v-row justify="center">
    <v-dialog max-width="800px" persistent v-model="component.selectTemplateDialog">
        <v-card>
            <div class="container-header-select-table">
                <p class="title-select-table"> Selecione uma configuração de imposto </p>
                <v-form ref="form" v-model="valid">
                <v-select
                    class="mt-3"
                    label="Configuração de imposto"
                    outlined
                    :items="component.templates"
                    item-text="templateType"
                    return-object
                    v-model="component.selectedTemplate"
                    :rules="[
                    () =>
                      !!component.selectedTemplate || 'Campo Obrigatório',
                  ]"
                >
                </v-select>
            </v-form>
            </div>
            <v-divider></v-divider>
            <v-card-actions style="justify-content: center;">
                <v-btn
                     class="error"
                     @click="closeDialog()"
                >
                <v-icon class="mr-2">mdi-cancel</v-icon>
              CANCELAR
            </v-btn>
            <v-btn
                     class="success"
                     @click="selectTemplate()"
                     :disabled="!valid"
                >
                <v-icon class="mr-2">mdi-check</v-icon>
              CONFIRMAR
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        component: Object(),
    },
    data: function () {
        return {
            valid: false,
        }
    },

    methods: {
        selectTemplate() {
            this.component.selectTemplate()
        },
        closeDialog() {
            this.component.selectTemplateDialog = false
        }
    },
    created() {
    }
}
</script>

<style lang="css" scoped>
.title-select-table {
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
}

.container-header-select-table {
    padding: 5%;
}
</style>