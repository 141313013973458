<template>
  <v-form  ref="form" v-model="component.valid"  @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data Inicial da Movimentação"
          type="date"
          v-model="component.report.initialdatemovement"
          outlined
          :rules="[
                  () => !!component.report.initialdatemovement || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data Final da Movimentação"
          type="date"
          v-model="component.report.finaldatemovement"
          outlined
          :rules="[
                  () => !!component.report.finaldatemovement || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Tipo de Movimentação" 
          :items="move"
          v-model="component.report.type_moviment"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Caixa" 
          v-model="component.report.cash_id"
          :items="component.cashier"
          item-text="description"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Plano de Contas" 
          v-model="component.report.account_plan_id" 
          :items="component.account" 
          item-text="description" 
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props:{
    component: Object
  },

  data() {
    return {
      move: ["Entrada", "Saída"]
    }
  },

  methods:{
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  }
};
</script>

<style>
</style>