<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      hide-default-footer
      no-data-text="Sem registros"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-icon color="green darken-4" class="mr-2"  @click="
          () => {
            visibleItem(item);
          }
        ">mdi-eye</v-icon>
        <v-icon
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          @click="
            () => {
              active_delete(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div>
      <v-divider></v-divider>
      <v-pagination
        v-model="current"
        :length="total"
        total-visible="10"
        color="success"
      ></v-pagination>
    </div>
    <destroy
      v-if="destroyDialog"
      :destroy_object="destroyItem"
      :component="this"
    />
    <editOrder
      v-if="dialog"
      :component="this"
      :objectToForm="objectForAction"
    />
  </div>
</template>

<script>
import {
  GET_ALL_PURCHASEORDER,
  DESTROY_PURCHASEORDER,
  ITEM_TO_UPDATE
} from "../../../store/actions/purchaseOrder";
import destroy from "../destroyDialog/destroyDialog";
import editOrder from "../AddDialog/";
import { mapGetters } from "vuex";
import { headers } from "./script";
export default {
  components: {
    destroy,
    editOrder,
  },
  data: () => ({
    headers,
    current: Number(),
    total: Number(),
    results: Array(),
    objectForAction: Object(),
    destroyDialog: Boolean(),
    dialog: Boolean(),
  }),
  methods: {
    get_All(page = 1) {
      this.$store.dispatch(GET_ALL_PURCHASEORDER, {
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    active_delete(item) {
      this.destroyDialog = !this.destroyDialog;
      this.objectForAction = { ...item };
    },
    destroyItem() {
      let id = this.objectForAction.id;
      this.$store.dispatch(DESTROY_PURCHASEORDER, id);
      this.destroyDialog = !this.destroyDialog;
    },
    visibleItem(item){
      this.$store.dispatch(ITEM_TO_UPDATE, {item: item, justVisible: true})
      this.dialog = !this.dialog;
    },
    edit_item(item) {
      this.dialog = !this.dialog;
      this.$store.dispatch(ITEM_TO_UPDATE,item)
      // this.objectForAction = { ...item };
      
    },
  },
  async created() {
    await this.get_All();
  },
  computed: {
    ...mapGetters(["get_purchaseOrder"]),
  },
  watch: {
    get_purchaseOrder: function (val) {
      this.current = val.current_page;
      this.total = val.last_page;
      this.results = val.data;
    },
    current: function (val) {
      this.get_All && this.get_All(val);
    },
  },
};
</script>
