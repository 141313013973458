<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="CardTitle" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>

      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getFinancialMovement"
        :destroy_object="destroy_object"
        :search="search"
        :change_addDialog="change_addDialog"
        :visibleItem="visibleItem"

      />
      <AddDialog v-if="addDialog" :component="this" ref="edit" />
      <AddDialogCashRegisterTransfer
        :enterpriseID="enterpriseId"
        :component="this"
      />
    </v-card>
    <LoadScreen/>
    <AlertApi />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/financial_movement/CardTitle/";
import Table from "../components/financial_movement/Table/";
import AddDialog from "../components/financial_movement/AddDialog/";
import AddDialogCashRegisterTransfer from "../components/financial_movement/TransferCashRegister";
import {
  GET_ALL_FINANCIAL_MOVEMENTE,
  DESTROY_FINANCIAL_MOVEMENTE,
} from "../store/actions/financial_movement";
// import { GET_ALL_ACCOUNT_PLAN } from "../store/actions/account_plan";
import { GET_ALL_CASHIER } from "../store/actions/cashier";
import AlertApi from "./../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
export default {
  components: {
    AddDialog,
    LoadScreen,
    AddDialogCashRegisterTransfer,
    Container,
    CardTitle,
    Table,
    AlertApi,
  },

  data: () => ({
    search: "",
    code: "",
    getter: "getFinancialMovement",
    addDialog: false,
    activeDialogCashRegisterTransfer: false,
    objectToUpdate: {},
    title: "Movimentação financeira",
    enterpriseId: Number(),
    permission: Object(),
    cashier: Object(),
  }),

  methods: {
    async destroy_object(item) {
      const financial_movement = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(
          DESTROY_FINANCIAL_MOVEMENTE,
          financial_movement
        );
      } catch (error) {
        alert(error);
      }
    },
    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
      this.cashier.id = item.id_cashier;
      this.cashier.description = item.name_cashier;
    },
    visibleItem(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = {item: item, justVisible: true};
      this.cashier.id = item.id_cashier;
      this.cashier.description = item.name_cashier;
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_FINANCIAL_MOVEMENTE, value);
    },
    async GetAllTransferCashier() {},

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
    //  await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, value);
      await this.$store.dispatch(GET_ALL_CASHIER, value);
    },
  },

  async created() {
    this.GetAll();
    this.GetAllNoPage();
  },
  computed: {
    ...mapGetters([
      "getFinancialMovement",
     // "getAccount_Plan",
      "getFinanceiroLinks",
      "getCashier",
    ]),
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getFinanceiroLinks.getPermissions(name);
    this.$watch(
      () => this.$refs.CardTitle.selectValue,
      function (val) {
        this.enterpriseId = val.id;
      }
    );
  },
  watch: {
  
    activeDialogCashRegisterTransfer: function () {
      // console.log(this.activeDialogCashRegisterTransfer)
    },
  },
};
</script>

<style scoped></style>
