const ADD_PDV = "ADD_PDV";
const UPDATE_PDV = "UPDATE_PDV";
const GET_ALL_PDV = "GET_ALL_PDV";
const DESTROY_PDV = "DESTROY_PDV";
const GET_FILTER_PDV = "GET_FILTER_PDV";

module.exports = {
    ADD_PDV,
    UPDATE_PDV,
    GET_ALL_PDV,
    DESTROY_PDV,
    GET_FILTER_PDV
}