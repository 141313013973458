<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="ml-2" v-bind="attrs" v-on="on">
              <v-icon color="success">mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <span>Pendências da nota</span>
  </v-tooltip>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: "Fornecedor não cadastrado" },
      { title: "Item 1 não cadastrado" }
    ]
  })
};
</script>

<style>
</style>