var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.getShowDialogRegisterCFOP),callback:function ($$v) {_vm.getShowDialogRegisterCFOP=$$v},expression:"getShowDialogRegisterCFOP"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("CFOP")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Codigo","readonly":_vm.justVisible,"rules":[
                () => !!_vm.code || 'Campo Obrigatorio',
                _vm.getFiscalType == 1
                  ? () =>
                      (!!_vm.code &&
                        _vm.code.length == 4 &&
                        _vm.code >= 1000 &&
                        _vm.code <= 3000) ||
                      'CFOP de entrada inválido'
                  : _vm.getFiscalType == 2
                  ? () =>
                      (!!_vm.code &&
                        _vm.code.length == 4 &&
                        _vm.code >= 5000 &&
                        _vm.code <= 7000) ||
                      'CFOP de Saída inválido'
                  : () =>
                      (!!_vm.code && _vm.code.length == 4) ||
                      'Campo tem que ser igual a 4',
              ],"type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"4","outlined":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('v-col',[_c('v-textarea',{attrs:{"label":"*Descrição","rules":[() => !!_vm.description || 'Campo Obrigatorio'],"readonly":_vm.justVisible,"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  _vm.close_Cfop();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v("Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v("Salvar")],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }