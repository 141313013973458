<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="changeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ title }} Pré-Entrada de Nota Fiscal</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <v-divider></v-divider>

          <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
            <v-tab
              v-for="(item, index) in newArrayItems"
              :key="index"
              :href="'#tab-' + item.href"
            >
              {{ item.tabName }}
            </v-tab>

            <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="align-self-center mr-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  more
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                  v-for="(item, index) in more"
                  :key="index"
                  @click="addItem(item)"
                >
                  {{ item.tabName }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>

          <v-tabs-items v-model="currentItem">
            <v-tab-item value="tab-1">
              <v-col>
                <GenerateDate :component="this" :isDisable="disabled" />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-col>
                <NoteItems
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-col>
                <Transport
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <v-col>
                <InformationNote
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
          </v-tabs-items>

          <!-- <v-tabs color="primary" v-model="tabs">
            <v-tab>
              <v-icon class="mr-2">mdi-file-document</v-icon>Dados Gerais
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">mdi-file-find</v-icon>Itens da Nota
            </v-tab>
            <v-tab>
              <v-icon class="mr-2"> mdi-truck </v-icon> Transportadora
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">mdi-information</v-icon> Informações da Nota
            </v-tab>

            <v-tab-item
              ><GenerateDate :component="this" :isDisable="disabled"
            /></v-tab-item>
            <v-tab-item
              ><NoteItems :component="this" :isDisable="disabled" :eager="true"
            /></v-tab-item>
            <v-tab-item>
              <Transport
                :component="this"
                :isDisable="disabled"
                :eager="true"
              />
            </v-tab-item>
            <v-tab-item>
              <InformationNote
                :component="this"
                :isDisable="disabled"
                :eager="true"
              />
            </v-tab-item>

            <v-spacer></v-spacer>
          </v-tabs> -->
          <!-- <v-btn class="mt-2 mr-3 success" v-show="!disabled">
              <v-icon class="mr-1">mdi-file-import-outline</v-icon>Importar XML
            </v-btn> -->
          <v-divider></v-divider>
          <v-card-actions v-show="!disabled">
            <v-spacer></v-spacer>
            <v-btn color="error">
              <v-icon class="mr-1">mdi-cancel</v-icon>
              Limpar Formulário
            </v-btn>
            <v-btn
              color="success"
              class="mr-4"
              @click="change_confirm()"
              :disabled="!valid"
            >
              <v-icon class="mr-1"> mdi-content-save </v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
          <!-- </v-form> -->
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import InformationNote from "../Tabs/TabInfoNote/";
import GenerateDate from "../Tabs/TabGenerateDate/";
import NoteItems from "../Tabs/TabNoteItens/";
import Transport from "../Tabs/TabTransport/";

import { adjust_variables } from "../script/adjust_variables/";
import business_rule from "../script/business_rule/";
import { GET_ALL_TRANSPORTER_TO_SELECT_FIELD } from "../../../store/actions/transporters";
import {
  ADD_PRE_NOTEENTRY,
  UPDATE_PRE_NOTEENTRY,
} from "../../../store/actions/preNote_entry";

export default {
  components: {
    InformationNote,
    GenerateDate,
    NoteItems,
    Transport,
  },

  props: {
    component: Object,
    title: String,
    disabled: Boolean,
    dialog: Boolean,
    changeDialog: Function,
    enterpriseId: Number,
    object: Object,
  },

  data: () => ({
    newArrayItems: Array(),
    currentItem: "tab-1",
    items: [
      {
        tabName: "Dados Gerais",
        href: "1",
      },
    ],
    more: [
      {
        tabName: "Itens da Nota",
        href: "2",
      },
      {
        tabName: "Transportadora",
        href: "3",
      },
      {
        tabName: "Informações da Nota",
        href: "4",
      },
    ],
    tabs: Number(),
    select_provider: "",
    nameProvider: String(),
    valid: true,
    providers: Array(),
    validCST: Boolean(),

    dialogConfirm: Boolean(),
    confirmDialog: false,
    provider_id: Number(),
    contabil_value: Number(),
    aliquot: 1,
    provider: Object(),

    invoice: {
      status: String(),
      tax_operation_types_id: Number(),
      access_key: String(),
      authorization_protocol: String(),
      provider_id: Number(),
      number_grade: Number(),
      series: String(),
      date_of_issue: String(),
      entry_date: String(),

      //Itens da Nota
      itens: Array(),

      //Cabeçalho da Nota
      base_calc_icms_total: Number(),
      value_icms_total: Number(),
      base_calc_susbt_total: Number(),
      value_icms_substituicao_total: Number(),
      base_calc_ipi_total: Number(),
      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      value_total_products: Number(),
      value_discont: Number(),
      type_freigth: String(),
      value_freigth: Number(),
      value_security: Number(),
      value_icms_free_total: Number(),
      value_total_note: Number(),
      value_untaxed_tax_total: Number(),
      value_account_total: Number(),
      value_outher_tax_total: Number(),
      value_other_incidental_expenses: Number(),
      value_icms_outher_product: Number(),
      
      //Transportadora
      transporter_id: null,
      vehicle_plate: String(),
      volume_transported: Number(),
      gross_weigth: Number(),
      net_weight: Number(),
      volume_type: String(),
      outher_expenses_acess: Number(),
      note: String(),
      account_plans_id: Number(),
      enterprise_id: Number(),
    },
  }),

  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    adjust_variables,
    ...business_rule,

    change_confirm() {
      if (this.getNoteEntryItens.length == 0) {
        alert("Adicione um item a nota!");
      } else {
        this.mountGradeItens();
        this.adjust_variables(this);

        this.invoice.id == undefined
          ? this.create_noteEntry()
          : this.update_noteEntry();
      }
    },

    refresh_discount() {
      this.calculate_all();
    },

    mountGradeItens() {
      this.invoice.itens =
        this.invoice.id == undefined
          ? this.getNoteEntryItens
          : this.getNoteEntryUpdate;
    },

    async create_noteEntry() {
      this.invoice.status = "PRE-NOTA";
      this.invoice.account_plans_id = null;
      let invoice = { ...this.invoice };
      invoice.provider_id = this.select_provider
      try {
        await this.$store.dispatch(ADD_PRE_NOTEENTRY, invoice).then(() => {
          this.changeDialog();
        });
      } catch (error) {
        alert(error);
      }
    },

    async update_noteEntry() {
       
      this.invoice.grade_itens = this.getListUpdatedItem
      // let x = this.getListUpdatedItem
      const invoice = this.invoice
      try {
        await this.$store.dispatch(UPDATE_PRE_NOTEENTRY, invoice);
      } catch (error) {
        alert(error);
      } finally {
        this.changeDialog();
      }
    },

    getComponent() {
      return this.component;
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_TRANSPORTER_TO_SELECT_FIELD, value);
    },

    mountedObjectForView() {
      let item = this.object;
      this.invoice = { ...this.object };
      this.invoice.enterprise_id = item.enterprise_id;
      this.invoice.value_base_icms_st_product = 0;
      this.invoice.grade_itens.forEach((e) => {
        e.value_products = e.value_sub_total_product;
      });

      this.invoice.date_of_issue = item.date_of_issue
        .split("/")
        .reverse()
        .join("-");
      this.invoice.entry_date = item.entry_date.split("/").reverse().join("-");

      



      this.nameProvider = item.provider.type_people == "LegalPeople" ?  item.provider.people.legalpeople.fantasyName : item.provider.people.physicalpeople.name

      this.select_provider = item.provider_id;
      this.invoice.gross_weigth = item.gross_weight;

      this.calculate_all(this);
    },
  },

  created() {
    this.setArrayItens()
    this.GetAllNoPage();

    let isNew = 1;
    let isUpdate_View = 2;

    if (this.component.checkinfo == isNew) {
      this.invoice.enterprise_id = this.enterpriseId;
    } else if (this.component.checkinfo == isUpdate_View) {
      this.mountedObjectForView();
    }
  },

  computed: {
    ...mapGetters([
      "getNoteEntryItens",
      "getTransporterAuto",
      "getNoteEntryUpdate",
      "getListUpdatedItem"
    ]),
  },

  watch: {
    "$vuetify.breakpoint.xsOnly": function(){
      this.setArrayItens()
    },
    getNoteEntryItens: function (val) {
      if (val[0]) {
        this.aliquot = val[0].aliquot;
      }

      this.calculate_all();
    },
    // getNoteEntryItens: function (val) {
    //   let arrayEmpty = 0;
    //   if (this.getNoteEntryItens.length !== arrayEmpty) {
    //     if (val[0]) {
    //       this.aliquot = val[0].aliquot;
    //     }
    //     this.calculate_all();
    //   }
    // },

    select_provider: function (val) {
      let isNew = 1;
      let isUpdate_View = 2;

      if (this.component.checkinfo == isNew) {
        this.invoice.provider_id = val.provider_id;
      } else if (this.component.checkinfo == isUpdate_View) {
        if (typeof val === "object") {
          this.invoice.provider_id = val.provider_id;
        } else {
          this.invoice.provider_id = val;
        }
      }
    },
  },
};
</script>
