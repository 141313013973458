<template>
  <div>
    <v-data-table
      :headers="Header"
      :items="items"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-printer</v-icon
            >
          </template>
          <span>Reimprimir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <ReprintNFE />
  </div>
</template>

<script>
import { Header } from "./helpers/Headers";
import { mapGetters } from "vuex";
import ReprintNFE from "../ReprintNFE/index.vue";
import { ITEM_TO_REPRINT } from "../../../store/actions/nfe";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_noteModels: Function,
    search: String,
  },
  components: {
    ReprintNFE,
  },
  data: () => ({
    Header: Header,
    items: Array(),
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),

  methods: {
    open_Dialog(item) {
      this.$store.dispatch(ITEM_TO_REPRINT, item);
      this.$store.dispatch("setShowDialogRegisterReprintNFE", true);
    },
    loadTable() {
      this.items = Object.values(this.getNfe);
    },
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },

  created() {
    this.loadTable();
  },

  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.noteModel_filter && this.component.noteModel_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
  computed: {
    ...mapGetters(["getNfe"]),
  },
};
</script>
