<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" :getters="getEnterprises" />
      </v-card-text>

      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :search="search"
        :destroy_object="destroy_object"
        :component="this"
        :getters="getSeller"
      />
      <AddDialog
        v-if="getShowDialogRegisterSeller"
        :component="this"
        :enterpriseId="selectValue.id"
      />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/Sellers/CardTitle/";
import AddDialog from "../components/Sellers/AddDialog/";
import Table from "../components/Sellers/Table/";
import AlertAPI from "../components/alert_api/alert_api";
import { DESTROY_SELLER, GET_ALL_SELLER } from "../store/actions/seller";
import loadScreen from "../components/load/";
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise";

export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getSeller",
    addDialog: false,
    destroyDialog: false,
    updateDialog: Boolean(),
    dialog_delete: Boolean(),
    permission: Object(),
    idToDestroy: 0,
    objectToUpdate: {},
    title: "Vendedor",
    selectValue: null,
  }),
  methods: {
    async destroy_object(item) {
      const seller = {
        id: item.id,
        enterprise_id: item.enterprise_id,
      };
      try {
        await this.$store.dispatch(DESTROY_SELLER, seller);
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_SELLER, value);
      //await dispatchGetAll(this, GET_ALL_ENTERPRISE, value);
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);

      if (this.getEnterprises.length > 0) {
        this.selectValue = this.getEnterprises[0].id;
      }
    },
  },

  components: {
    Container,
    CardTitle,
    Table,
    loadScreen,
    AddDialog,
    AlertAPI,
  },

  created() {
    this.GetAll();
    this.GetAllNoPage();
  },

  computed: {
    ...mapGetters([
      "getSeller",
      "getCadastrosLinks",
      "getEnterprises",
      "getShowDialogRegisterSeller",
    ]),
  },

  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>

<style scoped></style>
