<template>
  <v-container>
    <v-form :disabled="isDisable">
      <v-row>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Base Calc do ICMS"
            v-model="component.invoice.base_calc_icms_total"
            readonly
            required
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            readonly
            label="Valor do ICMS"
            v-model="component.invoice.value_icms_total"
            required
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="6" lg="4" md="4" sm="4" xl="4" >
          <v-text-field
            label="Base Calc ICMS ST"
            v-model="component.invoice.value_base_icms_st_product"
            readonly
            required
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Valor do ICMS ST"
            v-model="component.invoice.value_icms_substituicao_total"
            readonly
            required
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Valor Contábil"
            v-model="component.invoice.value_account_total"
            readonly
            required
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Base de Cálculo IPI"
            v-model="component.invoice.base_calc_ipi_total"
            readonly
            outlined
            prefix="R$"
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Valor Total do IPI"
            v-model="component.invoice.value_ipi_total"
            readonly
            outlined
            prefix="R$"
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Valor IPI Outros"
            v-model="component.invoice.value_ipi_outher_total"
            readonly
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Valor Isento IPI"
            v-model="component.invoice.value_ipi_free_total"
            readonly
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            label="Valor Total dos Produtos"
            v-model="component.invoice.value_total_products"
            readonly
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xl="2" >
          <v-text-field
            outlined
            v-model="component.invoice.value_icms_outher_product"
            label="Outros Valores"
            readonly
            prefix="R$"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" lg="4" md="4" sm="4" xl="4" >
          <v-text-field
            label="Desconto"
            v-model="component.invoice.value_discont"
            v-on:change="component.refresh_discount()"
            prefix="R$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="4" md="4" sm="4" xl="4" >
          <v-text-field
            label="Valor ICMS Isento"
            v-model="component.invoice.value_icms_free_total"
            readonly
            outlined
            prefix="R$"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4" >
          <v-text-field
            label="Valor Total da Nota"
            v-model="component.invoice.value_total_note"
            prefix="R$"
            readonly
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    component: Object(),
    isDisable: Boolean(),
  },
  data: () => ({}),
};
</script>

<style>
</style>