function returnArrayToRegister(payload, arrayManufacturedCode, responseData) {
  for (
    let indexArrayManufactured = 0;
    indexArrayManufactured < arrayManufacturedCode.length;
    indexArrayManufactured++
  ) {
    for (
      let indexResponseData = 0;
      indexResponseData < responseData.length;
      indexResponseData++
    ) {
      if (
        responseData[indexResponseData][
        arrayManufacturedCode[indexArrayManufactured]
        ] != false &&
        responseData[indexResponseData][
        arrayManufacturedCode[indexArrayManufactured]
        ] != undefined
      ) {
        let amountAux = payload.arrayProducts[indexArrayManufactured].amount
        payload.arrayProducts[indexArrayManufactured] =
          responseData[indexResponseData][
          arrayManufacturedCode[indexArrayManufactured]
          ][0];
        payload.arrayProducts[indexArrayManufactured].Tax = payload.arrayTax[indexArrayManufactured]
        payload.arrayProducts[indexArrayManufactured].isRegistered = true;
        payload.arrayProducts[indexArrayManufactured].amount = amountAux
      }
    }
  }

  return payload.arrayProducts;
}

module.exports = {
  returnArrayToRegister,
};
