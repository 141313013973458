const ADD_NCM = "ADD_NCM";
const UPDATE_NCM = "UPDATE_NCM";
const GET_ALL_NCM = "GET_ALL_NCM";
const DESTROY_NCM = "DESTROY_NCM";
const GET_FILTER_NCM = "GET_FILTER_NCM";
const AUX_TO_UPDATE_NCM = "AUX_TO_UPDATE_NCM";

module.exports = {
    ADD_NCM,
    UPDATE_NCM,
    GET_ALL_NCM,
    DESTROY_NCM,
    GET_FILTER_NCM,
    AUX_TO_UPDATE_NCM,
}
