/* eslint-disable no-unused-vars */
module.exports = _ => {
    // array
    Array.prototype.Update = function (editNoteModel) {
        let result = this.find(r => r.id === editNoteModel.id);

        for (let key in editNoteModel) {
            result[key] = editNoteModel[key]
        }
    }
    Array.prototype.convertItemToSeed = function () {
        let a = []

        let items = this.map(r => {
            return {
                name: r.text,
                display_name: r.text,
                route_name: r.route.split('/').pop(),
                description: ''
            }
        });

        items.forEach(r => {
            let response = `
    DB::table($tableName)->insert([
            'name' =>'${r.name}',
            'display_name' => '${r.display_name}',
            'route_name' => '${r.route_name}',
            'description' => '${r.description}',

        ]);
`
            a.push(response)


        })
        return JSON.stringify(a).trim()

    }

    Array.prototype.getPermissions = function (name) {
        let currentRoute = this.find(
            (r) => r.route.split("/").pop() === name
        );

        let permission = {
            "Editar": Boolean (),
            "Visualizar": Boolean (),
            "Excluir": Boolean (),
            "Cadastrar": Boolean (),
            "Confirmar": Boolean(),
            "Estornar":Boolean(),
            "Baixar":Boolean(),
            
        }
        for (const key of currentRoute.permission_col) {
            permission[key] = true;
        }
        return permission
    }

    // array

    String.prototype.dateDDMMYYY = function () {
        var data = new Date(this);
        let dia = (data.getDate() + 1).toString();
        let diaF = dia.length == 1 ? "0" + dia : dia;
        let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        let mesF = mes.length == 1 ? "0" + mes : mes;
        let anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }
    String.prototype.dateYYMMDD = function () {
        if (this.includes('/')) {
            let dateParts = this.split("/");
            let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            let day = date.getDate() < 9 ? '0' + date.getDate() : date.getDate()
            let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            let fullYear = date.getFullYear()

            return `${fullYear}-${month}-${day}`
        } else {
            let date = new Date(parseInt(this));
            let day = date.getDate() < 9 ? '0' + date.getDate() + 1 : date.getDate() + 1
            let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            let fullYear = date.getFullYear()

            return `${fullYear}-${month}-${day}`
        }
    }
}