<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col>
        <v-text-field
          label="*Contabilidade"
          :readonly="justVisible"
          v-model="component.enterprise.accountancy"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*Contador"
          :readonly="justVisible"
          v-model="component.enterprise.conter"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*CRC"
          :readonly="justVisible"
          v-model="component.enterprise.crc"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-select
          label="Ambiente de Emissão de Nota"
          :readonly="justVisible"
          v-model="component.enterprise.noteissung_enviroment"
          :items="tbAmb"
          item-value="value"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>

      <v-col>
        <v-text-field
          label="*Cnai"
          :readonly="justVisible"
          v-model="component.enterprise.cnai"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="*Cnpj para autorização NF"
            :readonly="justVisible"
            v-model="component.enterprise.cnpj_authorization"
            :rules="campoRules"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="*Cpf para autorização NF"
            :readonly="justVisible"
            v-model="component.enterprise.cpf_authorization"
            :rules="campoRules"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Informações nfe"
            :readonly="justVisible"
            v-model="component.enterprise.information_nfe"
            :rules="[v => v.length <= 300 || 'Máximo 300 caracteres']"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Informações nfce"
            :readonly="justVisible"
            v-model="component.enterprise.information_nfce"
            :rules="[v => v.length <= 300 || 'Máximo 300 caracteres']"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            label="*Codigo de Situação tributaria"
            :readonly="justVisible"
            :items="codeSituationTax"
            v-model="component.enterprise.crt"
            outlined
            :rules="campoRules"
          ></v-select>
        </v-col>
      </v-row>

  </v-form>
</template>

<script>
import { campoRules } from "../script";
export default {
  props: {
    component: Object(),
    justVisible: Boolean(),
  },
  data: () => ({
    valid: false,
    tbAmb: [
      { text: "Produção", value: "1" },
      { text: "Homologação", value: "2" },
    ],
     codeSituationTax: [
      { text: "Simples Nacional", value: "1" },
      { text: "Simples Nacional - Crédito", value: "2" },
      { text: "Regime Normal ", value: "3" },
    ],
    campoRules,
  }),
};
</script>
