<template>
    <v-form ref="form" v-model="component.valid" @submit.prevent="submit">
      <v-row class="mr-1 ml-1">
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            outlined
            label="Data Inicial das Vendas"
            type="date"
            v-model="component.report.date_initial_sales"
            :rules="[
              () => !!component.report.date_initial_sales || 'Campo Obrigatorio',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
          <v-text-field
            outlined
            label="Data Final das Vendas"
            v-model="component.report.date_finish_sales"
            :rules="[
              () => !!component.report.date_finish_sales || 'Campo Obrigatorio',
            ]"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-autocomplete
                label="Tipo de Custo"
                v-model="component.report.type_cost"
                :items="items"
                item-value="value"
                item-text="label"
                outlined
                :rules="[() => !!component.report.type_cost || 'Campo Obrigatorio']"
                return-object
              ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </template>
<script>
  export default {
    props: {
      component: Object,
    },
  
    data() {
      return {
        items: [
          { label: 'Valor da Nota', value: 'note_price' },
          { label: 'Custo Médio', value: 'avarenge_cost' },
          { label: 'Valor de Custo', value: 'cost_price' },
          { label: 'Valor de Venda', value: 'sale_price' },
          { label: 'Valor de Contabilidade', value: 'accounting_price' },
          { label: 'Valor de Gerenaciamento', value: 'management_cost' },
        ]
      };
    },

    methods: {

      validate() {
        this.$refs.form.validate();
      },

      reset() {
        this.$refs.form.reset();
      },

      resetValidation() {
        this.$refs.form.resetValidation();
      },
    },
  };

  </script>
  <style>

</style>