var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.results,"search":_vm.search,"no-data-text":"Nenhum resultado encontrado","no-results-text":"Nenhum resultado encontrado","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(_vm.component.permission.Excluir)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
            _vm.visibleItem(item);
          }}},[_vm._v("mdi-eye")]):_vm._e(),(_vm.component.permission.Editar)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange darken-3"},on:{"click":() => {
            //   edit_item(item);
            _vm.editItem(item);
          }}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.component.permission.Excluir)?_c('v-icon',{attrs:{"color":"red darken-3"},on:{"click":() => {
            _vm.deleteItem(item);
          }}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)}),_c('v-divider'),_c('div',[_c('v-pagination',{staticClass:"mb-3",attrs:{"length":_vm.total,"color":"success","total-visible":10},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1),_c('Edit',{ref:"edit",attrs:{"component":this}}),_c('Delete',{attrs:{"component":this}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }