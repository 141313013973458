const compras = [
    {
        "text": "Pedido de Compras",
        "icon": "mdi-basket-plus-outline",
        "route": "/purchase_order",
        "class": "white--text",
        "active": true

    },
];

export { compras };