<template>
  <v-dialog persistent max-width="1200" v-model="component.addDialogVendas">
    <v-card>
      <v-card-title> Visualizar Vendas </v-card-title>
      <v-container>
        <v-divider></v-divider>

        <v-row>
          <v-tabs centered>
            <v-tab href="#tab-1">Dados gerais</v-tab>
            <v-tab href="#tab-2">Informações da nota</v-tab>
            <v-tab href="#tab-3">Itens da nota</v-tab>
            <v-tab href="#tab-4">Pagamento</v-tab>

            <!---->

            <v-tab-item value="tab-1">
              <DadosGerais
                :component="this"
               
              />
            </v-tab-item>
            <v-tab-item value="tab-2">
              <InfNota :component="this" :items_received_add_dialog="items_received_add_dialog" />
            </v-tab-item>
            <v-tab-item value="tab-3">
              <ItensNota
              :component="this"
                :items_received_add_dialog="items_received_add_dialog"
              />
            </v-tab-item>
            <v-tab-item value="tab-4">
              <Pagamento
              :component="this"
                :items_received_add_dialog="items_received_add_dialog"
              />
            </v-tab-item>
          </v-tabs>
        </v-row>

        <!---->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="component.call_dialog()" class="error">Fechar</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import DadosGerais from "./Dados_Gerais/index.vue";
import InfNota from "./Inf_Nota";
import ItensNota from "./Itens_Nota";
import Pagamento from "./Pagamento";

export default {
  components: {
    DadosGerais,
    InfNota,
    ItensNota,
    Pagamento,
  },
  props: {
    component: Object(),
    items: Object(),
  },
  data() {
    return {
      AllInfNote: undefined,
      items_received_add_dialog: undefined,
    };
  },
  created() {},
  watch: {
    items: function (val) {
      this.items_received_add_dialog = {...val};
    },
  },
};
</script>

<style>
</style>
