<template>
  <v-card>
    <v-col>
      <v-select
        v-model="component.tax_operation_type.calculate_tax"
        :items="items"
        item-text="value"
        item-value="key"
        :rules="rulesText"
        label="Calcular Imposto"
        required
        outlined
      ></v-select>
    </v-col>
    <v-col>
      <v-select
        v-model="component.tax_operation_type.move_stock"
        :items="items"
        item-text="value"
        item-value="key"
        :rules="rulesText"
        label="Movimento Estoque"
        persistent-hint
        outlined
      ></v-select>
    </v-col>
    <v-col>
      <v-select
        v-model="component.tax_operation_type.move_financial"
        :items="items"
        item-text="value"
        item-value="key"
        :rules="rulesText"
        label="Movimento Financeiro"
        persistent-hint
        outlined
      ></v-select>
    </v-col>
    <v-col>
      <v-select
        v-model="component.tax_operation_type.feeds_cost"
        :items="items"
        item-text="value"
        :rules="rulesText"
        item-value="key"
        label="Atualizar Custo"
        persistent-hint
        outlined
      ></v-select>
    </v-col>
  </v-card>
</template>
<script>
import { rulesText } from "../General/script";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },
  computed: {
    ...mapGetters["getItemToUpdateTaxOperationType"],
  },
  data() {
    return {
      items: [
        { value: "Não", key: 0 },
        { value: "Sim", key: 1 },
      ],
      rulesText,
    };
  },
  watch: {
    getItemToUpdateTaxOperationType: function () {
      console.log("entrou");
      this.component.tax_operation_type.calculate_tax =
        this.getItemToUpdateTaxOperationType.calculate_tax;

      this.component.tax_operation_type.feeds_cost =
        this.getItemToUpdateTaxOperationType.feeds_cost;
      this.component.tax_operation_type.move_financial =
        this.getItemToUpdateTaxOperationType.move_financial;
      this.component.tax_operation_type.move_stock =
        this.getItemToUpdateTaxOperationType.move_stock;
    },
  },
};
</script>
