<template>
  <v-container>
    <v-data-table :headers="header" :items="items_received_add_dialog.itens_sales"> </v-data-table>
  </v-container>
</template>

<script>
export default {
  props:{
    items_received_add_dialog:Object(),
  },
  data() {
    return {
      header: [
        { text: "Código do Item", value: "code_item" },
        { text: "Produto", value: "product.description_item" },
        { text: "Quantidade", value: "amount" },
        { text: "Valor da Venda R$", value: "value_sale" },
        { text: "Subtotal R$", value: "value_subtotal_product" },
        { text: "Valor de ICMS", value: "value_icms_product" },
        { text: "Valor Base ICMS", value: "value_base_icms_product" },
      ],
    };
  },
};
</script>

<style>
</style>