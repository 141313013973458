
import importStore from "./store"
import { ADD_MESSAGE } from "./store/actions/errors"

const key_errors_field = {
    cep: 'Cep',
    number:"Número",
    district: "Bairro",
    aliquot: 'Alíquota',
    complement: 'Complemento',
    street: 'Rua',
    description: 'Descrição',
    server_error: 'Error no servidor',
    code: 'Código',
    cashierType: 'Tipo de Caixa',
    initial_balance: 'Saldo Inicial',
    managers: 'Administradoras',
    name: 'Nome',
    bar_code: 'Código de Barras',
    addition: 'Acréscimo',
    amount: 'Parcela',
    deadline: 'Prazo',
    accountCode: 'Código Contabil',
    generateMovement: 'Gera Movimentação',
    accountType: 'Tipo Contábil',
    planType: 'Tipo de Plano',
    sequence: 'Sequência',
    model: "Modelo",
    series: 'Série',
    tax_document_type: 'Tipo de Documento Fiscal',
    description_item: "Descrição do Item",
    description_pdv: "Descrição do pdv",
    master_box: "Caixa Master",
    reference: "Referência",
    cost_price: 'Preço de Custo',
    sale_price: 'Preço de Venda',
    note_price: 'Custo de Nota',
    accounting_price: 'Custo de Contábil',
    rntr: 'RNTR',
    security: 'Segurança',
    gender: 'Gênero',
    dateOfBirth: 'Data de Nascimento',
    type: 'Tipo',
    normal_output: 'Código Fiscal Normal',
    replacement: 'Código Fiscal Substituição',
    output_of_state: 'Código Fiscal Fora Estado',
    calculate_tax: 'Calcular Imposto',
    move_stock: 'Movimento Estoque',
    move_financial: 'Movimento Financeira',
    feeds_cost: 'Atualizar Custo',
    sigla: 'Sigla',
    status: 'Status',
    emitter: 'Emissão',
    emitterState: 'Estado Emissor',
    idCard: 'Identidade',
    fatherName: 'Nome do Pai',
    motherName: 'Nome da Mãe',
    nacionality: 'Nacionalidade',
    naturality: 'Naturalidade',
    monthlyIncome: 'Renda Conjugal',
    civilStatus: 'Estado civíl',
    spouse: 'Conjugue',
    value_title: 'Valor do Título',
    dejection: 'Abatimento',
    interest: 'Interesse',
    issuance_date: 'Data de Emissão',
    issuance_due: 'Data de Vencimento',
    type_title: 'Tipo do Título',
    number_title: 'Número do Título',
    client_id: 'Cliente é Obrigatório',
    user_change: 'Mudança de usuário',
    product_group_id: 'Id do grupo de produto',
    count: "Dados da conta"
}

const key_errors_message = {
    required: 'Campo Obrigatório!',
    try_again: 'Tente Novamente!',
    contact_adm: 'Contate o administrador!',
    maxLength: (value) => `O maximo caracteres permitido é ${value.split('|')[1]}`,
    mustbeNumeric: 'Apenas Valores Numéricos!',
    mustbeString: 'Deve conter números ou letras',

}

function showMessageErros(response) {
    key_errors_field;
    key_errors_message;

    let httpCode = response.status;
    let dataResponse = response.data;
    var arreyKeysResponse = Object.keys(dataResponse);
    // var arreyKeysErrors = Object.keys(key_errors_field);
    // var arrayKeysMessage = Object.keys(key_errors_message);
    var objectTradution = key_errors_field
    var arrayToShowUser = Array();

    switch (httpCode) {
        case 400:
            // Testado
            var message400 = String();
            for (let index = 0; index < dataResponse.length; index++) {
                message400 = `${message400} ${dataResponse[index]}`
            }
            arrayToShowUser.push(message400)
            importStore.commit(ADD_MESSAGE, arrayToShowUser);
            arrayToShowUser = [];
            break;

        case 404:
            var message404 = String();
            for (let index = 0; index < dataResponse.length; index++) {
                message404 = `${message404} ${dataResponse[index]}`
            }
            arrayToShowUser.push(message404)
            importStore.commit(ADD_MESSAGE, arrayToShowUser);
            arrayToShowUser = [];
            break;

        case 405:
            var message405 = String();
            for (let index = 0; index < dataResponse.length; index++) {
                message405 = `${message405} ${dataResponse[index]}`
            }
            arrayToShowUser.push(message405)
            importStore.commit(ADD_MESSAGE, arrayToShowUser);
            arrayToShowUser = [];
            break;

        case 422:
            var message422 = String();
            arreyKeysResponse.forEach(itemError => {
                if (itemError === "error") {
                    message422 = `Campos com erro de validação` 
                    arrayToShowUser.push(message422) 
                } else {
                    for (const key in objectTradution){
                        if (key === itemError) {
                            message422 = `Erro de validação no campo: ${objectTradution[key]}`
                            arrayToShowUser.push(message422)
                        }
                    }
                }
            });
            importStore.commit(ADD_MESSAGE, arrayToShowUser);
            arrayToShowUser = [];
            break;

        case 500:
            var message500 = "Erro no Servidor";
            arrayToShowUser.push(message500)
            importStore.commit(ADD_MESSAGE, arrayToShowUser);
            arrayToShowUser = [];
            break;

        default:
            var messageDefault = "Erro no Servidor";
            arrayToShowUser.push(messageDefault)
            importStore.commit(ADD_MESSAGE, arrayToShowUser);
            arrayToShowUser = [];
            break;
    }
}


export default showMessageErros