let EmitterNFe = require("../../../Domain/Service/ReprocessNfe/MounterEmitterReprocessNFeDomainService");
let IdentifyNFe = require("../../../Domain/Service/ReprocessNfe/MounterIdentifyReprocessNFeDomainService");
let mounterIdentifyReferenceNFe = require("../../../Domain/Service/IssueNfe/MounterIdentifyNFeReferenceNfeDomainService")
let DestinationNFe = require("../../../Domain/Service/ReprocessNfe/MounterDestinationReprocessNFeDomainService");
let mounterProductNfe = require("../../../Domain/Service/ReprocessNfe/MounterProductReprocessNFeDomainService")
let TransporteInfoNfe = require("../../../Domain/Service/ReprocessNfe/MounterTransportInfoReprocessNFeDomainService");

async function execute(revenues) {
  try {
    let emmiterNfe = await EmitterNFe.execute(revenues.enterprise.cnpj);
    let identifyNfe = await IdentifyNFe.execute(revenues);
    let identifyReferenceNFe = await mounterIdentifyReferenceNFe.execute(revenues)
    let destinationNfe = await DestinationNFe.execute(revenues);
    let productNfe = await mounterProductNfe.execute(revenues, revenues.enterprise);
    let transportInfoNfe = await TransporteInfoNfe.execute(revenues);
    let nfe = {}

    if (revenues.nfereferences != undefined ) {
      nfe = await mounterNfeIdentifyReference(
        emmiterNfe,
        identifyReferenceNFe,
        identifyNfe,
        destinationNfe,
        transportInfoNfe,
        productNfe
      );

    } else {
      nfe = await mounterNfe(
        emmiterNfe,
        identifyNfe,
        destinationNfe,
        transportInfoNfe,
        productNfe
      );

    }
    nfe.cnpjEmitter = nfe.emitterNFe.cnpjEmitter
    nfe.keyNfe = nfe.identifyNFe.keyAccess
    
    return nfe;
  }
  catch (error) {
    return error
  }
}


async function mounterNfe(emmiterNfe, identifyNfe, destinationNfe, transportInfoNfe, productNfe) {
  let mounterNfe = {}
  mounterNfe.emitterNFe = { ...emmiterNfe }
  mounterNfe.identifyNFe = { ...identifyNfe }
  mounterNfe.destinationNFe = { ...destinationNfe }
  mounterNfe.productsNFe = { ...productNfe }
  mounterNfe.transportInfo = { ...transportInfoNfe }

  return mounterNfe;
}

async function mounterNfeIdentifyReference(emmiterNfe, identifyReference, identifyNfe, destinationNfe, transportInfoNfe, productNfe) {
  let mounterNfe = {}
  mounterNfe.emitterNFe = { ...emmiterNfe }
  mounterNfe.identifyNFe = { ...identifyNfe }
  mounterNfe.identifyReference = { ...identifyReference }
  mounterNfe.destinationNFe = { ...destinationNfe }
  mounterNfe.productsNFe = { ...productNfe }
  mounterNfe.transportInfo = { ...transportInfoNfe }


  return mounterNfe;
}

module.exports = {
  execute,
};

