<template>
  <v-row>
    <v-dialog
      v-model="getShowDialogRegisterProduct"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                closeAddDialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Novo/Editar Produto</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-divider></v-divider>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>Dados Gerais
            </v-tab>
            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-cash</v-icon>Valores
            </v-tab>
            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-calculator</v-icon>ICMS
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-alpha-p-box</v-icon>Pis
            </v-tab>
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-alpha-p-box</v-icon>Cofins
            </v-tab>
            <v-tab href="#tab6">
              <v-icon class="mr-1">mdi-domain</v-icon>IPI
            </v-tab>
            <v-tab-item :eager="true" value="tab1">
              <v-card flat>
                <GenetalDate
                  :out_of_register="out_of_register"
                  :component="this"
                  ref="generate"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab2">
              <v-card flat>
                <Cost :component="this" ref="cost" />
              </v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab3">
              <v-card flat>
                <TaxIcms :component="this" ref="taxIcms" />
              </v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab4">
              <v-card flat>
                <TaxPis :component="this" ref="taxPis" />
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true" value="tab5">
              <v-card flat>
                <TaxCofins :component="this" ref="taxCofins" />
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true" value="tab6">
              <v-card flat>
                <TaxIpi :component="this" ref="taxIpi" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="justVisible" class="error" @click="clear()">
              <v-icon class="mr-2">mdi-cancel</v-icon>Limpar Formulário
            </v-btn>

            <v-btn
              v-if="!justVisible"
              class="success"
              :disabled="!valid"
              @click="
                () => {
                  submit();
                }
              "
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GenetalDate from "../Tabs/genatedate/index.vue";
import Cost from "../Tabs/cost";
// import Taxation from "../Tabs/taxation";
// import { productToUpdate } from "./script";
import FunctionsToUpdate from "./script";
import TaxIcms from "../Tabs/TaxIcms";
import TaxPis from "../Tabs/TaxPis";
import TaxCofins from "../Tabs/TaxCofins";
import TaxIpi from "../Tabs/TaxIpi";
import { mapGetters } from "vuex";
import {
  AUTO_LOAD_PRODUCT,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  ITEM_UPDATE_PRODUCT,
  SAVE_IMAGE_PRODUCT,
} from "../../../store/actions/product";
import { GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import { GET_ALL_SUB_PRODUCT_GROUP } from "../../../store/actions/sub_product_group";
import { GET_ALL_UNIT } from "../../../store/actions/unit";
import { GET_ALL_NCM } from "../../../store/actions/ncm";
import { GET_ALL_ALIQUOT } from "../../../store/actions/aliquot";
import { GET_ALL_PIS_COFINS } from "../../../store/actions/pis_cofins";
import { GET_ALL_CFOP } from "../../../store/actions/cfop";
import { GET_ALL_CST } from "../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../store/actions/csosn";
import { GET_ALL_CST_IPI } from "../../../store/actions/cstipi";

export default {
  components: { GenetalDate, Cost, TaxIcms, TaxPis, TaxCofins, TaxIpi },

  props: {
    component: Object(),
    objectToUpdate: Object(),
    out_of_register: Boolean(),
  },

  data: () => ({
    justVisible: Boolean(),
    allValidated: 0,
    validations: Array(),
    productImage: null,
    test: 0,
    textRules: [(v) => !!v || "Campo obrigatório!"],
    title: String(),
    valid: Boolean(),
    linkImage : null, 
    productToUpdateStore: Object(),
    productToUpdateState: Object(),
    idEnterprise: Number(),
    ncm: String(),
    csosn: String(),
    cst: String(),
    aliquot: String(),
    cfop: String(),
    cstPis: String(),
    cstCofins: String(),
    cstIpi: String(),
    product: {
      accounting_price: Number(),
      additional_aliquot_tax_st: Number(),
      additional_value_margin: Number(),
      aliquot_fcp: Number(),
      aliquot_id: Number(),
      amount_stamp: String(),
      avarenge_cost: Number(),
      bar_code: String(),
      calculation_base_mode: String(),
      cfop_id: Number(),
      cnpj_producer: String(),
      code_stamp: String(),
      cost_price: Number(),
      csosn_id: Number(),
      cst_cofins_id: Number(),
      cst_id: Number(),
      cst_ipi_id: Number(),
      cst_pis_id: Number(),
      date_last_buy: String(),
      date_last_register: String(),
      description_item: String(),
      description_pdv: String(),
      enterprise_id: Number(),
      final_promotion_date: String(),
      framing_class_cest: String(),
      id: undefined,
      initial_promotion_date: String(),
      ipi_calculation_form: String(),
      cofins_calculation_form: String(),
      pis_st_calculation_form: String(),
      cofins_st_calculation_form: String(),
      ipi: Number(),
      management_cost: Number(),
      manufacturer_code: String(),
      master_box: String(),
      max_stock: Number(),
      mechandise_cost: Number(),
      minimum_stock: Number(),
      ncm_id: Number(),
      nfci: String(),
      note_price: Number(),
      percentage_aliquot_tax_st: Number(),
      percentage_calc_base_reduction: Number(),
      percentage_deferral: Number(),
      percentage_fcp_tax: Number(),
      percentage_supported_final_consumer: Number(),
      percentagem_calc_st_reduction: Number(),
      photo: null,
      product_group_id: Number(),
      product_type: String(),
      promotion_price: Number(),
      reason_exemption_icms: Number(),
      reference: String(),
      sale_price: Number(),
      source_product: Number(),
      status: String(),
      stock_turning: Number(),
      sub_group_product_id: Number(),
      tax_bemefot_code_uf: String(),
      tax_replacement_base_modality: String(),
      unit_id: Number(),
      use_type: null,
      user_change: String(),
    },
  }),

  computed: {
    ...mapGetters([
      "getShowDialogRegisterProduct",
      "getProductGroup",
      "getItemUpdateProduct",
      "getAutoLoadProductObjec",
    ]),
  },
  methods: {
    ...FunctionsToUpdate,

    closeAddDialog() {
      this.$store.dispatch(AUTO_LOAD_PRODUCT, null);
      this.$store.dispatch("setShowDialogRegisterProduct", false);
      this.$store.dispatch(ITEM_UPDATE_PRODUCT, {});
      this.clear();
      this.product = {};
    },
    autloadProduct() {
      this.product.manufacturer_code =
        this.getAutoLoadProductObjec.manufacturer_code;
      this.product.description_item =
        this.getAutoLoadProductObjec.description_item;
      this.product.bar_code = this.getAutoLoadProductObjec.bar_code;
      this.product.cost_price = Number(this.getAutoLoadProductObjec.cost_price);
    },
    resetValidation() {
      this.$refs.generate.reset();
      this.$refs.cost.reset();
      this.$refs.taxIcms.reset();
      this.$refs.taxPis.reset();
      this.$refs.taxCofins.reset();
      this.$refs.taxIpi.reset();
    },

    clear() {
      this.product.id = undefined;
      this.product.status = 1;
      this.product.description_item = "";
      this.product.description_pdv = "";
      this.product.bar_code = "";
      this.product.master_box = "";
      this.product.reference = "";
      this.product.use_type = "";
      this.product.date_last_buy = new Date();
      this.product.date_last_register = new Date();
      this.product.user_change = "";
      this.product.photo = null;
      this.product.management_cost = 0;
      this.product.mechandise_cost = 0;
      this.product.avarenge_cost = 0;
      this.product.note_price = 0;
      this.product.cost_price = 0;
      this.product.accounting_price = 0;
      this.product.sale_price = 0;
      this.product.promotion_price = 0;
      this.product.initial_promotion_date = "";
      this.product.final_promotion_date = "";
      this.product.minimum_stock = 0;
      this.product.max_stock = 0;
      this.product.stock_turning = 0;
      this.product.ipi = "";
      this.product.product_group_id = 0;
      this.product.sub_group_product_id = 0;
      this.product.cfop_id = 0;
      this.product.cst_pis_id = 0;
      this.product.cst_confis_id = 0;
      this.product.ncm_id = 0;
      this.product.cst_id = 0;
      this.product.csosn_id = 0;
      this.product.aliquot_id = 0;
      this.product.product_type = null;
      this.product.unit_id = 0;
      this.framing_class_cest = 0;
      this.tax_bemefot_code_uf = 0;
      this.tax_replacement_base_modality = "";
      this.nfci = 0;
      this.percentage_calc_base_reduction = 0;
      this.percentage_supported_final_consumer = 0;
      this.source_product = 0;
      this.calculation_base_mode = "";
      this.reason_exemption_icms = 0;
      this.percentage_deferral = 0;
      this.additional_value_margin = 0;
      this.percentagem_calc_st_reduction = 0;
      this.additional_aliquot_tax_st = 0;
      this.percentage_fcp_tax = 0;
      this.percentage_aliquot_tax_st = 0;
      this.resetValidation();
      this.productImage = null;
    },

    submit() {
      this.product.id == undefined
        ? this.create_products()
        : this.update_products();
    },

    async create_products() {
      let createProduct = { ...this.product };
      if (this.out_of_register) {
        createProduct.enterprise_id = this.product.enterprise_id;
      } else {
        createProduct.enterprise_id = this.product.enterprise_id;
        createProduct.enterprise_id = this.component.enterpriseId;
      }
      await this.$store
        .dispatch(ADD_PRODUCT, { ...createProduct })
        .then((response) => {
          if (this.productImage != null) {
            this.$store.dispatch(SAVE_IMAGE_PRODUCT, {
              product_id: response.response.data[0].id,
              productImage: this.productImage,
            });
          }

          this.$store.dispatch("setShowDialogRegisterProduct", false);
        });
    },

    async update_products() {
      const product = { ...this.product };
      await this.$store.dispatch(UPDATE_PRODUCT, product).then(() => {
        this.$store.dispatch("setShowDialogRegisterProduct", false);
        this.$store.dispatch(ITEM_UPDATE_PRODUCT, {});
      });
    },
    validate() {
      this.$refs.form.validate();
    },

    getComponent() {
      return this.component;
    },

    async GetAllNoPage() {
      let response = Array();
      let value = {
        page: null,
        limit: null,
        pagination: false,
      };
      this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
      response[1] = this.$store.dispatch(GET_ALL_SUB_PRODUCT_GROUP, value);
      response[2] = this.$store.dispatch(GET_ALL_UNIT, value);
      response[3] = this.$store.dispatch(GET_ALL_NCM, value);
      response[4] = this.$store.dispatch(GET_ALL_ALIQUOT, value);
      response[5] = this.$store.dispatch(GET_ALL_CFOP, value);
      response[6] = this.$store.dispatch(GET_ALL_CST, value);
      response[7] = this.$store.dispatch(GET_ALL_CSOSN, value);
      response[8] = this.$store.dispatch(GET_ALL_PIS_COFINS, value);
      response[9] = this.$store.dispatch(GET_ALL_CST_IPI, value);
      Promise.all(response);
    },
    compareDate() {
      let initial = new Date(this.product.initial_promotion_date);
      let final = new Date(this.product.final_promotion_date);

      final > initial || alert("Data final é maior que a inicial!");
      this.product.final_promotion_date = "";
    },

    checkValidation() {
      if (this.validations.length == 6) {
        this.allValidated = 0;
        this.validations.forEach((element) => {
          if (element == true) {
            this.allValidated++;
            if (this.allValidated == 6) {
              this.valid = true;
            } else {
              this.valid = 0;
            }
          }
        });
      }
    },
  },

  created() {
    if (this.getItemUpdateProduct.justVisible) {
      this.justVisible = this.getItemUpdateProduct.justVisible;
      this.productToUpdateStore = this.getItemUpdateProduct.item;
      this.productToUpdate(this.productToUpdateStore.item);
    } else if (this.getItemUpdateProduct) {
      this.productToUpdateStore = this.getItemUpdateProduct;
      this.productToUpdate(this.productToUpdateStore);
    }

    this.GetAllNoPage();

    this.idEnterprise = this.component.enterpriseId
    this.product.enterprise_id = this.component.enterpriseId
  },
  watch: {
    getAutoLoadProductObjec: function () {
      this.autloadProduct();
    },
    "product.final_promotion_date": function (val) {
      if (val) {
        this.compareDate();
      }
    },
  },

  beforeDestroy() {
    this.product = {};
    this.productToUpdateStore = {}
    this.productImage = null;
  
  },
};
    // compareCust() {
    //   let initial = this.product.cost_price;
    //   let final = this.product.sale_price;

    //   if (initial > final) {
    //     alert("O preço de custo não pode ser maior que o preço de venda!");
    //     this.product.cost_price = "";
    //   }
    // },
    // validations: function () {
    //   console.log('validations', this.validations);
    //   if (this.validations.length == 6) {
    //     this.allValidated = 0;
    //     this.validations.forEach((element) => {
    //       if (element == true) {
    //         this.allValidated++;
    //         if (this.allValidated == 6) {
    //           this.valid = true;
    //         }
    //       }
    //     });
    //   }
    // },
</script>


