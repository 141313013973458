<template>
  <div>
    <Alert
      :showAlert="showAlert"
      someMessage="Selecionar o tipo de operção fiscal primeiro"
    />
    <v-form ref="form" >
    <v-card>
      <v-col>
        <v-select
          label="Status"
          v-model="component.tax_operation_type.status"
          :items="liststatus"
          outlined
          item-value
          :rules="[
            () => !!component.tax_operation_type.status || 'Campo Obrigatório',
          ]"
        ></v-select>
      </v-col>

      <v-col>
        <v-text-field
          label="Codigo"
          type="number"
          v-model="component.tax_operation_type.code"
          v-mask="`#########`"
          counter="9"
          :rules="[(v) => !!v || 'Campo Obrigatório']"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Descrição"
          v-model="component.tax_operation_type.description"
          :rules="[
            () =>
              !!component.tax_operation_type.description || 'Campo Obrigatório',
            () =>
              (!!component.tax_operation_type.description &&
                component.tax_operation_type.description.length >= 1) ||
              'Campo tem que ser maior do que 1',
          ]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          label="Selecione o Tipo da Operação Fiscal"
          v-model="component.type"
          :items="types"
          item-value="value"
          outlined
          :rules="[() => !!component.type || 'Campo Obrigatório']"
        ></v-select>
      </v-col>

      <v-col>
        <v-select
          label="Codigo Fiscal Normal"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialogCFOP"
          v-model="component.normal_output_text"
          :rules="[() => !!component.normal_output_text || 'Campo Obrigatório']"
          :items="component.cfop"
          item-value="id"
          item-text="code"
          outlined
          required
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Codigo Fiscal Fora Estado"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialogCFOP"
          v-model="component.output_of_state_text"
          :items="component.cfop"
          item-value="id"
          item-text="code"
          :rules="[
            () => !!component.output_of_state_text || 'Campo Obrigatório',
          ]"
          outlined
          required
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Codigo Fiscal Substituição"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialogCFOP"
          v-model="component.replacement_text"
          :items="component.cfop"
          item-value="id"
          item-text="code"
          :rules="[() => !!component.replacement_text || 'Campo Obrigatório']"
          outlined
        ></v-select>
      </v-col>
      <ModalCFOP :addDialogCfop="getShowDialogRegisterCFOP" />
    </v-card>
      </v-form> 
  </div>
</template>
<script>
import { incomeNegative } from "./script";
import { mdiPlusCircle } from "@mdi/js";
import ModalCFOP from "../../../cfop/AddDialog/";
import Alert from "../../../global_alerts";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalCFOP,
    Alert,
  },
  props: {
    component: Object(),
  },

  data() {
    return {
      showAlert: false,
      icons: [mdiPlusCircle],
      items: [
        { value: "Não", key: 0 },
        { value: "Sim", key: 1 },
      ],
      types: [
        { text: "ENTRADA", value: 1 },
        { text: "SAIDA", value: 2 },
      ],
      liststatus: [
        { text: "ATIVO", value: "1" },
        { text: "INATIVO", value: "0" },
      ],
      incomeNegative,
    };
  },
  created(){
  },
  beforeDestroy(){
    this.$refs.form.reset()
  },
  computed: {
    ...mapGetters([
      "getFiscalType",
      "getShowDialogRegisterCFOP",
      "getItemToUpdateTaxOperationType",
    ]),
  },
  watch: {
    getItemToUpdateTaxOperationType: function(val){
      console.log("dan", val)
    },
    "component.type": function () {
      this.$store.dispatch("setFiscalType", this.component.type);
    },
  },
  methods: {
    showDialogCFOP() {
      if (this.getFiscalType) {
        this.$store.dispatch("setShowDialogRegisterCFOP", true);
      } else {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 2000);
      }
    },
  },
};
</script>


