<template>
  <v-row justify="center">
    <v-dialog persistent v-model="getShowDialogRegisterCFOP" max-width="600">
      <v-card>
        <v-card-title primary-title>CFOP</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Codigo"
                v-model="code"
                :readonly = "justVisible"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  getFiscalType == 1
                    ? () =>
                        (!!code &&
                          code.length == 4 &&
                          code >= 1000 &&
                          code <= 3000) ||
                        'CFOP de entrada inválido'
                    : getFiscalType == 2
                    ? () =>
                        (!!code &&
                          code.length == 4 &&
                          code >= 5000 &&
                          code <= 7000) ||
                        'CFOP de Saída inválido'
                    : () =>
                        (!!code && code.length == 4) ||
                        'Campo tem que ser igual a 4',
                ]"
                type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="4"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-textarea
                label="*Descrição"
                v-model="description"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                :readonly = "justVisible"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    close_Cfop();
                  }
                "
                ><v-icon class="mr-1">mdi-cancel</v-icon>Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                :disabled="!valid"
                class="mr-4"
                @click="
                  () => {
                    submit();
                  }
                "
                ><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { mapGetters } from "vuex";
import UiBusinessRules from "../../../UIBusinessRule/CFOP/AddDialog/AddDialog"
export default {
  props: {
    component: Object,
  },

  data() {
    return {
      justVisible: Boolean(),
      attrs: false,
      on: false,
      id: undefined,
      code: "",
      valid: true,
      updated_at: "",
      created_at: "",
      search: "",
      description: "",
      formHasErrors: false,
    };
  },

  computed: {
    ...mapGetters([
      "getFiscalType",
      "getShowDialogRegisterCFOP",
      "getCfopToUpdate",
    ]),
  },
  methods: {
    ...UiBusinessRules
  },
  watch: {
    getCfopToUpdate: function (val) {

    if (val.justVisible) {
      this.justVisible = val.justVisible;
      this.code = String(val.item.code);
      this.description = val.item.description;
    } else {
      this.code = String(this.getCfopToUpdate.code);
      this.created_at = this.getCfopToUpdate.created_at;
      this.description = this.getCfopToUpdate.description;
      this.id = this.getCfopToUpdate.id;
      this.updated_at = this.getCfopToUpdate.updated_at;
      }
    },
  },
  async created() {
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.description = this.object.description;
    // if (this.id) {
    //   this.code = this.object.code.toString();
    // } else {
    //   this.code = this.object.code;
    // }
  },
  beforeDestroy() {
    // this.getComponent().objectToUpdate = {};
  },
};
</script>
