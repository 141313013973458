import axios from "axios";
const {
    GET_ALL_PRE_NOTEENTRY,
    ADD_PRE_NOTEENTRY,
    UPDATE_PRE_NOTEENTRY,
    CONFIRM_PRE_NOTEENTRY,
    DESTROY_PRE_NOTEENTRY,
    UPDATED_LIST_ITEMS

} = require('./actions/preNote_entry')
const {
    SPLICE_ERRO,
} = require('./actions/alert_api')

const modulePreNoteModel = {
    state: () => ({
        note_entry: Object(),
        listItemUpdated: Array()
    }),

    mutations: {
        GET_ALL_PRE_NOTEENTRY: (state, note_entry) => {
            state.note_entry = note_entry;
        },
        ADD_PRE_NOTEENTRY: (state, newNoteEntry) => {
            state.note_entry = newNoteEntry;
        },
        CONFIRM_PRE_NOTEENTRY: (state, payload) => {
            let auxState = [...state.note_entry.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === payload),
            )
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.note_entry = [...auxState];
        },
        UPDATE_PRE_NOTEENTRY: (state, updateNoteEntry) => {
            state.note_entry.data.Update(updateNoteEntry)

        },

        DESTROY_PRE_NOTEENTRY: (state, payload) => {
            let auxState = [...state.note_entry.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === payload),
            )
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.note_entry = [...auxState];
        },
        UPDATED_LIST_ITEMS: function(state, payload){
            state.listItemUpdated = payload
        },

    },
    actions: {
        UPDATED_LIST_ITEMS: function(context, payload){
            context.commit(UPDATED_LIST_ITEMS, payload)
        },
        GET_ALL_PRE_NOTEENTRY({
            commit
        }, payload) {
            axios.get("invoiceentry/getbystatus", {
       
                params: {
                    page: payload.page,
                    limit: payload.limit,
                    paginate: Boolean(payload.paginate) ,
                    status: payload.status
                }
            }).then((response) => {
                commit(GET_ALL_PRE_NOTEENTRY, response.data[0]);
            });
        },

        ADD_PRE_NOTEENTRY({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
            axios.post("invoiceentry/createpreinvoiceentry", payload).then((response) => {
                resolve();
                commit(ADD_PRE_NOTEENTRY, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: response.data.msg,
                    type: 'S'
                })
            }).catch(r => {
                reject();
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: erro + ' : ' + err,
                        type: 'E'
                    }))
                }
            });
        });
        },
 

        CONFIRM_PRE_NOTEENTRY({
            commit
        }, payload) {
            axios.post("invoiceentry/confirmationpreinvoiceentry", payload).then((response) => {

                commit(CONFIRM_PRE_NOTEENTRY, payload.id)
                commit(SPLICE_ERRO, {
                    alert: response.data.message,
                    type: 'S'
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: erro + ' : ' + err,
                        type: 'E'
                    }))
                }
            });
        },

        UPDATE_PRE_NOTEENTRY({
            commit
        }, payload) {
            axios.post("invoiceentry/updatePreInvoiceEntry", payload).then((response) => {

                commit(UPDATE_PRE_NOTEENTRY, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: response.data.msg,
                    type: 'S'
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: erro + ' : ' + err,
                        type: 'E'
                    }))
                }
            });
        },

        DESTROY_PRE_NOTEENTRY({
            commit
        }, payload) {
            axios.post("invoiceentry/destroyPreInvoiceEntry",
                { id: payload.id, enterprise_id: payload.enterprise_id }
            ).then((response) => {
                commit(DESTROY_PRE_NOTEENTRY, payload.id);
                commit(SPLICE_ERRO, {
                    alert: response.data.sucess.message[0],
                    type: 'S'
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: erro + ' : ' + err,
                        type: 'E'
                    }))
                }
            });
        },

    },
    getters: {
        getListUpdatedItem: (state) => state.listItemUpdated,
        getPreNoteEntry: (state) => ({
            ...state.note_entry
        }),
    },
};

export default modulePreNoteModel;