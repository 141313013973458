<template>
  <v-card class="ma-5 pa-1">
    <CardTitle
      title="Sped Icms"
      :action="GENERATE_SPED_ICMS"
      :component="this"
    />
    <v-divider />
    <Form :component="this" />
  </v-card>
</template>

<script>
import CardTitle from "../../../components/sped/CardTitle.vue";
import Form from "../../../components/sped/icms/Body/index.vue";
import { GENERATE_SPED_ICMS } from "../../../store/actions/sped";
import { mapGetters } from "vuex";

export default {
  components: {
    CardTitle,
    Form,
  },
  data: () => ({
    GENERATE_SPED_ICMS,
    disableButtonCardTitle: Boolean(),
    form: Object(),
  }),
  computed: {
    ...mapGetters(["getSelectedEnterprise"]),
  },
  watch: {
    disableButtonCardTitle: function (val) {
      this.disableButtonCardTitle = false;
      if (val && this.getSelectedEnterprise) {
        this.disableButtonCardTitle = true;
      }
    },
  },
};
</script>
