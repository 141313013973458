<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="getters"
      no-data-text="Sem Itens cadastrados!"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          color="red darken-4"
          @click="
            () => {
              actionDelete(item);
            }
          "
          >mdi-delete</v-icon
        >
        <v-icon
          class="mr-2"
          color="yellow darken-4"
          @click="
            () => {
              actionEdit(item);
            }
          "
          >mdi-pencil</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    component: Object,
    getters: Array,
    actionDelete: Function,
    actionEdit: Function,
  },
  data() {
    return {
      headers: [
        { text: "Código", value: "product_id" },
        { text: "Descrição", value: "description_product" },
        { text: "Un", value: "description_unit" },
        { text: "Quant.", value: "amount" },
        { text: "Valor de Custo R$", value: "cust" },
        { text: "Sub Total R$", value: "value_sub_total_product" },
        { text: "CFOP", value: "code_cfop" },
        { text: "Cst/Csosn", value: "code_cst" },
        { text: "Base ICMS R$", value: "value_base_icms_product" },
        { text: "Valor Icms	R$", value: "value_icms_product" },
        { text: "Valor IPI	R$", value: "value_ipi_product" },
        { text: "Valor Isento	R$", value: "value_free_icms_product" },
        { text: "Outros Valores R$", value: "value_icms_outher_product" },
        { text: "Base de calculo ST R$", value: "value_base_icms_st_product" },
        { text: "A.IPI %", value: "aliquot_ipi_product" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
};
</script>

<style></style>
