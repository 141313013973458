<template>
  <v-col>
    <v-form ref="form" v-model="form">
      <v-row class="d-flex justify-center">
        <v-card-title>Relatório de Vendas</v-card-title>
      </v-row>
      <v-row>
        <v-select
          :items="listEnterprises"
          v-model="formData.enterprise_id"
          item-text="fantasyName"
          item-value="id"
          outlined
          dense
          label="Empresas"
        />
      </v-row>
      <v-row>
        <v-btn block :disabled="!form" @click="getReport" class="success">Visualizar</v-btn>
      </v-row>
      <v-row>
        <v-divider class="mt-5 mb-5" />
      </v-row>
      <v-row>
        <v-text-field
          type="date"
          v-model="formData.initial_date_sale"
          :rules="rules"
          outlined
          label="Data da venda Inicial"
        />
      </v-row>
      <v-row>
        <v-text-field
          type="date"
          v-model="formData.final_date_sale"
          :rules="rules"
          outlined
          label="Data da venda Final"
        />
      </v-row>
      <v-row>
        <v-text-field
          outlined
          v-model="formData.initial_code_client"
          
          label="Código do cliente Inicial"
        />
      </v-row>
      <v-row>
        <v-text-field
          outlined
          v-model="formData.final_code_client"
          
          label="Código do cliente Final"
        />
      </v-row>
      <v-row>
        <v-text-field
          outlined
          v-model="formData.invoice_number"
          
          label="Número da Nota Fiscal"
        />
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import data from "../../helpers/data";
import methods from "../../helpers/methods";
import getters from "../../helpers/getters"
// import {watch} from "../../helpers/watch"
import { mapGetters } from "vuex";
export default {
  data: function() {
    return {
      ...data
    };
  },
  computed: {
    ...mapGetters(getters)
  },
  methods: {
    ...methods
  },
  
  // watch: {
  //   ...watch,

  //   // }
  // }
};
</script>

<style>
</style>