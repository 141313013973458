



export const generate_ecf_itens = [
    {text:"Sim", value: "sim"},
    {text:"Não", value: "nao"},
]

export const printCredtItens = [
    {text:"Sim", value: "sim"},
    {text:"Não", value: "nao"},
]

export const apurationIpiItens = [
    {text:"Mensal", value: "mensal"},
    {text:"Anual", value: "anual"},
]
