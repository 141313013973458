const header =
    [
        { text: "Código", value: "product_id" },
        { text: "Descrição", value: "description" },
        { text: "Unidade", value: "description_unit" },
        { text: "Quantidade", value: "description_cfop" },
        { text: "Valor Unitário", value: "cust" },
        { text: "Valor Total", value: "value_unit" },
        { text: "Cst", value: "description_cst" },
        { text: "CFOP", value: "description_cfop" },
        { text: "BC ICMS	", value: "base_icms" },
        { text: "Valor ICMS	", value: "value_icms" },
        { text: "Valor IPI	", value: "value_ipi" },
        { text: "A.ICMS	", value: "aliquot_icms" },
        { text: "A.IPI	", value: "aliquot_ipi" },
        { text: "Ações", value: "actions" },
    ];



export { header };