<template>
 <v-container>
  <v-divider></v-divider>
  <v-form ref="form" v-model="component.validContact">
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="component.contact.primaryEmail"
          hint="exemplo@mail.com"
          :rules="emailRules1"
          outlined
          label="*E-mail"
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="component.contact.secundaryEmail"
          hint="exemplo@mail.com"
          :rules="emailRules2"
          outlined
          label="E-mail Secundário"
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.contact.cellPhone"
          label="*Telefone Celular"
          :rules="textRules"
          hint="Exemplo: (12) 3 4567-8901"
          v-mask="'(##) # ####-####'"
          outlined
          :readonly="component.justView"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.contact.phone"
          label="Telefone Residencial"
          hint="Exemplo: (12) 3456-7890"
          v-mask="'(##) # ####-####'"
          outlined
          :readonly="component.justView"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.contact.phoneReference"
          label="Telefone Referência"
          hint="Exemplo: (12) 3 4567-8901"
          v-mask="'(##) # ####-####'"
          outlined
          :readonly="component.justView"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="component.close()"> Cancelar</v-btn>
      <v-btn
        :disabled="!valid"
        color="success"
        @click="component.add_contact()"
      >
        Adicionar</v-btn
      >
    </v-card-actions> -->
  </v-form>
 </v-container>
</template>

<script>
import { emailRules1, emailRules2 } from "./script";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: Boolean(),
    textRules: [(v) => !!v || "Campo obrigatório!"],
    emailRules1,
    emailRules2,
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
