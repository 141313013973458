<template>
  <v-form ref="form" v-model="validationForm">
    <v-row>
      <v-col>
        <v-text-field
          outlined
          v-model="component.cstIpi"
          label="*CST IPI"
          :rules="component.textRules"
          :readonly="component.justVisible"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="changeIpiDialog"
          append-icon="mdi-magnify"
          @click:append="openCstIpiTable()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Classe de enquadramento"
          v-model="component.product.framing_class"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Cnpj do Produtor"
          v-model="component.product.cnpj_producer"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Codigo do selo controle Ipi"
          v-model="component.product.code_stamp"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Quantidade do selo Ipi"
          v-model="component.product.amount_stamp"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout>
          <v-select
            class="ma-2"
            :readonly="component.justVisible"
            label="Forma de calculo do Ipi"
            v-model="component.product.ipi_calculation_form"
            full-width
            outlined
            :items="items"
          >
          </v-select>
        </v-layout>
      </v-col>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="IPI"
          v-model="component.product.ipi"
        ></v-text-field>
      </v-col>
    </v-row>
    <DialogRegister
      v-if="getShowDialogRegisterIPI"
      :component="this"
      title="Cadastro de Cst IPI"
      :addDialogPisCofins="showDialog"
      :add_value="add_pis_cofins"
      :update_value="update_pis_cofins"
    />
    <CstIpiDataTable :component="this" title="CST IPI" />
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import DialogRegister from "../../../global_components/dialog_register.vue";
import CstIpiDataTable from "@/components/products/Tabs/TaxIpi/CstIpiDialog/index.vue";
import { ADD_CST_IPI, UPDATE_CST_IPI,GET_ALL_CST_IPI, GET_FILTER_CST_IPI } from "../../../../store/actions/cstipi";

export default {
  components: {
    DialogRegister,
    CstIpiDataTable,
  },
  props: {
    component: Object(),
  },
  data: () => ({
     validationForm:false,
    auxIPI: 0,
    add_pis_cofins: ADD_CST_IPI,
    update_pis_cofins: UPDATE_CST_IPI,

    showDialog: false,
    items: ["Porcentagem", "Em valor"],
    selectTableShow: Boolean(),
    cstIpiHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "code"},
    ],
    cstIpiItems: Array(),
    currentPage: 1,
    lastPage: 1,
    search: String(),
  }),
  computed: {
    ...mapGetters(["getCstIpi", "getShowDialogRegisterIPI"]),
  },
  methods: {
    openCstIpiTable() {
      this.selectTableShow = !this.selectTableShow
    },

    getAllCstIpi(page){
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_CST_IPI,payload).then((response) => {
        this.cstIpiItems = response.data
        this.currentPage = response.current_page
        this.lastPage = response.last_page
      })
    },

    autocompleteCstIpi(page = 1) {
      if(this.search == "") {
        this.getAllCstIpi(1)
      } else {
        const payload = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
        }
        this.$store.dispatch(GET_FILTER_CST_IPI, payload).then((response) => {
          this.cstIpiItems = response.data
          this.currentPage = response.current_page
          this.lastPage = response.last_page
        })
      }
    },

    rowClickSelectTable(item) {
      this.component.product.cst_ipi_id = item.id
      this.component.cstIpi = item.description
      this.selectTableShow = !this.selectTableShow
    },

    changeIpiDialog() {
      this.$store.dispatch("setShowDialogRegisterIPI", true);
    },
    reset() {
      this.$refs.form.resetValidation();
    },
  },

  watch: {
     validationForm:function(val){
      this.component.validations[5] = val
      this.component.checkValidation()
    },
    'component.validations': function() {
      this.component.validations[5] = this.validationForm;
      this.component.checkValidation()
    },
    currentPage: function () {
      this.getAllCstIpi(this.currentPage)
    },
    search: function () {
      this.autocompleteCstIpi()
    }
    // getCstIpi: function (val) {
    //   val;
    //   this.cstipiItemsSelect = [];
    //   this.cstipiItemsSelect = Object.keys(this.getCstIpi).map(
    //     (key) => this.getCstIpi[key]
    //   );
    //   if (this.auxIPI == 0) {
    //     this.auxIPI = 1;
    //   } else {
    //     this.component.product.cst_ipi_id =
    //       this.cstipiItemsSelect[this.cstipiItemsSelect.length - 1];
    //   }
    // },
  },
  created() {
    this.getAllCstIpi()
    // this.cstipiItemsSelect = Object.keys(this.getCstIpi).map(
    //   (key) => this.getCstIpi[key]
    // );
  },
};
</script>
