<template>
  <div>
    <v-dialog persistent v-model="getShowDialogRegisterCancelSequenceInvoice" max-width="800">
      <v-card class="mx-auto" outlined>
        <v-card-title>Cancelamento de Nota de Sequênciade Nota Fiscal</v-card-title>
        <v-divider />
        <v-form ref="form" v-model="formValidate">
          <v-container>
            <v-row no-gutters>
              <v-col class="pa-1" cols="12" sm="4">
                <v-select label="Empresa" return-object outlined dense :rules="rules[0]" item-value="id"
                  item-text="fantasyName" :items="getEnterprises.data" v-model="enterpriseSelect" :readonly="component.viewOnly" ></v-select>
              </v-col>
              <v-col class="pa-1" cols="12" sm="4">
                <v-select label="Tipo De nota" outlined dense :rules="rules[0]"
                  :items="noteType" item-value="value"  v-model="formData.noteType" :readonly="component.viewOnly" ></v-select>
              </v-col>
              <v-col class="pa-1" cols="12" sm="4">
                <v-text-field label="Série da Nota" outlined dense type="Number" :rules="rules[0]"
                  v-model="formData.seriesNote" :readonly="component.viewOnly" ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3"> </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12" sm="6">
                <v-text-field label="*Número de Sequência Incial" outlined type="Number"
                  v-model="formData.initialNumberSequence" :rules="rules[0]" dense :readonly="component.viewOnly" ></v-text-field>
              </v-col>
              <v-col class="pr-4 pl-4" cols="12" sm="6">
                <v-text-field label="*Número de Sequência Final" outlined type="Number"
                  v-model="formData.finalNumberSequence" :rules="rules[0]" dense :readonly="component.viewOnly" ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12">
                <v-textarea counter outlined label="*Motivo" :rules="rules[1]" v-model="formData.reason" :readonly="component.viewOnly" ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="closeDialog">Cancelar</v-btn>
            <v-btn color="success" :disabled="activateButtom" @click="submit" v-if="!component.viewOnly" >Adicionar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { ADD_ORDER_SEQUENCE_INVOICE } from "../../../store/actions/cancel_sequecence_invoice";
import { rulesText, rulesTextArea } from "./rules";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters([
      "getShowDialogRegisterCancelSequenceInvoice", "auth/getUsers", "getEnterprises"
    ]),
    activateButtom: function () {
      if (this.formValidate) {
        return false;
      } else {
        return true;
      }
    },
  },

  data: function () {
    return {
      rules: [rulesText, rulesTextArea],
      enterpriseSelect:Object(),
      noteType: [
      { text: "NFE", value: "55"},
      { text: "NFC-e", value: "65"},],
      formValidate: Boolean(),
      currenctComponent: Object(),
      enterpriseList: Array(),
      formData: {
        enterprise: Object(),
        noteType: String(),
        initialNumberSequence: Number(),
        finalNumberSequence: Number(),
        reason: String(),
      },
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterCancelSequenceInvoice", false);
    },
    submit() {
      let payload = {
        cnpjEmitter: this.enterpriseSelect.cnpj,
        series: this.formData.seriesNote,
        dateOfCancelattion: this.formaterDateSales(),
        initialNumberDiscontinued: this.formData.initialNumberSequence,
        finalNumberDiscontinued: this.formData.finalNumberSequence,
        justification: this.formData.reason,
        modelNf: this.formData.noteType,
        user_identfy: this["auth/getUsers"].user.email
      }
      this.$store.dispatch(ADD_ORDER_SEQUENCE_INVOICE, payload);
      this.closeDialog()
    },
    async getAllEnterprise(page = 1) {
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
  formaterDateSales() {
  let dataFormat = new Date();
  let year = dataFormat.getFullYear();
  let day = dataFormat.getDate()
  let month = dataFormat.getMonth() + 1;

  if (day < 10) {
    day = `0${day}`;
  } else {
    day = day.toString();
  }
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = month.toString();
  }

  let dateTimeFormat =
    year +
    "-" +
    month +
    "-" +
    day +
    " " 

  return dateTimeFormat;
}
  },
  watch: {
    getEnterprises(val) {
      this.enterpriseSelect = val.data[0];
    },
  },
  created() {
    this.getAllEnterprise();
    if(this.component.viewOnly) {
      this.formData.enterprise = 1
      this.formData.noteType = this.component.itemToLoadData.modelNf
      this.formData.seriesNote = this.component.itemToLoadData.note_series
      this.formData.initialNumberSequence = this.component.itemToLoadData.sequence_initial_cancel
      this.formData.finalNumberSequence = this.component.itemToLoadData.sequence_finish_cancel
      this.formData.reason = this.component.itemToLoadData.reason_for_cancellation
    }
  },
  beforeDestroy() {
    this.formData = {};
    this.component.viewOnly = false
    this.component.itemToLoadData = {}
  },



};
</script>

<style></style>