<template>
  <v-form ref="form" v-model="validationForm">
    <v-container height="50px"></v-container>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          v-model="this.component.cst"
          :readonly="component.justVisible"
          label="*CST de Saída"
          :rules="component.textRules"
          append-icon="mdi-magnify"
          @click:append="openCstTable()"
        >
        <template slot="append-outer">
          <v-icon @click="changeCstDialog()">mdi-plus-circle</v-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openTemplateDialog()">mdi-file-document-edit-outline</v-icon>
            </template>
            <span>Config. Fiscal</span>
          </v-tooltip>
        </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          v-model="this.component.csosn"
          :readonly="component.justVisible"
          label="*CSOSN de Saída"
          :rules="component.textRules"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeCsosnDialog()"
          append-icon="mdi-magnify"
          @click:append="openCsosnTable()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          v-model="this.component.aliquot"
          :readonly="component.justVisible"
          label="*Aliquota de Saída"
          :rules="component.textRules"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeAliquotDialog()"
          append-icon="mdi-magnify"
          @click:append="openAliquotTable()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          v-model="this.component.cfop"
          :readonly="component.justVisible"
          label="*CFOP de Saída"
          :rules="component.textRules"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeCfopDialog()"
          append-icon="mdi-magnify"
          @click:append="openCfopTable()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout>
          <v-autocomplete
            hide-no-data
            class="ma-2"
            label="*Origem mercadoria"
            v-model="component.product.source_product"
            :readonly="component.justVisible"
            :rules="[
              () => !!component.product.source_product || 'Campo Obrigatório!',
            ]"
            full-width
            outlined
            :items="items"
          >
          </v-autocomplete>
        </v-layout>
      </v-col>

      <v-col>
        <v-text-field
          outlined
          v-model="this.component.ncm"
          :readonly="component.justVisible"
          label="*NCM"
          :rules="component.textRules"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeNcmDialog()"
          append-icon="mdi-magnify"
          @click:append="openNcmTable()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete
          class="ma-2"
          hide-no-data
          :readonly="component.justVisible"
          outlined
          v-model="component.product.calculation_base_mode"
          label="*Modalidade BC"
          item-text="text"
          item-value="value"
          :items="modBC"
          :rules="[
            () => !!component.product.calculation_base_mode || 'Campo Obrigatório!',
          ]"
          required
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="value_st">
      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="string"
            outlined
            label="Codigo CEST"
            v-model="component.product.framing_class_cest"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="number"
            outlined
            label="Codigo Beneficio Fiscal na UF "
            v-model="component.product.tax_bemefot_code_uf"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
            class="ma-2"
            hide-no-data
            :readonly="component.justVisible"
            outlined
            v-model="component.product.tax_replacement_base_modality"
            label="Modalidade BC ST"
            item-text="text"
            item-value="value"
            :items="modST"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Margem de valor adicional (MVA)"
            v-model="component.product.additional_value_margin"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Redução da base ST Icms(%)"
            v-model="component.product.percentagem_calc_st_reduction"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="number"
            outlined
            label="Percentual Icms St (%)"
            v-model="component.product.additional_aliquot_tax_st"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="number"
            outlined
            label="NFCI "
            v-model="component.product.nfci"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual FCP (%)"
            v-model="component.product.aliquot_fcp"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual FCP retido ST (%)"
            v-model="component.product.percentage_fcp_tax"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual Red. BC (%) "
            v-model="component.product.percentage_calc_base_reduction"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-autocomplete
            class="ma-2"
            :readonly="component.justVisible"
            hide-no-data
            outlined
            v-model="component.product.reason_exemption_icms"
            label="Motivo desoneracao Icms"
            item-text="text"
            item-value="value"
            :items="modDesIcms"
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual Diferimento (%) "
            v-model="component.product.percentage_deferral"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Aliquota Sup. Cons. Final "
            v-model="component.product.percentage_supported_final_consumer"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <FiscalTemplateDialog v-if="selectTemplateDialog" :component="this"/>
    <ModalNcm
      v-if="addDialogNcm"
      :addDialogNcm="addDialogNcm"
      :component="this"
      @close="addDialogNcm = $event"
    />
    <ModalAliquot v-if="getShowDialogRegisterAliquot" :component="this" />
    <ModalPisCofins
      v-if="addDialogPisCofins"
      :addDialogPisCofins="addDialogPisCofins"
      :component="this"
      @close="addDialogPisCofins = $event"
    />
    <ModalCfop v-if="getShowDialogRegisterCFOP" :component="this" />
    <ModalCst v-if="getShowDialogRegisterCST" :component="this" />
    <ModalCsosn v-if="getShowDialogRegisterCSOSN" :component="this" />
    <NcmDataTable :component="this" title="NCM" />
    <CsosnDataTable :component="this" title="CSOSN" />
    <CstDataTable :component="this" title="CST" />
    <AliquotDataTable :component="this" title="Alíquota" />
    <CfopDataTable :component="this" title="CFOP" />
  </v-form>
</template>

<script>
import ModalNcm from "../../../ncm/AddDialog/index";
import ModalAliquot from "../../../aliquot/AddDialog/index";
import ModalCfop from "../../../cfop/AddDialog/index";
import ModalCst from "../../../cst/AddDialog/index";
import ModalCsosn from "../../../csosn/AddDialog/index";
import ModalPisCofins from "../../../pis_cofins/AddDialog/index";
import { GET_ALL_ALIQUOT, GET_FILTER_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_CFOP, GET_FILTER_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_CST, GET_FILTER_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN, GET_FILTER_CSOSN } from "../../../../store/actions/csosn";
import { GET_ALL_NCM, GET_FILTER_NCM } from "../../../../store/actions/ncm";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";
import { GET_ALL_CST_IPI } from "../../../../store/actions/cstipi";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import NcmDataTable from "@/components/products/Tabs/TaxIcms/NcmDialog/index.vue";
import CsosnDataTable from "@/components/products/Tabs/TaxIcms/CsosnDialog/index.vue";
import CstDataTable from "@/components/products/Tabs/TaxIcms/CstDialog/index.vue";
import AliquotDataTable from "@/components/products/Tabs/TaxIcms/AliquotDialog/index.vue";
import CfopDataTable from "@/components/products/Tabs/TaxIcms/CfopDialog/index.vue";
import FiscalTemplateDialog from "./FiscalTemplateDialog/index.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalNcm,
    ModalAliquot,
    ModalPisCofins,
    ModalCfop,
    ModalCst,
    ModalCsosn,
    NcmDataTable,
    CsosnDataTable,
    CstDataTable,
    AliquotDataTable,
    CfopDataTable,
    FiscalTemplateDialog,
  },

  props: {
    component: Object(),
  },

  data: () => ({
    validationForm:false,
    auxSetValuesFields: {
      auxCST: 0,
      auxCSOSN: 0,
      auxNCM: 0,
    },
    page: Number(),
    last_page: Number(),
    item_listNcm: Array(),
    item_list: Array(),
    addDialogNcm: false,
    addDialogAliquot: false,
    addDialogPisCofins: false,
    addDialogCfop: false,
    addDialogCst: false,
    addDialogCsosn: false,
    aliquotItemsSelect: Array(),
    cstPisCofinsItemsSelect: Array(),
    cfopItemsSelect: Array(),
    cstItemsSelect: Array(),
    csosnItemsSelect: Array(),
    objectToUpdate: Object(),
    vSelect: String(),
    myTeste: "valor aleatório",
    items: [
      { text: "Nacional", value: "0" },
      { text: "Estrangeira - Imoportação direta", value: "2" },
      { text: "Estrangeira - Adquirida no mercado interno", value: "3" },
    ],
    modBC: [
      { text: "Margem de valor agregado", value: "0" },
      { text: "Pauta", value: "1" },
      { text: "Preço Tabelado Max", value: "2" },
      { text: "Valor de Operação", value: "3" },
    ],
    modST: [
      { text: "Preço tabelado ou máximo sugerido", value: "0" },
      { text: "Lista Negativa (valor)", value: "1" },
      { text: "Lista Positiva (valor)", value: "2" },
      { text: "Lista Neutra (valor)", value: "3" },
      { text: "Margem Valor Agregado (%)", value: "4" },
      { text: "Pauta (valor)", value: "5" },
      { text: "Valor da Operação (%)", value: "6" },
    ],
    modDesIcms: [
      { text: "Uso na agropecuária", value: 3 },
      { text: "Utilitários e Motocicletas da Amazônia Ocidental", value: 6 },
      { text: "Suframa", value: 7 },
      { text: "Órgão de fomento e desenvolvimento agropecuário.", value: 12 },
      { text: "Outros", value: 9 },
    ],
    value_st: true,
    // NCM DIALOG
    selectTableShow: Boolean(),
    ncmItems: Array(),
    ncmHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "code"},
    ],
    currentPage: 1,
    lastPage: 1,
    searchNcm: String(),
    searchInputNcm: String(),
    // CSOSN DIALOG
    selectTableShowCsosn: Boolean(),
    csosnItems: Array(),
    csosnHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "code"},
    ],
    currentPageCsosn: 1,
    lastPageCsosn: 1,
    searchCsosn: String(),
    searchInputCsosn: String(),
    // CST DIALOG
    selectTableShowCst: Boolean(),
    cstItems: Array(),
    cstHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "code"},
    ],
    currentPageCst: 1,
    lastPageCst: 1,
    searchCst: String(),
    searchInputCst: String(),
    // ALIQUOT DIALOG
    selectTableShowAliquot: Boolean(),
    aliquotItems: Array(),
    aliquotHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "aliquot"},
    ],
    currentPageAliquot: 1,
    lastPageAliquot: 1,
    searchAliquot: String(),
    searchInputAliquot: String(),
    // CFOP DIALOG
    selectTableShowCfop: Boolean(),
    cfopItems: Array(),
    cfopHeaders: [
      { text: "ID", value: "id"},
      { text: "Descrição", value: "description"},
      { text: "Código", value: "code"},
    ],
    currentPageCfop: 1,
    lastPageCfop: 1,
    searchCfop: String(),
    searchInputCfop: String(),

    enterprise_crt: Number(),
    templates: Array(),
    selectedTemplate: null,
    selectTemplateDialog: false,
    pisItems: Array(),
    ipiItems: Array(),

  }),
  
  computed: {
    ...mapGetters([
      "getNcm",
      "getAliquot",
      "getCfop",
      "getCst",
      "getCsosn",
      "getPisCofins",
      "getShowDialogRegisterCSOSN",
      "getShowDialogRegisterAliquot",
      "getShowDialogRegisterCFOP",
      "getShowDialogRegisterCST",
      "getAliquotToAdd",
      "getCfopToAdd",
      "getEnterprises",
    ]),
  },

  watch: {
     validationForm:function(val){
      this.component.validations[2] = val
      this.component.checkValidation()
    },
    'component.validations': function() {
      this.component.validations[2] = this.validationForm;
      this.component.checkValidation()
    },
    
    //Deprecated
    // getNcm: function (val) {
    //   val;
    //   this.ncmItemsSelect = [];
    //   this.ncmItemsSelect = Object.keys(this.getNcm).map(
    //     (key) => this.getNcm[key]
    //   );
    //   if (this.auxSetValuesFields.auxNCM == 0) {
    //     this.auxSetValuesFields.auxNCM = 1;
    //   } else {
    //     this.component.product.ncm_id =
    //       this.ncmItemsSelect[this.ncmItemsSelect.length - 1].id;
    //   }
    // },
    getAliquotToAdd: function () {
      this.aliquotItemsSelect.push(this.getAliquotToAdd);
      this.component.product.aliquot_id = this.getAliquotToAdd.id;
    },
    getAliquot: function (value) {
      if(value.data) {
        this.aliquotItems = value.data
        this.currentPageAliquot = 1
        this.lastPageAliquot = 1
      } else {
        this.aliquotItems = Object.values(value)
        this.currentPageAliquot = 1
        this.lastPageAliquot = 1
      }
    },
    getCfopToAdd: function () {
      this.cfopItemsSelect.push(this.getCfopToAdd);
      this.component.product.cfop_id = this.getCfopToAdd.id;
    },
    getCfop: function (val) {
       
      this.filterCFOP(val)
      // this.cfopItemsSelect = Object.values(this.getCfop);
    },
    //Deprecated
    // getCst: function (val) {
    //   val;
    //   this.cstItemsSelect = Object.keys(this.getCst).map(
    //     (key) => this.getCst[key]
    //   );
    //   if (this.auxSetValuesFields.auxCST == 0) {
    //     this.auxSetValuesFields.auxCST = 1;
    //   } else {
    //     this.component.product.cst_id =
    //       this.cstItemsSelect[this.cstItemsSelect.length - 1];
    //   }
    // },

    //Deprecated
    // getCsosn: function (val) {
    //   val;
    //   this.csosnItemsSelect = Object.keys(this.getCsosn).map(
    //     (key) => this.getCsosn[key]
    //   );

    //   if (this.auxSetValuesFields.auxCSOSN == 0) {
    //     this.auxSetValuesFields.auxCSOSN = 1;
    //   } else {
    //     this.component.product.csosn_id =
    //       this.csosnItemsSelect[this.csosnItemsSelect.length - 1];
    //   }
    // },

    getPisCofins: function (val) {
      this.cstPisCofinsItemsSelect = Object.keys(val).map((key) => val[key]);
    },

    currentPage: function () {
      this.getAllNCM(this.currentPage)
    },

    currentPageCsosn: function () {
      this.getAllCSOSN(this.currentPageCsosn)
    },
    currentPageCst: function () {
      this.getAllCST(this.currentPageCst)
    },
    currentPageCfop: function () {
      this.getAllCFOP(this.currentPageCfop)
    },

    searchNcm: function () {
      this.autocompleteNCM()
    },
    searchInputNcm: function (val) {
      if(val == "") {
        this.searchNcm = ""
      }
    },

    searchCsosn: function () {
      this.autocompleteCSOSN()
    },
    searchInputCsosn: function (val) {
      if(val == "") {
        this.searchCsosn = ""
      }
    },

    searchCst: function () {
      this.autocompleteCST()
    },
    searchInputCst: function (val) {
      if(val == "") {
        this.searchCst = ""
      }
    },

    searchAliquot: function () {
      this.autocompleteAliquot()
    },
    searchInputAliquot: function (val) {
      if(val == "") {
        this.searchAliquot = ""
      }
    },

    searchCfop: function () {
      this.autocompleteCfop()
    },
    searchInputCfop: function (val) {
      if(val == "") {
        this.searchCfop = ""
      }
    },

    enterprise_crt: function(val) {
      if(val == 1 || val == 2) {
        this.templates = [
          {
            templateType: "Saida Simples nacional do produto - e Pis e Cofins e IPi Padrão",
            cstSaida: 0,
            cfop: 5102,
            csosn: 102,
            aliquot: 17,
            modalidadBc: "Margem de valor agregado",
            pis: 99,
            cofins: 99,
            ipi: 99
          },
          {
            templateType: "Saida Simples nacional do produto - Com ST Anterior - e Pis e Cofins e IPi Padrão",
            cstSaida: 60,
            cfop: 5405,
            csosn: 500,
            aliquot: 17,
            modalidadBc: "Margem de valor agregado",
            pis: 99,
            cofins: 99,
            ipi: 99
          },
        ]
      } else if(val == 3) {
        this.templates = [
          {
            templateType: "Saída Regime Normal do produto - e Pis e Cofins e IPi Padrão",
              cstSaida: 0,
              cfop: 5102,
              csosn: 102,
              aliquot: 17,
              modalidadBc: "Margem de valor agregado",
              pis: 99,
              cofins: 99,
              ipi: 99
          },
          {
            templateType: "Saída Regime Normal do produto - Com ST Anterior - e Pis e Cofins e IPi Padrão",
              cstSaida: 60,
              cfop: 5405,
              csosn: 500,
              aliquot: 17,
              modalidadBc: "Margem de valor agregado",
              pis: 99,
              cofins: 99,
              ipi: 99
          },
        ] 

      }
    },
  },
  created() {
    // this.getAll();
    this.getAllEnterprise()
    this.getAllNCM(1);
    this.getAllCSOSN(1);
    this.getAllCST(1);
    this.getAllCFOP(1);
    this.getAllPisCofins();
    this.getAllCstIpi();
    //Deprecated
    // this.ncmItemsSelect = Object.keys(this.getNcm).map(
    //   (key) => this.getNcm[key]
    // );
    this.cstPisCofinsItemsSelect = Object.keys(this.getPisCofins).map(
      (key) => this.getPisCofins[key]
    );
    //Deprecated
    // this.cstItemsSelect = Object.keys(this.getCst).map(
    //   (key) => this.getCst[key]
    // );
    this.csosnItemsSelect = Object.keys(this.getCsosn).map(
      (key) => this.getCsosn[key]
    );

    this.checkEnterprises(this.component.idEnterprise)
  },

  destroyed() {
    this.$destroy;
  },

  methods: {
    openNcmTable() {
      this.getAllNCM(1)
      this.selectTableShow = !this.selectTableShow
    },
    openCsosnTable() {
      this.getAllCSOSN(1)
      this.selectTableShowCsosn = !this.selectTableShowCsosn
    },
    openCstTable() {
      this.getAllCST(1)
      this.selectTableShowCst = !this.selectTableShowCst
    },
    openAliquotTable() {
      this.getAllAliquot(1)
      this.selectTableShowAliquot = !this.selectTableShowAliquot
    },
    openCfopTable() {
      this.getAllCFOP(1)
      this.selectTableShowCfop = !this.selectTableShowCfop
    },
    
    // filterCFOP(value){
    //   var auxArrayCfop = value
    //   auxArrayCfop.map((element) => {
    //     if(element.code >= 5000 && element.code <= 8000){
    //       this.cfopItemsSelect.push(element)
    //     }
    //   })
    // },
    filterCFOP(value) {
      if (!Array.isArray(value)) {
        return [];
      }
      else {
        this.cfopItemsSelect = value.filter(element => {
          return element.code >= 5000 && element.code <= 8000;
        });
      }
    },
    // getAll(page = 1) {
    //   var payload = {
    //     page: page,
    //     limit: 10,
    //     paginate: true,
    //   };
    //   // this.$store.dispatch(GET_ALL_ALIQUOT, payload);
    //   // this.$store.dispatch(GET_ALL_CFOP, payload).then((response) => {
        
    //   //   this.filterCFOP(response.data)
    //   // });
    //   // this.$store.dispatch(GET_ALL_CST, payload).then((response) => {
    //   //   this.cstItemsSelect = [...response.data]
    //   // });
    //   // this.$store.dispatch(GET_ALL_CSOSN, payload).then((response) => {
    //   //   this.csosnItemsSelect = [...response.data]
    //   // });
    //   // this.$store.dispatch(GET_ALL_NCM, payload).then((response) => {
        
    //   //   this.ncmItemsSelect = [...response.data]
    //   // });
    // },

    getAllNCM(page) {
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_NCM, payload).then((response) => {
        this.ncmItems = response.data
        this.currentPage = response.current_page
        this.lastPage = response.last_page
      });
    },

    getAllCSOSN(page) {
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_CSOSN, payload).then((response) => {
        this.csosnItems = response.data
        this.currentPageCsosn = response.current_page
        this.lastPageCsosn = response.last_page
      });
    },

    getAllCST(page) {
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_CST, payload).then((response) => {
        this.cstItems = response.data
        this.currentPageCst = response.current_page
        this.lastPageCst = response.last_page
      });
    },

    getAllAliquot(page) {
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_ALIQUOT, payload).then((response) => {
      });
    },

    getAllCFOP(page) {
      let cfopArr = Array()
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_CFOP, payload).then((response) => {

        
        for(let i=0; i<response.data.length; i++) {
          if(response.data[i].code.toString()[0] == '5' || response.data[i].code.toString()[0] == '6') {
            cfopArr.push(response.data[i])
          }
        }
        
        if(this.component.cst == "0") {
          for(let i=0; i<cfopArr.length; i++) {
            if(cfopArr[i].code == 5405) {
              cfopArr.splice(i, 1)
            }
          }
        }

        if(this.component.cst == "60") {
          for(let i=0; i<cfopArr.length; i++) {
            if(cfopArr[i].code == 5102) {
              cfopArr.splice(i, 1)
            }
          }

        }


        this.cfopItems = cfopArr
        this.currentPageCfop = response.current_page
        this.lastPageCfop = response.last_page
      });
    },

    async getAllPisCofins(){
      const payload = {
        page: 1,
        paginate: true,
        limit: 100,
      }
      await this.$store.dispatch(GET_ALL_PIS_COFINS,payload).then((response) => {
        this.pisItems = response.data
      })
    },

    getAllCstIpi(){
      const payload = {
        page: 1,
        limit: 100,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_CST_IPI,payload).then((response) => {
        this.ipiItems = response.data
      })
    },

    async getAllEnterprise(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },

    autocompleteNCM(page = 1) {
      if (this.searchNcm == "") {
        this.getAllNCM(1) 
      } else {
        const payload = {
          search: this.searchNcm,
          page: page,
          limit: 10,
          paginate: true,
        };
        this.$store.dispatch(GET_FILTER_NCM, payload).then((response) => {
          this.ncmItems = response.data
          this.currentPage = response.current_page
          this.lastPage = response.last_page
        })
      }
    },
    
    autocompleteCSOSN(page = 1) {
      if (this.searchCsosn == "") {
        this.getAllCSOSN(1)
      } else {
        const payload = {
          search: this.searchCsosn,
          page: page,
          limit: 10,
          paginate: true,
        };
        this.$store.dispatch(GET_FILTER_CSOSN, payload).then((response) => {
          this.csosnItems = response.data
          this.currentPageCsosn = response.current_page
          this.lastPageCsosn = response.last_page
        })
      }
    },

    autocompleteCST(page = 1) {
      if (this.searchCst == "") {
        this.getAllCST(1)
      } else {
        const payload = {
          search: this.searchCst,
          page: page,
          limit: 10,
          paginate: true,
        };
        this.$store.dispatch(GET_FILTER_CST, payload).then((response) => {
          this.cstItems = response.data
          this.currentPageCst = response.current_page
          this.lastPageCst = response.last_page
        })
      }
    },

    autocompleteAliquot(page = 1) {
      if (this.searchAliquot == "") {
        this.getAllAliquot(1)
      } else {
        const payload = {
          search: this.searchAliquot,
          page: page,
          limit: 10,
          paginate: true,
        };
        this.$store.dispatch(GET_FILTER_ALIQUOT, payload)
      }
    },

    autocompleteCfop(page = 1) {
      if (this.searchCfop == "") {
        this.getAllCFOP(1)
      } else {
        const payload = {
          search: this.searchCfop,
          page: page,
          limit: 10,
          paginate: true,
        };
        this.$store.dispatch(GET_FILTER_CFOP, payload).then((response) => {
          this.cfopItems = response.data
          this.currentPageCfop = response.current_page
          this.lastPageCfop = response.last_page
        })
      }
    },


    rowClickSelectTable(item) {
      this.component.product.ncm_id = item.id
      this.component.ncm = item.code
      this.selectTableShow = !this.selectTableShow
    },

    rowClickSelectTableCsosn(item) {
      this.component.product.csosn_id = item.id
      this.component.csosn = item.code
      this.selectTableShowCsosn = !this.selectTableShowCsosn
    },

    rowClickSelectTableCst(item) {
      this.component.product.cst_id = item.id
      this.component.cst = item.code.toString()
      this.selectTableShowCst = !this.selectTableShowCst
    },
    
    rowClickSelectTableAliquot(item) {
      this.component.product.aliquot_id = item.id
      this.component.aliquot = item.aliquot.toString()
      this.selectTableShowAliquot = !this.selectTableShowAliquot
    },

    rowClickSelectTableCfop(item) {
      this.component.product.cfop_id = item.id
      this.component.cfop = item.code.toString()
      this.selectTableShowCfop = !this.selectTableShowCfop
    },
    

    

    change_addDialog(item) {
      this.objectToUpdate = item;
    },
    changeNcmDialog() {
      this.addDialogNcm = !this.addDialogNcm;
    },
    changeAliquotDialog() {
      this.$store.dispatch("setShowDialogRegisterAliquot", true);
    },
    changeCfopDialog() {
      this.$store.dispatch("setShowDialogRegisterCFOP", true);
    },
    changeCstDialog() {
      this.$store.dispatch("setShowDialogRegisterCST", true);
    },
    changeCsosnDialog() {
      this.$store.dispatch("setShowDialogRegisterCSOSN", true);
    },
    changePisCofinsDialog() {
      this.addDialogPisCofins = !this.addDialogPisCofins;
    },
    reset() {
      this.$refs.form.resetValidation();
    },

    enableTaxFieldIcms(val) {
      switch (val) {
        case 0:
          this.cst_tribute_integralmente();
          break;
        case 10:
          this.cst_tribute_icms_st();
          break;

        default:
          break;
      }
    },

    cst_tribute_integralmente() {
      (this.csosn_show = false),
        (this.code_cest = false),
        (this.mode_st = false),
        (this.mva = false),
        (this.nfci = true),
        (this.percentagem_st_reduction = false);
      this.aliquot_fcp_show = true;
    },

    cst_tribute_icms_st() {
      (this.aliquot_fcp = true),
        (this.csosn_show = false),
        (this.code_cest = true),
        (this.mode_st = true),
        (this.mva = true),
        (this.nfci = true),
        (this.additional_aliquot_tax_st = true),
        (this.percentagem_st_reduction = true),
        (this.percentage_fcp_tax = true);
    },

    checkEnterprises(selectedEnterpriseId) {
      for(let i = 0; i < this.getEnterprises.data.length; i++) {
        if(this.getEnterprises.data[i].id == selectedEnterpriseId) {
          this.enterprise_crt = this.getEnterprises.data[i].crt
        }
      }

    },
    openTemplateDialog() {
      this.selectTemplateDialog = true
    },

    selectTemplate() {
      this.selectTemplateDialog = false

      this.component.product.cst_id = this.findCstId(this.selectedTemplate.cstSaida)
      this.component.cst = this.selectedTemplate.cstSaida.toString()

      this.component.product.csosn_id = this.findCsosnId(this.selectedTemplate.csosn)
      this.component.csosn = this.selectedTemplate.csosn.toString()

      this.component.product.aliquot_id = this.findAliquotId(this.selectedTemplate.aliquot)
      this.component.aliquot = this.selectedTemplate.aliquot.toString()

      this.component.product.cfop_id = this.findCfopId(this.selectedTemplate.cfop)
      this.component.cfop = this.selectedTemplate.cfop.toString()

      this.component.product.calculation_base_mode = "0"

      this.findPisId(this.selectedTemplate.pis)

      this.findCofinsId(this.selectedTemplate.cofins)
      
      this.findIpiId(this.selectedTemplate.ipi)
    },

    findCstId(code) {
      let cstObj = this.cstItems.filter(object => object.code == code);
      return cstObj[0].id
    },

    findCsosnId(code) {
      let csosnObj = this.csosnItems.filter(object => object.code == code);
      return csosnObj[0].id
    },

    findAliquotId(aliquot) {
      let aliquotObj = this.aliquotItems.filter(object => object.aliquot == aliquot);
      return aliquotObj[0].id
    },

    findCfopId(code) {
      let cfopObj = this.cfopItems.filter(object => object.code == code);
      return cfopObj[0].id
    },

    findPisId(code) {
      let pisObj = this.pisItems.filter(object => object.code == code);
      this.component.product.cst_pis_id = pisObj[0].id 
      this.component.cstPis = pisObj[0].description
    },

    findCofinsId(code) {
      let cofinsObj = this.pisItems.filter(object => object.code == code);
      this.component.product.cst_cofins_id = cofinsObj[0].id
      this.component.cstCofins = cofinsObj[0].description
    },

    findIpiId(code) {
      let ipiObj = this.ipiItems.filter(object => object.code == code);
      this.component.product.cst_ipi_id = ipiObj[0].id
      this.component.cstIpi = ipiObj[0].description
    },
  },

};
</script>
