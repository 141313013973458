<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field label="Pesquisar" outlined append-icon="mdi-magnify" v-model="search"></v-text-field>
      </v-card-text>
      <Table />
    </v-card>
    <AlertAPI />
    <LoadScreen/>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/purchase_order/CardTitle/";
import Table from "../components/purchase_order/Table/";
import AlertAPI from "../components/alert_api/alert_api";
import LoadScreen from "../components/load/index.vue"
export default {
  components: { Container, CardTitle, Table, AlertAPI, LoadScreen },
  data: () => ({
    search: "",
    enterpriseId: Object()
  }),
  watch: {
    enterpriseId: function() {
      
    }
  }
};
</script>
