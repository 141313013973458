const GET_ALL_NOTEENTRY = "GET_ALL_NOTEENTRY";
const REVERSAL_NOTEENTRY = "REVERSAL_NOTEENTRY";
const ADD_NOTE_ENTRY = "ADD_NOTE_ENTRY";
const GET_FILTER = "GET_FILTER";

/** Entrada com XML */

const VALIDATION_PRODUCT = "VALIDATION_PRODUCT";

module.exports = {
  GET_FILTER,
  GET_ALL_NOTEENTRY,
  REVERSAL_NOTEENTRY,
  ADD_NOTE_ENTRY,
  VALIDATION_PRODUCT,
};
