import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import { SET_REPORT_SALES } from "../../../../store/actions/report_sales";
import { headerPDF } from "./headers";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function getReport() {
  try {
    this.$store.dispatch(SET_REPORT_SALES, this.formData)
  } catch (error) {
    return error;
  }
}

function viewPDF(object) {
  this.iterator++
  var valuesOfTable = Array();

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  if (object.length > 0) {
    for (let count = 0; count < object.length; count++) {
      valuesOfTable.push(Array())
      valuesOfTable[count].push(object[count].status)
      valuesOfTable[count].push(object[count].client_id)
      valuesOfTable[count].push(object[count].data_sale)
      valuesOfTable[count].push(object[count].number_sale)
      valuesOfTable[count].push(object[count].series)
      valuesOfTable[count].push(object[count].value_total_products) 
      valuesOfTable[count].push(object[count].value_descount_total)
      valuesOfTable[count].push(object[count].base_cal_icms_total)//value_total_note
      valuesOfTable[count].push(object[count].value_icms_total)
      valuesOfTable[count].push(object[count].value_total_note)
    }
    // var headerPDF = [
    //   "Dados do relatório",
    //   "Status ",
    //   "Data da venda",
    //   "numero da venda",
    //   "serie",
    //   "Valor do produto",
    //   "Valor Desconto",
    //   "Base de calculo icms",
    //   "Valor icms",
    //   "Valor da venda",
    // ];
  } else {
    valuesOfTable.push(Array())
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
  }


let valorProdutoTotal = 0;
let valorDescontoTotal = 0;
let baseCalculoIcmsTotal = 0;
let valorIcmsTotal = 0;
let valorVendaTotal = 0;

for (let i = 0; i < object.length; i++) {
  const row = object[i];
  valorProdutoTotal += row.value_total_products || 0;
  valorDescontoTotal += row.value_descount_total || 0;
  baseCalculoIcmsTotal += row.base_cal_icms_total || 0;
  valorIcmsTotal += row.value_icms_total || 0;
  valorVendaTotal += row.value_total_note || 0;
}
const totalRowStyle = {
  bold: true,
};

const totalRow = [
  { text: 'Total:', style: totalRowStyle },
  '', 
  '',
  '',
  '',
  { text: valorProdutoTotal.toFixed(2), style: totalRowStyle }, 
  { text: valorDescontoTotal.toFixed(2), style: totalRowStyle },
  { text: baseCalculoIcmsTotal.toFixed(2), style: totalRowStyle },
  { text: valorIcmsTotal.toFixed(2), style: totalRowStyle },
  { text: valorVendaTotal.toFixed(2), style: totalRowStyle },
];

valuesOfTable.push(totalRow);

  var docDefinition = {
    pageOrientation: "landscape",
    content: [
      { text: "Relatório de Vendas", style: "header" },
      {
        layout: "lightHorizontalLines",
        table: {
          headerRows: 1,
          body: [headerPDF].concat(valuesOfTable),
        },
      },
    ],
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        lineHeight: 2,
      },
    },
  };

  pdfMake.createPdf(docDefinition).open();
}

function getAllEnterprises(page = 1) {
  page;
  var payload = {
    page: null,
    limit: null,
    paginate: false,
  };
  this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
}

export default {
  getAllEnterprises,
  getReport,
  viewPDF,
};
