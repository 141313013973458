import rules from "./rules"





var data = {
    rules,
    formValidation:Boolean(),
    headers: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "legalpeople.socialName" },
        { text: "Nome Fantasia", value: "legalpeople.fantasyName" },
        { text: "CNPJ", value: "legalpeople.cnpj" },
        { text: "Telefone", value: "contacts[0].phone" },
        { text: "Cidade", value: "address[0].city.name" },
        { text: "Estado", value: "address[0].state" },
        { text: "Ação", value: "actions" },
    ],
    modeShipping:[
        {text: "CIF", value: 'cif'},
        {text: "FOB", value: 'fob'},
    ],
    transporterRegistered: Array(),
    selectedTransporter: {
        modeShipping: Number(),
        vehicle_plate: String(),
        value_shipping: String(),
        value_secure: String(),
        others_values: String(),
        product_type: String(),
        // volume_transported: Number(),
        transporter: {
            uf: String(),
            address: String(),
            city: String(),
            name: String(),
        },
        volume: {
            especification: String(),
            brand: String(),
            grossWeigth: String(),
            netWeight: String(),
            nVolume: String(),
            volume_transported: Number()
        },
        legalpeople: {
            fantasyName: String()
        }
    },
}



export default data