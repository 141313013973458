



function setModelFutureSales(data, infPayment) {
    
    const futureSales = {
        access_key: data.access_key,
        account_plans_id: data.account_plans_id,
        authorization_protocol: data.authorization_protocol,
        base_cal_icms_total:parseFloat(data.base_cal_icms_total) ,
        base_calc_ipi_total:parseFloat(data.base_calc_ipi_total) ,
        base_calc_susbt_total:parseFloat(data.base_calc_susbt_total) ,
        // billstoReceive: 0,
        // client: 0,
        client_id: data.client_id,
        data_sale: data.data_sale,
        // enterprise: 0,
        enterprise_id: data.enterprise_id,
        form_of_payments: infPayment.form.code,
        itens_sales: data.itens_sales,
        note: data.note,
        note_models_id: data.note_models_id,
        number_doc_client: data.number_doc_client,
        number_sale: data.number_sale,
        pdv_id: data.pdv_id,
        seller_id: data.seller_id,
        series: data.series,
        status: data.status,
        tax_operation_types_id: data.tax_operation_types_id,
        user_id: data.user_id,
        value_account_total: parseFloat(data.value_account_total),
        value_base_cofins_st_total: parseFloat(data.value_base_cofins_st_total),
        value_base_cofins_total: parseFloat(data.value_base_cofins_total),
        value_base_pis_st_total: parseFloat(data.value_base_pis_st_total),
        value_base_pis_total: parseFloat(data.value_base_pis_total),
        value_cofins_st_total: parseFloat(data.value_cofins_st_total),
        value_cofins_total: parseFloat(data.value_cofins_total),
        // value_descount_total: parseFloat(data.value_descount_total),
        value_icms_free_total: parseFloat(data.value_icms_free_total),
        value_icms_substituicao_total: parseFloat(data.value_icms_substituicao_total),
        value_icms_total: parseFloat(data.value_icms_total),
        value_ipi_free_total: parseFloat(data.value_ipi_free_total),
        value_ipi_outher_total: parseFloat(data.value_ipi_outher_total),
        value_ipi_total: parseFloat(data.value_ipi_total),
        value_other_cofins_total: parseFloat(data.value_other_cofins_total),
        value_other_pis_total: parseFloat(data.value_other_pis_total),
        value_outher_tax_total: parseFloat(data.value_outher_tax_total),
        value_pis_st_total: parseFloat(data.value_pis_st_total),
        value_pis_total: parseFloat(data.value_pis_total),
        value_total_note: parseFloat(data.value_total_note),
        value_total_products: parseFloat(data.value_total_note),
        wasProcessed: false

    }
    
    return futureSales
}


module.exports = { setModelFutureSales }