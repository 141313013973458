<template>
  <v-container>
    <v-divider></v-divider>
    <v-form ref="form" v-model="component.validGeneral">
      <v-row>
        <v-col v-if="out_of_register" cols="3">
          <v-select
            :items="arrayEnterprises"
            item-text="fantasyName"
            v-model="enterpriseSelected"
            :readonly="component.justVisible"
            item-value="id"
            outlined
            label="Empresa"
          />
        </v-col>
        <v-col :cols="out_of_register ? 4 : 6">
          <v-text-field
            v-model="component.physical_people.name"
            :readonly="component.justVisible"
            label="*Nome"
            
            :rules="[
            () => !!component.physical_people.name || 'Campo Obrigatorio',
          ]"
            outlined
            required
          ></v-text-field>
        </v-col>
        <v-col :cols="out_of_register ? 4 : 6">
          <v-text-field
            v-model="component.physical_people.lastName"
            :readonly="component.justVisible"
            label="*Sobrenome"
            
            :rules="[
            () => !!component.physical_people.lastName || 'Campo Obrigatorio',
          ]"
            outlined
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="component.physical_people.cpf"
            :readonly="component.justVisible"
            label="*CPF"
            
            v-mask="'###.###.###-##'"
            :rules="[cpfRule]"
            outlined
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.physical_people.idCard"
            :readonly="component.justVisible"
            label="Identidade"
            
            type="number"
            outlined
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.physical_people.emitter"
            :readonly="component.justVisible"
            label="Orgão Emissor"
            outlined
            
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.physical_people.emitterState"
            :readonly="component.justVisible"
            label="UF Emissor"
            outlined
            
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.physical_people.dateOfBirth"
            :readonly="component.justVisible"
            label="Data de Nascimento"
            outlined
            
            type="date"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            v-model="component.physical_people.nacionality"
            :readonly="component.justVisible"
            label="Nacionalidade"
            :items="nacionality"
            
            outlined
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.physical_people.naturality"
            :readonly="component.justVisible"
            label="Naturalidade"
            outlined
            
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="*Gênero"
            required
            outlined
            v-model="component.physical_people.gender"
            :readonly="component.justVisible"
            :items="items_gener"
            :rules="[
            () => !!component.physical_people.gender || 'Campo Obrigatorio',
          ]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.sellerComission"
            :readonly="component.justVisible"
            label="Porcentagem de Comissão %"
            outlined
            required
            :rules="[
            () => component.sellerComission < 100 || 'Comissão deve ser menor que 100%',
          ]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { nameRules, LastNameRules, cpfRules } from "./script";
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE
} from "../../../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getEnterprises"])
  },
  props: {
    component: Object(),
    out_of_register: Boolean(),
    justVisible: Boolean()
  },
  data: () => ({
    items_gener: ["Masculino", "Feminino"],
    nacionality: ["Brasileiro", "Estrangeiro"],
    arrayEnterprises: Array(),
    enterpriseSelected: Number(),
    nameRules,
    LastNameRules,
    cpfRules
  }),
  methods: {
    getAllEnterprise() {
      var payload = {
        page: null,
        limit: null,
        paginate: false
      };
      this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
    clear() {
      this.$refs.form.reset();
    },
    checkCpf(cpf) {
      // Remove caracteres não numéricos do CPF
      cpf = cpf.replace(/[^\d]/g, '');

      // Verifica se o CPF tem 11 dígitos
      if (cpf.length !== 11) {
        return false;
      }

      // Verifica se todos os dígitos são iguais (CPF inválido se for verdadeiro)
      if (/^(\d)\1+$/.test(cpf)) {
        return false;
      }

      // Calcula o primeiro dígito verificador
      let soma = 0;
      for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let primeiroDigito = 11 - (soma % 11);
      if (primeiroDigito === 10 || primeiroDigito === 11) {
        primeiroDigito = 0;
      }

      // Verifica se o primeiro dígito verificador está correto
      if (primeiroDigito !== parseInt(cpf.charAt(9))) {
        return false;
      }

      // Calcula o segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
      }
      let segundoDigito = 11 - (soma % 11);
      if (segundoDigito === 10 || segundoDigito === 11) {
        segundoDigito = 0;
      }

      // Verifica se o segundo dígito verificador está correto
      if (segundoDigito !== parseInt(cpf.charAt(10))) {
        return false;
      }

      // Se todas as verificações passaram, o CPF é válido

      return true;
    },

    cpfRule(v) {
      if (!this.checkCpf(v)) {
        return 'CPF Inválido';
      }
      return true;
    },
  },
  watch: {
    enterpriseSelected: function() {
   
      this.$store.dispatch(SELECTED_ENTERPRISE, this.enterpriseSelected);
    },
    getEnterprises: function() {
     
      this.arrayEnterprises = Object.values(this.getEnterprises);
    }
  },
  created() {
    this.getAllEnterprise();
  }
};
</script>

<style></style>
