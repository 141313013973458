var register = Boolean()
var valid = Boolean()
var validPhysical = Boolean()
var validLegal = Boolean()
var validTable = Boolean(false)
var enterprises = Array()
var enterprise_id = String()
var provider = {
    type_people: String(),
    people_id: Number(),
    type: String(),
    relatedRepresentative: String()
}
var PhysicalPeople = {
    name: String(),
    lastName: String(),
    cpf: String(),
    gender: String(),
    emitter: String(),
    emitterState: String(),
    idCard: String(),
    dateOfBirth: String(),
    fatherName: String(),
    motherName: String(),
    nacionality: String(),
    naturality: String(),
    monthlyIncome: String(),
    civilStatus: String(),
    spouse: String(),
    icomeSpouse: Number(),
    related_representative: String(),
    type: String(),
    people_id: Number(),
    id: undefined,
    enterprise_id: String()
}

var LegalPeople = {
    type_people: String(),
    enterprise_id: Number(),
    cnpj: String(),
    fantasyName: String(),
    socialName: String(),
    stateRegistration: String(),
    municipalRegistration: String(),
    homePage: String(),
    address: Array(),
    banks: Array(),
    contacts: Array(),
    related_representative: String(),
    type: String(),
    id: undefined,
    people_id: Number()
}
var address = {
    street: String(),
    number: String(),
    district: String(),
    complement: String(),
    city: String(),
    state: String(),
    cep: String(),
    city_id: Number(),
    people_id: Number()
}
var banks = {
    bank: String(),
    agency: String(),
    count: Number(),
    manager: String(),
    people_id: Number()
}
var contact = {
    primaryEmail: "",
    secundaryEmail: "",
    phone: String(),
    cellPhone: String(),
    phoneReference: String(),
    people_id: Number()
}
var new_city = Object()


module.exports = {
    register,
    valid,
    validPhysical,
    validLegal,
    validTable,
    enterprises,
    enterprise_id,
    provider,
    PhysicalPeople,
    LegalPeople,
    address,
    banks,
    contact,
    new_city,
}