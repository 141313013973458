import {
    enterpirse_list,
    enterprise_selected,
    register,
    valid,
    validPhysical,
    validLegal,
    validTable,
    transporter,
    PhysicalPeople,
    LegalPeople,
    address,
    banks,
    contact,
} from "./WipeData"

function wipeData() {
    this.enterpirse_list = enterpirse_list
    this.enterprise_selected = enterprise_selected
    this.register = register
    this.valid = valid
    this.validPhysical = validPhysical
    this.validLegal = validLegal
    this.validTable = validTable
    this.transporter = { ...transporter }
    this.PhysicalPeople = { ...PhysicalPeople }
    this.LegalPeople = { ...LegalPeople }
    this.address = { ...address }
    this.banks = { ...banks }
    this.contact = { ...contact }
}

function itemToUpdate(item) {

    this.enterprise_id = item.enterprise_id;
    this.transporter.type_people = item.type_people;
    this.transporter.id = item.id;
    this.transporter.rntr = item.rntr;
    this.transporter.security = item.security;


    if (item.type_people === "PhysicalPeople") {
        this.register = false;
        this.PhysicalPeople.id = item.physicalpeople.id;
        this.PhysicalPeople.name = item.physicalpeople.name;
        this.PhysicalPeople.lastName = item.physicalpeople.lastName;
        this.PhysicalPeople.cpf = item.physicalpeople.cpf;
        this.PhysicalPeople.gender = item.physicalpeople.gender;
        this.PhysicalPeople.emitter = item.physicalpeople.emitter;
        this.PhysicalPeople.emitterState =
            item.physicalpeople.emitterState;
        this.PhysicalPeople.idCard = item.physicalpeople.idCard;
        this.PhysicalPeople.dateOfBirth =
            item.physicalpeople.dateOfBirth;
        this.PhysicalPeople.fatherName =
            item.physicalpeople.fatherName;
        this.PhysicalPeople.motherName =
            item.physicalpeople.motherName;
        this.PhysicalPeople.nacionality =
            item.physicalpeople.nacionality;
        this.PhysicalPeople.naturality =
            item.physicalpeople.naturality;
        this.PhysicalPeople.monthlyIncome =
            item.physicalpeople.monthlyIncome;
        this.PhysicalPeople.civilStatus =
            item.physicalpeople.civilStatus;
        this.PhysicalPeople.spouse = item.physicalpeople.spouse;
        this.PhysicalPeople.icomeSpouse =
            item.physicalpeople.icomeSpouse;
        this.PhysicalPeople.people_id =
            item.physicalpeople.people_id;
        this.address = { ...item.address[0] }
        this.banks = { ...item.banks[0] }
        this.contact = { ...item.contacts[0] }
    } else if (item.type_people === "LegalPeople") {
        this.register = true;
        this.LegalPeople.people_id = item.legalpeople.people_id;
        this.LegalPeople.id = item.legalpeople.id;
        this.LegalPeople.cnpj = item.legalpeople.cnpj;
        this.LegalPeople.fantasyName = item.legalpeople.fantasyName;
        this.LegalPeople.socialName = item.legalpeople.socialName;
        this.LegalPeople.stateRegistration =
            item.legalpeople.stateRegistration;
        this.LegalPeople.municipalRegistration =
            item.legalpeople.municipalRegistration;
        this.LegalPeople.homePage = item.legalpeople.homePage;
        this.address = { ...item.address[0] }
        this.banks = { ...item.banks[0] }
        this.contact = { ...item.contacts[0] }
    }

}





export default {
    itemToUpdate,
    wipeData
}