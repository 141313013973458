<template>
  <v-form ref="form" v-model="formValid" @submit.prevent="submit">
    <v-col>
      <v-row>
        <v-col lg="3" xl="3">
          <v-select
            outlined
            :items="type_input"
            dense
            label="Tipo de Entrada"
            item-text="text"
            item-value="value"
            v-model="type_input_value"
          />
        </v-col>
      </v-row>
      <div v-if="type_input_value == 'in'">
        <v-row>
          <v-col>
            <v-text-field
              :rules="text_field_rules"
              v-model="formIn.initial_date_invoice"
              type="date"
              label="Data de Entrada Incial"
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              :rules="text_field_rules"
              v-model="formIn.final_date_invoice"
              type="date"
              label="Data de Entrada Final"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
             
              v-model="formIn.number_grade"
              label="Número da Nota"
              outlined
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col>
            <v-text-field
              :rules="text_field_rules"
              v-model="formOut.initial_date_out"
              type="date"
              label="Data de Saída Incial"
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              :rules="text_field_rules"
              v-model="formOut.final_date_out"
              type="date"
              label="Data de Saída Final"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
             
              v-model="formOut.number_note"
              label="Número da Nota"
              outlined
            />
          </v-col>
          <v-col>
            <v-select
              :rules="text_field_rules"
              :items="typeBookSupervisorOut"
              item-value="value"
              v-model="formOut.type_book_supervision_out"
              label="Tipo de Saída"
              outlined
            />
          </v-col>
          <v-col v-if="formOut.type_book_supervision_out == 'Salles'">
            <v-text-field
             
              v-model="formOut.sale_id"
              label="Número da Venda"
              outlined
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-form>
</template>

<script>
import { type_input, typeBookSupervisorOut } from "./helpers/list_options";
import { text_field_rules, select_rules } from "./helpers/rules";
export default {
  props: {
    component: Object
  },

  data() {
    return {
      text_field_rules,
      select_rules,
      formValid: Boolean(),
      type_input,
      typeBookSupervisorOut,
      type_input_value: "in",
      formIn: {
        type: "in",
        initial_date_invoice: String(),
        final_date_invoice: String(),
        number_grade: String(),
        enterprise_id: String()
      },
      formOut: {
        type: "out",
        initial_date_out: String(),
        final_date_out: String(),
        number_note: String(),
        type_book_supervision_out: String(),
        enterprise_id: String(),
        sale_id: String()
      }
    };
  },
  watch: {
    formValid: function(value) {
      if (value) {
        this.component.valid = value;
        if(this.type_input_value == "in"){
          this.component.request_item = this.formIn
        }else{
          this.component.request_item = this.formOut
        }
        
      } else {
        this.resetValidation();
        this.component.valid = false;
      }
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style>
</style>