const ADD_CASHIER = "ADD_CASHIER";
const UPDATE_CASHIER = "UPDATE_CASHIER";
const AUX_TO_UPDATE_CASHIER = "AUX_TO_UPDATE_CASHIER";
const GET_ALL_CASHIER = "GET_ALL_CASHIER";
const DESTROY_CASHIER = "DESTROY_CASHIER";
const GET_FILTER_CASHIER = "GET_FILTER_CASHIER"

module.exports = {
    ADD_CASHIER,
    UPDATE_CASHIER,
    AUX_TO_UPDATE_CASHIER,
    GET_ALL_CASHIER,
    DESTROY_CASHIER,
    GET_FILTER_CASHIER
}
