var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Alert',{attrs:{"showAlert":_vm.showAlert,"someMessage":"Selecionar o tipo de operção fiscal primeiro"}}),_c('v-form',{ref:"form"},[_c('v-card',[_c('v-col',[_c('v-select',{attrs:{"label":"Status","items":_vm.liststatus,"outlined":"","item-value":"","rules":[
          () => !!_vm.component.tax_operation_type.status || 'Campo Obrigatório',
        ]},model:{value:(_vm.component.tax_operation_type.status),callback:function ($$v) {_vm.$set(_vm.component.tax_operation_type, "status", $$v)},expression:"component.tax_operation_type.status"}})],1),_c('v-col',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(`#########`),expression:"`#########`"}],attrs:{"label":"Codigo","type":"number","counter":"9","rules":[(v) => !!v || 'Campo Obrigatório'],"outlined":"","required":""},model:{value:(_vm.component.tax_operation_type.code),callback:function ($$v) {_vm.$set(_vm.component.tax_operation_type, "code", $$v)},expression:"component.tax_operation_type.code"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Descrição","rules":[
          () =>
            !!_vm.component.tax_operation_type.description || 'Campo Obrigatório',
          () =>
            (!!_vm.component.tax_operation_type.description &&
              _vm.component.tax_operation_type.description.length >= 1) ||
            'Campo tem que ser maior do que 1',
        ],"outlined":""},model:{value:(_vm.component.tax_operation_type.description),callback:function ($$v) {_vm.$set(_vm.component.tax_operation_type, "description", $$v)},expression:"component.tax_operation_type.description"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Selecione o Tipo da Operação Fiscal","items":_vm.types,"item-value":"value","outlined":"","rules":[() => !!_vm.component.type || 'Campo Obrigatório']},model:{value:(_vm.component.type),callback:function ($$v) {_vm.$set(_vm.component, "type", $$v)},expression:"component.type"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Codigo Fiscal Normal","append-outer-icon":_vm.icons[0],"rules":[() => !!_vm.component.normal_output_text || 'Campo Obrigatório'],"items":_vm.component.cfop,"item-value":"id","item-text":"code","outlined":"","required":""},on:{"click:append-outer":_vm.showDialogCFOP},model:{value:(_vm.component.normal_output_text),callback:function ($$v) {_vm.$set(_vm.component, "normal_output_text", $$v)},expression:"component.normal_output_text"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Codigo Fiscal Fora Estado","append-outer-icon":_vm.icons[0],"items":_vm.component.cfop,"item-value":"id","item-text":"code","rules":[
          () => !!_vm.component.output_of_state_text || 'Campo Obrigatório',
        ],"outlined":"","required":""},on:{"click:append-outer":_vm.showDialogCFOP},model:{value:(_vm.component.output_of_state_text),callback:function ($$v) {_vm.$set(_vm.component, "output_of_state_text", $$v)},expression:"component.output_of_state_text"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Codigo Fiscal Substituição","append-outer-icon":_vm.icons[0],"items":_vm.component.cfop,"item-value":"id","item-text":"code","rules":[() => !!_vm.component.replacement_text || 'Campo Obrigatório'],"outlined":""},on:{"click:append-outer":_vm.showDialogCFOP},model:{value:(_vm.component.replacement_text),callback:function ($$v) {_vm.$set(_vm.component, "replacement_text", $$v)},expression:"component.replacement_text"}})],1),_c('ModalCFOP',{attrs:{"addDialogCfop":_vm.getShowDialogRegisterCFOP}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }