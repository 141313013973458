<template>
  <v-form ref="form" v-model="validationForm">
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-select
            v-if="out_of_register"
            label="Empresa"
            outlined
            :readonly="component.justVisible"
            item-text="fantasyName"
            item-value="id"
            :items="enterprises"
            v-model="component.product.enterprise_id"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            :items="items"
            :readonly="component.justVisible"
            item-text="text"
            item-value="value"
            label="*Status"
            :rules="component.textRules"
            outlined
            v-model="component.product.status"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            label="Código do Fabricante"
            :readonly="component.justVisible"
            v-model="component.product.manufacturer_code"
          >
          </v-text-field>
          <v-text-field
            outlined
            label="*Descrição completa"
            :readonly="component.justVisible"
            v-model="component.product.description_item"
            required
            @blur="descriptionRule()"
            :rules="[validateDescription()]"
          >
          </v-text-field>
          <v-text-field
            outlined
            label="*Descrição Pdv"
            :readonly="component.justVisible"
            v-model="component.product.description_pdv"
            :rules="component.textRules"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="component.product.bar_code"
            :rules="barCodeRules"
            :readonly="component.justVisible"
            append-outer-icon="mdi-barcode"
            label="Código de Barras /Gtin"
          ></v-text-field>
          <v-text-field
            outlined
            :readonly="component.justVisible"
            v-model="component.product.reference"
            label="Referencia"
            @blur="referenceRule()"
            :rules="[validateReference()]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <div class="productPhoto">
                <v-card
                  class="d-flex justify-center"
                  outlined
                  width="400"
                  height="400"
                >
                  <v-col>
                    <v-row justify="center">
                      <img
                        v-if="url != undefined"
                        id="Preview_image_create"
                        :src="url"
                        class="Preview_image pa-5"
                        width="400"
                        height="400"
                      />
                      <img
                        v-else
                        id="Preview_image_create"
                        src="../../../../assets/insirasuaimagem.png"
                        class="Preview_image pa-5"
                        width="400"
                        height="400"
                      />
                    </v-row>
                  </v-col>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="justify-space-between">
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            :readonly="component.justVisible"
            label="*Caixa Master"
            v-model="component.product.master_box"
            required
            :rules="component.textRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-file-input
            v-model="productImage"
            outlined
            rounded
            dense
            class="mt-2"
            id="myFile"
            label="Escolher imagem"
            @change="Preview_image($event)"
          >
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            outlined
            item-text="name"
            :readonly="component.justVisible"
            item-value="id"
            label="*Grupo de Produto"
            append-outer-icon="mdi-plus-circle"
            v-model="component.product.product_group_id"
            :items="product_group"
            :rules="component.textRules"
            @click:append-outer="() => OpenDialog('ProductGroup')"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            outlined
            v-model="component.product.sub_group_product_id"
            :items="sub_product_group"
            :readonly="component.justVisible"
            item-text="name"
            :rules="component.textRules"
            item-value="id"
            label="*Sub Grupo"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="() => OpenDialog('SubGroup')"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            outlined
            v-model="component.product.unit_id"
            :readonly="component.justVisible"
            item-text="description"
            item-value="id"
            :rules="component.textRules"
            :items="unit"
            label="*Unidade de Medida"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="() => OpenDialog('UnitMeasurement')"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>

    <UnitMeasurement :component="this" :OpenDialog="addDialogUnit" />
    <SubGroup
      v-if="getShowDialogRegisterSubProductGroup"
      :component="this"
      :getAllStateSubGroupProduc="getAllStateSubGroupProduc"
      :OpenDialog="addDialogSubGroupProduct"
    />
    <GroupProducts
      v-if="getShowDialogRegisterProductGroup"
      :component="this"
      :EnterPriseID="EnterPriseID"
    />
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_PRODUCT_GROUP } from "../../../../store/actions/productgroup";
import { GET_ALL_SUB_PRODUCT_GROUP } from "../../../../store/actions/sub_product_group";
import { GET_ALL_UNIT } from "../../../../store/actions/unit";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import generateGtin from "gtin-generator";
import { isValid, getFormat } from "gtin";
import UnitMeasurement from "../../../unit/AddDialog";
import SubGroup from "../../../sub_group_product/AddDialog";
import GroupProducts from "../../../productgroup/AddDialog";
import axios from "axios";

export default {
  components: {
    UnitMeasurement,
    SubGroup,
    GroupProducts,
  },
  props: {
    refForm: String(),
    component: Object(),
    EnterPriseID: Object(),
    out_of_register: Boolean(),
  },
  data: () => ({
    validationForm: Boolean(),
    productImage: Object(),
    product: {
      subProductId: Number(),
    },
    enterprises: Array(),
    groupProductGetAll: Boolean(),
    unitGetAll: Boolean(),
    subGroupGetAll: Boolean(),
    getAllStateSubGroupProduc: Boolean(),
    UnitMeasurementObject: Object(),
    addDialogUnit: false,
    addDialogSubGroupProduct: false,
    addDialogGroupProduct: false,
    items: [
      { text: "Ativo ", value: "Ativo" },
      { text: "Inativo", value: "Inativo" },
      { text: "Bloqueado", value: "Bloqueado" },
    ],
    product_group: [],
    sub_product_group: [],
    unit: [],
    url: undefined,
    barCodeRules: [
      (v) =>
        (v.length <= 15) || "Codigo de barras no máximo 15 caracteres",
    ],
    descriptionMessage: true,
    referenceMessage: true,
  }),
  computed: {
    ...mapGetters([
      "getProductGroup",
      "getSubProductGroup",
      "getUnit",
      "getID",
      "getShowDialogRegisterProductGroup",
      "getShowDialogRegisterSubProductGroup",
      "getEnterprises",
      "getNewProduct",
      "getSubProductGroupToAdd",
    ]),

    watchedValidations() {
    return this.component.validations;
  },
  },
  methods: {
    generateGtin() {
      if (this.component.justVisible != true) {
        const gtinCode14 = generateGtin.getGTIN();
        const gtinCode13 = gtinCode14.substring(1, 14);

        if (isValid(gtinCode13)) {
          this.component.product.bar_code = gtinCode13;
        } else {
          alert("Código de Barras Inválido");
        }
      }
    },
    async getAllEnterpises() {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    OpenDialog(Option) {
      if (Option == "UnitMeasurement") {
        this.addDialogUnit = !this.addDialogUnit;
      } else if (Option == "SubGroup") {
        this.$store.dispatch("setShowDialogRegisterSubProductGroup", true);
      } else if (Option == "ProductGroup") {
        this.$store.dispatch("setShowDialogRegisterProductGroup", true);
      }
    },
    Preview_image(e) {
      e ? (this.url = URL.createObjectURL(e)) : (this.url = null);
    },
    Reset_image() {
      this.url = "";
    },
    getAllSubProduct() {},
    reset() {
      this.$refs.form.resetValidation();
    },

    async checkDescription(){
      if(this.component.product.id == undefined) {
        if(this.component.product.description_item == null || this.component.product.description_item == "") {
          return true
        } else {
          const payload = {
            description_item: this.component.product.description_item,
            enterprise_id: this.component.idEnterprise
          } 
          
          return axios.post("product/productDescriptionExist", payload)
            .then(response => {
              return true;
            })
            .catch(error => {
              return false; 
            });
        }
      } else {
        return true
      }
    },
    
    
    async descriptionRule() {
      const result = await this.checkDescription();
      this.descriptionMessage = result;
    },
    
    validateDescription(value) {
      if (this.descriptionMessage != true) {
        return 'Produto já Cadastrado';
      }
      
      return true;
    },
    
    checkReference(){
      if(this.component.product.id == undefined) {
        if(this.component.product.reference == null || this.component.product.reference == "") {
          return true
        } else {
          const payload = {
            reference: this.component.product.reference,
            enterprise_id: this.component.idEnterprise
          } 

          return axios.post("product/productReferenceExist", payload)
            .then(response => {
              return true;
            })
            .catch(error => {
              return false; 
            });
        }
      } else {
        return true
      }
    },

    async referenceRule() {
      const result = await this.checkReference();
      this.referenceMessage = result;
    },

    validateReference(value) {
      if (this.referenceMessage != true) {
        return 'Referência já Cadastrado';
      }
      
      return true;
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        pagination: false,
      };
      await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
      await this.$store.dispatch(GET_ALL_SUB_PRODUCT_GROUP, value);
      await this.$store.dispatch(GET_ALL_UNIT, value);
    },
    
    handleValidationsChange() {
      this.component.validations[0] = this.validationForm;
      this.component.checkValidation()
    },
  },

  watch: {
    validationForm: function (value) {
      this.component.validations[0] = value;
      this.component.checkValidation()
    },

    watchedValidations: {
      handler: 'handleValidationsChange',
    },
    // 'component.validations': function() {
    //   console.log('updated');
    //   this.component.validations[0] = this.validationForm;
    //   this.component.checkValidation()
    // },

    productImage: function () {
      this.component.productImage = this.productImage;
    },
    getEnterprises: function (val) {
      this.enterprises = Object.values(val);
      // this.enterprises = this.getEnterprises[0];
    },
    getID: function () {
      this.component.product.unit_id = this.getID;
    },
    groupProductGetAll: function () {
      this.GetAllNoPage();
      this.component.product.product_group_id = this.getProductGroup.id;
    },
    unitGetAll: function () {
      this.GetAllNoPage();
    },
    subGroupGetAll: function () {
      this.GetAllNoPage();
      this.component.product.sub_group_product_id = this.getSubProductGroup.id;
    },
    getProductGroup: function (val) {
      this.product_group = Object.values(val);
    },
    getSubProductGroup: function (val) {
      this.sub_product_group = Object.values(val);
    },
    getSubProductGroupToAdd: function (val) {
      this.sub_product_group.push(val);
      this.component.product.sub_group_product_id = val;
    },
    getUnit: function (val) {
      this.unit = Object.values(val);
    },
    getNewProduct: function () {
      this.product_group.push(this.getNewProduct);
      this.component.product.product_group_id = this.getNewProduct.id;
    },
  },
  async created() {
    this.getAllEnterpises();
    await this.GetAllNoPage();
    this.component.unit_id = 0;
    this.component.validations = Array();
    if (this.component.linkImage != null) {
      this.url = this.component.linkImage;
    }
  },
};
</script>


<style>
.productPhoto {
  display: flex;
  justify-content: center;
}
</style>