var mapgetters = [
  "getAliquot",
  "getCfop",
  "getCsosn",
  "getPisCofins",
  "getItemNotes",
  "getEnterprises",
  "getProduct",
  "getSelectedEnterprise",
  "getShowDialogRegisterImportXml",
  "getItemTax",
  "getCst",
  "getNoteEntryItens",

  "getItemToAddPisConfins",
  "getAliquotToAdd",
  "getCfopToAdd",
];

export default mapgetters;
