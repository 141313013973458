<template>
  <div class="mt-5">
    <v-form :disabled="isDisable" ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field
            v-on:change="choose_product()"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="callRegisters('product')"
            outlined
            label="Produto"
            v-model="description.description_item"
            :readonly="component.justVisible"
            return-object
            :rules="rules"
            append-icon="mdi-magnify"
            @click:append="() => openDialogModalSearchProduct()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="1" xl="6" md="5" sm="6">
          <v-text-field
            outlined
            type="number"
            label="Qtd"
            :rules="rules"
            v-model="product.amount_add"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field
            outlined
            type="number"
            :rules="rules"
            label="Val. Venda"
            v-model="product.value_sale"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete
            outlined
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="callRegisters('aliquot')"
            label="Alíquota"
            :rules="rules"
            item-text="aliquot"
            :items="aliquotItems"
            v-model="descriptionAliquot"
            :readonly="component.justVisible"
            return-object
            @click:append="
              () => {
                changeDialogAliquot();
              }
            "
            append-icon="mdi-magnify"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete
            label="CFOP"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="callRegisters('cfop')"
            item-text="code"
            :items="cfopItems"
            :rules="rules"
            outlined
            v-model="descriptionCFOP"
            :readonly="component.justVisible"
            return-object
            append-icon="mdi-magnify"
            @click:append="() => openDialogModalSearchCfop()"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete
            v-if="component.validCST"
            v-model="cst_csosn"
            :readonly="component.justVisible"
            item-text="description"
            :rules="rules"
            outlined
            :items="csosnItems"
            return-object
            label="CSOSN"
          ></v-autocomplete>
          <v-autocomplete
            v-else
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="callRegisters('cst')"
            v-model="cst_csosn"
            :readonly="component.justVisible"
            item-text="description"
            :rules="rules"
            :items="cstItems"
            outlined
            return-object
            label="CST"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            outlined
            v-model="descriptionPis"
            :readonly="component.justVisible"
            item-text="description"
            item-value="id"
            :items="pis_cofis"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="callRegisters('pis')"
            label="CST/PIS"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            outlined
            v-model="descriptionCofins"
            :readonly="component.justVisible"
            item-text="description"
            item-value="id"
            :items="pis_cofis"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="callRegisters('confins')"
            label="CST/COFIS"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            v-model="product.aliquot_ipi_product"
            :readonly="component.justVisible"
            label="Valor de IPI"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="1" xl="1" md="2" sm="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                :disabled="isDisable"
                elevation="5"
                v-bind="attrs"
                @click="addProduct()"
                v-on="on"
                class="success mt-2"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>Adicionar</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <TableRevenues
        :component="component"
        :actionDelete="deleteItens"
        :getters="getNoteEntryItens"
      />
      <AddProduct
        v-if="dialogModalSearchProduct"
        :changeDialog="openDialogModalSearchProduct"
        :component="this"
      />
      <AddCfop
        v-if="dialog_cfop"
        :component="this"
        :changeDialog="openDialogModalSearchCfop"
      />
      <Aliquot
        v-if="dialog_aliquot"
        :component="this"
        :dialog="dialog_aliquot"
        :changeDialog="changeDialogAliquot"
      />
      <AlertApi />
    </v-form>
    <RegisterProductDialog :out_of_register="true" />
    <RegisterAliquotDialog />
    <RegisterCFOPDialog />
    <RegisterCSTDialog />
    <RegisterPisDialog />
    <RegisterConfins />
  </div>
</template>

<script>
import AddProduct from "./DialogProduct/";
import AddCfop from "./DialogCFOP/";
import TableRevenues from "../../Table/TablePurchase/index.vue";
import Aliquot from "./DialogAliquot/";
import AlertApi from "../../../alert_api/alert_api";
import RegisterProductDialog from "../../../products/AddDialog/index.vue";
import RegisterAliquotDialog from "../../../aliquot/AddDialog/index.vue";
import RegisterCFOPDialog from "../../../cfop/AddDialog/index.vue";
import RegisterCSTDialog from "../../../cst/AddDialog/index.vue";
import RegisterPisDialog from "../../../pis_cofins/AddDialog/index.vue";
import RegisterConfins from "../../../pis_cofins/AddDialog/index.vue";
import { ADD_NOTE_ENTRY_ITENS } from "../../../../store/actions/note_entry_itens";
import {
  // ADD_REVENUES_ITENS,
  DESTROY_REVENUES_ITENS,
} from "../../../../store/actions/revenuesitens";
import { GET_PRODUCT_BY_ID } from "../../../../store/actions/product";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";

import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
    isDisable: Boolean(),
  },
  data: () => ({
    enterprise: Array(),
    dialogModalSearchProduct: Boolean(),
    dialogModalSearchCst: Boolean(),
    dialog_cfop: Boolean(),
    loadingAddProduct: Boolean(),
    dialog_aliquot: Boolean(),
    product: Object(),
    description: Object(),
    descriptionAliquot: Object(),
    descriptionCFOP: Object(),
    descriptionPis: Object(),
    descriptionCofins: Object(),
    selectedItem: Object(),
    cst_csosn: Object(),
    aliquotItems: Array(),
    cfopItems: Array(),
    cstItems: Array(),
    csosnItems: Array(),
    pis_cofis: Array(),
    rules: [(v) => !!v || "Campo Obrigatório"],
    valid: Boolean(),
  }),
  components: {
    TableRevenues,
    AddProduct,
    AddCfop,
    Aliquot,
    AlertApi,
    RegisterProductDialog,
    RegisterAliquotDialog,
    RegisterCFOPDialog,
    RegisterCSTDialog,
    RegisterPisDialog,
    RegisterConfins,
  },
  computed: {
    ...mapGetters([
      "getAliquot",
      "getCfop",
      "getNoteEntryItens",
      "getCsosn",
      "getCst",
      "getPisCofins",
      "getProduct",
      "getAliquotToAdd",
      "getCfopToAdd",
    ]),
  },
  methods: {
    callRegisters(key) {
      if (key == "product") {
        this.$store.dispatch("setShowDialogRegisterProduct", true);
      } else if (key == "aliquot") {
        this.$store.dispatch("setShowDialogRegisterAliquot", true);
      } else if (key == "cfop") {
        this.$store.dispatch("setShowDialogRegisterCFOP", true);
      } else if (key == "cst") {
        this.$store.dispatch("setShowDialogRegisterCST", true);
      } else if (key == "pis") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      } else if (key == "confins") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      }
    },
    async choose_product() {
      let id = this.description.description_item;
      await this.$store.dispatch(GET_PRODUCT_BY_ID, id);
    },
    async addProduct() {
      var itemNote = { ...this.description, ...this.product };

      let products = {
        amount: itemNote.amount_add,
        cust: parseFloat(itemNote.cost_price),
        aliquot_ipi_product: parseFloat(itemNote.aliquot_ipi_product),
        description_product: itemNote.description_item,
        description_unit: itemNote.description_item,
        cst_code_ipi: String(itemNote.cst_ipi_id),
        aliquot_icms_product: parseFloat(itemNote.aliquot.aliquot),
        cst_pis_id: itemNote.cst_pis_id,
        cst_confis_id: itemNote.cst_cofins_id,
        product_id: itemNote.id,
        cfop_id: itemNote.cfop.id,
        csosn_id: itemNote.csosn_id,
        cst_id: itemNote.cst_id,
        invoiceentry_id: null,
        cst_code_pis: itemNote.cst_pis.code,
        cst_code_confis: itemNote.cst_pis_code,
        code_cfop: String(this.descriptionCFOP.code),
        code_csosn: itemNote.csons.code,
        code_cst: String(itemNote.cst.code),
        unit_id: itemNote.unit_id,
        unit_description: itemNote.unit.description,

        cust_product: parseFloat(itemNote.cost_price),
        sub_total:
          parseFloat(itemNote.cost_price) * parseFloat(itemNote.amount_add),
        value_ipi: 0,

        aliquot_icms: 0,
        aliquot_ipi: 0,
        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
      };

      try {
        await this.$store.dispatch(ADD_NOTE_ENTRY_ITENS, products);
      } catch (e) {
        alert(e);
      }
    },
    async GetAliquot() {
      await this.$store.dispatch(GET_ALL_ALIQUOT, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCfop() {
      await this.$store.dispatch(GET_ALL_CFOP, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCst() {
      await this.$store.dispatch(GET_ALL_CST, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCsosn() {
      await this.$store.dispatch(GET_ALL_CSOSN, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetPisCofins() {
      await this.$store.dispatch(GET_ALL_PIS_COFINS, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    openDialogModalSearchProduct() {
      this.dialogModalSearchProduct = !this.dialogModalSearchProduct;
    },
    openDialogModalSearchCst() {
      this.dialogModalSearchCst = !this.dialogModalSearchCst;
    },
    openDialogModalSearchCfop() {
      this.dialog_cfop = !this.dialog_cfop;
    },
    changeDialogAliquot() {
      this.dialog_aliquot = !this.dialog_aliquot;
    },
    deleteItens(item) {
      if (!this.isDisable) {
        try {
          this.$store.dispatch(DESTROY_REVENUES_ITENS, item.id);
        } catch (error) {
          alert(error);
        }
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },
    clearItens() {
      try {
        this.$store.dispatch(DESTROY_REVENUES_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },
    valid_cst_csosn(val) {
      if (this.component.validCST) {
        this.cst_csosn = val.csons;
      } else {
        this.cst_csosn = val.cst;
      }
    },
  },

  watch: {
    description: function (val) {
      if (val.id !== undefined) {
        this.product.product_id_add = val.id;
        this.product.value_sale = val.cost_price;
        this.descriptionCFOP = val.cfop;
        this.descriptionAliquot = val.aliquot;
        this.product.description_cfop_add = val.cfop.code;
        this.product.cfop_id = val.cfop.id;
        this.descriptionPis = val.cst_pis;
        this.descriptionCofins = val.cst_confis;
        if (val.ipi === null) {
          this.product.aliquot_ipi_product = 0;
        } else {
          this.product.aliquot_ipi_product = val.ipi;
        }
        this.valid_cst_csosn(val);
      }
    },
    descriptionAliquot: function (val) {
      this.product.description_aliquot_code = val.aliquot;
      this.product.aliquot_id = val.id;
    },
    descriptionCFOP: function (val) {
      this.product.description_cfop_code = val.code;
      this.product.cfop_id = val.id;
    },
    descriptionPis: function (val) {
      this.product.cst_pis_code = val.code;
      this.product.cst_pis_id = val.id;
    },
    descriptionCofins: function (val) {
      this.product.cst_cofins_id = val.id;
      this.product.cst_confis_code = val.code;
    },
    getAliquotToAdd: function () {
      this.aliquotItems.push(this.getAliquotToAdd);
    },
    getCfopToAdd: function () {
      this.cfopItems.push(this.getCfopToAdd);
    },
    getAliquot: function (val) {
      if (!this.dialog_aliquot) {
        this.aliquotItems = Object.values(val);
      }
    },
    getCfop: function (val) {
      if (!this.dialog_cfop) {
        this.cfopItems = Object.values(val);
      }
    },
    getCsosn: function (val) {
      this.csosnItems = Object.values(val);
    },
    getCst: function (val) {
      this.cstItems = Object.values(val);
    },
    getPisCofins: function (val) {
      this.pis_cofis = Object.values(val);
    },
    getProduct: function (val) {
      this.description = { ...val.data[0] };
    },
  },

  async created() {
    this.enterprise[0] = { ...this.getEnterpriseObject };

    await this.GetAliquot();
    await this.GetCfop();
    await this.GetPisCofins();
    if (this.component.validCST) {
      this.GetCsosn();
    } else {
      this.GetCst();
    }
  },
  destroyed() {
    this.clearItens();
  },
};
</script>

<style></style>
