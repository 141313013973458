<template>
  <v-container class="mt-5">
    <v-form :disabled="isDisable">
      <v-row>
        <v-col>
          <v-text-field
            label="Base Calc do ICMs"
            readonly
            required
            outlined
            v-model="component.purchaseOrder.base_calc_icms_total"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            readonly
            label="Valor do ICMS"
            v-model="component.purchaseOrder.value_icms_total"
            :readonly="component.justVisible"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            label="Base Calc ICMS ST"
            v-model="component.purchaseOrder.value_base_icms_st_product"
            :readonly="component.justVisible"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            label="Valor do ICMS ST"
            v-model="component.purchaseOrder.value_icms_substituicao_total"
            :readonly="component.justVisible"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor Contábil"
            v-model="component.purchaseOrder.value_account_total"
            :readonly="component.justVisible"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Base de Cálculo IPI"
            v-model="component.purchaseOrder.base_calc_ipi_total"
            :readonly="component.justVisible"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor Total do IPI"
            v-model="component.purchaseOrder.value_ipi_total"
            :readonly="component.justVisible"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor IPI Outros"
            v-model="component.purchaseOrder.value_ipi_outher_total"
            :readonly="component.justVisible"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor Isento IPI"
            v-model="component.purchaseOrder.value_ipi_free_total"
            :readonly="component.justVisible"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor Total dos Produtos"
            v-model="component.purchaseOrder.value_total_products"
            :readonly="component.justVisible"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            label="Outros Valores"
            v-model="component.purchaseOrder.value_icms_outher_product"
            :readonly="component.justVisible"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Desconto"
            prefix="R$"
            v-model="component.purchaseOrder.value_discont"
            :readonly="component.justVisible"
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor ICMS Isento"
            v-model="component.purchaseOrder.value_icms_free_total"
            :readonly="component.justVisible"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Valor Total da Nota"
            v-model="component.purchaseOrder.value_total_note"
            :readonly="component.justVisible"
            prefix="R$"
            readonly
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    component: Object(),
    isDisable: Boolean(),
    transporter: Object()
  },
  data: () => ({})
};
</script>

<style>
</style>