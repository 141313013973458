<template>
  <v-row>
    <v-dialog
      transition="scale-transition"
      persistent
      max-width="1100"
      v-model="component.dialog_finish"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-icon
              left
              class="mt-2"
              @click="
                () => {
                  close();
                }
              "
              >mdi-close</v-icon
            >
          </v-row>
          <v-row class="ml-2 mb-2">
            <v-card-title> Finalização de Venda</v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="center" class="ml-14">
              <v-col cols="3" class="titleValue primary--text"
                >Valor pago
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ total_pay }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="titleValue red--text"
                >Valor restante
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ total_remain.toFixed(2) }}</v-col>
                </v-row></v-col
              >
              <v-col cols="3" class="titleValue"
                >Valor Troco
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ total_change.toFixed(2) }}</v-col>
                </v-row></v-col
              >
              <v-col cols="3" class="titleValue">
                Valor final
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ component.total.toFixed(2) }}</v-col>
                </v-row></v-col
              >
            </v-row>
          </v-card-text>
          <v-form ref="form" v-model="validRegister" class="mt-5">
            <v-row class="ml-2">
              <v-col cols="12" md="3" sm="3" xl="3" class="ml-2">
                <v-select
                  label="Forma"
                  :items="items_formPay"
                  v-model="information_payment.form"
                  item-value="id"
                  item-text="description"
                  :rules="rules"
                  return-object
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="3" xl="3">
                <v-select
                  return-object
                  label="Condição"
                  :disabled="validCondition"
                  :items="items_payTerm"
                  v-model="information_payment.condition"
                  item-value="id"
                  item-text="description"
                  :rules="rules"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" sm="2" xl="3">
                <v-text-field
                  label="Valor"
                  v-model="information_payment.value_form_payment"
                  outlined
                  :rules="rules"
                  prefix="R$"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="2" xl="3">
                <v-btn
                  width="200"
                  class="ml-4"
                  color="success"
                  @click="register()"
                  >Registrar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col cols="11">
              <!-- database -->
              <v-data-table
                hide-default-footer
                :headers="headers"
                :items="table"
                no-data-text="Sem registros"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    color="red darken-3"
                    class="ml-2"
                    @click="
                      () => {
                        deleteItem(item);
                      }
                    "
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn
              block
              :loading="isloading"
              class="success"
              @click="finish_sale"
              :disabled="total_pay < component.total || isloading"
            >
              Finalizar</v-btn
            ></v-card-actions
          >
        </v-container>
      </v-card>
    </v-dialog>
    <loadSale v-if="dialog_load" :component="this" :status="status[0]" />
    <ModalError
      v-if="dialog_alert"
      :component="this"
      :title_error="titleError"
      :listErrors="listErrors"
    />
  </v-row>
</template>

<script>
import { headers } from "./script";
import { GET_ALL_FORM_OF_PAYMENT } from "../../../../store/actions/formofpayment";
import { GET_ALL_PAYMENT_TERM } from "../../../../store/actions/paymentterm";
import { mapGetters } from "vuex";
import loadSale from "../Load_Sale/";
import {
  ADD_SALES,
  CLEAN_CART,
} from "./../../../../store/actions/future_sales";
import { setModelFutureSales } from "./model_future_sales";
// import {

//   ISSUE_OF_INVOICE_NFCE,
//   GET_SEQUENCE_NFCE,
// } from "./../../../../store/actions/nfce";

import ModalError from "../Modal_Error/";

const reducer = (accumulator, currentValue) => accumulator + currentValue;
export default {
  components: {
    loadSale,
    ModalError,
  },
  props: {
    component: Object,
  },
  data: () => ({
    items_formPay: Array(),
    items_payTerm: Array(),
    validCondition: Boolean(),
    validRegister: Boolean(),
    headers,
    dialog_load: Boolean(),
    dialog_alert: Boolean(),
    titleError: String(),
    listErrors: Array(),

    table: Array(),
    total_pay: Number(),
    total_remain: Number(),
    total_change: Number(),
    value_form_payment: Number(),
    new_pay: Object(),
    information_payment: Object(),
    form_of_payments: Array(),
    billstoReceive: Array(),
    isloading: Boolean(),
    status: ["Emitindo Nota SEFAZ", "Salvando Nota", "Imprimindo Nota"],
    rules: [(v) => !!v || "Campo obrigatório"],
  }),
  /**
   * {
      code: String(),
      form: String(),
      condition: String(),
      value_form_payment: Number(),
    }
   */
  methods: {
    close() {
      this.component.dialog_finish = !this.component.dialog_finish;
      this.table = [];
      this.total_remain = 0;
    },
    value_pay(items) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      let variable = items.map((e) => e.value_form_payment);
      this.total_pay = variable.reduce(reducer, 0).toFixed(2);
    },
    value_remain() {
      let total = this.component.total - this.total_pay;
      if (total < 0) {
        this.total_change = Math.abs(total);
        this.total_remain = 0;
      } else {
        this.total_remain = total;
        this.total_change = 0;
      }
    },
    register() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.new_pay = { ...this.information_payment };
        this.new_pay.value_form_payment = parseFloat(
          this.new_pay.value_form_payment
        );
        // this.form_of_payments.form.description.replace(/\s/g, "").toLowerCase();
        const formPayment = {
          form_of_payment_id: this.information_payment.form.id,
          payment_term_id: this.information_payment.condition.id,
          sale_id: null,
          value_form_payment: this.information_payment.value_form_payment,
        };

        this.form_of_payments.push(formPayment);

        this.table.push(this.new_pay);
      }
    },
    deleteItem(item) {
      this.table.splice(this.table.indexOf(item), 1);
    },
    calc_value_tribute_itens(val, key) {
      let result = val.map((e) => parseFloat(e[key]));
      return result.reduce(reducer).toFixed(2);
    },

    calc_tribute_finish_sale() {
      let itens_venda = this.getCart;
      try {
        if (itens_venda.length !== 0) {
          this.component.sale.value_total_products =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_subtotal_product"
            );
          this.component.sale.value_total_note = this.calc_value_tribute_itens(
            itens_venda,
            "value_sale"
          );
          this.component.sale.value_account_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_accounting_product"
            );
          this.component.sale.value_descount_total =
            this.calc_value_tribute_itens(itens_venda, "value_descont_product");
          this.component.sale.base_cal_icms_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_icms_product"
            );
          this.component.sale.value_icms_free_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_free_icms_product"
            );
          this.component.sale.value_outher_tax_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_icms_outher_product"
            );
          this.component.sale.base_calc_susbt_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_icms_st_product"
            );
          this.component.sale.value_icms_substituicao_total =
            this.calc_value_tribute_itens(itens_venda, "value_icms_st_product");
          this.component.sale.value_ipi_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_ipi_product"
          );
          this.component.sale.value_ipi_outher_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_ipi_outher_product"
            );
          this.component.sale.value_ipi_free_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_ipi_free_product"
            );
          this.component.sale.base_calc_ipi_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_ipi_product"
            );
          this.component.sale.value_base_pis_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_pis_product"
            );
          this.component.sale.value_base_pis_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_pis_st_product"
            );
          this.component.sale.value_base_cofins_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_cofins_st_product"
            );
          this.component.sale.value_base_cofins_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_cofins_product"
            );
          this.component.sale.value_other_pis_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_other_pis_product"
            );
          this.component.sale.value_pis_st_total =
            this.calc_value_tribute_itens(itens_venda, "value_pis_st_product");
          this.component.sale.value_icms_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_icms_product"
          );
          this.component.sale.value_pis_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_pis_product"
          );
          this.component.sale.value_cofins_total =
            this.calc_value_tribute_itens(itens_venda, "value_cofins_product");
          this.component.sale.value_other_cofins_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_other_cofins_product"
            );
          this.component.sale.value_cofins_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_cofins_st_product"
            );
        }
      } catch (error) {
        alert(error);
      }
    },

    async finish_sale() {
      this.dialog_load = !this.dialog_load;
      this.calc_tribute_finish_sale();
      let sale = { ...this.component.sale };
      sale.enterprise_id = this.getEnterprises.data[0].id;
      sale.number_sale = "00001";
      sale.series = "1";
      sale.access_key = "0000000000000";
      sale.authorization_protocol = "000000000";
      //
      // await this.$store
      //   .dispatch(GET_SEQUENCE_NFCE, {
      //     enterprise_id: sale.enterprise_id,
      //     typeNoteModel: "NFC-e",
      //   })
      //   .then((response) => {
      //     let sequenceNFce = response.data.data;
      //     sale.number_sale = sequenceNFce.sequence;
      //     sale.series = sequenceNFce.series;
      //   }),

      //formas de pagamento
      this.table.forEach((e) => {
        let dueDateBillstoReceive = this.calculeCashPayment(e, sale);
        this.billstoReceive.push(dueDateBillstoReceive);
        let form = Object();
        form.form_of_payment_id = e.form.id;
        form.code = e.form.code;
        form.payment_term_id = e.condition.id;
        form.value_form_payment = e.value_form_payment;
        let finalForm = { ...form };
        sale.form_of_payments.push(finalForm);
      });
      sale.itens_sales = this.getCart;
      sale.value_total_note = this.component.total;
      sale.billstoReceive = { ...this.billstoReceive };
      this.status = "Emitindo Nota";
      //
      // await this.$store.dispatch(ISSUE_OF_INVOICE_NFCE, sale).then((response) => {
      //    let nfce = response.data.nfce
      //    sale.access_key = nfce.key
      //     sale.number_sale = nfce.numberNfe
      //     sale. authorization_protocol = nfce.referencedAccessKey
      //     sale. series =  nfce.seriesDocumentSupervisior

      //   })
      //   .catch((error) => {

      //     this.dialog_load = !this.dialog_load;
      //     this.dialog_alert = !this.dialog_alert;
      //     this.titleError =
      //       error.code + " - " + "Error ao Emitir NFce";

      //     error.errors.forEach((e) => {
      //       let item = Object();
      //       item.text = e;
      //       this.listErrors.push(item);
      //     });
      //   });

      // const infPayment = this.form_of_payments;

      //   const completeFutureSale = setModelFutureSales(sale, infPayment);

      await this.$store
        .dispatch(ADD_SALES, sale)
        .then(() => {
          this.dialog_load = !this.dialog_load;
          this.component.dialog_finish = !this.component.dialog_finish;
          this.component.clearClientSeller();
        })
        .catch((error) => {
          this.dialog_load = !this.dialog_load;
          this.dialog_alert = !this.dialog_alert;
          this.titleError = error.code + " - " + "Error ao finalizar";

          error.errors.forEach((e) => {
            let item = Object();
            item.text = e;
            this.listErrors.push(item);
          });
        });
      this.$store.dispatch(CLEAN_CART);
    },

    calculeCashPayment(payment, sales) {
      const AVISTA = 0;
      let salesBillsToReceive = { ...sales };
      console.log("values billstoreceivei");
      console.log(salesBillsToReceive);
      let paymentFormPayment = payment.condition.deadline;
      if (paymentFormPayment == AVISTA) {
        sales.paymentMethodIndicator = "0";
        let BillsToReceive = this.mounterBillsToReceiveWithInCash(
          salesBillsToReceive.data_sale,
          salesBillsToReceive.number_sale,
          salesBillsToReceive.value_total_note,
          salesBillsToReceive.client_id,
          salesBillsToReceive.enterprise_id
        );
        return BillsToReceive;
      } else {
        sales.paymentMethodIndicator = "1";
        let BillsToReceive = this.mounterPaymentDeferredPayment(
          payment,
          salesBillsToReceive
        );
        return BillsToReceive;
      }
    },

    mounterPaymentDeferredPayment(payment, salesAttributes) {
      let deadline = payment.condition.deadline;
      let numberOfInstallmentsPaymentForm = payment.condition.amount;
      let valueTotalSales = salesAttributes.value_total_note;
      let valuePaymentDebTotal = parseFloat(valueTotalSales).toFixed(2);
      let valueOfPortion = parseFloat(
        (valuePaymentDebTotal / numberOfInstallmentsPaymentForm).toFixed(2)
      );
      let calculeValueTotalPendingOfDivision =
        valueOfPortion * numberOfInstallmentsPaymentForm;

      let billstoReceiveArray = [];
      let portion = 1;
      let aux_deadline = deadline.split("/");
      let deadlineArray = aux_deadline.map((item) => parseInt(item));
      for (portion = 1; portion <= numberOfInstallmentsPaymentForm; portion++) {
        let billsToReceiveCalcule = this.mounterBillsToReceiveWithOnTerm(
          salesAttributes.data_sale,
          salesAttributes.number_sale,
          portion,
          deadlineArray[portion - 1],
          valueOfPortion,
          salesAttributes.enterprise_id,
          salesAttributes.client_id
        );
        billstoReceiveArray.push(billsToReceiveCalcule);
      }

      if (calculeValueTotalPendingOfDivision != valuePaymentDebTotal) {
        let remaining =
          valuePaymentDebTotal - calculeValueTotalPendingOfDivision;

        billstoReceiveArray = this.calculateDiferenceInValueBillstoReceive(
          billstoReceiveArray,
          remaining
        );
        return billstoReceiveArray;
      }

      return billstoReceiveArray;
    },

    calculateDiferenceInValueBillstoReceive(billstoReceiveArray, remaining) {
      for (let index = 0; index < billstoReceiveArray.length; index++) {
        let valueIndexLastInstallment = index + 1;
        if (valueIndexLastInstallment === billstoReceiveArray.length) {
          billstoReceiveArray[index].value_title += remaining;
          billstoReceiveArray[index].value_net =
            billstoReceiveArray[index].value_title;
          billstoReceiveArray[index].title_balance =
            billstoReceiveArray[index].value_title;
        }
      }
      return billstoReceiveArray;
    },

    mounterBillsToReceiveWithOnTerm(
      dateIssuanceDate,
      number_sale,
      portation,
      deadline = 0,
      valueOfPortion,
      enterprise_id,
      client_id
    ) {
      let auxdate = dateIssuanceDate.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let dateIssuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];

      const STATUS_ABERTO = "ABERTO";
      const TYPE_TITLE = "RECEBIMENTO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_ABERTO;
      billstoReceiveInCash.issuance_date = dateIssuanceDateFormat;
      billstoReceiveInCash.issuance_due =
        this.generateOfMaturityBillsToReceivce(
          dateIssuanceDate,
          deadline,
          portation
        );
      billstoReceiveInCash.type_title = TYPE_TITLE;
      billstoReceiveInCash.number_title = `${number_sale}/${portation}`;
      billstoReceiveInCash.value_title = valueOfPortion;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = valueOfPortion;
      billstoReceiveInCash.title_balance = valueOfPortion;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);
      return billstoReceiveInCash;
    },

    mounterBillsToReceiveWithInCash(
      issuanceDate,
      number_sale,
      value_total_note,
      client_id,
      enterprise_id
    ) {
      let billstoReceiveInCashArray = [];
      let auxdate = issuanceDate.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let issuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];
      const STATUS_BAIXADO = "BAIXADO";
      const TITLE_TITULO = "DINHEIRO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_BAIXADO;
      billstoReceiveInCash.issuance_date = issuanceDateFormat;
      billstoReceiveInCash.issuance_due = issuanceDateFormat;
      billstoReceiveInCash.type_title = TITLE_TITULO;
      billstoReceiveInCash.number_title = `${number_sale}/${1}`;
      billstoReceiveInCash.value_title = value_total_note;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = 0;
      billstoReceiveInCash.title_balance = 0;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);

      billstoReceiveInCashArray.push(billstoReceiveInCash);
      return billstoReceiveInCashArray;
    },

    generateOfMaturityBillsToReceivce(dateReceive, deadline) {
      let auxdate = dateReceive.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let stringFormatada =
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0] +
        "-" +
        arrDataExclusao[2];
      let day = 0;
      let month = 0;
      let dateNew = new Date(stringFormatada);
      dateNew.setDate(dateNew.getDate() + deadline);

      day = dateNew.getDate();
      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }

      month = dateNew.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }
      let final_date = `${dateNew.getFullYear()}-${month}-${day}`;

      return final_date;
    },

    async GetFormPay() {
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, {
        page: 0,
        limit: 10,
      });
    },
    async GetPayTerms() {
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, {
        page: 0,
        limit: 10,
      });
    },
    form_verify(val) {
      let string = val.toLowerCase();
      if (string === "DINHEIRO") {
        this.items_payTerm.forEach((e) => {
          let description = e.description.replace(/\s/g, "").toLowerCase();
          if (description === "AVISTA") {
            this.form_of_payments.condition = e;
            this.validCondition = true;
          }
        });
      } else {
        this.validCondition = false;
      }
    },
  },
  updated() {
    this.value_pay(this.table);
    this.value_remain();
  },
  watch: {
    items: function () {
      this.register(this.items);
    },
    getFormOfPayment: function (val) {
      this.items_formPay = Object.values(val);
    },
    getPaymentTerms: function (val) {
      this.items_payTerm = Object.values(val);
    },
    "form_of_payments.form": function (val) {
      this.form_verify(val.description);
    },
  },
  computed: {
    ...mapGetters([
      "getCart",
      "getClientSales",
      "getFormOfPayment",
      "getPaymentTerms",
      "getSequenceNfce",
      "getEnterprises",
    ]),
  },
  async mounted() {
    await this.GetFormPay();
    await this.GetPayTerms();
  },
};
</script>
<style scoped>
.valueFinal {
  font-weight: bold;
  font-size: 20px;
}
.titleValue {
  font-size: 15px;
}
.disable-events {
  pointer-events: none;
}
</style>
