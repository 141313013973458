const adjust_variables = (context) => {

    if (context.component.checkinfo == 1) {
        context.invoice.base_calc_susbt_total = parseFloat(
            context.invoice.base_calc_susbt_total
        );
        context.invoice.value_icms_substituicao_total = parseFloat(
            context.invoice.value_icms_substituicao_total
        );
        context.invoice.value_untaxed_tax_total = parseFloat(
            context.invoice.value_untaxed_tax_total
        );
    } else if (isNaN(context.invoice.base_calc_susbt_total) == true) {
        context.invoice.base_calc_susbt_total = parseFloat(
            context.invoice.base_calc_susbt_total.replace(",", ".")
        );
        context.invoice.value_icms_substituicao_total = parseFloat(
            context.invoice.value_icms_substituicao_total.replace(",", ".")
        );
        context.invoice.value_untaxed_tax_total = parseFloat(
            context.invoice.value_untaxed_tax_total.replace(",", ".")
        );
    }

    context.invoice.base_calc_icms_total = parseFloat(
        context.invoice.base_calc_icms_total.replace(",", ".")
    );

    context.invoice.base_calc_ipi_total = parseFloat(
        context.invoice.base_calc_ipi_total.replace(",", ".")
    );

    context.invoice.value_account_total = parseFloat(
        context.invoice.value_account_total.replace(",", ".")
    );
    context.invoice.value_base_icms_st_product = parseFloat(
        context.invoice.value_base_icms_st_product.replace(",", ".")
    );
    context.invoice.value_discont = parseFloat(context.invoice.value_discont);
    context.invoice.value_freigth = parseFloat(context.invoice.value_freigth);
    context.invoice.value_icms_free_total = parseFloat(
        context.invoice.value_icms_free_total.replace(",", ".")
    );
    context.invoice.value_icms_total = parseFloat(
        context.invoice.value_icms_total.replace(",", ".")
    );
    context.invoice.value_ipi_free_total = parseFloat(
        context.invoice.value_ipi_free_total.replace(",", ".")
    );
    context.invoice.value_ipi_outher_total = parseFloat(
        context.invoice.value_ipi_outher_total.replace(",", ".")
    );
    context.invoice.value_ipi_total = parseFloat(
        context.invoice.value_ipi_total.replace(",", ".")
    );
    context.invoice.value_security = parseFloat(context.invoice.value_security);
    context.invoice.value_total_note = parseFloat(
        context.invoice.value_total_note.replace(",", ".")
    );
    context.invoice.value_total_products = parseFloat(
        context.invoice.value_total_products.replace(",", ".")
    );
    context.invoice.value_icms_outher_product = parseFloat(
        context.invoice.value_icms_outher_product.replace(",", ".")
    );
    context.invoice.outher_expenses_acess = parseFloat(
        context.invoice.outher_expenses_acess
    );

    context.invoice.gross_weight = parseFloat(context.invoice.gross_weigth);
    context.invoice.itens.forEach((e) => {
        e.aliquot_ipi_product = parseFloat(e.aliquot_ipi_product);
        e.amount = parseFloat(e.amount);
        e.value_base_icms_product = parseFloat(e.value_base_icms_product);
        e.cust = parseFloat(e.cust);
        e.value_base_icms_st_product = parseFloat(e.value_base_icms_st_product);
        e.value_free_icms_product = parseFloat(e.value_free_icms_product);
        e.value_icms_outher_product = parseFloat(e.value_icms_outher_product);
        e.value_icms_product = parseFloat(e.value_icms_product);
        e.value_ipi_product = parseFloat(e.value_ipi_product);
        e.value_sub_total_product = parseFloat(e.value_products);
    });
}

module.exports = { adjust_variables }