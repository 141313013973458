



<template>
  <v-dialog
    persistent
    max-width="500px"
    v-model="getShowDialogRegisterParameterItens"
  >
    <v-card class="pa-10">
      <p class="p-parameter">Cadastrar Itens do Parâmetro</p>
      <v-divider class="mb-5" />
      <v-text-field v-model="form.name" outlined label="Nome" />
      <v-select
        outlined
        append-outer-icon="mdi-plus-circle"
        item-text="description"
        item-value="id"
        label="Parâmetros"
        @click:append-outer="showDialog"
      />
      <v-text-field v-model="form.description" outlined label="Descrição" />
      <v-text-field v-model="form.value" outlined label="Valor" />
      <div class="form-actions-parameter">
        <v-btn class="mr-2 error" @click="closeDialog">Cancelar</v-btn>
        <v-btn class="ml-2" @click="submit" color="success">Cadastrar</v-btn>
      </div>
      <ParametersAddDialog />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ParametersAddDialog from "../../parameter/AddDialog";
export default {
  components: {
    ParametersAddDialog,
  },
  props: {},
  computed: {
    ...mapGetters(["getShowDialogRegisterParameterItens"]),
  },
  data: () => ({
    form: Object(),
  }),
  methods: {
    submit() {
      //cadastrar um novo parâmetro
    },
    showDialog() {
      this.$store.dispatch("setShowDialogRegisterParameter", true);
    },
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterParameterItens", false);
    },
  },
  watch: {},
  created() {},
};
</script>

<style>
@import "./index.css";
</style>