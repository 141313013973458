<template>
  <Container>
    <v-card>
      <v-card-text>
        <CardTilte :component="this" />
      </v-card-text>

      <v-card-text>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          v-model="search"
          label="Pesquisar"
        ></v-text-field>
      </v-card-text>
      <Table
        :getters="getBookSupervisionOut"
        :component="this" 
      />
    </v-card>
    <AlertApi />
    <LoadScreen/>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTilte from "../components/BookSupervisionOut/CardTitle/index";
import Table from "../components/BookSupervisionOut/Table/index";

import { mapGetters } from 'vuex';
import AlertApi from "../components/alert_api/alert_api";
import LoadScreen from "../components/load/index.vue"
export default {
  components: {
    Container,
    Table,
    CardTilte,
    AlertApi,
    LoadScreen
  },

  data: () => ({
    search: String(),
    enterpriseId: Number(),
  }),

  methods:{
    
  },

  created(){
    this.GetAll()
  },

  computed:{
    ...mapGetters(["getBookSupervisionOut"]),
  }
};
</script>

<style>
</style>