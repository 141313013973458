<template>
  <div>
    <v-card class="ma-5">
      <CardTitle actionsShowDialog="setShowDialogRegisterCancelSequenceInvoice"
        title="Cancelamento de Sequência de Nota Fiscal" />
      <Table :headers="headers"  :component="this" :lengthPaginator="lengthPaginator" :selectValue="selectValue" />
      <AddDialog v-if="getShowDialogRegisterCancelSequenceInvoice" :component="this" />
    </v-card>
    <loadScreen />
    <AlertApi />
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise";
import { GET_ALL_SEQUENCE_INVOICE } from "../store/actions/cancel_sequecence_invoice"
import CardTitle from "../components/cancel_sequence_invoice/CardTitle";
import Table from "../components/cancel_sequence_invoice/Table";
import tableHeaders from "./headers_tables/Cancel_sequence_invoice";
import AddDialog from "../components/cancel_sequence_invoice/AddDialog";
import loadScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";

import { mapGetters } from "vuex";
export default {
  name: "CorrectionLetter",
  components: {
    CardTitle,
    Table,
    AddDialog,
    loadScreen,
    AlertApi
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterCancelSequenceInvoice", "getEnterprises"]),
  },
  data: () => ({
    headers: tableHeaders,
    lengthPaginator: Number(),
    selectValue: Object(),
    viewOnly: false,
    itemToLoadData: Object(),
  }),

  methods: {
    async getAllEnterprise(page = 1) {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: page,
        limit: 10,
        paginate: true
      });
    },
    getAllCancelSequence(page = 1) {
      this.$store.dispatch(GET_ALL_SEQUENCE_INVOICE, {
        cnpjEmitter: this.selectValue.cnpj,
        page: page,
        limit: 10,
        paginate: true,

      })
    },
    
    calculateNumberPage(itemQuantity) {
      if (itemQuantity % 14 > 1) {
        this.lengthPaginator = itemQuantity / 14 + 1;
      } else {
        this.lengthPaginator = itemQuantity / 14;
      }
    },
    visibleItem(item) {
      this.viewOnly = true
      this.itemToLoadData = item
      this.$store.dispatch("setShowDialogRegisterCancelSequenceInvoice", true)
    }
  },
  watch: {
    getEnterprises(val) {
      this.selectValue = val.data[0];
      this.getAllCancelSequence();
    },
  },
  async created() {
    await this.getAllEnterprise();
    
  },
};
</script>

<style></style>