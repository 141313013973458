async function calculeTributeIpi(productRevenues, cst_ipi_code, aliquot_ipi) {
  try {
    let productTributeIpi = classificationCstIpi(
      productRevenues,
      cst_ipi_code,
      aliquot_ipi
    );

    return productTributeIpi;
  } catch (error) {
    return error;
  }
}

async function classificationCstIpi(
  productRevenues,
  cst_ipi_code,
  aliquot_ipi
) {
  if (cst_ipi_code >= 0 || cst_ipi_code <= 55) {
    return _normal_Ipi_Calculation(productRevenues, aliquot_ipi);
  } else if (cst_ipi_code >= 98 || cst_ipi_code <= 99) {
    return _others_Calculation_Ipi(productRevenues, aliquot_ipi);
  }
}

async function _normal_Ipi_Calculation(productRevenues, aliquot_ipi) {
  try {
    productRevenues.value_base_ipi_product = productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.aliquot_ipi_product = aliquot_ipi;
    productRevenues.value_ipi_outher_product = 0;
    productRevenues.value_ipi_free_product = 0;
    productRevenues.value_ipi_product = productRevenues.value_base_ipi_product * (aliquot_ipi / 100);

    return productRevenues;
  } catch (error) {
    return error;
  }
}

async function _others_Calculation_Ipi(productRevenues, aliquot_ipi) {
  try {
    productRevenues.value_base_ipi_product = 0;
    productRevenues.aliquot_ipi_product = aliquot_ipi;
    productRevenues.value_ipi_outher_product = productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_ipi_free_product = 0;
    productRevenues.value_ipi_product = 0;

    return productRevenues;
  } catch (error) {
    error;
  }
}

module.exports = {
  calculeTributeIpi,
};
