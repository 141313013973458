<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <v-row>
          <v-card-title class="ml-2">Permissões</v-card-title>
          <v-spacer></v-spacer>
          <AddDialog v-if="addDialog" :component="this" />
          <v-btn
            color="success"
            class="mr-5 mt-4"
            @click="create()"
            :disabled="isload"
            :loading="isload"
            >Adicionar</v-btn
          >
        </v-row>
        <v-row>
          <v-text-field
            outlined
            append-icon="mdi-magnify"
            class="ma-2"
            no-data-text="Sem registro!"
            label="Pesquisar"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <DestroyDialogView
        v-if="destroyDialog"
        :component="this"
        :destroy_object="destroy_object"
      />
      <Table :edit_item="edit_item" :component="this" />
    </v-card>
    <AlertAPI />
  </Container>
</template>

<script>
import Table from "../components/Permission/Table/";
import DestroyDialogView from "../components/Permission/destroyDialog/destroyDialog";
import Container from "../components/Utils/Container/Container";
import AddDialog from "../components/Permission/AddDialog/Index";
import AlertAPI from "../components/alert_api/alert_api";
import {
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  GETALL_PERMISSIONS,
  GETALL_ROLES,
  UPDATE_PERMISSION,
} from "../store/actions/permission";

export default {
  components: {
    Table,
    Container,
    AddDialog,
    DestroyDialogView,
    AlertAPI,
  },

  data: () => ({
    title: "Permissão",
    item_Aloc: null,
    currentItem: null,
    isload: false,
    destroyDialog: false,
    addDialog: false,
    levels: ["Visualizar", "Cadastrar", "Editar", "Excluir"],
    form: {
      id: undefined,
      group_name: String(),
      group_description: String(),
    },
    items: [],
  }),

  methods: {
    async destroy_object(item) {
      try {
        this.isload = true;
        await this.$store.dispatch(DELETE_PERMISSION, { form: item });
        this.currentItem = null;
        this.isload = false;
        this.getall();
      } catch (error) {
        console.log(error);
      }
    },
    clearForm() {
      this.form = {
        id: undefined,
        group_name: String(),
        group_description: String(),
      };
    },
    create() {
      this.addDialog = !this.addDialog;
    },
    changeDialod(val = undefined) {
      if (val != undefined) this.addDialog = val;
      else this.addDialog = !this.addDialog;
    },
    edit_item(item) {
      this.item_Aloc = item;
      this.changeDialod(true);
    },
    async getall(page = 1) {
      this.isload = true;
      let value = Object();
      value.limit = 10;
      value.page = page;
      value.paginate = true;
      await this.$store.dispatch(GETALL_ROLES, value);
      this.isload = false;
    },
    async getAllPermissions() {
      await this.$store.dispatch(GETALL_PERMISSIONS);
    },
    async submit() {
      let data = Object();
      let item = JSON.parse(JSON.stringify(this.items));
      data.form = this.form;
      item.map((i) => {
        let levels = i.level;
        let levelString = String();
        let l0 = levels[0] ? levels[0] + "," : "";
        let l1 = levels[1] ? levels[1] + "," : "";
        let l2 = levels[2] ? levels[2] + "," : "";
        let l3 = levels[3] ? levels[3] + "," : "";
        levelString = `${l0}${l1}${l2}${l3}`;
        i.level = levelString.substring(0, levelString.length - 1);
      });

      data.items = item;
      this.item_Aloc = data;
      this.isload = true;
      try {
        if (data.form.id != undefined) {
          await this.update();
        } else {
          await this.save();
        }
      } catch (error) {
        await this.update();
      } finally {
        await this.getall();
        this.isload = false;
        this.changeDialod();
      }
    },

    async save() {
      await this.$store.dispatch(CREATE_PERMISSION, this.item_Aloc);
    },
    async update() {
      await this.$store.dispatch(UPDATE_PERMISSION, this.item_Aloc);
    },
  },
  async mounted() {
    await this.getall();
  },
};
</script>

<style>
</style>