import { GET_ALL_TRANSPORTER } from "../../../../../../../../store/actions/transporters"
function trimString(data) {
    if (data.length > 0) {
        return data.trim()
    } else {
        return data
    }
}
async function getAllInformations() {

    var payload = {
        page: 1,
        limit: 10,
        paginate: true,
        type_people: "LegalPeople"
    };
    await this.$store.dispatch(GET_ALL_TRANSPORTER, payload)

}


function registerTransporter() {
    this.$store.dispatch("setShowDialogRegisterTransporter", true)
}

function showDilogTable() {//mudar nome da função
    this.$store.dispatch("setShowDialogRegisterSelectionTable", true)

}

function populateObjectShippingCompany(objectStore) {
    if (objectStore) {
        this.selectedTransporter.modeShipping = trimString(objectStore.modFrete._text)
        this.selectedTransporter.transporter.uf = trimString(objectStore.transporta.UF._text)
        this.selectedTransporter.transporter.address = trimString(objectStore.transporta.xEnder._text)
        this.selectedTransporter.transporter.city = trimString(objectStore.transporta.xMun._text)
        this.selectedTransporter.volume.especification = trimString(objectStore.vol.esp._text)
        this.selectedTransporter.volume.brand = trimString(objectStore.vol.marca._text)
        this.selectedTransporter.volume.grossWeigth = trimString(objectStore.vol.pesoB._text)
        this.selectedTransporter.volume.netWeight = trimString(objectStore.vol.pesoL._text)
        this.selectedTransporter.volume.nVolume = trimString(objectStore.vol.nVol._text)
        this.selectedTransporter.volume.volume_transported = trimString(objectStore.vol.qVol._text)
    }
}

function setArrayTransporters() {

    this.transporterRegistered = this.getLegalTransporter
}


export default {
    populateObjectShippingCompany,
    registerTransporter,
    getAllInformations,
    setArrayTransporters,
    showDilogTable
}