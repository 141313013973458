const UPDATE_REVENUES_ITENS = 'UPDATE_REVENUES_ITENS'
const DESTROY_REVENUES_ITENS = 'DESTROY_REVENUES_ITENS'
const ADD_REVENUES_ITENS = 'ADD_REVENUES_ITENS'
const REFRESH_REVENUES_ITENS = 'REFRESH_REVENUES_ITENS'
const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT'
const ENTERPRISE_OBJECT = 'ENTERPRISE_OBJECT'

module.exports = {
    ENTERPRISE_OBJECT,
    UPDATE_REVENUES_ITENS,
    DESTROY_REVENUES_ITENS,
    ADD_REVENUES_ITENS,
    REFRESH_REVENUES_ITENS,
    UPDATE_DISCOUNT
}