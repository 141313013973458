<template>
  <div class="errors">
    <v-alert :value="showAlert" type="warning" transition="scale-transition">
      {{ someMessage }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    showAlert: Boolean(),
    someMessage: String(),
  },
};
</script>

<style>
.errors {
  position: fixed;
  display: flex;
  justify-content: center;
}
</style>
