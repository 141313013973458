const ADD_FORM_OF_PAYMENT = "ADD_FORM_OF_PAYMENT";
const UPDATE_FORM_OF_PAYMENT  = "UPDATE_FORM_OF_PAYMENT";
const GET_ALL_FORM_OF_PAYMENT  = "GET_ALL_FORM_OF_PAYMENT";
const DESTROY_FORM_OF_PAYMENT  = "DESTROY_FORM_OF_PAYMENT";
const GET_FILTER_FORM_OF_PAYMENT = "GET_FILTER_FORM_OF_PAYMENT";
const AUX_TO_UPDATE_FORM = "AUX_TO_UPDATE_FORM"

module.exports = {
    ADD_FORM_OF_PAYMENT,
    UPDATE_FORM_OF_PAYMENT,
    GET_ALL_FORM_OF_PAYMENT,
    DESTROY_FORM_OF_PAYMENT,
    GET_FILTER_FORM_OF_PAYMENT,
    AUX_TO_UPDATE_FORM,
    
};
