<template>
  <v-row>
    <v-dialog max-width="600" v-model="component.dialogPass">
      <v-card>
        <v-card-title> Alterar Senha </v-card-title>
        <v-container>
          <v-form ref="form" v-model="validDisable"> 
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="component.createNewPassword.currentPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="Senha Atual"
                hint="Minimo 8 digitos"
                @click:append="show1 = !show1"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                v-model="component.createNewPassword.newPassword"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show2 ? 'text' : 'password'"
                label="Nova Senha"
                hint="Minimo 8 digitos"
                @click:append="show2 = !show2"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                v-model="component.createNewPassword.passwordConfirmation"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, checkPasswordConfirmation]"
                :type="show3 ? 'text' : 'password'"
                label="Confirmar Senha"
                hint="Minimo 8 digitos"
                @blur="checkPasswordConfirmation"
                @click:append="show3 = !show3"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="component.dialogPass = !component.dialogPass"
          >
            <v-icon class="mr-2">mdi-cancel</v-icon>Cancelar</v-btn
          >
          <v-btn @click="component.newChangePassword" :disabled="!validDisable" class="success">
            <v-icon class="mr-2">mdi-content-save</v-icon>Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    show1: Boolean(),
    show2: Boolean(),
    show3: Boolean(),
    validDisable: true,
    rules: {
      required: (value) => !!value || "Obrigatório",
      min: (value) => value.length >= 8 || "Min 8 caracteres",
    },
  }),

  methods: {
    checkPasswordConfirmation() {
      if(this.component.createNewPassword.newPassword != this.component.createNewPassword.passwordConfirmation) {
        return "Confirmação diferente da Nova Senha"
      }
    }    
  },

  props: {
    component: Object,
  },

  beforeDestroy(){
    this.$refs.form.reset()
  },

  created(){
    this.component.createNewPassword.currentPassword = "",
    this.component.createNewPassword.newPassword = "",
    this.component.createNewPassword.passwordConfirmation = ""
  }

};
</script>

<style>
</style>