const fiscal = [
  {
    text: "Livro Fiscal de Entrada",
    icon: "mdi-book-outline",
    route: "/BookSupervisionIn",
    class: "white--text",
    active: false,
  },
  {
    text: "Livro Fiscal de Saída",
    icon: "mdi-book-outline",
    route: "/BookSupervisionOut",
    class: "white--text",
    active: false,
  },
  {
    text: "Inventario",
    icon: "mdi-note-outline",
    route: "/Inventory",
    class: "white--text",
    active: false,
  },
  // {
  //   text: "Carta Correção",
  //   icon: "mdi-truck-outline",
  //   route: "/Aliquot",
  //   class: "white--text",
  //   active: false,
  // },
  // {
  //   text: "Inutilização Nfe",
  //   icon: "mdi-truck-outline",
  //   route: "/Aliquot",
  //   class: "white--text",
  //   active: false,
  // },
  {
    text: "Carta Correção",
    icon: "mdi-truck-outline",
    route: "/correctionLetter",
    class: "white--text",
    active: false,
  },
  {
    text: "Baixa de XML",
    icon: "mdi-xml",
    route: "/WriteOffXML",
    class: "white--text",
    active: true,
  },
  {
    text: "Cancelamento de Sequência de Nota Fiscal",
    icon: "mdi-truck-outline",
    route: "/CancelSequenceInvoice",
    class: "white--text",
    active: false,
  },
  {
    text: "Sped Icms",
    icon: "mdi-truck-outline",
    route: "/sped-icms",
    class: "white--text",
    active: false,
  },
  // {
  //   "text": "Sped Contabil",
  //   "icon": "mdi-truck-outline",
  //   "route": "/Aliquot",
  //   "class": "white--text",
  //   "active": false
  // },
  // {
  //   "text": "Sintegra",
  //   "icon": "mdi-truck-outline",
  //   "route": "/Aliquot",
  //   "class": "white--text",
  //   "active": false
  // },
];
export { fiscal };
