<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="70%">
      <v-card>
        <v-card-title> Adicionar Alíquota</v-card-title>
        <v-data-table
          :headers="header"
          :items="result"
          dense
          @click:row="handleClick"
          hide-default-footer
        >
        </v-data-table>
        <v-divider></v-divider>
        <div>
          <v-pagination
            color="success"
            v-model="current"
            :length="total"
            :total-visible="10"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="changeDialog()" text> Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_ALIQUOT } from "../../../../../store/actions/aliquot";
export default {
  props: {
    component: Object,
    dialog: Boolean,
    changeDialog: Function,
  },
  data: () => ({
    result: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    header: [
      { text: "Código", value: "aliquot" },
      { text: "Descrição", value: "description" },
    ],
  }),
  async created() {
    await this.GetAll();
  },
  methods: {
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    GetAll(page = 1) {
      this.$store.dispatch(GET_ALL_ALIQUOT, {
        page: page,
        limit: 10,
        paginate: true,
      });
    },
  },
  computed: {
    ...mapGetters(["getAliquot"]),
  },

  watch: {
    getAliquot: function (val) {
      this.result = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.GetAll && this.GetAll(val);
    },
    itemSelectedOnClick: function (val) {
      this.component.descriptionAliquot = val;

      this.changeDialog();
    },
  },
};
</script>

