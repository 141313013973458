<template>
  <div>
    <v-data-table
      :headers="header"
      :items="items"
      no-data-text="Sem registro!"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, -1],
        'items-per-page-text': 'Items por página',
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              editItem(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon color="blue" class="mr-2" @click="change_password(item)"
          >mdi-lock</v-icon
        >
        <v-icon color="red darken-4" @click="changeDestroyDialog(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    editItem: Function,
    changeDestroyDialog: Function,
    change_password: Function,
  },
  data: () => ({
    header: [
      { text: "Código", value: "id" },
      { text: "Nome", value: "name" },
      { text: "E-mail", value: "email" },
      { text: "Ações", value: "actions" },
    ],
  }),
};
</script>

<style>
</style>