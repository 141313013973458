<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="getShowDialogRegisterAccountPlan"
      max-width="600"
    >
      <v-card>
        <v-card-title primary-title> Plano de Contas </v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-select
                v-model="status"
                :readonly="justVisible"
                :items="statusList"
                label="Status"
                outlined
                :rules="[() => !!status || 'Campo Obrigatório']"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="*Código Contabil"
                v-model="accountCode"
                :readonly="justVisible"
                outlined
                required
                :rules="[
                  () => !!accountCode || 'Campo Obrigatório',
                  () =>
                    (!!accountCode && accountCode.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
              />
            </v-col>

            <v-col>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                outlined
                required
                :rules="[
                  () => !!description || 'Campo Obrigatório',
                  () =>
                    (!!description && description.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="generateMovement"
                :readonly="justVisible"
                :items="generateMovementList"
                label="*Gera Movimentação"
                outlined
                :rules="[() => !!generateMovement || 'Campo Obrigatório']"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="accountType"
                :readonly="justVisible"
                :items="accountTypeList"
                label="*Tipo Contabil"
                outlined
                :rules="[() => !!accountType || 'Campo Obrigatório']"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="planType"
                :readonly="justVisible"
                :items="planTypeList"
                label="*Tipo de Plano"
                outlined
                :rules="[() => !!planType || 'Campo Obrigatório']"
            /></v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    changeDialog();
                  }
                "
              >
                <v-icon>mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon>mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_ACCOUNT_PLAN,
  UPDATE_ACCOUNT_PLAN,
} from "../../../store/actions/account_plan";
import { mapGetters } from "vuex";

export default {
  props: {
    showDialog: Boolean(),
    component: Object,
    out_of_register: Boolean(),
  },
  data() {
    return {
      justVisible: Boolean(),
      attrs: false,
      on: false,
      dialog: false,
      valid: true,
      id: undefined,
      status: false,
      description: "",
      accountCode: "",
      generateMovementList: ["Sim", "Não"],
      generateMovement: "",
      accountTypeList: ["DESPESA", "RECEITA"],
      accountType: "",
      statusList: ["Ativo", "Inativo"],
      planTypeList: ["ATIVO", "PASSIVO"],
      planType: "",
      search: "",
      formHasErrors: false,
    };
  },
  computed: {
    ...mapGetters([
      "getShowDialogRegisterAccountPlan",
      "getItemToUpdateAccountPlan",
      "getSelectedEnterprise",
    ]),
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getComponent() {
      return this.component;
    },
    async create_account_plan() {
      const account_plan = {
        status: this.status,
        accountCode: this.accountCode,
        description: this.description,
        generateMovement: this.generateMovement,
        accountType: this.accountType,
        planType: this.planType,
        enterprise_id:
          this.out_of_register == true
            ? this.getSelectedEnterprise.id
            : this.component.selectValue.id,
      };
      await this.$store.dispatch(ADD_ACCOUNT_PLAN, account_plan).then(() => {
        this.changeDialog();
      });
    },
    changeDialog() {
      this.$store.dispatch("setShowDialogRegisterAccountPlan", false);
      this.reset();
      this.resetValidation();
      this.justVisible = false
    },
    submit() {
      this.id == undefined
        ? this.create_account_plan()
        : this.update_account_plan();
    },
    async update_account_plan() {
      const account_plan = {
        id: this.id,
        status: this.status,
        accountCode: this.accountCode,
        description: this.description,
        generateMovement: this.generateMovement,
        accountType: this.accountType,
        planType: this.planType,
        enterprise_id: this.component.selectValue.id,
      };

      await this.$store.dispatch(UPDATE_ACCOUNT_PLAN, account_plan).then(() => {
        this.changeDialog();
      });
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  watch: {
    getItemToUpdateAccountPlan: function (val) {

      if(val.justVisible) {
        this.justVisible = val.justVisible;
        this.status = val.item.status;
        this.accountCode = val.item.accountCode;
        this.description = val.item.description;
        this.generateMovement = val.item.generateMovement;
        this.accountType = val.item.accountType;
        this.planType = val.item.planType;
      } else {
        this.status = this.getItemToUpdateAccountPlan.status;
        this.accountCode = this.getItemToUpdateAccountPlan.accountCode;
        this.description = this.getItemToUpdateAccountPlan.description;
        this.generateMovement = this.getItemToUpdateAccountPlan.generateMovement;
        this.accountType = this.getItemToUpdateAccountPlan.accountType;
        this.planType = this.getItemToUpdateAccountPlan.planType;
        this.id = this.getItemToUpdateAccountPlan.id;
      }
    },
  },
  async created() {
    // // this.object = { ...this.getComponent().objectToUpdate };
    // this.status == this.object ? this.object.status : undefined;
    // this.id = this.object ? this.object.id : undefined;
    // this.description = this.object ? this.object.description : undefined;
    // this.accountCode = this.object ? this.object.accountCode : undefined;
    // this.generateMovement = this.object
    //   ? this.object.generateMovement
    //   : undefined;
    // this.accountType = this.object ? this.object.accountType : undefined;
    // this.planType = this.object ? this.object.planType : undefined;
  },
  beforeDestroy() {
    // this.getComponent().objectToUpdate = {};
  },
};
</script>
