<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="400"
      v-model="this.component.dialog"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title>Consultar Produto</v-card-title>
          </v-row>
          <v-row justify="center">
            <div>
              <v-img max-height="200" max-width="200" :src="picture"> </v-img>
            </div>
          </v-row>
          <v-form class="mt-10">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="codeBar"
                  label="Código de barra"
                  append-icon="mdi-magnify"
                  outlined
                  @keyup.enter="searchProduct()"
                  :readonly="readonly"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row rows="4">
              <v-textarea
                v-model="product.description_item"
                label="Descrição"
                outlined
                class="ml-2 mr-2"
                readonly
              >
              </v-textarea>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Categoria"
                  v-model="product.productgroup.name"
                  outlined
                  class="ml-2 mr-2"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Data Inicial"
                  v-model="product.initial_promotion_date"
                  outlined
                  class="ml-2"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data Final"
                  v-model="product.final_promotion_date"
                  outlined
                  class="mr-2"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="NCM"
                  v-model="product.ncm.code"
                  outlined
                  class="ml-2"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="CFOP"
                  v-model="product.cfop.code"
                  outlined
                  class="mr-2"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="CSOSN"
                  v-model="product.csosn_code"
                  outlined
                  class="ml-2"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="CST"
                  v-model="product.cst_code"
                  outlined
                  class="mr-2"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="error mr-1"
              @click="
                () => {
                  disableDialog();
                }
              "
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    component: Object,
    itemProduct: Object,
    readonly: Boolean,
  },
  data: () => ({
    codeBar: String(),
    picture: String(),
    product: Object(),
    empty: {
      description_item: String(),
      productgroup: {
        name: String(),
      },
      initial_promotion_date: String(),
      final_promotion_date: String(),
      ncm: {
        code: String(),
      },
      cfop: {
        code: String(),
      },
      csosn_code: String(),
      cst_code: String(),
    },
  }),
  methods: {
    disableDialog() {
      this.component.dialog = !this.component.dialog;
    },
    mountedData(item) {
      if (item.id === undefined) {
        alert("Código de barra não encontrado!");
      } else {
        this.codeBar = item.bar_code;
        this.picture = `${this.component.url}${item.photo}`;
        this.product = { ...item };
      }
    },
    async searchProduct() {
      if (!this.readonly) {
        let barcode = this.codeBar;
        let product = await this.component.searchProductGetBarCode({ barcode });
        this.mountedData(product);
      }
    },
  },
  created() {
    if (this.itemProduct.bar_code === undefined) {
      this.product = { ...this.empty };
    } else {
      this.mountedData(this.itemProduct);
    }
  },
};
</script>
