




function modelCalculateProduct(product) {
    let products = {
        aliquot_icms_product: parseFloat(product.Tax.aliquot.aliquot),
        aliquot_ipi_product: parseFloat(product.Tax.ipi),
        amount: product.amount,
        cfop_id: product.Tax.cfop.id,
        code_cfop: String(product.Tax.cfop.code),
        code_csosn: product.Tax.csosn != undefined ? product.Tax.csosn.code : product.csons.code,
        csosn_id: product.Tax.csosn != undefined ? product.Tax.csosn.id : product.csons.id,
        code_cst: product.Tax.cst != undefined ? product.Tax.cst.code : null,
        cst_id: product.Tax.cst != undefined ? product.Tax.cst.id : null,
        cst_code_confis: product.Tax.cofins.code,
        cst_code_ipi: String(product.cst_ipi_id),
        cst_code_pis: product.Tax.pis.code,
        cst_confis_id: product.Tax.cofins.id,
        cst_pis_id: product.Tax.pis.id,
        cust: parseFloat(product.cost_price),
        description_product: product.description_item,
        description_unit: product.unit.sigla,
        invoiceentry_id: null,
        product_id: product.id,
        unit_id: product.unit_id,

        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
    };

    return products
}



export default modelCalculateProduct