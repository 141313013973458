<template>
  <v-row>
    <v-dialog
      v-model="getShowDialogRegisterSubProductGroup"
      max-width="400"
      persistent
    >
      <v-card>
        <v-container>
          <v-row>
            <v-card-title> Registrar Subgrupo de Produtos</v-card-title>
          </v-row>
          <v-row class="mb-3"> </v-row>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" class="mt-6">
            <v-autocomplete
              outlined
              label="*Grupo de Produtos"
              item-value="id"
              item-text="name"
              return-object
              v-model="productgroup"
              :items="productgroup_items"
              :rules="[() => !!productgroup || 'Campo Obrigatório']"
            ></v-autocomplete>
            <v-text-field
              v-model="code"
              outlined
              label="*Código"
              :rules="[() => !!code || 'Campo Obrigatório']"
            ></v-text-field>
            <v-text-field
              v-model="name"
              outlined
              label="*Nome"
              :rules="[() => !!code || 'Campo Obrigatório']"
            ></v-text-field>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="() => cancel()" class="error">
              <v-icon>mdi-cancel</v-icon> Cancelar</v-btn
            >
            <v-btn class="success" @click="() => confirm()">
              <v-icon>mdi-content-save</v-icon> Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_SUB_PRODUCT_GROUP,
  UPDATE_SUB_PRODUCT_GROUP,
} from "../../../store/actions/sub_product_group";
import { GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import { mdiPlusCircle } from "@mdi/js";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },

  data: () => ({
    icons: [mdiPlusCircle],
    CallDialog: Boolean(),
    valid: true,
    productgroup_items: Array(),
    search: String(),
    id: undefined,
    product_group_id: String(),
    productgroup: Object(),
    productGroupList: Array(),
    code: Number(),
    name: String(),
    productgroupname: String(),
    getter: "getProductGroup",
  }),

  methods: {
    cancel() {
      this.id = undefined
      this.$refs.form.reset()
      this.$store.dispatch("setShowDialogRegisterSubProductGroup", false);
    },

    showDialogRegisterProductGroup() {
      this.$store.dispatch("setShowDialogRegisterProductGroup", true);
    },

    confirm() {
      this.id == undefined
        ? this.create_subproductgroup()
        : this.update_subproductgroup();
    },

    async create_subproductgroup() {
      const subproductgroup = {
        product_group_id: this.productgroup.id,
        code: parseInt(this.code),
        name: this.name,
        productgroupname: this.productgroup.name,
      };
      await this.$store
        .dispatch(ADD_SUB_PRODUCT_GROUP, subproductgroup)
        .then(() => {
          this.$refs.form.reset()
          this.cancel();
        });
    },

    async update_subproductgroup() {
      const subproductgroup = {
        id: this.id,
        product_group_id: this.productgroup.id,
        code: parseInt(this.code),
        name: this.name,
        productgroupname: this.productgroup.name,
      };
      await this.$store
        .dispatch(UPDATE_SUB_PRODUCT_GROUP, subproductgroup)
        .then(() => {
          this.id = undefined
          this.$refs.form.reset()
          this.cancel();
        });
    },
    async getAllProdutcGroup() {
      await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, {
        search: this.search,
        limit: null,
        page: null,
        paginate: false,
      });
      this.productGroupList.push(Object.values(this.getProductGroup));
    },
    getComponent() {
      return this.component;
    },
    autoReloadToUpdate(item){
      this.id = item.id
      this.code = item.code
      this.name = item.name
      this.productgroup  = item.productgroup
    }
    
  },
  watch: {
    getItemToUpdateSubGroupProduct: function(val){
      this.autoReloadToUpdate(val)
    },
    getProductGroup: function () {
      this.productgroup_items = Object.values(this.getProductGroup);
    },
  },

  async created() {
    this.getAllProdutcGroup();
  },
  computed: {
    ...mapGetters([
      "getProductGroup",
      "getShowDialogRegisterSubProductGroup",
      "getNewProduct",
      "getItemToUpdateSubGroupProduct"
    ]),
  },
};
</script>
