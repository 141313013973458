
<template>
  <v-container class="d-flex justify-center">
    <v-col>
      <v-row class="d-flex justify-center">
      </v-row>
      <v-form ref="formData" v-model="formValidation">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6" >
          <v-text-field
            :rules="rules"
            outlined
            dense
            readonly
            :label="returnString"
            prepend-inner-icon="mdi-cursor-default-click"
            append-outer-icon="mdi-plus-circle"
            v-model="selectedTransporter.legalpeople.fantasyName"
            @click="showDilogTable"
            @click:append-outer="registerTransporter()"
          />
          <v-text-field
            outlined
            dense
            label="Placa do Veículo"
            v-model="selectedTransporter.vehicle_plate"
          />
          <v-select
            outlined
            dense
            :items="modeShipping"
            label="Tipo de Frete"
            v-model="selectedTransporter.modeShipping"
          />
          <v-text-field
            outlined
            dense
            prefix="R$"
            label="Valor do Frete"
            v-model="selectedTransporter.value_shipping"
          />
          <v-text-field
            outlined
            dense
            prefix="R$"
            label="Valor do Seguro"
            v-model="selectedTransporter.value_secure"
          />
          <v-text-field
            outlined
            dense
            prefix="R$"
            label="Outras despesas Acessórias"
            v-model="selectedTransporter.others_values"
          />
        </v-col>

        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            outlined
            dense
            label="Volume Transportado"
            v-model="selectedTransporter.volume.volume_transported"
          />
          <v-text-field
            outlined
            dense
            label="Peso Bruto da Mercadoria"
            v-model="selectedTransporter.volume.grossWeigth"
          />
          <v-text-field
            outlined
            dense
            label="Peso Líquido da Mercadoria"
            v-model="selectedTransporter.volume.netWeight"
          />
          <v-text-field
            outlined
            dense
            label="Tipo de Produto Transportado"
            v-model="selectedTransporter.product_type"
          />
        </v-col>
      </v-row>
      </v-form>
      <RegisterTransporter :out_of_register="true" />
      <TableSelection :headers="headers" :values="transporterRegistered" :component="this" />
    </v-col>
  </v-container>
</template>

<script>
import RegisterTransporter from "../../../../../../transport/AddDialog/index.vue";
import TableSelection from "../../../../../../Utils/dialog_selection_table/dialog_selection_table.vue";
import { PREPARE_TRANSPORTER } from "../../../../../../../store/actions/import_xml";
import { SET_SHIPPING_COMPANY } from "../../../../../../../store/actions/import_xml";
import { mapGetters } from "vuex";
import data from "./helpers/data";
import methods from "./helpers/mehtods";
export default {
  components: {
    RegisterTransporter,
    TableSelection
  },
  props: {
    component: Object(),
    NotaFiscalInformacoes: null
  },
  computed: {
    ...mapGetters(["getLegalTransporter","getShippingCompany"]),
    returnString(){
      if(this.selectedTransporter.legalpeople.fantasyName != ''){
        return "Transportadora"
      }else{
        return 'Clique aqui para selecionar uma transportadora'
      }
    }
  },
  methods: {
    ...methods
  },
  data() {
    return {
      ...data
    };
  },
  async created() {
    await this.getAllInformations();
    this.setArrayTransporters();
    this.populateObjectShippingCompany(this.getShippingCompany) 
    this.selectedTransporter.modeShipping = 'cif'
  },
  beforeDestroy(){
    this.$store.dispatch(SET_SHIPPING_COMPANY, null)
    this.$refs.formData.reset()
  },
  watch: {
    formValidation: function(){
      this.component.validationSteps.push(true)
      this.$store.dispatch(PREPARE_TRANSPORTER,this.selectedTransporter)
    },
    getShippingCompany: function(val) {
      this.populateObjectShippingCompany(val)
    }
  }
};
</script>

<style scoped>
</style>