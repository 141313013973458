



var headersNFE_NFCE = [
    {text:"NFE", value:"nfe"},
    {text:"NFCE", value:"nfce"}
]



module.exports = {
    headersNFE_NFCE
}