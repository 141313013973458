var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","no-data-text":"Sem registros"},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
          _vm.visibleItem(item);
        }}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
            _vm.edit_item(item);
          }}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
            _vm.active_delete(item);
          }}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('div',[_c('v-divider'),_c('v-pagination',{attrs:{"length":_vm.total,"total-visible":"10","color":"success"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1),(_vm.destroyDialog)?_c('destroy',{attrs:{"destroy_object":_vm.destroyItem,"component":this}}):_vm._e(),(_vm.dialog)?_c('editOrder',{attrs:{"component":this,"objectToForm":_vm.objectForAction}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }