




function modelCalculateProduct(product) {

    let products = {
        aliquot_icms_product: parseFloat(product.aliquot_icms_product),
        aliquot_ipi_product: parseFloat(product.aliquot_ipi_product),
        amount: product.amount,
        cfop_id: product.product.cfop.id,
        code_cfop: String(product.product.cfop.code),
        code_csosn: product.product.csons.code != undefined ? product.product.csons.code : '101',
        csosn_id: product.product.csons.id != undefined ? product.product.csons.id : 1,
        code_cst: product.product.cst.code != undefined ? product.product.cst.code : null,
        cst_id: product.product.cst.id != undefined ? product.product.cst.id : null,
        cst_code_confis: product.product.cst_confis.code,
        cst_code_ipi: String(product.product.cst_id),
        cst_code_pis: product.product.cst_pis.code,
        cst_confis_id: product.product.cst_cofins_id,
        cst_pis_id: product.product.cst_pis_id,
        cust: parseFloat(product.product.sale_price),
        description_product: product.product.description_item,
        description_unit: product.product.unit.sigla,
        invoiceentry_id: null,
        product_id: product.product.id,
        unit_id: product.product.unit.id,
        total_value: parseFloat(product.amount) * parseFloat(product.product.sale_price),

        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
    };

     

    return products
}



export default modelCalculateProduct