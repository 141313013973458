<template>
  <v-row justify="center">
    <v-dialog
      v-model="getShowDialogRegisterSuply"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <v-card>
        <v-card-title>Suprimento</v-card-title>
        <v-card-subtitle>Informe o valor a ser adicionado ao caixa.</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Valor de Adição"
                prefix="R$"
                v-model="supply.value_supply"
                placeholder="0.00"
              ></v-text-field>
            </v-col>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row justify="center">
              <v-btn
                class="error mr-1"
                @click="suggest_print?submit_supply(null):cancel()"
              >
                <v-icon>mdi-cancel</v-icon>{{words.cancel}}</v-btn>
                <v-btn
                  class="success"
                  @click="current_operation=='CONFIRM'?suggestPrint():submit_supply(1)"
                >
                  <v-icon>mdi-content-save</v-icon> {{words.confirm}}</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateDate, randomNumber } from '../../../Utils/global_functions/utils_function';

import { SUPPLY } from "../../../../store/actions/future_sales";

export default {
  props: {
    component: Object(),
  },
  data: () => ({
    supply: {
      enterprise_id: 1,
      value_supply: String(),
    },
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    current_operation: "CONFIRM",
    isPritting: Boolean(),
    suggest_print: Boolean(),
    isDisablead: Boolean(),
    words: {
      confirm: "Confirmar",
      cancel: "Cancelar",
    },
  }),
  computed:{
    ...mapGetters(['getShowDialogRegisterSuply', 'getSelectedEnterprise'])
  },
  methods: {
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
    suggestPrint(val) {
      if (val) this.isPritting = true;

      this.suggest_print = true;
    },
    cancel() {
      this.isDisablead = false;

      this.$store.dispatch('setShowDialogRegisterSuply', false)
    },
    async submit_supply(value) {
      if (value) this.isPritting = true;
      // this.component.titlePDF = "Suprimento de Caixa";

      // let object = { ...this.supply };
      // this.component.needPrint = this.isPritting;



      // this.component.open_cashie_payload = object.value_supply;

      const payload = {
        dateMovement: generateDate(),
        numberMovement: String(randomNumber(9999, 1)),
        descriptionMovement: 'Suprimento de caixa',
        typeMovement: 'Entrada',
        enterprise_id: this.getSelectedEnterprise,
        value_supply: this.supply.value_supply,
      }
      await this.$store.dispatch(SUPPLY, payload);
      this.cancel();
    },
  },
  watch: {
    current_operation: function() {
      this.checkState();
    },
    suggest_print: function(v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },
  },
};
</script>
