<template>
    <v-container>
      <v-card elevation="4" outlined>
        <v-card-text>
          <v-row>
            <v-card-title>Relatório de Conferência de Vendas</v-card-title>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                outlined
                dense
                return-object
                flat
                height="10px"
                label="Empresa"
                item-value="id"
                item-text="fantasyName"
                loader-height
                v-model="report.selectValue"
                :items="enterprise"
              ></v-select>
            </v-col>
            <v-btn
              class="mt-3 mr-4"
              width="200"
              color="success"
              type="submit"
              @click="Generate()"
              :disabled="!valid"
            >
              <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-container>
          <Form :component="this" ref="form" />
        </v-container>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import Form from "../../../components/report/saleConference/Form/index.vue";
  import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
  import { mapGetters } from "vuex";
  
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import lastAutoTable from 'jspdf-autotable';
  import axios from "axios";
  
  export default {
    components: { Form },
  
    data: () => ({
      dialog: Boolean(),
      valid: false,
      // selectValue: Object(),
      userName: String(),
      enterprise: [],
      report: {
        selectValue: Object(),
        date_initial_sales: null,
        date_finish_sales: null,
      },
    }),
  
    methods: {
      async Generate() {
        let payload = {
          enterprise_id: this.report.selectValue.id,
          date_initial_sales: this.report.date_initial_sales,
          date_finish_sales: this.report.date_finish_sales,
        }
        let all_report = Object.assign({}, payload);

        
  
        let response = await axios.post("/sales/getsalesconferencereport", {
          ...all_report,
        });

        

        // for (let i = 0; i <= response.data.list_of_authorized_sales.length; i++) {
        //   console.log(response.data.list_of_authorized_sales[i])
        // }

        this.dialog = !this.dialog;
        this.$refs.form.reset();
        this.$refs.form.validate();
        this.$refs.form.resetValidation();
        let arrayenterprise = [];
        let arrayaddress = [];
        let objeto = Object.assign({}, this.report.selectValue);
        let objetoadress = Object.assign({}, this.report.selectValue.address);
        arrayenterprise.push(objeto);
        arrayaddress.push(objetoadress);
        const sales_conference_report_summary = Object.keys(response.data.sales_conference_report_summary).map(key => response.data.sales_conference_report_summary[key]);


        const obj_list_of_authorized_sales = {};
        for (const obj of response.data.list_of_authorized_sales) {
          obj_list_of_authorized_sales[obj.id] = obj;
        }


        const list_of_authorized_sales = Object.keys(obj_list_of_authorized_sales).map(key => obj_list_of_authorized_sales[key]);



        for (let i = 0; i <= list_of_authorized_sales.length - 1; i++) {
          if (list_of_authorized_sales[i].valor_icms_substituicao_total == null) {
            list_of_authorized_sales[i].valor_icms_substituicao_total = '0'
          }
        }
        let sales_canceled = null
        if(response.data.sales_canceled) {
          sales_canceled = Object.keys(response.data.sales_canceled).map(key => response.data.sales_canceled[key]);
          let total_value_canceled_sales = 0
          for(let i = 0;i<sales_canceled.length;i++) {
            total_value_canceled_sales += sales_canceled[i].value_total_note
          }
          const total_value_canceled_sales_object = {
            number_sale: 'Total:',
            value_total_note: total_value_canceled_sales.toFixed(2)
          }
          sales_canceled.push(total_value_canceled_sales_object)
        }
        
        this.createPDF(sales_conference_report_summary, list_of_authorized_sales, sales_canceled, arrayenterprise, arrayaddress);
      },
  
      createPDF(item, listItem, salesCanceled,  enterprise, address) {
        let data = new Date();

        for(let i = 0; i<item.length; i++) {
          item[i].value_account_note_entry = item[i].value_account_note_entry.toFixed(2)
          item[i].value_icms_out = item[i].value_icms_out.toFixed(2)
          item[i].value_base_calculo_icms = item[i].value_base_calculo_icms.toFixed(2)
        }

        let totalValueSale = Number()
        let totalValueIcms = Number()
        let totalValueSubst = Number()

        for(let i = 0; i< listItem.length; i++) {
          totalValueSale += listItem[i].value_total_note
          totalValueIcms += listItem[i].value_icms_total
          totalValueSubst += Number(listItem[i].valor_icms_substituicao_total)
        }
        
        totalValueSale = totalValueSale.toFixed(2)
        totalValueIcms = totalValueIcms.toFixed(2)
        totalValueSubst = totalValueSubst.toFixed(2)
        
        const totalValues = {
          number_sale: 'Total:',
          value_total_note: totalValueSale,
          value_icms_total: totalValueIcms,
          valor_icms_substituicao_total: totalValueSubst,
        }

        listItem.push(totalValues)

        // item.forEach((e) => {
        //   if (e.type_people == "LegalPeople") {
        //     e.name = e.fantasyName;
        //     e.cpf = e.cnpj;
        //   } else {
        //     e.dateOfBirth = e.dateOfBirth.split("-").reverse().join("/");
        //   }
        // });
  
        var doc = new jsPDF("l", "pt");
        let columns = [
          { title: "Código CFOP", dataKey: "code" },
          { title: "Descrição", dataKey: "description" },
          { title: "Aliquota", dataKey: "aliquot" },
          { title: "Venda", dataKey: "value_account_note_entry" },
          { title: "Base", dataKey: "value_base_calculo_icms" },
          { title: "Imposto", dataKey: "value_icms_out" },
        ];
        
        let sales_canceled_columns = [
          { title: "Data da Venda", dataKey: "data_sale" },
          { title: "Chave da Nota", dataKey: "access_key" },
          { title: "Número da Nota", dataKey: "number_sale" },
          { title: "Valor da Venda", dataKey: "value_total_note" },
        ];

        let list_of_authorized_sales_columns = [
          { title: "Data da Venda", dataKey: "data_sale" },
          { title: "Chave da Nota", dataKey: "access_key" },
          { title: "Número da Nota", dataKey: "number_sale" },
          { title: "Valor da Venda", dataKey: "value_total_note" },
          { title: "Valor total de Icms", dataKey: "value_icms_total" },
          { title: "Valor total de substituição de Icms", dataKey: "valor_icms_substituicao_total" },
        ];
        
        doc.setLineWidth(1);
        doc.line(40, 85, 800, 85);
        doc.line(40, 125, 800, 125);
        doc.setFontSize(7);
        doc.text(`Usuário : ${this.userName}`, 700, 40);
        doc.text(`${data.toLocaleString()}`, 700, 50);
  
        doc.setFontSize(15);
        doc.text("RELATÓRIO DE CONFERÊNCIA DE VENDAS", 270, 70);
  
        doc.setFontSize(7);
        doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
        doc.text(`Razão Social : ${enterprise[0].name}`, 240, 100);
        doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 630, 100);
        doc.text(`Rua : ${address[0].street}`, 40, 115);
        doc.text(`Numero : ${address[0].number}`, 250, 115);
        doc.text(`Cidade : ${address[0].city}`, 340, 115);
        doc.text(`Estado : ${address[0].state}`, 550, 115);
        doc.text(`CEP : ${address[0].cep}`, 700, 115);
  

        doc.setFontSize(13);
        doc.text("Resumo Das Vendas", 320, 145);
        doc.autoTable(columns, item, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: 150,
          theme: "grid",
        });
        // console.log('doc.lastAutoTable.finalY', doc.lastAutoTable.finalY);

        if(salesCanceled) {
          
          doc.setFontSize(13);
          doc.text("Vendas Canceladas", 320, doc.lastAutoTable.finalY + 15);
          doc.autoTable(sales_canceled_columns, salesCanceled, {
            columnStyles: {
              //
            },
            headStyles: {
              fillColor: [230, 230, 230],
              textColor: [54, 54, 54],
            },
            startY: doc.lastAutoTable.finalY + 20,
            theme: "grid",
          });
        }




        doc.setFontSize(13);
        doc.text("Lista de Vendas Autorizadas", 320, doc.lastAutoTable.finalY + 15);
        doc.autoTable(list_of_authorized_sales_columns, listItem, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: doc.lastAutoTable.finalY + 20,
          theme: "grid",
        });

        
  
        const pages = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
        for (let j = 1; j < pages + 1; j++) {
          doc.setLineWidth(1);
          doc.rect(20, 20, 800, 555);
          doc.setPage(j);
          doc.text(`${j} / ${pages}`, 795, 35);
        }
  
        doc.output("dataurlnewwindow");
      },
  
      async GetAllNoPage() {
        let value = {
          page: null,
          limit: null,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      },
    },
  
    computed: {
      ...mapGetters(["getEnterprises"]),
      ...mapGetters({ userLogin: "auth/userLogin" }),
    },
  
    created() {
      this.GetAllNoPage();
      this.userName = this.userLogin.user.name;
    },
  
    watch: {
      getEnterprises(val) {
        this.enterprise = Object.keys(val).map((key) => val[key]);
        let aux = Object.assign([], this.enterprise[0]);
        this.report.selectValue = aux;
      },
  
    },
  };
  </script>
  