



const headers = [
    {
        text: 'Chave de Acesso da NFE',
        align: 'start',
        sortable: false,
        value: 'x1',
    },
    {
        text: 'Prot.Autorizacao de uso',
        align: 'start',
        sortable: false,
        value: 'x2',
    },
    {
        text: 'Número da nota',
        align: 'start',
        sortable: false,
        value: 'x3',
    },
    {
        text: 'Data de emissão',
        align: 'start',
        sortable: false,
        value: 'x4',
    },
    {
        text: 'Cnpj emitente',
        align: 'start',
        sortable: false,
        value: 'x5',
    },
    {
        text: 'Último Nsu',
        align: 'start',
        sortable: false,
        value: 'x6',
    },
    {
        text: 'Manifestação',
        align: 'start',
        sortable: false,
        value: 'x7',
    },
    {
        text: 'Data de Entrada',
        align: 'start',
        sortable: false,
        value: 'x8',
    },
    {
        text: 'Ações',
        align: 'start',
        sortable: false,
        value: 'actions',
    },
]


const mockData = [
    {
        x1: 'Chave de Acesso da NFE',
        x2: 'Prot.Autorizacao de uso',
        x3: 'Número da nota',
        x4: 'Data de emissão',
        x5: 'Cnpj emitente',
        x6: 'Último Nsu',
        x7: 'Manifestação',
        x8: 'Data de Entrada',
        x10: 'Ações',
    }
]

export {
    headers,
    mockData
}