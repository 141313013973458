<template>
  <v-form  ref="form" v-model="component.valid"  @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Produto Inicial" 
          v-model="component.report.codProductInitial"
          type="number"
          :rules="[
                  () => !!component.report.codProductInitial || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Produto Final"
          type="number" 
          v-model="component.report.codProductFinal"
          :rules="[
                  () => !!component.report.codProductFinal || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Grupo de Produto" 
          v-model="component.report.product_group_id" 
          :items="component.productGroup" 
          item-text="name" 
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Sub Grupo de Produtos" 
          v-model="component.report.sub_group_product_id" 
          :items="component.subproductGroup" 
          item-text="name" 
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Tipo de Produto" 
          :items="typeproduct" 
          v-model="component.report.product_type_id"
          item-text="text"
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="3" md="3" xl="3" lg="3">
        <v-select outlined label="Quantidade em Estoque" :items="options" v-model="quantityOption"></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" xl="3" lg="3">
        <v-text-field outlined label=">=" v-show="aux1" v-model="component.report.currentbalance_max"></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" xl="3" lg="3">
        <v-text-field outlined label="<=" v-show="aux2" v-model="component.report.currentbalance_min"></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" xl="3" lg="3">
        <v-text-field outlined label="=" v-show="aux3" v-model="component.report.currentbalance_equal"></v-text-field>
      </v-col>
    </v-row>  
  </v-form>
</template>

<script>
export default {
  props:{
    component: Object
  },

  data() {
    return {
      typeproduct: [ 
        { text: "Revenda", value: 1},
        { text: "Insumo", value: 2},
        { text: "Patrimônio", value: 3},
        { text: "Consumo", value: 4}
      ],
      options: ["Maior ou igual a >=", "Menor ou igual a <=", "Intervalo <>", "Igual a ="],
      quantityOption: "",
      aux1: false,
      aux2: false,
      aux3: false
    }
  },

  methods:{
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  watch:{
    quantityOption(val){
      this.aux1 = false;
      this.aux2 = false;
      this.aux3 = false;
      this.component.report.currentbalance_max = null;
      this.component.report.currentbalance_min = null;
      this.component.report.currentbalance_equal = null;
      if(val == "Maior ou igual a >="){
        this.aux1 = true;
      }else if(val == "Menor ou igual a <="){
        this.aux2 = true;
      }else if(val == "Intervalo <>"){
        this.aux1 = this.aux2 = true; 
      }else if(val == "Igual a ="){
        this.aux3 = true;
      }
    }
  }
};
</script>

<style>
</style>