<template>
  <v-col>
    <v-row>
      <Header />
    </v-row>
    <v-row class="ma-5" >
      <Body />
    </v-row>
  </v-col>
</template>

<script>
import Header from "../../../components/shortcuts_reports/header";
import Body from "../../../components/shortcuts_reports/body";
export default {
  components: {
    Header,
    Body
  }
};
</script>

<style>
</style>