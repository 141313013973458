


var Watches = {
    getListXML: function (val) {
        this.itemsXML = val;
    },
}


export default Watches