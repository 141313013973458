import { key_errors_field, key_errors_message } from "./keys/"

export default class processMessage {
    data = Array()
    preProcessing = Array()
    key = key_errors_field
    errorKeys = key_errors_message

    static toProcessMessage(json) {
        const inst = new processMessage()
        inst.preProcessing = json


        return inst;

    }
    build() {

        this.preProcessing.forEach((e) => {

            let field = this.key[e.field] || `Valor :${e.field} desconhecido`
            let value = e.value.map(i => {

                let key = i.split('|')[0]
                return this.errorKeys[key] && this.errorKeys[key](i) || `Valor :${key} desconhecido`
            })

            this.data.push({
                value,
                field
            })



        })

        return this.data
    }
}