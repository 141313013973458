<template>
  <v-row justify="center">
    <v-dialog
      v-model="component.dialogModalSearchProduct"
      persistent
      max-width="70%"
    >
      <v-card>
        <v-card-title class="headline"> Pesquisar Produto </v-card-title>
        <v-data-table
          :headers="headers"
          :items="results"
          dense
          @click:row="handleClick"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="yellow darken-4"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    () => {
                      change_addDialog(item);
                    }
                  "
                  >mdi-plus-circle</v-icon
                >
              </template>
              <span>Adicionar Produto</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <div>
          <v-pagination
            color="success"
            v-model="current"
            :length="total"
            :total-visible="10"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="component.dialogModalSearchProduct = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_PRODUCT } from "../../../../../store/actions/product";

export default {
  data: () => ({
    itemSelectedOnClick: {},
    productSelectAdd: Object(),
    current: Number(),
    total: Number(),
    results: Array(),
    headers: [
      { text: "Codigo", value: "id" },
      { text: "Descrição", value: "description_item" },
      { text: "Referência", value: "reference" },
      { text: "Unidade", value: "unit.sigla" },
      { text: "Preço de Venda", value: "sale_price" },
    ],
  }),
  props: {
    component: Object,
    changeDialog: Function,
  },
  methods: {
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    async GetProduct(page = 1) {
      await this.$store.dispatch(GET_ALL_PRODUCT, {
        page: page,
        limit: 10,
        paginate: true,
      });
    },
  },

  async created() {
    await this.GetProduct();
  },

  computed: {
    ...mapGetters(["getProduct"]),
  },
  watch: {
    getProduct: function (val) {
      this.results = val.data;
      this.current = val.current_page;
      this.total = val.last_page;
    },
    current: function (val) {
      this.GetProduct && this.GetProduct(val);
    },
    itemSelectedOnClick: function (val) {
      this.component.description = { ...val };
      this.changeDialog();
    },
  },
};
</script>

<style></style>
