const ADD_CASHIER_OPERATION_TYPE = "ADD_CASHIER_OPERATION_TYPE";
const UPDATE_CASHIER_OPERATION_TYPE = "UPDATE_CASHIER_OPERATION_TYPE";
const GET_ALL_CASHIER_OPERATION_TYPE = "GET_ALL_CASHIER_OPERATION_TYPE";
const DESTROY_CASHIER_OPERATION_TYPE = "DESTROY_CASHIER_OPERATION_TYPE";
const GET_FILTER_CASHIER_OPERATION_TYPE = "GET_FILTER_CASHIER_OPERATION_TYPE";
const AUX_TO_UPDATE_CASHIER_OPERATION_TYPE = "AUX_TO_UPDATE_CASHIER_OPERATION_TYPE";

module.exports = {
  ADD_CASHIER_OPERATION_TYPE,
  UPDATE_CASHIER_OPERATION_TYPE,
  GET_ALL_CASHIER_OPERATION_TYPE,
  DESTROY_CASHIER_OPERATION_TYPE,
  GET_FILTER_CASHIER_OPERATION_TYPE,
  AUX_TO_UPDATE_CASHIER_OPERATION_TYPE,
};
