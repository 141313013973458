let dataValuesSalesWithFormOfPayments = {
    series: [],
    chartOptions: {
        title: {
            text: 'Vendas por Forma de Pagamento',
            align: 'center',
            floating: false
        },
        chart: {
            type: 'pie',
            width: 450
        },
        labels: [],

        responsive: [{
            //breakpoint: 480,
            options: {
                chart: {
                    //width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    },
}
let modelValuesSalesWithFormOfPaymentsModel = {
    series: [],
    chartOptions: {
        chart: {
            type: 'pie',
            width: 450
        },
        labels: [],
        responsive: [{
            // breakpoint: 480,
            options: {
                chart: {
                    // width: 600
                    // height: 350,
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    },

}


export {
    dataValuesSalesWithFormOfPayments,
    modelValuesSalesWithFormOfPaymentsModel
}