async function calculeIcmsIn(productInvoiceEntry, cfop_entry) {
  try {
    let productTributeIcms = await rangeCfopCalcuteIcmsRegimeNormal(
      cfop_entry,
      productInvoiceEntry
    );
    return productTributeIcms;
  } catch (error) {
    return error;
  }
}

async function rangeCfopCalcuteIcmsRegimeNormal(
  cfop_entry,
  produtInvoiceEntry
) {
  
  try {
    if (cfop_entry >= 1100 && cfop_entry <= 1359) {
       
      return await calculeIcmsNormalOperationOutPutInternal(produtInvoiceEntry);
    } else if (cfop_entry >= 1400 && cfop_entry <= 1415)
      return await calculeIcmsSubstituicaotributeOutPutOperationOutPutInternal(
        produtInvoiceEntry
      );
    else if (cfop_entry >= 2100 && cfop_entry <= 2359)
      return await calculeIcmsNormalOperationOutPutState(produtInvoiceEntry);
    else if (cfop_entry >= 2400 && cfop_entry <= 2415)
      return await calculeIcmsSubstituicaoTributariaOutPutSate(
        produtInvoiceEntry
      );
    throw "Cfop informado não é referente a entrada!";
  } catch (error) {
    return error;
  }
}

async function calculeIcmsNormalOperationOutPutInternal(productInvoiceEntry) {
   
  productInvoiceEntry.value_sub_total_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_base_icms_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_accounting_product =
    productInvoiceEntry.value_sub_total_product;
  productInvoiceEntry.value_icms_product =
    productInvoiceEntry.value_base_icms_product *
    (productInvoiceEntry.aliquot_icms_product / 100);
  productInvoiceEntry.value_free_icms_product = 0;
  productInvoiceEntry.value_icms_outher_product = 0;
  productInvoiceEntry.value_base_icms_st_product = 0;
  productInvoiceEntry.value_icms_st_product = 0;

  return productInvoiceEntry;
}

async function calculeIcmsSubstituicaotributeOutPutOperationOutPutInternal(
  productInvoiceEntry
) {
  productInvoiceEntry.value_sub_total_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_base_icms_product = 0;
  productInvoiceEntry.aliquot_icms_product = 0;
  productInvoiceEntry.value_accounting_product =
    productInvoiceEntry.value_sub_total_product;
  productInvoiceEntry.value_icms_product = 0;
  productInvoiceEntry.value_free_icms_product = 0;
  productInvoiceEntry.value_icms_outher_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_base_icms_st_product = 0;
  productInvoiceEntry.value_icms_st_product = 0;
  return productInvoiceEntry;
}

async function calculeIcmsNormalOperationOutPutState(productInvoiceEntry) {
  productInvoiceEntry.value_sub_total_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_base_icms_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_accounting_product =
    productInvoiceEntry.value_sub_total_product;
  productInvoiceEntry.value_icms_product =
    productInvoiceEntry.value_base_icms_product *
    (productInvoiceEntry.aliquot_icms_product / 100);
  productInvoiceEntry.value_free_icms_product = 0;
  productInvoiceEntry.value_icms_outher_product = 0;
  productInvoiceEntry.value_base_icms_st_product = 0;
  productInvoiceEntry.value_icms_st_product = 0;

  return productInvoiceEntry;
}

async function calculeIcmsSubstituicaoTributariaOutPutSate(
  productInvoiceEntry
) {
  productInvoiceEntry.value_sub_total_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_base_icms_product = 0;
  productInvoiceEntry.aliquot_icms_product = 0;
  productInvoiceEntry.value_accounting_product =
    productInvoiceEntry.value_sub_total_product;
  productInvoiceEntry.value_icms_product = 0;
  productInvoiceEntry.value_free_icms_product = 0;
  productInvoiceEntry.value_icms_outher_product =
    productInvoiceEntry.amount * productInvoiceEntry.cust;
  productInvoiceEntry.value_base_icms_st_product = 0;
  productInvoiceEntry.value_icms_st_product = 0;
  return productInvoiceEntry;
}

module.exports = {
  calculeIcmsIn,
};
