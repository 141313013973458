<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="changeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }} Nota Fiscal</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <!-- <v-form ref="form" v-model="valid" @submit.prevent="submit"> -->
          <v-divider></v-divider>

          <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
            <v-tab
              v-for="(item, index) in newArrayItems"
              :key="index"
              :href="'#tab-' + item.href"
            >
              {{ item.tabName }}
            </v-tab>

            <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="align-self-center mr-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  more
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                  v-for="(item, index) in more"
                  :key="index"
                  @click="addItem(item)"
                >
                  {{ item.tabName }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>

          <v-tabs-items v-model="currentItem">
            <v-tab-item value="tab-1">
              <v-col>
                <GenerateDate
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-col>
                <NoteItems
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-col>
                <Transport
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <v-col>
                <InformationNote
                  :component="this"
                  :isDisable="disabled"
                  :eager="true"
                />
              </v-col>
            </v-tab-item>
          </v-tabs-items>

          <!-- <v-tabs color="primary" v-model="tabs">
            <v-tab>
              <v-icon class="mr-2">mdi-file-document</v-icon>Dados Gerais
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">mdi-file-find</v-icon>Itens da Nota
            </v-tab>
            <v-tab>
              <v-icon class="mr-2"> mdi-truck </v-icon> Transportadora
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">mdi-information</v-icon> Informações da Nota
            </v-tab>

            <v-tab-item
              ><GenerateDate
                :component="this"
                :isDisable="disabled"
                :eager="true"
            /></v-tab-item>
            <v-tab-item
              ><NoteItems :component="this" :isDisable="disabled" :eager="true"
            /></v-tab-item>
            <v-tab-item>
              <Transport
                :component="this"
                :isDisable="disabled"
                :eager="true"
              />
            </v-tab-item>
            <v-tab-item>
              <InformationNote
                :component="this"
                :isDisable="disabled"
                :eager="true"
              />
            </v-tab-item>

            <v-spacer></v-spacer>
            <v-btn
              @click="call_Dialog_Import_XML()"
              class="mt-2 mr-3 success"
              v-show="!disabled"
            >
              <v-icon class="mr-1">mdi-file-import-outline</v-icon>Importar XML
            </v-btn>
          </v-tabs> -->
          <v-divider></v-divider>
          <v-card-actions v-show="!disabled">
            <v-spacer></v-spacer>
            <v-btn color="error">
              <v-icon class="mr-1">mdi-cancel</v-icon>
              Limpar Formulário
            </v-btn>
            <v-btn
              color="success"
              class="mr-4"
              @click="change_confirm()"
              :disabled="!valid"
            >
              <v-icon class="mr-1"> mdi-content-save </v-icon>
              Faturas
            </v-btn>
          </v-card-actions>
          <!-- </v-form> -->
        </v-container>
      </v-card>
    </v-dialog>
    <ConfirmNote
      v-if="confirmDialog"
      :component="this"
      :paymentTerms="getPaymentTerms"
      :formPayment="getFormOfPayment"
      :accountPlan="getAccount_Plan"
      :totalNF="parseFloat(invoice.value_total_note)"
      :changeDialog="changeDialog"
    />
    <LoadLayer />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import InformationNote from "../Tabs/TabInfoNote/";
import GenerateDate from "../Tabs/TabGenerateDate/";
import NoteItems from "../Tabs/TabNoteItens/";
import Transport from "../Tabs/TabTransport/";
import ConfirmNote from "../AddDialog/ConfirmNote/index";
import LoadLayer from "../../load/index.vue";
import { adjust_variables } from "../script/adjust_variables/";
import business_rule from "../script/business_rule/";
import { GET_ALL_PROVIDER } from "../../../store/actions/provider";
import { GET_ALL_TRANSPORTER_TO_SELECT_FIELD } from "../../../store/actions/transporters";
import { DESTROY_NOTE_ENTRY_ITENS } from "../../../store/actions/note_entry_itens";

export default {
  props: {
    component: Object,
    title: String,
    disabled: Boolean,
    dialog: Boolean,
    changeDialog: Function,
    enterpriseId: Number,
    objectToView: Object,
  },
  components: {
    InformationNote,
    GenerateDate,
    NoteItems,
    Transport,
    ConfirmNote,
    LoadLayer,
  },
  data: () => ({
    newArrayItems: Array(),
    currentItem: "tab-1",
    items: [
      {
        tabName: "Dados Gerais",
        href: "1",
      },
    ],
    more: [
      {
        tabName: "Itens da Nota",
        href: "2",
      },
      {
        tabName: "Transportadora",
        href: "3",
      },
      {
        tabName: "Informações da Nota",
        href: "4",
      },
    ],
    infNFe: {},
    addDialogImportXML: false,
    tabs: Number(),
    select_provider: Number(),
    valid: false,
    validGenerateDate: false,
    validNoteItems: false,
    providers: Array(),
    cst: Array,
    csosn: Array,
    dialogConfirm: Boolean(),
    confirmDialog: false,
    provider_id: Number,
    contabil_value: Number(),
    aliquot: 1,
    provider_name: String(),
    invoice: {
      //Dados Gerais
      tax_operation_types_id: Number(),
      access_key: String(),
      authorization_protocol: String(),
      provider_id: Number(),
      number_grade: Number(),
      series: String(),
      date_of_issue: String(),
      entry_date: String(),

      //Itens da Nota
      gradeItens: Array(),

      //Cabeçalho da Nota
      base_calc_icms_total: Number(),
      value_icms_total: Number(),
      base_calc_susbt_total: Number(),
      value_icms_substituicao_total: Number(),
      base_calc_ipi_total: Number(),
      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      value_total_products: Number(),
      value_discont: Number(),
      type_freigth: String,
      value_freigth: Number(),
      value_security: Number(),
      value_icms_free_total: Number(),
      value_total_note: Number(),
      value_untaxed_tax_total: Number(),
      value_account_total: Number(),
      value_outher_tax_total: Number(),
      value_other_incidental_expenses: Number(),
      value_icms_outher_product: Number(),
      value_base_icms_st_product: Number(),

      //Transportadora
      transporter_id: null,
      vehicle_plate: String(),
      volume_transported: Number(),
      gross_weigth: Number(),
      net_weight: Number(),
      volume_type: String(),
      outher_expenses_acess: Number(),

      status: String(),
      note: String(),
      account_plans_id: Number(),
      enterprise: Object(),
      enterprise_id: Number(),
    },
  }),
  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    call_Dialog_Import_XML() {
      this.addDialogImportXML = !this.addDialogImportXML;
    },
    adjust_variables,
    ...business_rule,

    change_confirm() {
      if (this.getNoteEntryItens.length == 0) {
        alert("Adicione um item a nota!");
      } else {
        this.invoice.gradeItens = this.getNoteEntryItens;
        this.invoice.status = "CONFIRMADA";
        this.confirmDialog = !this.confirmDialog;
      }
    },

    getComponent() {
      return this.component;
    },

    refresh_discount() {
      this.calculate_all();
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
        type_people: null,
      };

      await this.$store.dispatch(GET_ALL_TRANSPORTER_TO_SELECT_FIELD, value);
    },

    async GetProviders(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PROVIDER, {
        value,
        type_people: "PhysicalPeople",
        limit: 10,
        paginate: true,
      });
      await this.$store.dispatch(GET_ALL_PROVIDER, {
        page: null,
        type_people: "LegalPeople",
        limit: null,
        paginate: false,
      });
    },

    refresh_dates() {
      let array = this.getPhisycalProvider;
      let legal = this.getLegalProvider;
      this.cst = Object.keys(this.getCst).map((key) => this.getCst[key]);
      this.csosn = Object.keys(this.getCsosn).map((key) => this.getCsosn[key]);

      legal = this.getLegalProvider.map((e, i) => {
        legal[i].name = e.fantasyName;
      });

      this.providers = array.concat(this.getLegalProvider);
    },

    clearItens() {
      this.count = 0;
      try {
        this.$store.dispatch(DESTROY_NOTE_ENTRY_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },

    mountedObjectView() {
      let item = this.component.info_itens;
      
      this.invoice.tax_operation_types_id = item.tax_operation_types_id;
      this.invoice.access_key = item.access_key;
      this.invoice.authorization_protocol = item.authorization_protocol;
      this.invoice.number_grade = item.number_grade;
      this.invoice.series = item.series;
      this.invoice.date_of_issue = item.date_of_issue
        .split("/")
        .reverse()
        .join("-");
      this.invoice.entry_date = item.entry_date.split("/").reverse().join("-");

      this.invoice.base_calc_icms_total = item.base_calc_icms_total;
      this.invoice.value_icms_total = item.value_icms_total;
      this.invoice.base_calc_susbt_total = item.base_calc_susbt_total;
      this.invoice.value_icms_substituicao_total =
        item.value_icms_substituicao_total;
      this.invoice.base_calc_ipi_total = item.base_calc_ipi_total;
      this.invoice.value_ipi_total = item.value_ipi_total;
      this.invoice.value_ipi_outher_total = item.value_ipi_outher_total;
      this.invoice.value_ipi_free_total = item.value_ipi_free_total;
      this.invoice.value_total_products = item.value_total_products;
      this.invoice.value_book = item.value_book;
      this.invoice.value_discont = item.value_discont;

      this.invoice.value_freigth = item.value_freigth;
      this.invoice.value_security = item.value_security;
      this.invoice.value_icms_free_total = item.value_icms_free_total;
      this.invoice.value_total_note = item.value_total_note;
      this.invoice.value_untaxed_tax_total = item.value_untaxed_tax_total;
      this.invoice.value_account_total = item.value_account_total;
      this.invoice.value_outher_tax_total = item.value_outher_tax_total;
      this.invoice.note = item.note;
      this.select_provider = item.provider_id;
      this.provider_name = this.component.info_itens.provider.people.legalpeople.socialName
      this.invoice.vehicle_plate = item.vehicle_plate;
      this.invoice.volume_transported = item.volume_transported;
      this.invoice.gross_weigth = item.gross_weigth;
      this.invoice.net_weight = item.net_weight;
      this.invoice.volume_type = item.volume_type;
      this.invoice.outher_expenses_acess = item.outher_expenses_acess;

      this.getTransporterAuto.forEach((e) => {
        if (e.id == item.transporter_id) {
          if (e.type_people == "LegalPeople") {
            this.invoice.transporter_id = e.people.legalpeople.fantasyName;
          } else {
            this.invoice.transporter_id = e.people.physicalpeople.name;
          }
        }
      });
    },
  },

  created() {
    this.setArrayItens()
    this.GetAllNoPage();
    this.refresh_dates();

    let isNew = 1;
    let isUpdate_View = 2;

    // checkinfo e utilizado para saber se a rotina e chamada para adicionar um item novo ou para editar(recebe 1 se vier do NoteEntry e 2 se vier do Table)
    if (this.component.checkinfo == isNew) {
      this.invoice.enterprise_id = this.enterpriseId;
    } else if (this.component.checkinfo == isUpdate_View) {
      this.mountedObjectView();
    }
  },
  destroyed() {
    this.clearItens();
  },

  computed: {
    ...mapGetters([
      "getTaxOperationType",
      "getPhisycalProvider",
      "getLegalProvider",
      "getCsosn",
      "getCst",
      "getNoteEntryItens",
      "getFormOfPayment",
      "getPaymentTerms",
      "getAccount_Plan",
      "getTransporterAuto",
    ]),
  },

  watch: {
    "$vuetify.breakpoint.xsOnly": function(){
      this.setArrayItens()
    },
    infNFe: function () {
      this.infNFe = this.infNFe;
    },
    getNoteEntryItens: function (val) {
      if (val[0]) {
        this.aliquot = val[0].aliquot;
      }

      this.calculate_all();
    },

    select_provider: function (val) {
      this.invoice.provider_id = val.provider_id;
    },

    validGenerateDate: function (val) {
      if(val && this.validNoteItems) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validNoteItems: function (val) {
      if(this.validGenerateDate && val) {
        this.valid = true
      } else {
        this.valid = false
      }
    },

  },
};
</script>

<style>
</style>