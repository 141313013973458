import axios from "axios";
const {
  ADD_PF_PROVIDER,
  ADD_PJ_PROVIDER,
  UPDATE_PF_PROVIDER,
  UPDATE_PJ_PROVIDER,
  GET_ALL_PROVIDER,
  DESTROY_PROVIDER,
  GET_AUTOCOMPLETE_PROVIDER,
  ITEM_TO_UPDATE,
  AUTO_RELOAD_PROVIDER
} = require("./actions/provider");
const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleProvider = {
  state: () => ({
    itemToUpdate: Object(),
    PhisycalProvider: Array(),
    LegalProvider: Array(),
    legalAutocomplete: Array(),
    physicalAutocomplete: Array(),
    newProviderToAdd: Object(),
    autoReload: Object(),
  }),

  mutations: {
    ITEM_TO_UPDATE: function (state, payload) {
      state.itemToUpdate = payload;
    },
    GET_ALL_PROVIDER: (state, payload) => {
      let Infos = Object()
      let contacts = Object()
      let address = Object()
      let banks = Object()
      let relatedRepresentative = String()
      let physicalpeople = {};
      let legalpeople = {};
      let type = String()
      var indx = 0
      let auxPayload = { ...payload }
      delete auxPayload.data

      if (payload.type === "PhysicalPeople") {
        while (state.PhisycalProvider.length) {
          state.PhisycalProvider.pop()
        }
      } else {
        while (state.LegalProvider.length) {
          state.LegalProvider.pop()
        }
      }
      if(payload.data){
        payload.data.forEach((e) => {
          if (e.type_people === "PhysicalPeople") {
            Infos = {}
            contacts = {}
            address = {}
            banks = {}
            relatedRepresentative = e.relatedRepresentative
            type = e.type
  
            physicalpeople = { ...e.people.physicalpeople }
            contacts = e.people.contact
            address = e.people.address
            banks = e.people.bank_data
  
            Object.assign(Infos, { relatedRepresentative })
            Object.assign(Infos, { type })
            Object.assign(Infos, { contacts })
            Object.assign(Infos, { address })
            Object.assign(Infos, { banks })
            Object.assign(Infos, { physicalpeople });
            Infos.type_people = e.type_people
            Infos.enterprise_id = e.enterprise_id
            Infos.page = { ...auxPayload }
            Infos.id = e.id
  
            indx = state.PhisycalProvider.find((e) => e.id === Infos.id)
            if (indx === undefined) {
              state.PhisycalProvider.push(Infos)
            }
          } else if (e.type_people === "LegalPeople") {
            Infos = {}
            contacts = {}
            address = {}
            banks = {}
            relatedRepresentative = e.relatedRepresentative
            type = e.type
  
            legalpeople = { ...e.people.legalpeople }
            contacts = e.people.contact
            address = e.people.address
            banks = e.people.bank_data
  
            Object.assign(Infos, { relatedRepresentative })
            Object.assign(Infos, { type })
            Object.assign(Infos, { contacts })
            Object.assign(Infos, { address })
            Object.assign(Infos, { banks })
            Object.assign(Infos, { legalpeople });
            Infos.type_people = e.type_people
            Infos.enterprise_id = e.enterprise_id
            Infos.page = { ...auxPayload }
            Infos.id = e.id
  
            indx = state.LegalProvider.find((e) => e.id === Infos.id)
            if (indx === undefined) {
              state.LegalProvider.push(Infos)
            }
          }
        })
        
      }
    },
    GET_AUTOCOMPLETE_PROVIDER: (state, payload) => {
      if (payload.type_people === "PhysicalPeople") {

        state.physicalAutocomplete = payload[0];
      } else{
        state.legalAutocomplete = payload[0];
      }
    },

    ADD_PF_PROVIDER: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
     // let relatedRepresentative = ""
    //  let type = ""
      let physicalpeople = {};

      physicalpeople = { ...payload.people.physicalpeople }
      contacts = payload.people.contact
      address = payload.people.address
      banks = payload.people.bank_data

      Object.assign(Infos, { contacts })
      Object.assign(Infos, { address })
      Object.assign(Infos, { banks })
      Object.assign(Infos,{physicalpeople})

      Infos.type_people = payload.type_people
      Infos.enterprise_id = payload.enterprise_id
      Infos.id = payload.id
      Infos.type  =payload.type
      Infos.relatedRepresentative =payload.relatedRepresentative


      // state.PhisycalProvider.push(Infos)
      state.newProviderToAdd = Infos
    },
    ADD_PJ_PROVIDER: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let legalpeople = {};

      legalpeople = { ...payload.people.legalpeople }
      contacts = payload.people.contact
      address = payload.people.address
      banks = payload.people.bank_data


      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks })
      Object.assign(Infos, {legalpeople})
      Infos.type_people = payload.type_people
      Infos.enterprise_id = payload.enterprise_id
      Infos.id = payload.id
      Infos.type  =payload.type
      Infos.relatedRepresentative =payload.relatedRepresentative

      // state.LegalProvider.push(Infos)
      state.newProviderToAdd = Infos

    },

    UPDATE_PF_PROVIDER(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople



      physicalpeople = { ...payload.people.physicalpeople }
      contacts = payload.people.contact
      address = payload.people.address
      banks = payload.people.bank_data


      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks })
      Object.assign(Infos,{physicalpeople})
      Infos.type_people = payload.type_people
      Infos.enterprise_id = payload.enterprise_id
      Infos.id = payload.id
      Infos.type  =payload.type
      Infos.relatedRepresentative =payload.relatedRepresentative

      state.PhisycalProvider.Update(Infos)

    },

    UPDATE_PJ_PROVIDER(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let legalpeople




      legalpeople = { ...payload.people.legalpeople }
      contacts = payload.people.contact
      address = payload.people.address
      banks = payload.people.bank_data



      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks })
      Object.assign(Infos, {legalpeople})
      
      Infos.type_people = payload.type_people
      Infos.enterprise_id = payload.enterprise_id
      Infos.id = payload.id
      Infos.type  =payload.type
      Infos.relatedRepresentative =payload.relatedRepresentative

      state.LegalProvider.Update(Infos)

    },
    DESTROY_PROVIDER: (state, payload) => {
      let index = -1
      if (payload.type_people === "PhysicalPeople") {
        let statePhisycal = state.PhisycalProvider
        index = state.PhisycalProvider.findIndex(value => value.id === payload.id)
        if (index >= 0) {
          statePhisycal.splice(index, 1)
          state.PhisycalProvider = statePhisycal
        }
      } else if (payload.type_people === "LegalPeople") {
        let stateLegal = state.LegalProvider
        index = state.LegalProvider.findIndex(value => value.id === payload.id)
        if (index >= 0) {
          stateLegal.splice(index, 1)
          state.LegalProvider = stateLegal
        }
      }
    },
    AUTO_RELOAD_PROVIDER: function (state, payload) {
      state.autoReload = payload
    }
  },
  actions: {
    AUTO_RELOAD_PROVIDER: function (context, payload) {
      context.commit(AUTO_RELOAD_PROVIDER, payload)
    },
    ITEM_TO_UPDATE: function (context, payload) {
      context.commit(ITEM_TO_UPDATE, payload)
    },

    async GET_AUTOCOMPLETE_PROVIDER({ commit }, payload) {
      try {
        const response = await axios.post("provider/autocomplete", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          search: payload.search,
          enterprise_id: payload.enterprise_id,
        })
        response.data[0].type = payload.type_people
        commit(GET_AUTOCOMPLETE_PROVIDER, response.data);
        return response.data[0]
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: "Erro ao Pesquisar Fornecedor",
          type: "E"
        });
      }

    },
    async GET_ALL_PROVIDER({
      commit
    }, payload) {
      try {
        const response = await axios.post("provider/index", {
          page: payload.page, limit: payload.limit, paginate: payload.paginate,
          type_people: payload.type_people
        })
        response.data[0].type = payload.type_people
        commit(GET_ALL_PROVIDER, response.data[0]);
        return response.data[0]
        
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: "Cadastrado com Sucesso!",
        });
      }

    },

    ADD_PF_PROVIDER({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("provider/createphysicalpeople", payload).then((response) => {
          resolve()
          response.data[0].enterprise_id = payload.enterprise_id
          commit(ADD_PF_PROVIDER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com Sucesso!",
          });
        }).catch(() => {
          reject()
        })

      })
    },


    ADD_PJ_PROVIDER({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("provider/createlegalPeople", payload).then((response) => {
          resolve()
          response.data[0].enterprise_id = payload.enterprise_id
          commit(ADD_PJ_PROVIDER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com Sucesso!",
          });
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_PF_PROVIDER({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("provider/updatephysicalpeople", payload).then((response) => {
          resolve()
          response.data[0].enterprise_id = payload.enterprise_id
          commit(UPDATE_PF_PROVIDER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Fornecedor atualizado com Sucesso!",
            type: "S"
          });
        }).catch(() => {
          reject()
        })

      })

    },

    UPDATE_PJ_PROVIDER({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("provider/updatelegalpeople", payload).then((response) => {
          resolve()
          response.data[0].enterprise_id = payload.enterprise_id
          commit(UPDATE_PJ_PROVIDER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Fornecedor atualizado com Sucesso!",
            type: "S"
          });
        }).catch(() => {
          reject()
        })

      })

    },

    DESTROY_PROVIDER({
      commit
    }, payload) {
      axios
        .post("provider/delete", {
          id: payload.id,
          enterprise_id: payload.enterprise_id
        })
        .then((response) => {
          response,
            commit(DESTROY_PROVIDER, payload);
          commit(SPLICE_ERRO, {
            alert: "Deletado com sucesso!",
          });
        })
    },
  },

  getters: {
    getItemToUpdateProvider:(state)=> state.itemToUpdate,
    getPhisycalProvider: (state) => state.PhisycalProvider,
    getLegalProvider: (state) => state.LegalProvider,
    getProviderPhysicalAuto: (state) => state.physicalAutocomplete,
    getProviderLegalAuto: (state) => state.legalAutocomplete,
    getNewProviderToAdd: (state) => state.newProviderToAdd,
    getAutoReload: (state) =>  state.autoReload
    
  },
};

export default moduleProvider;