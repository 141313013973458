<template>
  <div class="cardtitle">
    <v-card-title primary-title>Entrada de Notas via XML</v-card-title>
  </div>
</template>

<script>
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE
} from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object()
  },
  computed: {
    ...mapGetters(["getEnterprises"])
  },
  data: function () {
    return {
      listEnterprise: Array(),
      selectedEnterprise: Number()
    };
  },
  methods: {
    callAddDialog() {
      this.$store.dispatch("setShowDialogRegisterImportXml", true);
    },
    getEnterprise(page = 1) {
      let payload = {
        page: page,
        limit: 10,
        paginate: true
      };
      this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    }
  },
  watch: {
    selectedEnterprise: function () {
      this.$store.dispatch(SELECTED_ENTERPRISE, this.selectedEnterprise);
    },
    getEnterprises: function (val) {
      if (val) {
        this.listEnterprise = val.data ? Object.values(val.data) : Object.values(val);
        this.selectedEnterprise = this.listEnterprise[0];
      }

    }
  },
  created() {
    this.getEnterprise();
  }
};
</script>

<style>
.cardtitle {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
</style>