<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-container>
          <v-card-title>Editar item da nota</v-card-title>
          <v-divider></v-divider>
          <v-form v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="component.product.amount_add"
                  label="Quantidade"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="component.product.value_sale"
                  label="Custo"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  prefix="R$"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.aliquotItems"
                  v-model="component.product.aliquot"
                  item-text="aliquot"
                  item-value="id"
                  return-object
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  label="Alíquota"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.cfopItems"
                  v-model="component.product.cfop_id"
                  item-value="id"
                  item-text="code"
                  return-object
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  label="CFOP"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-if="disabledField"
                  outlined
                  item-text="description"
                  item-value="id"
                  :items="component.csosnItems"
                  v-model="component.product.csosn_id"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  return-object
                  label="CSOSN"
                ></v-autocomplete>
                <v-autocomplete
                  v-else
                  outlined
                  item-text="description"
                  item-value="id"
                  :items="component.cstItems"
                  v-model="component.product.csosn_id"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  return-object
                  label="CST"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="IPI"
                  v-model="component.product.value_ipi_product"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.pis_cofis"
                  v-model="component.product.cst_pis_id"
                  item-value="id"
                  label="CST/PIS"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  return-object
                  item-text="description"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="component.pis_cofis"
                  v-model="component.product.cst_confis_id"
                  item-value="id"
                  label="CST/COFINS"
                  :rules="[(v) => !!v || 'Campo Obrigatorio']"
                  return-object
                  item-text="description"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="changeDialog" class="error">
            <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
          >
          <v-btn @click="confirm" class="success" :disabled="!valid"
            ><v-icon class="mr-1">mdi-content-save</v-icon> Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    disabledField: Boolean,
    changeDialog: Function,
    submit: Function,
    component: Object,
  },
  data: () => ({
    formEdit: {
      tableId: Number(),
      id: Number(),
      product_id: Number(),
      tax_operation_types_id: Number(),
      enterprise_id: Number(),
      amount: Number(),
      cust: Number(),
      aliquot: Number(),
      cfop: Object(),
      cst_csosn: Object(),
      ipi: Number(),
      cst_pis: Number(),
      cst_cofins: Number(),
    },
    valid: Boolean(),
  }),
  methods: {
    confirm() {
      this.submit(this.component.product);
    },
    refreshObject(item) {
      this.formEdit.id = item.id;
      this.formEdit.tableId = item.tableId;
      this.formEdit.product_id = item.product_id;
      this.formEdit.enterprise_id = item.enterprise_id;
      this.formEdit.amount = item.amount;
      this.formEdit.cust = item.produto.sale_price;
      this.formEdit.aliquot = item.produto.aliquot;
      this.formEdit.cfop = item.produto.cfop;
      this.formEdit.ipi = item.produto.ipi;
      this.formEdit.cst_pis = item.cst_pis;
      this.formEdit.cst_cofins = item.cst_confis;
      this.formEdit.tax_operation_types_id = item.tax_operation_types_id;
    },
    refreshObjectBeforeCreateItem(item) {
      this.formEdit.tableId = item.tableId;
      this.formEdit.id = item.id;
      this.formEdit.product_id = item.product_id;
      this.formEdit.enterprise_id = item.enterprise_id;
      this.formEdit.amount = item.amount;
      this.formEdit.cust = item.venda_sale;
      this.formEdit.aliquot = {
        id: item.aliquot_id,
        aliquot: item.aliquot_code,
      };
      this.formEdit.cfop = { id: item.cfop_id, code: item.cfop_code };
      this.formEdit.ipi = item.value_ipi_product;
      this.formEdit.cst_pis = item.cst_pis_id;
      this.formEdit.cst_cofins = item.cst_confis_id;
      this.formEdit.tax_operation_types_id = item.tax_operation_types_id;
    },
  },

  created() {
    if (this.component.ObjectToUpdate.produto === undefined) {
      this.refreshObjectBeforeCreateItem(this.component.ObjectToUpdate);
    } else {
      this.refreshObject(this.component.ObjectToUpdate);
    }
  },
};
</script>

<style>
</style>