



var type_input = [
    {
        text:"Entrada",
        value:"in"
    },
    {
        text:"Saída",
        value:"out"
    }
]

var typeBookSupervisorOut = [
    {
        text: "Faturamento",
        value:"BookSupervisionRevenues"
    },
    {
        text: "Vendas",
        value:"BookSupervisionSales"
    }
]



module.exports = {
    type_input,
    typeBookSupervisorOut
}