<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      @click:row="handleClick"
      no-data-text="Nenhum produto cadastrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              color="yellow darken-4"
              v-on="on"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              color="green darken-4"
              class="mr-2"
              v-if="component.permission.Excluir"
              @click="
                () => {
                  visibleItem(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Visualizar item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              color="red darken-4"
              v-if="component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div>
      <v-pagination
        class="mb-3"
        color="success"
        v-model="current"
        :length="total"
        :total-visible="10"
      ></v-pagination>
      <DestroyDialog :component="this" :destroy_object="destroy_object" />
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
import DestroyDialog from "../DestroyDialog/";
import { ITEM_UPDATE_PRODUCT } from "../../../store/actions/product";

export default {
  components: { DestroyDialog },
  props: {
    destroy_object: Function,
    getters: Object,
    component: Object,
    // change_addDialog: Function,
  },
  data: () => ({
    destroyDialog: false,
    itemSelectedOnClick: Object(),
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    headers,
    page: 1,
  }),
  methods: {
    visibleItem(item){
      this.$store.dispatch(ITEM_UPDATE_PRODUCT, {item: item, justVisible: true})
      this.$store.dispatch("setShowDialogRegisterProduct", true);
    },
    change_addDialog(item) {
      this.$store.dispatch("setShowDialogRegisterProduct", true);
      this.$store.dispatch(ITEM_UPDATE_PRODUCT, item);
    },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    created() {
      this.component.GetAll();
    },

    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.product_filter && this.component.product_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>

