import { headersTable } from "./headers"

var localState = {
    headersTable: headersTable,
    itemsXML: Array(),
}


export default localState


