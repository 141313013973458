<template>
    <v-form ref="form" v-model="component.validBankAccount">
      <v-row>
        <v-col>
          <v-autocomplete
            :items="listBanks"
            item-text="label"
            label="*Banco"
            :rules="textRules"
            v-model="component.banks.bank"
            :readonly="component.justVisible"
            outlined
            required
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="component.banks.count"
            :readonly="component.justVisible"
            :rules="textRules"
            label="*Conta"
            outlined
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="component.banks.agency"
            :readonly="component.justVisible"
            :rules="textRules"
            label="*Agência"
            outlined
            required
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.banks.manager"
            :readonly="component.justVisible"
            label="Gerente"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import { listBanks } from "./listBank";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: Boolean(),
    listBanks,
    textRules: [(v) => !!v || "Campo obrigatório!"],
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>