<template>
  <v-form ref="form" v-model="component.valid" @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          outlined
          label="Cod. Produto Inicial"
          type="number"
          v-model="component.report.codProductInitial"
          :rules="[
            () => !!component.report.codProductInitial || 'Campo Obrigatorio',
          ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          outlined
          label="Cod. Produto Final"
          type="number"
          v-model="component.report.codProductFinal"
          :rules="[
            () => !!component.report.codProductFinal || 'Campo Obrigatorio',
          ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data de Compra"
          type="date"
          v-model="component.report.dateBuy"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data de Promoção"
          type="date"
          v-model="component.report.datePromotion"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select
          outlined
          label="Grupo de Produto"
          v-model="component.report.groupProducts"
          :items="component.productGroup"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select
          outlined
          label="NCM"
          v-model="component.report.ncm"
          :items="component.ncm"
          item-text="code"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          outlined
          label="Estoque Minimo"
          v-model="component.report.moinimoStock"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          outlined
          label="Estoque Máximo"
          v-model="component.report.maximoStock"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object,
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>