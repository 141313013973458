<template>
  <div>
    <v-spacer />
    <v-text-field label="Pesquisar" outlined append-icon="mdi-magnify" />
    <v-divider />
    <v-data-table 
      :items="itemsExchange" 
      :headers="Header"
      no-data-text="Sem registro!"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="blue"
            class="mr-2"
            @click="
              () => {
                view_info(item);
              }
            "
            >mdi-information-outline</v-icon
          >
          <v-icon
          color="green darken-4"
          class="mr-2"
          @click="
            () => {
              print_item(item);
            }
          "
          >mdi-printer</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          @click="
            () => {
              cancelExchange(item);
            }
          "
          >mdi-cancel</v-icon
        >
        </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination 
      class="mb-1 mt-10"  
      v-model="paginationInformation.current_page" 
      :length="paginationInformation.last_page"
    > </v-pagination>
    <ViewInfo
      v-if="dialog"
      :component="this"
      :objectToView="objectToView"
    />
    <div v-show="false">
      <svg id="barcode"></svg>
    </div>
    <AddDialogCancel v-if="dialogCancel" :component="this" />
    <TaxOperationTypeDialog v-if="taxOperationTypeErrorDialog" :component="this" />
  </div>
</template>

<script>
import Header from "./headers";
import AddDialogCancel from "../AddDialogCancel/index.vue"
import TaxOperationTypeDialog from "../AddDialogCancel/ErrorDialog/index.vue"
import JsBarcode from "jsbarcode"
import generatePdf from "../AddDialog/table/generatePDF";
import { GET_ALL_EXCHANGE, VIEW_EXCHANGE, GET_ALL_TAX_OPERATION_TYPE } from "../../../store/actions/exchange_control";
import { mapGetters } from "vuex";

export default {
  components: {
    AddDialogCancel,
    TaxOperationTypeDialog,
  },

  props: {
    component: Object,
  },

  data: () => ({
    Header,
    dialog: Boolean(),
    itemsExchange: Array(),
    objectToView: Object(),
    paginationInformation: {
      current_page:0,
      last_page:0,
      total_page: 0 
    },
    dialogCancel: false,
    cancelExchangeData: Object(),
    taxOperationTypeValidation: false,
    taxOperationTypeErrorDialog: false,
    taxOperationToCancel: Object(),
  }),
  computed: {
    ...mapGetters(["getExchangeItems", "getExchangeToAdd", "getShowDialogRegisterExchangeControl"])
  },
  methods: {
    generatePdf,
    view_info(item) {
      this.$store.dispatch(VIEW_EXCHANGE, {item: item, justVisible: true});
      this.$store.dispatch("setShowDialogRegisterExchangeControl", true);
    },
    getAllExchange(page = 1) {
      const payload = {
        page,
        paginate: true,
        limit: 10,
        enterprise_id: this.component.selectedEnterprise.id
      };
      this.$store.dispatch(GET_ALL_EXCHANGE, payload).then((response) => {
        this.itemsExchange = response.data
        this.paginationInformation.current_page = response.current_page
        this.paginationInformation.last_page = response.last_page
        this.paginationInformation.total_page = response.total
      });
    },

    print_item(item) {
      JsBarcode("#barcode", item.number_exchange );
      const getElementDOM = document.getElementById('barcode')
      const tagHTML = getElementDOM.outerHTML
      item.exchange = Object()
      item.exchange.access_key = item.access_key
      item.exchange.total_credit= item.value_credit_icms_total
      this.generatePdf( this.component.selectedEnterprise, item.sale, item, item, tagHTML );
    },

    cancelExchange(item) {
      if(this.taxOperationTypeValidation) {
        this.cancelExchangeData = item
        this.dialogCancel = true
      } else {
        this.taxOperationTypeErrorDialog = true
      }
    },
    getAllTaxOperationType() {
      const payload = {
        paginate: false,
        page: null,
        limit: null
      }
      this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, payload).then((response) => {
        const taxOperationTypes = response[0]
        for(let i=0; i<taxOperationTypes.length; i++) {
          if(taxOperationTypes[i].code == 999) {
            this.taxOperationTypeValidation = true
            this.taxOperationToCancel = taxOperationTypes[i]
          }
        }
      });
    }
  },
  watch:{
    getExchangeToAdd: function(val){
      this.itemsExchange.push(val.data)
    },
    'paginationInformation.current_page': function(value){
      this.getAllExchange(value)
    },
    // getExchangeItems: function(val){
    //   this.itemsExchange = val.data
    //   this.paginationInformation.current_page = val.current_page
    //   this.paginationInformation.last_page = val.last_page
    // },
    "component.selectedEnterprise": function() {
      this.getAllExchange()
    }
  },
  mounted(){
    this.getAllTaxOperationType()
  }
};
</script>

<style></style>
