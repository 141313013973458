var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.component.valid),callback:function ($$v) {_vm.$set(_vm.component, "valid", $$v)},expression:"component.valid"}},[_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Data Inicial das Vendas","type":"date","rules":[
          () => !!_vm.component.report.date_initial_sales || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.date_initial_sales),callback:function ($$v) {_vm.$set(_vm.component.report, "date_initial_sales", $$v)},expression:"component.report.date_initial_sales"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Data Final das Vendas","rules":[
          () => !!_vm.component.report.date_finish_sales || 'Campo Obrigatorio',
        ],"type":"date"},model:{value:(_vm.component.report.date_finish_sales),callback:function ($$v) {_vm.$set(_vm.component.report, "date_finish_sales", $$v)},expression:"component.report.date_finish_sales"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-select',{attrs:{"outlined":"","label":"Vendedor","item-value":"id","return-object":"","item-text":"people.physicalpeople.name","loader-height":"","items":_vm.component.sellers,"rules":[
          () => !!_vm.component.report.seller || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.seller),callback:function ($$v) {_vm.$set(_vm.component.report, "seller", $$v)},expression:"component.report.seller"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-select',{attrs:{"outlined":"","label":"Tipo de comissão","item-value":"comissionType","item-text":"comissionName","items":_vm.component.comissionTypes,"rules":[
          () => !!_vm.component.report.comission_type || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.comission_type),callback:function ($$v) {_vm.$set(_vm.component.report, "comission_type", $$v)},expression:"component.report.comission_type"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }