<template>
  <v-row>
    <v-dialog persistent max-width="450" v-model="model">
      <v-card>
        <v-card-title>
          <v-img src="./error_icon.png" max-width="40" class="mr-5"></v-img>
          Ops, ocorreu um erro!
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <ul>
            <li v-for="(item, i) in messagesError" :key="i">
              {{ item }}
              <!-- <ul>
                <li v-for="(value, e) in item.value" :key="e">{{ value }}</li>
              </ul> -->
            </li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeError()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
// import { RESET_MESSAGE } from "../../../store/actions/errors";
import processMessage from "../Errors/script";

export default {
  data: () => ({
    messagesError: Array(),
    model: false,
  }),
  computed: {
    ...mapGetters(["getError"]),
  },
  watch: {
    getError: function (val) {
      this.messagesError = this.getError
      this.model = true
      val
    },
  },
  methods: {
    mapError(json) {
      let data = processMessage.toProcessMessage(json);
      this.messagesError = data.build();
      this.messagesError.length === 0
        ? (this.model = false)
        : (this.model = true);
    },
    closeError() {
      this.model = false;
      // this.$store.commit(RESET_MESSAGE);
    },
  },
  created() {
    this.mapError(this.getError);
  },
};
</script>

<style>
</style>